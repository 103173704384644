import { Box } from "@mui/material"
import CommonText from "../../components/common/typographies/CommonText"
import Alert from "../../assets/Alert.svg";
import ArrowDown from "../../assets/ArrowDown.svg"
import { useState } from "react";

function NoticeButton({ label, content, style = {} }) {
    const [isExpand, setIsExpand] = useState(false)
    return (
        <Box bgcolor="rgba(255, 255, 255, 0.1)" display="flex" flexDirection="column" style={{ cursor: 'pointer', padding: '10px 15px', ...style }} onClick={() => setIsExpand(!isExpand)}>
            <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }} >
                <img src={Alert} alt="Alert" style={{ width: 20, height: 20, marginRight: 10 }} />
                <CommonText fontSize="16px" lineHeight="28px" style={{ flex: 1 }}>{label}</CommonText>
                <img src={ArrowDown} alt="ArrowDown" style={{ cursor: "pointer" }} />

            </Box>
            {
                isExpand &&
                <CommonText fontSize="16px" lineHeight="28px" style={{ flex: 1 }}>
                    <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: content }} />
                </CommonText>
            }
        </Box>

    )
}

export default NoticeButton