import { useState } from 'react'
import { Collapse, Grid, Box } from '@mui/material';
import styled from '@emotion/styled';
import CommonText from '../../components/common/typographies/CommonText';
import { breakpoint, ResponsiveUI } from "../../utils/responsive";
import Vector from '../../assets/Vector.svg';

const GreyBorderContainer = styled(Grid)`
    border: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 30px;
    text-align: left;
    background: rgba(255, 255, 255, 0.1);
`

const Header = ({ header, open }) => {
    return (
        // Heading 4 Size
        <Box pt={4} pb={4} pl={3} pr={3} display='flex' style={{ cursor: 'pointer' }}>
            <CommonText sizeType='Heading4' type='display' style={{ flex: 1 }}>{header}</CommonText>
            <CommonText sizeType='Heading4' type='display'>
            {open ? 
                <img src={Vector} alt="vector"/> 
                :
                <img style={{ transform: 'rotate(180deg)' }} src={Vector} alt="vector"/> 
            }</CommonText>
        </Box>
    )
}

function CollapsibleItem(props) {
    const { header, content, children } = props
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <GreyBorderContainer container direction='column' onClick={handleClick}>
            <Header open={open} header={header} />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box pt={2} pl={3} pr={3} pb={3} style={{ textAlign:'left', cursor: 'pointer' }}>
                    <CommonText color="#BCBCBC" sizeType="Body" type='body'>{children || content}</CommonText>
                </Box>
            </Collapse>
        </GreyBorderContainer>
    )
}

export default CollapsibleItem