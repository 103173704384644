import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/common/buttons/CustomButton";
import CommonDivider from "../../components/common/dividers/CommonDivider";
import FeedbackDialog from "./FeedbackDialog";
import { useState } from "react";
import { ResponsiveUI, WindowSize, breakpoint } from "../../utils/responsive";
import { ReactComponent as ArrowDownSvg } from "../../assets/ArrowDown.svg";
import { ReactComponent as PencilEditSvg } from "../../assets/PencilEdit.svg";
import { ReactComponent as PencilEditDisableSvg } from "../../assets/PencilEditDisable.svg";
import { useOnHoverOutside } from "../../hooks/useOnHoverOutside";
import TicketShareButton from "../../components/common/buttons/TicketShareButton";
import { useWebShare } from "../../hooks/useWebShare";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import BlackButton from "../../components/common/buttons/BlackButton";

const TicketView = ({ ticketData, feedbackOnClick }) => {
  const hoverRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const { t, i18n } = useTranslation("myTicket");
  const { onWebShareClick } = useWebShare();
  //TODO: submit value from api
  const submitted = false;
  const dayPast = dayjs().diff(dayjs(ticketData.streamEnd), 'days');
  const disableFeedback = dayPast > 14;

  // hover event handler
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  useOnHoverOutside(hoverRef, handleMouseOut);

  const titleZh = useMemo(() => {
    if (ticketData?.event?.sponsorZh)
      return (
        ticketData?.event?.sponsorZh + " " + ticketData?.event?.eventNameZh
      );
    return ticketData && ticketData?.event?.eventNameZh;
  }, [ticketData, ticketData?.event]);

  const titleEn = useMemo(() => {
    if (ticketData?.event?.sponsorEn)
      return (
        ticketData?.event?.sponsorEn + " " + ticketData?.event?.eventNameEn
      );
    return ticketData && ticketData?.event?.eventNameEn;
  }, [ticketData, ticketData?.event]);

  const ticketImageUrl = useMemo(() => {
    return i18n.language === "zh"
      ? ticketData.displayImageZh
      : ticketData.displayImageEn;
  }, [i18n?.language]);

  const isMobileScreen = WindowSize() === "mobile";

  return (
    <Styled.TicketWrapper ref={hoverRef}>
      <TicketImage
        onMouseOver={handleMouseOver}
        src={ticketImageUrl}
        applyFilter={!isMobileScreen && isHovering}
      />
      {isMobileScreen && (
        <Styled.MobileFooter>
          {
            disableFeedback?
            <BlackButton disabled 
              style={{
                width: "180px",
                display: "flex",
                alignItem: "center",
                gap: "8.45px",
                backgroundColor: "rgba(255, 255, 255, 0.1)"
              }}>
              {t("feedback")}
              <PencilEditDisableSvg />
            </BlackButton>:
            <CustomButton
              style={{
                width: "180px",
                display: "flex",
                alignItem: "center",
                gap: "8.45px",
              }}
              background="#1F1F21"
              hoverBackgroundColor="#000"
              onClick={feedbackOnClick}
            >
              {t("feedback")}
              <PencilEditSvg />
            </CustomButton>
          }
          <TicketShareButton
            onClick={() =>
              onWebShareClick(
                i18n.language === "zh"
                  ? ticketData.downloadImageZh
                  : ticketData.downloadImageEn,
                `${ticketData?.product ?? "ticket"}-${i18n.language}`
              )
            }
          />
        </Styled.MobileFooter>
      )}
      {!isMobileScreen && (
        <div
          className={"past-ticket-overlay"}
          style={{ visibility: isHovering ? "visible" : "hidden", opacity: isHovering ? 1 : 0 }}
        >
          <CommonText
            fontSize="17px"
            fontWeight="500"
            lineHeight="1.2"
            textAlign="center"
          >
            {i18n.language === "zh" ? titleZh : titleEn}
          </CommonText>
          {submitted === true ? (
            <Styled.FeedbackSentText>
              <CommonText background="#1F1F21" color="#FFFFFF">
                {t("feedbackSent")}
              </CommonText>
            </Styled.FeedbackSentText>
          ) : (
            disableFeedback?
            <BlackButton disabled 
              style={{
                fontSize: "16px",
                fontWeight: "500",
                width: "100%",
                display: "flex",
                alignItem: "center",
                gap: "8.45px",
                backgroundColor: "rgba(27, 27, 27, 1)"
              }}>
              {t("feedback")}
              <PencilEditDisableSvg />
            </BlackButton>:
            <CustomButton
              style={{
                fontSize: "16px",
                fontWeight: "500",
                width: "100%",
                display: "flex",
                alignItem: "center",
                gap: "8.45px",
              }}
              background="#1F1F21"
              hoverBackgroundColor="#000"
              onClick={feedbackOnClick}
            >
              {t("feedback")}
              <PencilEditSvg />
            </CustomButton>
          )}
          <TicketShareButton
            style={{ position: "absolute", top: "16px", right: "0px" }}
            onClick={() =>
              onWebShareClick(
                i18n.language === "zh"
                  ? ticketData.downloadImageZh
                  : ticketData.downloadImageEn,
                `${ticketData?.product ?? "ticket"}-${i18n.language}`
              )
            }
          />
        </div>
      )}
    </Styled.TicketWrapper>
  );
};

const displaySizePerPage = {
  mobile: 2,
  desktop: 4,
};

const PastTicketList = ({ withOngoing, data }) => {
  const { t } = useTranslation("myTicket");
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  const feedbackOnClick = (id) => {
    setSelectedId(id);
  };

  const pageSize =
    pageNum *
    (useMediaQuery(`(min-width: 1320px)`)
      ? displaySizePerPage.desktop
      : displaySizePerPage.mobile);

  const TicketListRender = data
    .slice(0, pageSize)
    .map((item, index) => (
      <TicketView
        key={item._id}
        ticketData={item}
        feedbackOnClick={() => feedbackOnClick(index)}
      />
    ));
  return (
    <Styled.ComponentWrapper withOngoing={withOngoing}>
      { withOngoing && <Styled.BackgroundCircle /> }
      <Styled.FlexColumnWrapper>
        <Styled.GridTitleDiv>
          <CommonText fontSize="22px" fontWeight="400">
            {t("past")}
          </CommonText>
        </Styled.GridTitleDiv>
        <Styled.TicketListGrid gridSize={TicketListRender.length}>
          {
            <ResponsiveUI
              mobile={TicketListRender.reduce(
                (acc, x, idx) =>
                  acc === null
                    ? [x]
                    : [
                        acc,
                        <CommonDivider
                          color="rgba(255, 255, 255, 0.5)"
                          key={"divider_" + idx}
                        />,
                        x,
                      ],
                null
              )}
              desktop={TicketListRender}
            />
          }
        </Styled.TicketListGrid>

        {data && data?.length > pageSize ? (
          <Styled.LoadMoreButtonWrapper>
            <Styled.LoadMoreButton
            onClick={() => setPageNum((prev) => prev + 1)}
            >
              <CommonText fontSize="16px" fontWeight="500" line-height="100%">
                {t("viewMore")}
              </CommonText>
              <ArrowDownSvg />
            </Styled.LoadMoreButton>
          </Styled.LoadMoreButtonWrapper>
        ) : null}
        {
          <FeedbackDialog
            setSelectedId={setSelectedId}
            visible={selectedId !== null}
            data={data && data[selectedId]}
          />
        }
      </Styled.FlexColumnWrapper>
    </Styled.ComponentWrapper>
  );
};

const LoadMoreButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 32px; // 64px - 32px(gap)

  @media ${breakpoint.mobile} {
    margin-top: 8px; // 40px - 32px(gap)
  }
`;

const LoadMoreButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const BackgroundCircle = styled.div`
  position: absolute;
  top: 0;
  width: 140vw;
  min-width: 2000px;
  height: 800px;
  left: 50%;
  transform: translateX(-50%);

  background: linear-gradient(180deg, #1e1e20 0%, rgba(30, 30, 32, 0) 100%);
  border-radius: 50% 50% 0 0;
  z-index: 0;
`;

const ComponentWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  overflow: hidden;
  justify-content: center;
  @media ${breakpoint.desktop} {
    padding-top: ${props => props.withOngoing?"150px":"0px"};
  }
  @media ${breakpoint.mobile} {
    padding-top: ${props => props.withOngoing?"100px":"0px"};
  }
`;

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 80px;
  z-index: 999;
  width: 600px;
  @media (min-width: 1320px) {
    width: 1200px;
  }
  @media ${breakpoint.mobile} {
    width: 300px;
    align-items: center;
  }
`;

const GridTitleDiv = styled.div`
  flex-basis: 100%;
  z-index: 2;
`;

const TicketListGrid = styled.div`
  display: grid;
  width: unset;
  row-gap: 40px;
  column-gap: 21px;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 1320px) {
    grid-template-columns: repeat(
      ${(props) => Math.min(props.gridSize, 4)},
      auto
    );
  }

  @media ${breakpoint.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
  }
`;

const TicketWrapper = styled.div`
  position: relative;
  display: block;
  /* flex: 0 0 20%; */

  & .past-ticket-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 23.68px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 23.66px;
    transition: opacity 0.3s ease-in-out;
  }

  & .share-btn {
    position: absolute;
    top: 39.32px;
    right: 24.22px;
  }
`;

const FeedbackSentText = styled.div`
  height: 48px;
`;

const MobileFooter = styled.div`
  width: 280px;
  margin-top: 24px; // 40px - 16px (gap) = 24px
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TicketImage = styled.img`
  width: 280px;
  height: 560px;
  transition: filter 0.3s ease-in-out;
  filter: ${(props) =>
    props.applyFilter ? "brightness(0.2)" : "brightness(1)"};
  @media ${breakpoint.mobile} {
    width: 300px;
  }
`;

const Styled = {
  ComponentWrapper,
  LoadMoreButtonWrapper,
  LoadMoreButton,
  BackgroundCircle,
  FlexColumnWrapper,
  GridTitleDiv,
  TicketListGrid,
  TicketWrapper,
  FeedbackSentText,
  MobileFooter,
};

export default PastTicketList;
