import styled from 'styled-components';
import CommonText from "../../components/common/typographies/CommonText";
import { breakpoint } from "../../utils/responsive";
import WhiteVector from "../../assets/Vector.svg";
import dayjs from "dayjs";
import orderStatus from "../../utils/orderStatus";
import dateString from '../../utils/dateString';

function Order({t, language, data, setOrder}) {
    return (
        // Should set order number on click.
        <Container onClick={() => setOrder(data.id)}>
            <TitleDiv>
                <OrderDiv>
                    <CommonText lineHeight="34px" fontWeight="500" type="display" sizeType="Heading3">{t("order")}</CommonText>
                    <CommonText lineHeight="28px" fontWeight="700" type="title" sizeType="Heading4">#{data.id}</CommonText>
                </OrderDiv>
                <img style={{ transform:'rotate(90deg'}} src={WhiteVector} alt="next"></img>
            </TitleDiv>
            <InfoDiv>
                <CommonText color="#BCBCBC" fontWeight="400" type="body" sizeType="Body"> {dateString(data.createdAt, language)} | {data.items.length} {t("item")} |</CommonText>
                <pre> </pre>
                <CommonText color="#F44434" fontWeight="400" type="body" sizeType="Body">{orderStatus(data.status)}</CommonText>
            </InfoDiv>
            {/* <ImgDiv>
                <img style={{ width:60, height: 60, marginRight:'15px', border:'1px solid red'}} src={"hi"} alt="image"></img>
                <img style={{ width:60, height: 60, marginRight:'15px', border:'1px solid red'}} src={"hi"} alt="image"></img>
                <img style={{ width:60, height: 60, marginRight:'15px', border:'1px solid red'}} src={"hi"} alt="image"></img>
            </ImgDiv> */}
        </Container>
    )
}

export default Order;

const Container = styled.div`
    padding: 15px;
    @media ${breakpoint.desktop} {
        padding: 30px;
    }
    @media ${breakpoint.largeDesktop} {
        padding: 30px 0px;
    }
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
`

const TitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
`

const OrderDiv = styled.div`
    display: flex;
    align-items: center;
`


const InfoDiv = styled.div`
    display: flex;
    align-items: center;
`

const ImgDiv = styled.div`
    display: flex;
    margin-top: 15px; 
    margin-bottom: 15px;
`