import Dialog from '@mui/material/Dialog';
import styled from "styled-components";
import { useState } from 'react';
import RootSignin from './RootSignin';
import PasswordSignin from './PasswordSignin';
import ForgotPassword from './ForgotPassword';
import { GoogleOAuthProvider } from '@react-oauth/google';
import constants from '../../constants';
import CloseButton from '../common/buttons/CloseButton';
import { breakpoint } from "../../utils/responsive";

export default function SignInPopup({ handleClose, open }) {
    const [page, setPage] = useState('root');
    const handlePageChange = (page, params) => {
        setPage(page)
    }
    const renderPage = () => {
        switch (page) {
            case "root":
                return <RootSignin handlePageChange={handlePageChange} handleClose={handleClose} />
            case "password":
                return <PasswordSignin handlePageChange={handlePageChange} handleClose={handleClose} />
            case "forgotpassword":
                return <ForgotPassword handlePageChange={handlePageChange} />
            default:
                return <RootSignin handlePageChange={handlePageChange} />
        }
    }
    return <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        PaperProps={{
            style: {
                margin: '10px',
                width: '100%',
                maxWidth: 425
            }
        }}>
        <GoogleOAuthProvider clientId={constants.GOOGLE_ID}>
            <Container>
                {renderPage()}
            </Container>
            <CloseButton onClick={handleClose} />
        </GoogleOAuthProvider>
    </Dialog>
}

const Container = styled.div`
        width: 100%;
        box-sizing: border-box;
        @media ${breakpoint.mobile} {
            padding: 15px;
    }
        @media ${breakpoint.desktop} {
            padding: 30px;
    }
        @media ${breakpoint.largeDesktop} {
            padding: 30px;
    }
        `