import CommonText from "../components/common/typographies/CommonText";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
export const StreamDateConverter = ({ i18n, streams, displayLimit = 0 }) => {
  const getLocaleTime = (stream, idx) => {
    return (
      <div>
        {streams.length !== idx + 1 &&
          streams.length > 1 &&
          ((displayLimit > 0 && idx < displayLimit - 1) ||
            displayLimit === 0) && (
            <CommonText
              mFontsize="18px"
              fontSize="20px"
              lFontSize="23px"
              lineHeight="27px"
              fontWeight="500"
              style={{ margin: "0 5px" }}
            >
              /
            </CommonText>
          )}
        {streams.length === idx + 1 && (
          <div
            style={{ display: "flex", alignItems: "flex-end", marginLeft: 10 }}
          >
            <CommonText
              mFontsize="18px"
              fontSize="20px"
              lFontSize="23px"
              lingHeight="27px"
              fontWeight="500"
            >
              {dayjs(stream.streamStart).tz("Asia/Hong_Kong").format("HH:mm")}
            </CommonText>
            <CommonText fontSize="14px" mFontSize="12px" lineHeight="21px">
              (HKT)
            </CommonText>
          </div>
        )}
      </div>
    );
  };

  if (i18n.language === "zh") {
    return (
      <div style={{ display: "flex", flexWrap: 'wrap' }}>
        {streams &&
          streams.map((stream, idx) => {
            return (
              <div style={{ display: "flex" }}>
                {(displayLimit > 0 && idx < displayLimit) ||
                displayLimit === 0 ? (
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <CommonText
                      mFontsize="18px"
                      fontSize="20px"
                      lFontSize="23px"
                      lineHeight="27px"
                      fontWeight="500"
                    >
                      {dayjs(stream.streamStart).tz("Asia/Hong_Kong").format("M")}
                    </CommonText>
                    <CommonText
                      fontSize="12px"
                      mFontSize="11px"
                      lineHeight="22px"
                    >
                      月
                    </CommonText>
                    <CommonText
                      mFontsize="18px"
                      fontSize="20px"
                      lFontSize="23px"
                      lineHeight="27px"
                      fontWeight="500"
                    >
                      {dayjs(stream.streamStart).tz("Asia/Hong_Kong").format("D")}
                    </CommonText>
                    <CommonText
                      fontSize="12px"
                      mFontSize="11px"
                      lineHeight="22px"
                    >
                      日
                    </CommonText>
                  </div>
                ) : (
                  streams.length === idx + 1 && (
                    <CommonText
                      mFontsize="18px"
                      fontSize="20px"
                      lFontSize="23px"
                      lineHeight="27px"
                      fontWeight="500"
                    >
                      ...
                    </CommonText>
                  )
                )}
                {getLocaleTime(stream, idx)}
              </div>
            );
          })}
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexWrap: 'wrap' }}>
        {streams &&
          streams.map((stream, idx) => {
            return (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                {(displayLimit > 0 && idx < displayLimit) ||
                displayLimit === 0 ? (
                  <CommonText
                    mFontsize="18px"
                    fontSize="20px"
                    lFontSize="23px"
                    lineHeight="27px"
                    fontWeight="500"
                  >
                    {dayjs(stream.streamStart).tz("Asia/Hong_Kong").format("D MMM")}
                  </CommonText>
                ) : (
                  streams.length === idx + 1 && (
                    <CommonText
                      mFontsize="18px"
                      fontSize="20px"
                      lFontSize="23px"
                      lineHeight="27px"
                      fontWeight="500"
                    >
                      ...
                    </CommonText>
                  )
                )}
                {getLocaleTime(stream, idx)}
              </div>
            );
          })}
      </div>
    );
  }
};
