import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import Vector from '../../assets/Vector.svg';
import { Link } from "react-router-dom";
import { Box } from '@mui/material'
import MyAccountInfo from "../../assets/MyAccountInfo.svg";
import MyAccountOrder from "../../assets/MyAccountOrder.svg";
import { breakpoint, WindowSize } from "../../utils/responsive";

export default function LinkMenu({ itemId, url, text, selected, ...props }) {
    let isLargeDesktop = WindowSize() === "largeDesktop";
    let icon = null;
    switch(itemId){
        case "myorder":
            icon = MyAccountOrder;
            break;
        case "managePersonalInfo":
            icon = MyAccountInfo;
            break;
        default:
            icon = null;
    }
    return [
        <Link style={{width:'100%'}} to={url} {...props}>
            <StyledDiv bgcolor={selected ? '#F44434' : '#000'}>
                {   
                    // isLargeDesktop &&
                    <ViewDetailButtonIconDiv>
                        <ViewDetailButtonIcon src={icon} alt={"Icon"}></ViewDetailButtonIcon>
                    </ViewDetailButtonIconDiv>
                }
                <StyledCommonText fontWeight="600" lineHeight="50px" type='body'>{text}</StyledCommonText>
                {
                    // isLargeDesktop && 
                    <ViewDetailButtonArrow src={Vector} alt="Vector" />
                }
            </StyledDiv>
        </Link>
    ]
} 

const ViewDetailButtonIconDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
`

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-right: 20px;
    min-height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    background: ${props => props.bgcolor};
`

const ViewDetailButtonIcon = styled.img`
    max-width: 32px;
    max-height: 32px;
`

const ViewDetailButtonArrow = styled.img`
    transform: rotate(90deg);
`

const StyledCommonText = styled(CommonText)`
    flex:1;
    margin-left: 18px;
    line-height: 120%;
`;