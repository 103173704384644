const colors = {
  black: "#111111",
  white: "#F9F9F9",

  black70Alpha: "#1F1F2170",

  red: "#F44434",
};

export default colors;
