import React, { useState, useEffect } from "react";
import CommonSelect from "./CommonSelect";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "../../../assets/Language.svg";
import CommonText from "../typographies/CommonText";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ResponsiveUI } from "../../../utils/responsive";
import WhiteTick from "../../../assets/WhiteTick.svg";

const LanguageSelect = ({ width }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("中文");

  useEffect(() => {
    let currentLang = localStorage.getItem("language");
    if (currentLang === "zh" || currentLang === "en") {
      i18n.changeLanguage(currentLang);
      setLang(currentLang === "zh" ? "中文" : "English");
    } else {
      localStorage.setItem("language", "zh");
      i18n.changeLanguage("zh");
      setLang("中文");
    }
  }, []);

  const handleChange = (event) => {
    let newLang = "zh";
    if (event.target.value === "English") {
      newLang = "en";
    }
    localStorage.setItem("language", newLang);
    i18n.changeLanguage(newLang);
    setLang(event.target.value);
    window.dataLayer.push({
      event: "custom_event",
      event_name: "selected_language",
      param: newLang == "en" ? "english" : "chinese",
    });
  };

  return (
    <CommonSelect
      name="lang"
      style={{ width: width || "100%" }}
      options={["中文", "English"]}
      value={lang}
      onChange={handleChange}
      openAbove
      renderValue={(value) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={LanguageIcon} style={{ marginRight: 10 }} />
          <ResponsiveUI
            largeDesktop={
              <CommonText sizeType="Caption" type="body">
                {value}
              </CommonText>
            }
            desktop={
              <CommonText sizeType="Caption" type="body">
                {value}
              </CommonText>
            }
            mobile={
              <CommonText sizeType="Caption" type="body">
                {value}
              </CommonText>
            }
          />
        </div>
      )}
    >
      <MenuItem value={"中文"}>
        <MenuLabel>
          <ResponsiveUI
            largeDesktop={
              <CommonText sizeType="Caption" type="body">
                中文
              </CommonText>
            }
            desktop={
              <CommonText sizeType="Caption" type="body">
                中文
              </CommonText>
            }
            mobile={
              <CommonText sizeType="Caption" type="body">
                中文
              </CommonText>
            }
          />
          {lang == "中文" ? <Tick src={WhiteTick} /> : null}
        </MenuLabel>
      </MenuItem>
      <MenuItem value={"English"}>
        <MenuLabel>
          <ResponsiveUI
            largeDesktop={
              <CommonText sizeType="Body" type="body">
                English
              </CommonText>
            }
            desktop={
              <CommonText sizeType="Caption" type="body">
                English
              </CommonText>
            }
            mobile={
              <CommonText sizeType="Caption" type="body">
                English
              </CommonText>
            }
          />
          {lang == "English" ? <Tick src={WhiteTick} /> : null}
        </MenuLabel>
      </MenuItem>
    </CommonSelect>
  );
};

const MenuLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Tick = styled.img`
  width: ${(props) => props.size || "15px"};
  height: ${(props) => props.size || "11px"};
`;

export default LanguageSelect;
