import { useRef, useState, useContext } from "react";
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import FormTextField from '../../components/common/textfields/FormTextField';
import LanguageToggleButton from '../../components/common/buttons/LanguageToggleButton';
import { AppContext } from "../../AppContext";
import PhoneTextfield from "../../components/common/textfields/PhoneTextField";
import CustomButton from "../../components/common/buttons/CustomButton";
import TickBlue from "../../assets/TickBlue.svg";
import CheckBox from "../../components/common/checkboxes/Checkbox";
import { breakpoint } from "../../utils/responsive";
import { updateProfile } from "../../apis"; 
import { TitleIconButtonDialog } from "../../components/common/dialogs/TitleIconButtonDialog";
import ExclamationRoundOrange from '../../components/icons/ExclamationRoundOrange';
import SuccessRoundOrange from '../../components/icons/SuccessRoundOrange';

export default function ManageProfileInfoForm() {
    const { t } = useTranslation('myAccount');
    const { user, setUser } = useContext(AppContext);
    const [language, setLanguage] = useState(user?.language || null);
    const [phoneNumber, setPhoneNumber] = useState(user?.phone || null);
    const [promotion, setPromotion] = useState(user?.promotion || null);
    const [username, setUsername] = useState(user?.username || null);
    const [smsSent, setSmsSent] = useState(false);
    const [failDialogOpen, setFailDialog] = useState(false);
    const [successDialogOpen, setSuccessDialog] = useState(false);
    const [error, setError] = useState({ code:"", title:"", body:""});
    const phoneOTPRef = useRef();
    let handleSave = async()=>{
        let body = {};
        if(language){
            body.language = language;
        }
        if(promotion !== null){
            body.promotion = promotion;
        }
        if(username != user.username){
            body.username = username;
        }
        if(phoneNumber != user.phone){
            body.phone = phoneNumber;
            body.phoneOTP = phoneOTPRef.current.value;
        }
        let result = await updateProfile(body);
        if (result.success) {
            setUser(result.data);
            setSuccessDialog(true);
            setSmsSent(false);
            // window.dataLayer.push({
            //     event: "custom_event",
            //     event_name: "user_info_update",
            // })
        } else {
            // set api error
            if (result.error?.code == "C0004") {
                setError({ code:"C0004", title:t("managePersonalInfo.updatePhoneFailed"), body:t('managePersonalInfo.used_phone')})
                setFailDialog(true);
            } else if (result.error?.code == "C0005") {
                setError({ code:"C0005", title:t("managePersonalInfo.updatePhoneFailed"), body: t('managePersonalInfo.invalid_phone_otp') })
            }
        }
    }

    return (
        <StyledMainDiv>
            <StyledTitleDiv>
                <CommonText fontWeight='600' sizeType="Heading2" type='display'>{t(`managePersonalInfo.title`)}</CommonText>
                {/* MANAGE PROFILE INFO FORM */}
            </StyledTitleDiv>

            <StyledDetailDiv>
                <StyledItemDiv>
                    <CommonText style={{color:'rgba(255, 255, 255, 0.8)', marginBottom:8}} fontWeight='400' sizeType="Caption" type='body'>{t(`managePersonalInfo.email`)}</CommonText>
                    <CommonText fontSize="16px" fontWeight='400' sizeType="Body" type='body'>{user?.email}</CommonText>
                </StyledItemDiv>

                <StyledItemDiv>
                    <CommonText style={{color:'rgba(255, 255, 255, 0.8)', marginBottom:8}} fontWeight='400' sizeType="Caption" type='body'>{t(`managePersonalInfo.emailLanguage`)}</CommonText>
                    <LanguageToggleButton value={language} onChange={(event) => setLanguage(event.target.value)} />
                </StyledItemDiv>

                <StyledItemDiv>
                    <CheckBox
                        onClick={() => setPromotion(!promotion)}
                        isChecked={promotion}
                    >
                    <Trans
                        t={t}
                        i18nKey="managePersonalInfo.agreement"
                        components={[
                            <Link
                                target="_blank"
                                style={{ textDecoration: 'underline' }}
                                key={0}
                                to="/private-policy?id=PICS"
                                rel="noreferrer"
                            >
                                個人資料收集聲明
                            </Link>,
                        ]}
                    />
                    </CheckBox>
                </StyledItemDiv>

                <StyledItemDiv>
                    <CommonText style={{color:'rgba(255, 255, 255, 0.8)', marginBottom:8}} fontSize="14px" fontWeight='400' sizeType="Caption" type='body'>{t(`managePersonalInfo.username`)}</CommonText>
                    <FormTextField value={username} onChange={(e) => setUsername(e.target.value)}/>
                </StyledItemDiv>

                <StyledItemDiv>
                    <StyledPhoneLabelDiv>
                        <CommonText style={{color:'rgba(255, 255, 255, 0.8)', marginBottom:8}} fontSize="14px" fontWeight='400' sizeType="Caption" type='body'>{t(`managePersonalInfo.mobile`)}</CommonText>
                        <div style={{ display:'flex', alignItems: 'center'}}>
                            <CommonText style={{color:'rgba(66, 133, 244, 1)', marginRight:5, display: (!user.phone || (user.phone != phoneNumber) ? 'none':'block') }} fontWeight='400' sizeType="Caption" type='body'>{t(`managePersonalInfo.verified`)}</CommonText>
                            <img style={{display: (!user.phone || (user.phone != phoneNumber) ? 'none':'block') }} src={TickBlue} alt="tick" />
                        </div>
                    </StyledPhoneLabelDiv>
                    <PhoneTextfield helperText={user.phone != phoneNumber ? smsSent? t('managePersonalInfo.smsSent') : t('managePersonalInfo.descUnderInput') : '' } autoComplete="new-password" value={phoneNumber} onChange={(value) => setPhoneNumber(value)} requestOTP={user.phone != phoneNumber}/>
                    { user.phone != phoneNumber && 
                        <div>
                            <Space></Space>
                            <CommonText style={{color:'rgba(255, 255, 255, 0.8)', marginBottom:8}} fontSize="14px" fontWeight='400' sizeType="Caption" type='body'>{t(`managePersonalInfo.otp`)}</CommonText>
                            <FormTextField placeholder={t('managePersonalInfo.otpPlaceholder')} errorText={error?.body} inputRef={phoneOTPRef} />
                        </div>
                    }
                </StyledItemDiv>

                <StyledCustomButton onClick={handleSave}>
                    <CommonText fontWeight='600' sizeType="Body" type='body'>{t(`managePersonalInfo.save`)}</CommonText>
                </StyledCustomButton>

                <TitleIconButtonDialog visible={failDialogOpen} setVisible={setFailDialog} title={error.title} body={error.body} ImgComp={ExclamationRoundOrange} buttonText={t("common.ok")}/>
                <TitleIconButtonDialog visible={successDialogOpen} setVisible={setSuccessDialog} title={t("managePersonalInfo.updateSuccess")} body={""} ImgComp={SuccessRoundOrange} buttonText={t("common.ok")}/>
            </StyledDetailDiv>
        </StyledMainDiv>
)}

const StyledMainDiv = styled.div`
    @media ${breakpoint.mobile} {
        padding: 38px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 31px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        display: flex;
        padding: 60px 90px 60px 60px;
    }
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    text-align: left;
`;

const StyledTitleDiv = styled.div`
    @media ${breakpoint.mobile} {
        margin-bottom: 23px;
    }
    @media ${breakpoint.desktop} {
        margin-bottom: 23px;
    }
    width: 100%;
    max-width: 273px;
`;

const StyledDetailDiv = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledItemDiv = styled.div`
    margin-bottom: 15px;
`;

const StyledPhoneLabelDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Space = styled.div`
    height: 16px;
`;

const StyledCustomButton = styled(CustomButton)`
    &&{
        height: 44px;
        width: 100%;
        background: rgba(31, 31, 33, 1);
    }
`;