import styled from "styled-components";
import CommonButton from "../common/buttons/CommonButton";
import TextButton from "../common/buttons/TextButton";
import Space from "../Space";
import CommonText from "../common/typographies/CommonText";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FacebookSignin from "./FacebookSignin";
import GoogleSignin from "./GoogleSignin";
import AppleSignin from "./AppleSignin";
import PasswordIcon from "../../assets/Signin.svg";
import Logo from '../../assets/Logo.svg';

export default function RootSignin({ handlePageChange, handleClose }) {
  const { t } = useTranslation("signin");
  return (
    <Container>
      <img src={Logo} alt="Logo" />
      <Space size="39px" />
      <CommonButton
        style={{ width: "100%" }}
        size="large"
        variant="contained"
        startIcon={<img src={PasswordIcon} />}
        onClick={() => handlePageChange("password")}>
        {t('password_signin')}
      </CommonButton>
      <Space size="15px" />
      <AppleSignin handlePageChange={handlePageChange} handleClose={handleClose} />
      <Space size="15px" />
      <FacebookSignin login handlePageChange={handlePageChange} handleClose={handleClose} />
      <Space size="15px" />
      <GoogleSignin login handlePageChange={handlePageChange} handleClose={handleClose} />
      <Space size="15px" />
      <CommonText style={{ color: 'rgba(188, 188, 188, 1)', textAlign: 'center' }} fontSize="14px" lineHeight="20px">
        <Trans
          t={t}
          i18nKey="register_terms"
          components={[
            <Link style={{ textDecoration: 'underline' }} key={1} target="_blank" to="/private-policy?id=PICS" />
          ]}
        />
      </CommonText>
      <Space size="30px" />
      <CommonText fontSize="16px" lineHeight="28px">
        <Trans
          t={t}
          i18nKey="register"
          components={[
            <Link style={{ color: '#F44434', textDecoration: 'none' }} key={0} to="/register" onClick={handleClose} />,
          ]}
        />
      </CommonText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
