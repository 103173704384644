import styled from 'styled-components';
import { useEffect, useMemo } from "react";
import { Grid } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import CollapsibleItem from '../pageComponents/faq/collapsibleItem';
import CommonText from '../components/common/typographies/CommonText';
import MenuItem from '../components/common/menuItems/CommonMenuItem';
import { breakpoint, ResponsiveUI } from "../utils/responsive";
import CommonDivider from "../components/common/dividers/CommonDivider";
import CustomButton from '../components/common/buttons/CustomButton';
import AirPlayIcon from "../assets/faq-airplay.svg";
import ChromecastIcon from "../assets/faq-chromecast.svg";
import LightStickAccessory from "../assets/faq_lightstick_accessory.png";
import LightStickBattery from "../assets/faq_lightstick_battery.png";
import RemoteAEn from "../assets/faq_graphic_remote_a_en.png"; 
import RemoteATC from "../assets/faq_graphic_remote_a_tc.png"; 
import RemoteBEn from "../assets/faq_graphic_remote_b_en.png"; 
import RemoteBTC from "../assets/faq_graphic_remote_b_tc.png"; 

const MENU_ITEMS = [
    'makealive',
    'membership',
    'order',
    'streaming',
    'lightstick'
]

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function FAQ() {
    const { t, i18n } = useTranslation('faq');
    const location = useLocation()
    const navigate = useNavigate();
    const query = useQuery();
    const currentId = query.get('id') || "";

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(() => {
        if (location.pathname === '/faq' && (!location.search || !currentId || !MENU_ITEMS.includes(currentId))) {
            navigate(`/faq?id=${MENU_ITEMS[0]}`, { replace: true })
        }
    }, [location, query])

    const MAKEALIVE_ITEMS = Object.keys(t("makealive.questions", { returnObjects: true }));
    const MEMBERSHIP_ITEMS = Object.keys(t("membership.questions", { returnObjects: true }));
    const ORDER_ITEMS = Object.keys(t("order.questions", { returnObjects: true }));
    const STREAMING_ITEMS = Object.keys(t("streaming.questions", { returnObjects: true }));
    const LIGHTSTICK_ITEMS = Object.keys(t("lightstick.questions", { returnObjects: true }));

    return (
        <Grid container direction={'column'} style={{ padding: `0px 5% 0px 5%` }}>

            <StyledTitleGrid>
                <CommonText style={{ textAlign: 'center' }} sizeType="Heading1" type='display'>{t(`landing.title`)}</CommonText>
            </StyledTitleGrid>
            <CommonDivider color={'#ffffff'} opacity={0.5}></CommonDivider>
            <div style={{ whiteSpace: "pre-line", width: '100%' }}>
                <ResponsiveUI
                    mobile={
                        <div>
                            <StyledMenuButtonDiv>
                                {
                                    MENU_ITEMS.map((itemId) => {
                                        return (
                                            <Link to={`/faq?id=${itemId}`} style={{ textDecoration: 'none', margin: '0px 10px' }} key={itemId}>
                                                <CustomButton
                                                    style={{
                                                        background: currentId === itemId ? '#F44434' : '#000',
                                                        border: '1px solid rgba(255,255,255,0.5)',
                                                        whiteSpace: "nowrap",
                                                        padding: '12px 20px'
                                                    }}
                                                >
                                                    {t(`menu.${itemId}`)}
                                                </CustomButton>
                                            </Link>
                                        )
                                    })
                                }
                            </StyledMenuButtonDiv>
                            <Grid xs={12} container direction='row' style={{ margin: '50px 0px' }}>
                                <Grid item xs={12}>
                                    {
                                        currentId === "makealive" &&
                                        <div>
                                            {
                                                MAKEALIVE_ITEMS.map((item, index) => {
                                                    return (
                                                        <CollapsibleItem header={t(`makealive.questions.question${index + 1}`)}>
                                                            <Trans
                                                                t={t}
                                                                i18nKey={`makealive.answers.answer${index + 1}`}
                                                                components={[
                                                                    <Link
                                                                        style={{ textDecoration: 'underline' }}
                                                                    >
                                                                        MakeALive
                                                                    </Link>
                                                                    ,
                                                                ]}
                                                            />
                                                        </CollapsibleItem>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        currentId === "membership" &&
                                        <div>
                                            {
                                                MEMBERSHIP_ITEMS.map((item, index) => {
                                                    return (
                                                        <CollapsibleItem header={t(`membership.questions.question${index + 1}`)}>
                                                            <Trans
                                                                t={t}
                                                                i18nKey={`membership.answers.answer${index + 1}`}
                                                                components={[
                                                                    <Link
                                                                        target="_blank"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        key={0}
                                                                        to="/aboutus"
                                                                        rel="noreferrer"
                                                                    >
                                                                        AboutUs
                                                                    </Link>
                                                                    ,
                                                                ]}
                                                            />
                                                        </CollapsibleItem>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        currentId === "order" &&
                                        <div>
                                            {
                                                ORDER_ITEMS.map((item, index) => {
                                                    return (
                                                        <CollapsibleItem header={t(`order.questions.question${index + 1}`)}>
                                                            <Trans
                                                                t={t}
                                                                i18nKey={`order.answers.answer${index + 1}`}
                                                                components={[
                                                                    <Link
                                                                        style={{ textDecoration: 'underline' }}
                                                                    >
                                                                        Order
                                                                    </Link>
                                                                    ,
                                                                ]}
                                                            />
                                                        </CollapsibleItem>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        currentId === "streaming" &&
                                        <div>
                                            {
                                                STREAMING_ITEMS.map((item, index) => {
                                                    return (
                                                        <CollapsibleItem header={t(`streaming.questions.question${index + 1}`)}>
                                                            <Trans
                                                                t={t}
                                                                i18nKey={`streaming.answers.answer${index + 1}`}
                                                                components={[
                                                                    <Link
                                                                        target="_blank"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        key={0}
                                                                        to="/howtowatch"
                                                                        rel="noreferrer"
                                                                    />,
                                                                    <a
                                                                        target="_blank"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        key={1}
                                                                        href="https://support.google.com/chromecast/answer/3212934"
                                                                        rel="noreferrer"
                                                                    />,
                                                                    <img src={ChromecastIcon} alt="Chromecast" style={{ width: 16, height: 16, verticalAlign: "middle"}} />,
                                                                    <a
                                                                        target="_blank"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        key={3}
                                                                        href="https://support.google.com/chromecast/answer/6006232?hl=zh-Hant&sjid=11774855207335038954-AP"
                                                                        rel="noreferrer"
                                                                    />,
                                                                    <img src={AirPlayIcon} alt="AirPlay" style={{ width: 16, height: 16, verticalAlign: "middle"}} />,
                                                                    <a
                                                                        target="_blank"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        key={5}
                                                                        href="https://support.apple.com/zh-hk/guide/tv/atvbf2be9ef7/tvos"
                                                                        rel="noreferrer"
                                                                    />,
                                                                    <img src={RemoteAEn} alt="RemoteAEn" style={{ width: "100%", maxWidth: 600}} />,
                                                                    <img src={RemoteBEn} alt="RemoteBEn" style={{ width: "100%", maxWidth: 600}} />,
                                                                    <img src={RemoteATC} alt="RemoteATC" style={{ width: "100%", maxWidth: 600}} />,
                                                                    <img src={RemoteBTC} alt="RemoteBTC" style={{ width: "100%", maxWidth: 600}} />,
                                                                    <a
                                                                        target="_blank"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        key={10}
                                                                        href="https://www.android.com/tv/"
                                                                        rel="noreferrer"
                                                                    />,
                                                                    
                                                                ]}
                                                            />
                                                        </CollapsibleItem>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        currentId === "lightstick" &&
                                        <div>
                                            {
                                                LIGHTSTICK_ITEMS.map((item, index) => {
                                                    return (
                                                        <CollapsibleItem header={t(`lightstick.questions.question${index + 1}`)}>
                                                            <Trans
                                                                t={t}
                                                                i18nKey={`lightstick.answers.answer${index + 1}`}
                                                                components={[
                                                                    <img src={LightStickBattery} alt="LightStickBattery" style={{ width: "100%", maxWidth: 600}} />,
                                                                <img src={LightStickAccessory} alt="LightStickAccessory" style={{ width: "100%", maxWidth: 600}} />
                                                                ]}
                                                            />
                                                        </CollapsibleItem>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </Grid>
                            </Grid>
                        </div>
                    }

                    desktop={
                        <Grid item xs container direction='row' alignItems="flex-start" style={{ margin: '50px 0px' }}>
                            <Grid item xs={3} style={{ position: 'sticky', top: 100 }}>
                                {
                                    MENU_ITEMS.map((itemId) => {
                                        return (
                                            <Link to={`/faq?id=${itemId}`} style={{ textDecoration: 'none' }} key={itemId}>
                                                <MenuItem
                                                    label={t(`menu.${itemId}`)}
                                                    mb={1}
                                                    selected={currentId === itemId}
                                                />
                                            </Link>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item xs style={{ marginLeft: 69 }}>
                                {
                                    currentId === "makealive" &&
                                    <div>
                                        {
                                            MAKEALIVE_ITEMS.map((item, index) => {
                                                return (
                                                    <CollapsibleItem header={t(`makealive.questions.question${index + 1}`)}>
                                                        <Trans
                                                            t={t}
                                                            i18nKey={`makealive.answers.answer${index + 1}`}
                                                            components={[
                                                                <Link
                                                                    style={{ textDecoration: 'underline' }}
                                                                >
                                                                    MakeALive
                                                                </Link>
                                                                ,
                                                            ]}
                                                        />
                                                    </CollapsibleItem>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    currentId === "membership" &&
                                    <div>
                                        {
                                            MEMBERSHIP_ITEMS.map((item, index) => {
                                                return (
                                                    <CollapsibleItem header={t(`membership.questions.question${index + 1}`)}>
                                                        <Trans
                                                            t={t}
                                                            i18nKey={`membership.answers.answer${index + 1}`}
                                                            components={[
                                                                <Link
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'underline' }}
                                                                    key={0}
                                                                    to="/aboutus"
                                                                    rel="noreferrer"
                                                                >
                                                                    AboutUs
                                                                </Link>
                                                                ,
                                                            ]}
                                                        />
                                                    </CollapsibleItem>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    currentId === "order" &&
                                    <div>
                                        {
                                            ORDER_ITEMS.map((item, index) => {
                                                return (
                                                    <CollapsibleItem header={t(`order.questions.question${index + 1}`)}>
                                                        <Trans
                                                            t={t}
                                                            i18nKey={`order.answers.answer${index + 1}`}
                                                            components={[
                                                                <Link
                                                                    style={{ textDecoration: 'underline' }}
                                                                >
                                                                    Order
                                                                </Link>
                                                                ,
                                                            ]}
                                                        />
                                                    </CollapsibleItem>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    currentId === "streaming" &&
                                    <div>
                                        {
                                            STREAMING_ITEMS.map((item, index) => {
                                                return (
                                                    <CollapsibleItem header={t(`streaming.questions.question${index + 1}`)}>
                                                        <Trans
                                                            t={t}
                                                            i18nKey={`streaming.answers.answer${index + 1}`}
                                                            components={[
                                                                <Link
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'underline' }}
                                                                    key={0}
                                                                    to="/howtowatch"
                                                                    rel="noreferrer"
                                                                />,
                                                                <a
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'underline' }}
                                                                    key={1}
                                                                    href="https://support.google.com/chromecast/answer/3212934"
                                                                    rel="noreferrer"
                                                                />,
                                                                <img src={ChromecastIcon} alt="Chromecast" style={{ width: 16, height: 16, verticalAlign: "middle"}} />,
                                                                <a
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'underline' }}
                                                                    key={3}
                                                                    href="https://support.google.com/chromecast/answer/6006232?hl=zh-Hant&sjid=11774855207335038954-AP"
                                                                    rel="noreferrer"
                                                                />,
                                                                <img src={AirPlayIcon} alt="AirPlay" style={{ width: 16, height: 16, verticalAlign: "middle"}} />,
                                                                <a
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'underline' }}
                                                                    key={5}
                                                                    href="https://support.apple.com/zh-hk/guide/tv/atvbf2be9ef7/tvos"
                                                                    rel="noreferrer"
                                                                />,
                                                                <img src={RemoteAEn} alt="RemoteAEn" style={{ width: "100%", maxWidth: 600}} />,
                                                                <img src={RemoteBEn} alt="RemoteBEn" style={{ width: "100%", maxWidth: 600}} />,
                                                                <img src={RemoteATC} alt="RemoteATC" style={{ width: "100%", maxWidth: 600}} />,
                                                                <img src={RemoteBTC} alt="RemoteBTC" style={{ width: "100%", maxWidth: 600}} />,
                                                                <a
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'underline' }}
                                                                    key={10}
                                                                    href="https://www.android.com/tv/"
                                                                    rel="noreferrer"
                                                                />,
                                                            ]}
                                                        />
                                                    </CollapsibleItem>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    currentId === "lightstick" &&
                                    <div>
                                        {
                                            LIGHTSTICK_ITEMS.map((item, index) => {
                                                return (
                                                    <CollapsibleItem header={t(`lightstick.questions.question${index + 1}`)}>
                                                        <Trans
                                                            t={t}
                                                            i18nKey={`lightstick.answers.answer${index + 1}`}
                                                            components={[
                                                                <img src={LightStickBattery} alt="LightStickBattery" style={{ width: "100%", maxWidth: 600}} />,
                                                                <img src={LightStickAccessory} alt="LightStickAccessory" style={{ width: "100%", maxWidth: 600}} />
                                                            ]}
                                                        />
                                                    </CollapsibleItem>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {/* {
                                    i18n.language === "zh" ? 
                                    <CommonText style={{ textAlign: 'center'}} type='primary'>{`*以上常見問題只供參考之用。有關詳情，請參閲適用的條款及條件 。`}</CommonText>
                                    :
                                    <CommonText style={{ textAlign: 'center' }} type='primary'>{`*This FAQ is for reference only. For details, please refer to the applicable terms and conditions. `}</CommonText>
                                }
                                    */}

                            </Grid>
                        </Grid>
                    }
                />

            </div>
        </Grid>
    )
}

const StyledTitleGrid = styled(Grid)`
    margin-top: 60px;
    margin-bottom: 60px;
    @media ${breakpoint.mobile} {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;

const StyledMenuButtonDiv = styled.div`
    margin: 30px 0px;
    width: 100%;
    overflow-x: scroll; 
    display:flex;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`;