import styled from 'styled-components';
import React from "react";
import CustomButton from '../../components/common/buttons/CustomButton';
import { breakpoint } from '../../utils/responsive';
import Space from '../../components/Space';
import { Grid, Stack } from '@mui/material';
import BlackButton from '../../components/common/buttons/BlackButton';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import ExclamationRoundOrange from '../../components/icons/ExclamationRoundOrange';
import CommonText from '../../components/common/typographies/CommonText';
import { CommonDialog } from '../../components/common/dialogs/commonDialog';
import { useTranslation } from 'react-i18next';

export function StreamingStartedDialog(props) {
  const { t, i18n } = useTranslation("shoppingCart");
  const { visible, setVisible, onClick, ...other } = props;
  const navigate = useNavigate();
  return (
    <CommonDialog
      visible={visible}
      {...other}
    >
      <StyledDiv>
        <CommonText type="display" textAlign="center" sizeType="Heading2">{t("attention")}</CommonText>
        <div style={{ margin:'15px'}}><ExclamationRoundOrange></ExclamationRoundOrange></div>
        <CommonText style={{marginBottom:"15px", whiteSpace: "pre-wrap"}} textAlign="center" color="#BCBCBC" type="body" sizeType="Body">{t("streamStarted")}</CommonText>
        <Space size="15px"></Space>
        <Grid container flexDirection="row" spacing={1}>
          <Grid item xs={6}>
            <StyledBlackButton  style={{ width: "100%" }} onClick={() => {setVisible(false);}}>
              <CommonText type="title" sizeType="Heading5">{t('cancel')}</CommonText>
            </StyledBlackButton>
          </Grid>
          <Grid item xs={6}>
            <StyledButton style={{ width: "100%" }} onClick={() => {setVisible(false); if (onClick) onClick()}}>
              <CommonText type="title" sizeType="Heading5">{t('understand')}</CommonText>
            </StyledButton>
          </Grid>
        </Grid>
      </StyledDiv>
    </CommonDialog>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #1F1F21;
  @media ${breakpoint.mobile} {
      width: 300px;
      padding: 20px;
  }
  @media ${breakpoint.desktop} {
      width: 400px;
      padding: 30px;
  }
  @media ${breakpoint.largeDesktop} {
      width: 640px;
      padding: 30px;
  }
`;

const StyledBlackButton = styled(BlackButton)`
  @media ${breakpoint.mobile} {
      width: 260px;
      height: 48px;
  }
  @media ${breakpoint.desktop} {
      width: 325px;
      height: 48px;
  }
  @media ${breakpoint.largeDesktop} {
      width: 300px;
      height: 45px;
  }
`;

const StyledButton = styled(CustomButton)`
  @media ${breakpoint.mobile} {
      width: 260px;
      height: 48px;
  }
  @media ${breakpoint.desktop} {
      width: 325px;
      height: 48px;
  }
  @media ${breakpoint.largeDesktop} {
      width: 300px;
      height: 45px;
  }
`;