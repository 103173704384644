import { Button, Divider } from "@mui/material";
import HomeBanner from "../pageComponents/home/HomeBanner";
import EventItem from "../pageComponents/home/EventItem";
import { listEvents, login, listTicket } from "../apis";
import { useQuery } from "react-query";
import Loading from "../components/Loading";
import Lottie from "react-lottie";
import * as animationData from "../assets/animation/HomeBgLine.json";
import styled, { createGlobalStyle } from "styled-components";
import { breakpoint, ResponsiveUI } from "../utils/responsive";
import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";

export default function Home() {
  const { user } = useContext(AppContext);
  const {
    isLoading: eventLoading,
    isError: eventIsError,
    data: eventData,
    error: eventError,
  } = useQuery("listEvents", () => listEvents());
  const {
    isLoading: ticketLoading,
    isError: ticketIsError,
    data: ticketData,
    error: ticketErorr,
  } = useQuery(["listTicket", user], () => listTicket(), {
    enabled: !!user,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (eventLoading || ticketLoading) {
    return <Loading />;
  }
  if (eventIsError || ticketIsError) {
    return <div>Error: {eventError?.message ?? ticketErorr?.message}</div>;
  }
  return (
    <div>
      {/* <HomeBanner /> */}
      <HeaderBackground />
      <Divider />
      <ResponsiveUI
        desktop={
          <div>
            <LottieLeftDiv>
              <Lottie options={defaultOptions} height="100vh" width="100%" />
            </LottieLeftDiv>
            <LottieRightDiv>
              <Lottie options={defaultOptions} height="100vh" width="100%" />
            </LottieRightDiv>
          </div>
        }
        mobile={
          <LottieMobileDiv>
            <Lottie options={defaultOptions} height="100%" width="100%" />
          </LottieMobileDiv>
        }
      />
      <div style={{ position: "relative" }}>
        {eventData.data &&
          eventData.data.map((event, idx) => {
            const ticket = !ticketData
              ? {}
              : ticketData?.data &&
                ticketData?.data.ongoing.find(
                  (item) => item.event._id === event._id
                );
            return <EventItem event={event} idx={idx} ticket={ticket ?? {}} />;
          })}
      </div>
    </div>
  );
}

const HeaderBackground = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 75px;
  z-index: 1;
  background-color: black;
  @media ${breakpoint.mobile} {
    height: 60px;
  }
`;

const LottieLeftDiv = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  bottom: 0%;
  width: auto;
  @media (max-width: 1024px) {
    right: 0%;
  }
`;

const LottieRightDiv = styled.div`
  position: fixed;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const LottieMobileDiv = styled.div`
  z-index: －9999;
  // position: absolute;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
`;
