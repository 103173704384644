import React from "react"
import styled from 'styled-components';
import SmallTickIcon from "../../icons/SmallTickIcon";
import CommonText from "../typographies/CommonText"

const CheckBox = (props) => {
  const { isChecked, onClick, children } = props
  return (
    <Container>
      <SmallTickIcon isChecked={isChecked} onClick={onClick} />
      {/* Ref: src/typographies.json > Checkbox */}
      <CommonText sizeType="Checkbox" style={{ marginLeft: '17px', textAlign: 'start' }}>{children}</CommonText>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export default CheckBox
