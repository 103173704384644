import React, { useContext } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import Home from './pages/Home';
import Live from "./pages/Live";
import EventDetail from "./pages/EventDetail";
import Checkout from "./pages/Checkout";
import Register from "./pages/Register";
import MyAccount from "./pages/MyAccount";
import MyTicket from "./pages/MyTicket";
import TNC from './pages/TNC';
import FAQ from './pages/FAQ';
import HowToWatch from './pages/HowToWatch';
import AboutUs from './pages/AboutUs';
import ShoppingCart from './pages/ShoppingCart';
import Loading from "./components/Loading";
import PaymentComplete from './pages/PaymentComplete';
import PrivatePolicy from "./pages/PrivatePolicy";
import { LightStickTutorial } from "makealive-component"
import TVLink from "./pages/TVLink";
import TVPermission from "./pages/TVPermission";
import TvSpec from "./pages/TvSpec";

function CustomRoutes() {
  const { init, user, signInRedirectUrl } = useContext(AppContext);
  if (!init) return <Loading />;
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/register" element={user ? <Navigate replace to={signInRedirectUrl ?? "/"} /> : <Register />} />
      <Route exact path="/event/:id" element={<EventDetail />} />
      <Route exact path="/live/:id" element={user ? <Live /> : <Navigate replace to="/" />} />
      <Route exact path="/checkout" element={<Checkout />} />
      <Route exact path="/myticket" element={user ? <MyTicket /> : <Navigate replace to="/" />} />
      <Route exact path="/myaccount" element={user ? <MyAccount /> : <Navigate replace to="/" />} />
      <Route exact path="/tnc" element={<TNC />} />
      <Route exact path="/faq" element={<FAQ />} />
      <Route exact path="/howtowatch" element={<HowToWatch />} />
      <Route exact path="/aboutus" element={<AboutUs />} />
      <Route exact path="/shoppingcart" element={<ShoppingCart />} />
      <Route exact path="/payment-complete" element={<PaymentComplete />} />
      <Route exact path="/private-policy" element={<PrivatePolicy />} />
      <Route exact path="/light-stick-user-guide" element={<LightStickTutorial />} />
      <Route exact path="/tvapp" element={<TVLink />} />
      <Route exact path="/tv-permission" element={<TVPermission />} />
      <Route exact path="/tv-spec" element={<TvSpec />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default CustomRoutes;

