import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { WindowSize, breakpoint } from "../../../utils/responsive";
import Typographies from "../../../typographies.json";
import { useTranslation } from "react-i18next";

function CommonText(props) {
  // Grouping text style
  let windowSize = WindowSize();
  const { i18n } = useTranslation("");
  let fontFamily = "";
  let fontSizeType = "";
  if (props.sizeType) {
    fontSizeType = Typographies[props.sizeType][windowSize];
  }

  // if (i18n.language === "zh") {
  //   fontFamily = "Noto Sans HK";
  // } else {
    switch (props.type) {
      case "display":
        fontFamily = "Clash Grotesk";
        break;
      case "title":
        fontFamily = "Montserrat";
        break;
      case "body":
        fontFamily = "Roboto";
        break;
      default:
        fontFamily = "Clash Grotesk";
    }
  // }

  return (
    <StyledTypography
      fontFamily={fontFamily}
      fontSize={fontSizeType}
      {...props}
    >
      {props.children}
    </StyledTypography>
  );
}

const StyledTypography = styled.div`
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  text-transform: ${(props) =>
    props.uppercase ? "uppercase" : props.capitalize ? "capitalize" : "none"};
  line-height: ${(props) => props.lineHeight};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "white")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "inherit")};

  @media ${breakpoint.largeDesktop} {
    font-size: ${(props) =>
      props.lFontSize
        ? props.lFontSize
        : props.fontSize
        ? props.fontSize
        : "16px"};
    line-height: ${(props) =>
      props.lLineHeight ? props.lLineHeight : props.lineHeight};
  }

  @media ${breakpoint.mobile} {
    font-size: ${(props) =>
      props.mFontSize
        ? props.mFontSize
        : props.fontSize
        ? props.fontSize
        : "16px"};
    line-height: ${(props) =>
      props.mLineHeight ? props.mLineHeight : props.lineHeight};
  }
`;

export default CommonText;
