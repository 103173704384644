import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint } from "../../../utils/responsive";
import CommonText from "../typographies/CommonText";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 2px;
    width: 100%;
`
const StyledToggleButton = styled(ToggleButton)`
    && {
        border-radius: 0;
        width: 50%;
        height: 53px;
        color: white;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        border: 0px solid rgba(255, 255, 255, 0.5);
        text-transform: none;
        &.Mui-selected, &.Mui-selected:hover {
            background-color: #F44434;
            color: white;
            font-weight: 600;
        }
    }
`

export default function LanguageToggleButton({ value, onChange, textTransform }) {
    const { t } = useTranslation('register');
    return (
        <div style={{ display: "flex", width: '100%' }}>
            <StyledToggleButtonGroup value={value} onChange={onChange} exclusive>
                <StyledToggleButton value="chinese" textTransform={textTransform} >
                    {t('chinese')}
                    {/* <CommonText fontWeight="600" sizeType="Body" type="body">{t('chinese')}</CommonText> */}
                </StyledToggleButton>
                <StyledToggleButton value="english" textTransform={textTransform} >
                    {t('english')}
                    {/* <CommonText fontWeight="600" sizeType="Body" type="body">{t('english')}</CommonText> */}
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        </div>
    )
}
