import { forgotPassword, resetPassword } from "../../apis";
import { useRef, useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CommonButton from "../common/buttons/CommonButton";
import PhoneTextField from "../common/textfields/PhoneTextField";
import FormTextField from "../common/textfields/FormTextField";
import EmailPhoneToggleButton from "../common/buttons/EmailPhoneToggleButton";
import CommonText from "../common/typographies/CommonText";
import SuccessIcon from "../../assets/Success.svg";
import Space from "../Space";
import constants from "../../constants";
import BackButton from "../common/buttons/BackButton";
import PasswordTextField from "../common/textfields/PasswordTextField";
export default function ForgotPassword({ handlePageChange }) {
    const { t, i18n } = useTranslation("signin")
    const [page, setPage] = useState("email");
    const [error, setError] = useState(null);
    const [phoneInput, setPhoneInput] = useState(null);
    const [resetPasswordToken, setResetPasswordToken] = useState(null);
    const emailRef = useRef();
    const phoneOTPRef = useRef();
    const passwordRef = useRef();
    const retypePasswordRef = useRef();
    const validatePhone = () => {
        let error = {};
        const validPhone = new RegExp(constants.PHONE_REGEX);
        if (!(validPhone.test(phoneInput))) error.phone = t('invalid_phone')
        setError(error);
        if (Object.keys(error).length === 0) return true;
        else return false;
    }
    const validateEmail = () => {
        let error = {};
        const validEmail = new RegExp(constants.EMAIL_REGEX);
        if (!(validEmail.test(emailRef.current.value))) error.email = t('invalid_email')
        setError(error);
        if (Object.keys(error).length === 0) return true;
        else return false;
    }
    const validatePassword = () => {
        let error = {};
        const validPassword = new RegExp(constants.PASSWORD_REGEX);
        if (!(validPassword.test(passwordRef.current.value))) error.password = t('invalid_password')
        else if (passwordRef.current.value != retypePasswordRef.current.value) error.retypePassword = t('invalid_retype_password')
        setError(error);
        if (Object.keys(error).length === 0) return true;
        else return false;
    }
    const handleResetPassword = async () => {
        if (validatePassword()){
            const result = await resetPassword(resetPasswordToken, passwordRef.current.value, retypePasswordRef.current.value);
            if (result.success) {
                setPage("resetSuccess")
            }
        }
    }
    const handleForgotPassword = async () => {
        if (page == "email" && validateEmail()) {
            const result = await forgotPassword(page, emailRef.current.value);
            if (result.success) {
                setPage("emailSuccess")
            } else {
                if (result.error?.code == "C0001") setPage("emailSuccess")
                else setError({ email: t('signin:register.invalid_email') });
            }
        } else if (page == "phone" && validatePhone()) {
            const result = await forgotPassword(page, phoneInput, phoneOTPRef.current.value);
            if (result.success) {
                setResetPasswordToken(result.data.token);
                setPage("phoneReset")
            }
        }
    }
    if (page == "email") {
        return (
            <Container>
                <CommonText fontSize="24px" lineHeight="34px">{t('forgotpassword')}</CommonText>
                <Space size="30px" />
                <CommonText style={{ width: '100%', textAlign: 'left' }} fontSize="16px" lineHeight="28px">{t('forgotpassword_desc')}</CommonText>
                <Space size="18px" />
                <EmailPhoneToggleButton value={page} onChange={(event) => setPage(event.target.value)} />
                <Space size="24px" />
                <FormTextField inputRef={emailRef} errorText={error?.email} label={t("email")} />
                <Space size="20px" />
                <CommonButton style={{ width: '100%' }} type={"primary"} onClick={handleForgotPassword}>{t('resetpassword')}</CommonButton>
                <BackButton onClick={() => handlePageChange("password")} />
            </Container>
        )
    }

    if (page == "phone") {
        return (
            <Container>
                <CommonText fontSize="24px" lineHeight="34px">{t('forgotpassword')}</CommonText>
                <Space size="30px" />
                <CommonText style={{ width: '100%', textAlign: 'left' }} fontSize="16px" lineHeight="28px">{t('forgotpassword_desc')}</CommonText>
                <Space size="18px" />
                <EmailPhoneToggleButton value={page} onChange={(event) => setPage(event.target.value)} />
                <Space size="24px" />
                <PhoneTextField onChange={(value) => setPhoneInput(value)} label={t('phone')} requestOTP />
                <FormTextField inputRef={phoneOTPRef} label={t('otp')} />
                <Space size="20px" />
                <CommonButton style={{ width: '100%' }} type={"primary"} onClick={handleForgotPassword}>{t('resetpassword')}</CommonButton>
                <BackButton onClick={() => handlePageChange("password")} />
            </Container>
        )
    }
    if (page == "phoneReset") {
        return (
            <Container>
                <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">{t('resetpassword_title')}</CommonText>
                <Space size="40px" />
                <PasswordTextField placeholder={t('newpassword_placeholder')} errorText={error?.password} inputRef={passwordRef} label={t('newpassword')} />
                <Space size="9px" />
                <CommonText style={{ width: '100%', textAlign: 'left' }} fontSize="16px" lineHeight="20px">{t('password_hint')}</CommonText>
                <Space size="20px" />
                <PasswordTextField placeholder={t('retype_newpassword_placeholder')} errorText={error?.retypePassword} inputRef={retypePasswordRef} label={t('retype_newpassword')} />
                <Space size="20px" />
                <CommonButton style={{ marginLeft: 30 }} type={"primary"} onClick={handleResetPassword}>{t('resetpassword')}</CommonButton>
                <BackButton onClick={() => handlePageChange("password")} />
            </Container>
        )
    }
    if (page == "emailSuccess")
        return (
            <Container>
                <CommonText fontWeight={600} fontSize="24px" lineHeight="30px">{t('resetpassword_link_sent')}</CommonText>
                <Space size="15px" />
                <img src={SuccessIcon} alt="Success" />
                <Space size="15px" />
                <CommonText style={{ width: 340, textAlign: 'center' }} fontSize="16px" lineHeight="28px">{t('resetpassword_check_email')}</CommonText>
                <Space size="22px" />
                <CommonButton style={{ width: '100%' }} size="large" type={"primary"} onClick={() => handlePageChange('password')}>{t('ok')}</CommonButton>
                <BackButton onClick={() => handlePageChange("password")} />
            </Container>
        )
    if (page == "resetSuccess")
        return (
            <Container>
                <CommonText style={{ textAlign: 'center' }} fontWeight={600} fontSize="30px" lineHeight="35px">{t('resetpassword_success_title')}</CommonText>
                <Space size="15px" />
                <img src={SuccessIcon} alt="Success" />
                <Space size="15px" />
                <CommonText style={{ textAlign: 'center' }} fontSize="20px" lineHeight="40px">{t('resetpassword_success_hint')}</CommonText>
                <Space size="30px" />
                <CommonButton size="large" type={"primary"} onClick={() => handlePageChange('password')}>{t('ok')}</CommonButton>
                <BackButton onClick={() => handlePageChange("password")} />
            </Container>
        )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 30px;
`

const ButtonContainer = styled.div`
    display: flex;
`