import React, { useMemo } from "react";
import { Grid, Divider } from "@mui/material";
import styled from "styled-components";
import CommonText from "../common/typographies/CommonText";
import CommonButton from "../common/buttons/CommonButton";
import BlackButton from "../common/buttons/BlackButton";
import Calendar from "../../assets/Calendar.svg";
import { ResponsiveUI, breakpoint, WindowSize } from "../../utils/responsive";
import dayjs from "dayjs";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
import { useNavigate } from "react-router-dom";
import AddToCalendarButton from "../addToCalendarButton";
import { useTranslation } from "react-i18next";
import orderStatus from "../../utils/orderStatus";
import utc from "dayjs/plugin/utc";
import dateString from "../../utils/dateString";
import { getStreamingStatus } from "./PaymentSummary";
import { useWebShare } from "../../hooks/useWebShare";

export const TicketBox = ({ data, currency, isPaymentSuccess }) => {
  const { t, i18n } = useTranslation("paymentComplete");
  const { onWebShareClick } = useWebShare();
  let navigate = useNavigate();
  dayjs.extend(utc);
  const isMobile = WindowSize() === "mobile";
  const windowSize = WindowSize();
  const productOption = data?.option || {};
  const price = (data?.option?.discountedPrice != null ? data?.option?.discountedPrice : data?.option?.price) || 0;
  const streamStatus = getStreamingStatus(
    data?.streaming?.streamStart,
    data?.streaming?.streamEnd
  );

  const ticketImageUrl = useMemo(() => {
    return i18n.language === "zh" ? data.displayImageZh : data.displayImageEn;
  }, [i18n?.language]);

  const title = useMemo(() => {
    if (i18n.language == "en") {
      if (data?.streaming?.event?.sponsorEn)
        return (
          data?.streaming?.event?.sponsorEn +
          " " +
          data?.streaming?.event?.eventNameEn
        );
      else return data?.streaming?.event?.eventNameEn;
    } else {
      if (data?.streaming?.event?.sponsorZh)
        return (
          data?.streaming?.event?.sponsorZh +
          " " +
          data?.streaming?.event?.eventNameZh
        );
      else return data?.streaming?.event?.eventNameZh;
    }
  }, [data?.streaming?.event, i18n.language]);

  const renderActionButton = () => {
    if (!isPaymentSuccess) {
      return (
        <ResponsiveCommonButton onClick={() => navigate("/checkout")}>
          {t("selectPaymentMethod")}
        </ResponsiveCommonButton>
      );
    }
    switch (streamStatus) {
      // case "end": {
      //   return (
      //     <ResponsiveBlackButton
      //         style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
      //         onClick={() => {
      //           if (data?.streaming?.event?._id)
      //             navigate(`/event/${data.streaming.event._id}`);
      //         }}
      //     >
      //       {t("eventPage")}
      //     </ResponsiveBlackButton>
      //   );
      // }
      case "streaming": {
        return (
          <ResponsiveCommonButton
            onClick={() => {
              if (data?.streaming?._id){
                window.dataLayer.push({
                    event: "custom_event",
                    event_name: "button_click",
                    button_text: "Web enter live"
                })
                navigate(`/live/${data.streaming._id}`);
              }
            }}
          >
            {t("watchNow")}
          </ResponsiveCommonButton>
        );
      }
      default: return null;
    }
  };

  const renderButtonGroup = () => {
    return (
      <BoxButtonView item container direction={"column"} maxWidth="470px">
        {renderActionButton()}
        <Grid container item columnSpacing="16px">
          <Grid item xs={isPaymentSuccess?6:12}>
            <ResponsiveBlackButton
              style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
              onClick={() => {
                if (data?.streaming?.event?._id)
                  navigate(`/event/${data.streaming.event._id}`);
              }}
            >
              {t("eventPage")}
            </ResponsiveBlackButton>
          </Grid>
          {
            isPaymentSuccess &&
            <Grid item xs={6}>
              <ResponsiveBlackButton
                style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                onClick={() => {
                  onWebShareClick(
                    i18n.language === "zh"
                      ? data.downloadImageZh
                      : data.downloadImageEn,
                    `${data?.streaming?.product ?? "ticket"}-${
                      i18n.language
                    }`
                  );
                }}
              >
                {t("share")}
              </ResponsiveBlackButton>
            </Grid>
          }
        </Grid>
      </BoxButtonView>
    );
  };
  let event = {
    name: i18n.language == "en"?`Live stream - ${data.streaming?.event?.sponsorEn || ""}${data.streaming?.event?.eventNameEn}`:`網上直播 - ${data.streaming?.event?.sponsorZh || ""}${data.streaming?.event?.eventNameZh}`,
    details: `https://www.makealive.com`,
    startsAt: data.streaming?.streamStart,
    endsAt: data.streaming?.streamEnd,
  };

  return (
    <TicketBoxContainer>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          flexShrink: 0,
          width: "100%",
          padding:
            windowSize === "1024" || windowSize === "largeDesktop"
              ? "0px 32px"
              : "0",
          marginBottom: "9.51px",
        }}
      >
        <Grid item xs>
          <CommonText
            align="left"
            fontSize="18px"
            lineHeight="26px"
            fontWeight="700"
          >
            {t("onlineLiveTicket")}
          </CommonText>
        </Grid>
        {isPaymentSuccess && (
          <div style={{ cursor: "pointer", display: "flex" }}>
            <Grid item>
              <AddToCalendarButton
                event={event}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {t("addToCalendar")}
                <img src={Calendar} alt="Calendar" />
              </AddToCalendarButton>
            </Grid>
          </div>
        )}
      </Grid>
      <div className="info-box">
        <div className="ticket-image">
          <TicketImage src={ticketImageUrl} />
        </div>
        <div className="ticket-info">
          <Grid
            container
            direction="column"
            className="detail-grid"
            style={{
              backgroundColor: "#1F1F21",
              height: "100%",
              width: "100%",
              // maxWidth: isMobile ? "500px" : "100%",
              overflowWrap: "break-word",
              padding:
                windowSize === "1024" || windowSize === "largeDesktop"
                  ? "0"
                  : "0 15px",
            }}
          >
            <TicketBoxTitleHeader item container direction="row">
              <Grid item style={{ height: "auto" }}>
                <CommonText
                  type="title"
                  align="left"
                  fontSize="18px"
                  lineHeight="22px"
                  fontWeight="700"
                >
                  {title}
                </CommonText>
              </Grid>
            </TicketBoxTitleHeader>
            <Grid
              container
              item
              style={{
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <BoxLeftView item>
                <Grid container direction="row" style={{ marginBottom: 15 }}>
                  <Grid item xs>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC", whiteSpace: "nowrap" }}
                    >
                      {t("ticketType")}
                    </CommonText>
                  </Grid>
                  <Grid item>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC", whiteSpace: "nowrap" }}
                    >
                      {productOption.name[i18n.language]}
                    </CommonText>
                  </Grid>
                </Grid>

                <Grid container direction="row" style={{ marginBottom: 15 }}>
                  <Grid item xs>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC", "white-space": "nowrap" }}
                    >
                      {t("showDateAndTime")}
                    </CommonText>
                  </Grid>
                  <Grid item>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC" }}
                    >
                      {" "}
                      {dateString(
                        data?.streaming?.streamStart,
                        i18n.language
                      )}{" "}
                    </CommonText>
                  </Grid>
                </Grid>

                <Grid container direction="row" style={{ marginBottom: 15 }}>
                  <Grid item xs>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC" }}
                    >
                      {t("quantity")}
                    </CommonText>
                  </Grid>
                  <Grid item>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC" }}
                    >
                      {data.quantity}
                    </CommonText>
                  </Grid>
                </Grid>

                <Grid container direction="row" style={{ marginBottom: 15 }}>
                  <Grid item xs>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC" }}
                    >
                      {t("price")}
                    </CommonText>
                  </Grid>
                  <Grid item>
                    <CommonText
                      align="left"
                      fontSize="14px"
                      lineHeight="20px"
                      style={{ color: "#BCBCBC" }}
                    >
                      {currency && currency.replace("D", "$")}
                      {price}
                    </CommonText>
                  </Grid>
                </Grid>
              </BoxLeftView>
              {renderButtonGroup()}
            </Grid>
          </Grid>
        </div>
      </div>
    </TicketBoxContainer>
  );
};

const TicketBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 23.25px;
  margin: 0px auto 58px auto;
  padding: 0px 27px;
  width: 100%;

  @media ${breakpoint.mobile} {
    max-width: 500px;
    padding: 0px;
  }

  & .info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 23.25px;
    align-items: center;

    @media ${breakpoint.size1024} {
      height: 543px;
      flex-direction: row;
      align-items: stretch;
      gap: 30px;
    }
  }

  & .ticket-image {
    width: fit-content;
  }

  & .ticket-info {
    height: 100%;
    width: 100%;
    flex-grow: 1;
  }
`;

const TicketBoxTitleHeader = styled(Grid)`
  && {
    padding: 40px 30px;

    @media ${breakpoint.mobile} {
      padding: 30px 15px;
    }
  }
`;

const BoxLeftView = styled(Grid)`
  padding: 0 30px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  @media ${breakpoint.mobile} {
    padding: 0px 15px;
  }
`;

const BoxButtonView = styled(Grid)`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 40px 0px;
`;

const ResponsiveCommonButton = styled(CommonButton)`
  @media ${breakpoint.mobile} {
    && {
      font-size: 14px;
    }
  }
`;

const ResponsiveBlackButton = styled(BlackButton)`
  width: 100%;
  @media ${breakpoint.mobile} {
    && {
      font-size: 14px;
    }
  }
`;

const TicketImage = styled.img`
  width: 270px;
  height: 540px;
  flex-grow: 0;
`;
