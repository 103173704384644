import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as ShareSvg } from "../../../assets/Share.svg";

function TicketShareButton(props) {
  const { t, i18n } = useTranslation("myTicket");
  return (
    <Styled.ShareButton style={props.style} onClick={props.onClick}>
      <ShareSvg />
      <span>{t("share")}</span>
    </Styled.ShareButton>
  );
}

TicketShareButton.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
};

const Styled = {
  ShareButton: styled.button`
    background: none;
    border: none;
    cursor: pointer;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5.29px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding: 16px 0px;

    &:hover {
      opacity: 0.8;
    }
  `,
};

export default TicketShareButton;
