import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export default function WhiteButton(props) {

  return (
    <StyledButton {...props} >{props.children}</StyledButton>
  );
}

const StyledButton = styled(Button)`
  && {
    height: 48px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    background: #ffffff;
    &:hover {
      background: #d0d0d0;
    }
  }
`;
