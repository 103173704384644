import styled from 'styled-components';
import CommonText from '../components/common/typographies/CommonText';
import Order from '../pageComponents/myOrder/Order';
import { useTranslation } from "react-i18next";
import OrderSummary from '../pageComponents/myOrder/OrderSummary';
import { useState } from "react";
import { getOrderList } from "../apis/";
import { useInfiniteQuery, useQuery } from 'react-query';
import Loading from '../components/Loading';
import { breakpoint } from '../utils/responsive';
import EmptyOrderGraphic from '../assets/EmptyOrderGraphic.svg';
import { Pagination, Stack } from '@mui/material';
import { useEffect } from 'react';

export default function MyOrder() {
    const { t, i18n } = useTranslation('myOrder');
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [order, setOrder] = useState(null);
    const { isLoading, isError, data, error } = useQuery(["getOrderList", page], () => getOrderList(page));
    useEffect(() => {
        if (totalPage == 0 && data && data.data) {
            console.log("set total page")
            setTotalPage(Math.ceil(data.data.total/data.data.limit));
        }
    }, data);
    if(order){
        return (<OrderSummary orderNumber={order} setOrder={setOrder} t={t} />);
    }
    else{
        return(
            <OrderDiv>
                {isLoading && <Loading />}
                <TitleDiv>
                    <CommonText fontWeight={600} sizeType="Heading2" type="display">{t('myOrder')}</CommonText>
                </TitleDiv>
                {
                    data && data.data?.data?.length > 0 ? 
                    data.data?.data?.map((item, index) => {
                        return <Order key={index} t={t} language={i18n.language} data={item} setOrder={setOrder}></Order>
                    })
                    :
                    <div>
                        <img src={EmptyOrderGraphic}></img>
                        <OrderMessageDiv>
                            <CommonText fontWeight={600} sizeType="Heading5" type="display">{t('noRecord')}</CommonText>
                            <CommonText color={"rgba(255, 255, 255, 0.8)"} fontWeight={400} sizeType="Caption" type="body">{t('buyNowMessage')}</CommonText>
                        </OrderMessageDiv>
                    </div>
                }
                <PaginationDiv direction="row" justifyContent="center" alignItems="center">
                    <Pagination count={totalPage} shape="rounded" color="primary" onChange={(event, value)=>setPage(value)}/>
                </PaginationDiv>
            </OrderDiv>
        )
    }
}

const PaginationDiv = styled(Stack)`
    padding-top: 30px;
    padding-bottom: 30px;
    @media ${breakpoint.size1024} {
        padding-bottom: 0px;
    }
    .MuiPaginationItem-text {
        color: grey;
    }
`
const OrderDiv = styled.div`
    padding: 0px;
    @media ${breakpoint.size1024} {
        padding: 30px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        border-top: 1px solid rgba(255, 255, 255, 0.5)
    }
    @media ${breakpoint.largeDesktop} {
        padding: 60px;
    }
    width: 100%;
`

const SpaceDiv = styled.div`
    @media ${breakpoint.mobile} {
        height:30px;
    }
    @media ${breakpoint.desktop} {
        height:30px;
    }
    @media ${breakpoint.largeDesktop} {
        height:60px;
    }
`

const TitleDiv = styled.div`
    padding: 30px 15px;
    @media ${breakpoint.desktop} {
        padding: 30px;
    }
    @media ${breakpoint.size1024} {
        padding: 0px 30px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        padding: 0px 0px 60px;
    }
`

const OrderMessageDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
