import { useTranslation } from "react-i18next";

export default function (status){
    const { t } = useTranslation('orderStatus');
    switch(status){
        case "Completed":
            return t("confirmed");
        case "Failed":
            return t("paymentFailed");
        case "Refunded":
            return t("cancelled");
        default:
            return;
    }
}