import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { Button, Divider } from "@mui/material";
import HelpIcon from "../../assets/HelpIcon.svg";
import { useNavigate } from "react-router-dom";
import { ResponsiveUI, WindowSize, breakpoint } from "../../utils/responsive";

const TicketMenu = ({
  currentSession,
  setCurrentSession,
  withNavbar = false,
}) => {
  const { t } = useTranslation("myTicket");
  const navigate = useNavigate();

  const MenuList = [
    { name: "upcoming", label: t("upcoming") },
    { name: "past", label: t("past") },
  ];

  const _renderMobile = () => {
    return (
      <div>
        <Title>
          <CommonText fontSize="24px" fontWeight="600" textAlign="left">
            {t("myTickets")}
          </CommonText>
          <HelpButton
            src={HelpIcon}
            onClick={() => {
              navigate("/faq");
            }}
          />
        </Title>
        {withNavbar ? (
          <>
            <Divider />
            <StyledTitle>
              <MenuItemTitle onClick={() => setCurrentSession("upcoming")}>
                <CommonText
                  fontSize="16px"
                  color={currentSession === "upcoming" ? "#fff" : "#ffffff50"}
                >
                  {t("upcoming")}
                </CommonText>
              </MenuItemTitle>

              <Divider orientation="vertical" style={{ height: "initial" }} />

              <MenuItemTitle onClick={() => setCurrentSession("past")}>
                <CommonText
                  fontSize="16px"
                  color={currentSession === "past" ? "#fff" : "#ffffff50"}
                >
                  {t("past")}
                </CommonText>
              </MenuItemTitle>
            </StyledTitle>
            <Divider />
          </>
        ) : null}
      </div>
    );
  };

  const _renderDesktop = () => {
    return (
      <Title>
      <CommonText fontSize="36px" fontWeight="600" textAlign="left">
        {t("myTickets")}
      </CommonText>
      <HelpButton
        src={HelpIcon}
        onClick={() => {
          navigate("/faq");
        }}
      />
    </Title>
    );
  };

  return <ResponsiveUI desktop={_renderDesktop()} mobile={_renderMobile()} />;
};

const StyledMainDiv = styled.div`
  display: flex;
  height: auto;
  width: auto;
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 36.29px;
  margin: 43px 60px;
  width: auto;
  min-width: 240px;

  @media ${breakpoint.mobile} {
    margin: 36px 15px;
    justify-content: space-between;
  }
`;

const HelpButton = styled.img`
  cursor: pointer;
`;

const MenuItemTitle = styled.div`
  padding: 15px 50px;
  cursor: pointer;
  align-self: center;

  @media ${breakpoint.mobile} {
    padding: 0px;
  }
`;

const StyledTitle = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-evenly;
`;

export default TicketMenu;
