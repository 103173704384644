import { CircularProgress } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../assets/animation/Loading.json";
import styled from "styled-components";

export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LottieDiv>
      <Lottie options={defaultOptions} height="400px" width="400px" />
    </LottieDiv>
  );
}

const LottieDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px;
`;
