import React from "react";
import styled from "styled-components";
import { breakpoint } from "../../../utils/responsive";

const OtpBox = React.forwardRef((props, ref) => {
  const { style, ...remainProps } = props.inputProps;
  return <StyledInput ref={ref} type="text" maxLength="1" required {...remainProps} />;
});

const StyledInput = styled.input`
  & {
    flex-shrink: 0;
    font-family: Clash Grotesk;
    color: rgba(255, 255, 255);
    caret-color: #f44434;
    font-weight: 600;
    background-color: transparent;
    text-align: center;
    outline: none;
    padding: 12px 5px;
    font-size: 30px;
    line-height: 44px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1px;
    width: 56.6px;
    height: 80px;
 
    &::selection { background-color: transparent }

    @media ${breakpoint.mobile} {
      width: 47.33px;
      height: 68px;
    }
  }
  &:focus {
    border-color: rgba(255, 255, 255);
  }
`;

export default OtpBox;
