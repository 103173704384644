import './App.css';
import React, { useState } from "react";
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppProvider } from './AppContext';
import styled from "styled-components";
import Routes from './Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import Space from './components/Space';
import ConsentSnackbar from './components/common/snackbar/ConsentSnackBar';
import { TVBrowserNoticeBar } from 'makealive-component';
import BannerSnackbar from './components/common/snackbar/BannerSnackbar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#F44434',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#000000',
      paper: '#1F1F21',
    },
    divider: 'rgba(255,255,255,0.5)',
    text: {
      primary: '#FFFFFF',
      secondary: '#BCBCBC',
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppContainer>
          <BrowserRouter>
            <AppProvider>
              <TVBrowserNoticeBar/>
              <ConsentSnackbar />
              <Header />
              <BannerSnackbar />
              <Routes />
              <Footer />
            </AppProvider>
          </BrowserRouter>
        </AppContainer>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const AppContainer = styled.div`
width: 100%;
max-height: -webkit-fill-available;
text-align: center;
`;

export default App;

