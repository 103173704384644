import ExclamationMark from "../../assets/ExclamationMark.svg"
import styled from "styled-components"

export default function ExclamationRoundOrange(props) {
  return (
    <Container>
      <ExclamationMarkImg src={ExclamationMark} {...props} />
    </Container>
  )
}

const Container = styled.div`
  background: #F44434;
  display: flex;
  width: ${(props) => props.size || "53px"};
  height: ${(props) => props.size || "53px"};
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 0px 30px #F44434;
`

const ExclamationMarkImg = styled.img`
  width: ${(props) => props.size || "27px"};
  height: ${(props) => props.size || "19px"};
`
