import { useTranslation } from "react-i18next";
import HomeBannerImage from "../../assets/HomeBanner.png";
import HomeBannerStar from "../../assets/HomeBannerStar.svg";
import HomeBannerMobileImage from "../../assets/HomeBannerMobile.png";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import Space from "../../components/Space";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import { Parallax } from "react-parallax";

export default function HomeBanner() {
  const { t } = useTranslation("home");
  return (
    <Container>
      <BannerAnimation>
        <ResponsiveUI
          desktop={
            <DesktopBanner src={HomeBannerImage} />
          }
          mobile={
            <MobileBanner src={HomeBannerImage} />
          }
        />
      </BannerAnimation>

      {/* <TitleContainer>
        <TitleAnimation>
          <CommonText
            lFontSize="80px"
            lLineHeight="88px"
            fontSize="50px"
            lineHeight="55px"
            mFontSize="40px"
            mLineHeight="44px"
            textAlign="center"
            fontWeight="600"
          >
            {t("title1")}
          </CommonText>
        </TitleAnimation>
        <Space size="15px" />
        <SubTitleAnimation>
          <CommonText
            lFontSize="38px"
            lLineHeight="52px"
            fontSize="30px"
            lineHeight="42px"
            mFontSize="19px"
            mLineHeight="41px"
            textAlign="center"
            fontWeight="600"
          >
            {t("title2")}
          </CommonText>
          <CommonText
            lFontSize="38px"
            lLineHeight="52px"
            fontSize="30px"
            lineHeight="42px"
            mFontSize="19px"
            mLineHeight="41px"
            textAlign="center"
            fontWeight="600"
          >
            {t("title3")}
          </CommonText>
        </SubTitleAnimation>
      </TitleContainer> */}
      {/* <Caption>
        <CommonText
          lFontSize="38px"
          fontSize="30px"
          mFontSize="28px"
          lineHeight="42px"
        >
          {t("caption")}
        </CommonText>
        <Space horizontal />
        <StarAnimation>
          <img src={HomeBannerStar} alt="Home Banner Star" />
        </StarAnimation>
      </Caption> */}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;

  @media ${breakpoint.mobile} {
    margin-top: -60px;
  }

  @media ${breakpoint.desktop} {
    margin-top: -40px;
  }

  @media ${breakpoint.largeDesktop} {
    margin-top: -75px;
  }
`;

const DesktopBanner = styled.img`
  width: 100%;
  height: auto;
` 

const MobileBanner = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
` 

const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

const Caption = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    left: 15px;
    bottom: 15px;
  }

  @media ${breakpoint.desktop} {
    left: 60px;
    bottom: 34px;
  }
`;

const BannerAnimation = styled.div`
  animation: scaleImage 1.5s linear;

  @-moz-keyframes scaleImage {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes scaleImage {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scaleImage {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const TitleAnimation = styled.div`
  position: relative;
  bottom: -20px;
  opacity: 0;
  animation: showUp 2s ease-in-out forwards;
  @-moz-keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
  @-webkit-keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
  @keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const SubTitleAnimation = styled.div`
  position: relative;
  bottom: -20px;
  opacity: 0;
  animation: showUp 3s ease-in-out forwards;
  @-moz-keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
  @-webkit-keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
  @keyframes showUp {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const StarAnimation = styled.div`
  animation-name: spin;
  animation-duration: 7000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
