import styled from "styled-components";
import { Grid, IconButton } from "@mui/material";
import Close from "../../assets/Close.svg";
import CommonText from "../../components/common/typographies/CommonText";
import CommonFormTextField from "../../components/common/textfields/FormTextField";
import CommonFormPhoneField from "../../components/common/textfields/PhoneTextField";
import WhiteButton from "../../components/common/buttons/WhiteButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import constants from "../../constants";
import { useMemo } from "react";
import { WindowSize } from "../../utils/responsive";
import dayjs from "dayjs";

function ExclusiveValidation({ validationRules = [], basket, onClose, onSubmit }) {
  const { t, i18n } = useTranslation("miroValidation");
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState({});
  const [collapseOpen, setCollapseOpen] = useState(false);
  const isSingle = validationRules.length === 1 && validationRules[0].product.length === 1;
  const productTitles = useMemo(() => {
    return validationRules.map((rule) =>
      rule.product
        .sort((a, b) => a - b)
        .slice(0, collapseOpen ? rule.product.length : 1)
    );
  }, [validationRules, collapseOpen]);

  const handleOnClickSubmit = () => {
    setLoading(true);
    const extraParams = validationRules.reduce((acc, each, index) => {
      for (const product of each.product) {
        acc[product.optionId] = { ...value[index] };
      }
      return acc;
    }, {});
    onSubmit &&
      onSubmit({
        value: extraParams,
        onError: (submitError) => {
          try {
            const optionIdErrorMap = submitError?.error?.details?.reduce(
              (acc, option) => {
                acc[option.optionId] = option.error;
                return acc;
              },
              {}
            );
            // loop each rule and set error if there the option contain error in the response
            validationRules.forEach((each, ruleIndex) => {
              const haveErrorOptionId = each.product?.find(
                (p) => optionIdErrorMap[p?.optionId] != null
              );
              const relatedRuleOrNull =
                optionIdErrorMap[haveErrorOptionId?.optionId];
              if (haveErrorOptionId != null && relatedRuleOrNull != null) {
                let error = optionIdErrorMap[each.product[0].optionId] || relatedRuleOrNull;
                if (error.code == "E0014" && error.relatedDataId){
                  basket?.items?.forEach((product) => {
                    if (product.productId == error.relatedDataId) {
                      let message = optionIdErrorMap[each.product[0].optionId]
                            ?.localizedMessage?.[i18n.language] ??
                          relatedRuleOrNull.localizedMessage?.en ??
                          "";
                      let streamStart = dayjs(product?.streaming?.streamStart);
                      setApiError((prev) => {
                        prev[ruleIndex] = message.replace("{{month}}", i18n.language == "zh"
                            ? streamStart.get("month") + 1
                            : streamStart.format("MMMM")).replace("{{day}}", streamStart.get("date"));
                        return prev;
                      });
                    }
                  });
                } else {
                  setApiError((prev) => {
                    prev[ruleIndex] =
                      optionIdErrorMap[each.product[0].optionId]
                        ?.localizedMessage?.[i18n.language] ??
                      relatedRuleOrNull.localizedMessage?.en ??
                      "";
                    return prev;
                  });
                }
              }
            });
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        },
      });
  };

  const handleOnChange = (ruleIndex, fieldName, fieldValue) => {
    setValue({
      ...value,
      [ruleIndex]: {
        ...value[ruleIndex],
        [fieldName]: fieldValue,
      },
    });
    setApiError((prev) => {
      if (prev?.[ruleIndex] != null) prev[ruleIndex] = null;
      return prev;
    });
  };

  const isMobile = WindowSize() === "mobile";

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "max-content",
        flexWrap: "nowrap",
        paddingX: isMobile ? "15px" : "20px",
        paddingTop: isMobile ? "15px" : "20px",
        paddingBottom: "30px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{
          height: "30px",
          paddingX: isMobile ? "0px" : "10px",
          marginBottom: isSingle ? (isMobile ? "15px" : "20px") : 0,
        }}
      >
        <Grid flex={1}>
          {isSingle && (
            <CommonText
              fontSize={isMobile ? "18px" : "20px"}
              lineHeight={isMobile ? "24px" : "24.3x"}
              fontWeight="700"
            >
              {validationRules[0].title?.[i18n.language] ??
                validationRules[0].title?.en}
            </CommonText>
          )}
        </Grid>
        <img
          src={Close}
          alt="Close"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Grid>
      <Grid
        item
        xs
        container
        direction="column"
        flexWrap={"nowrap"}
        sx={{
          marginTop: isSingle ? "10px" : "2px",
        }}
      >
        {validationRules.map((rule, ruleIndex) => (
          <Grid direction={"column"}>
            {ruleIndex > 0 && (
              <Grid
                container
                height="20px"
                marginBottom="20px"
                direction={"row"}
                alignItems={"center"}
                paddingX={isMobile ? "15px" : 0}
              >
                <Separator />
                <CommonText
                  fontSize={isMobile ? "14px" : "18px"}
                  fontWeight="700"
                  lineHeight={isMobile ? "18.9px" : "24.3px"}
                  color="#FFF"
                  style={{ marginLeft: "27px", marginRight: "27px" }}
                >
                  {i18n.language === "zh" ? "及" : "and"}
                </CommonText>
                <Separator />
              </Grid>
            )}
            {!isSingle && (
              <>
                <Grid container paddingX={isMobile ? 0 : "10px"}>
                  <ProductBookmark>
                    <Grid container direction={"column"}>
                      {rule.product?.length > 1 && (
                        <Grid
                          container
                          marginBottom={"8px"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <CommonText
                            type="title"
                            fontSize={isMobile ? "12px" : "14px"}
                            lineHeight={isMobile ? "14.4px" : "19.6px"}
                            fontWeight="500"
                            color="rgba(255,255,255,0.8)"
                          >
                            {t("notice.numberOfValidateProduct", {
                              number: rule.product.length,
                            })}
                          </CommonText>
                          <IconButton
                            sx={{
                              padding: 0,
                              transform: collapseOpen ? "rotate(180deg)" : "none",
                            }}
                            onClick={() => setCollapseOpen((prev) => !prev)}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.1329 5.86299C11.3313 5.66463 11.6529 5.66463 11.8512 5.86299C12.0496 6.06135 12.0496 6.38296 11.8512 6.58132L8.29567 10.1369C8.09731 10.3352 7.7757 10.3352 7.57734 10.1369L4.02179 6.58132C3.82343 6.38296 3.82343 6.06135 4.02179 5.86299C4.22015 5.66463 4.54176 5.66463 4.74012 5.86299L7.93651 9.05938L11.1329 5.86299H11.1329Z"
                                fill="white"
                              />
                            </svg>
                          </IconButton>
                        </Grid>
                      )}
                      {productTitles[ruleIndex].map((product, productIndex) => (
                        <Grid items>
                          {productIndex > 0 && <ProductTitleDivider />}
                          <CommonText
                            fontSize={isMobile ? "12px" : "14px"}
                            lineHeight={isMobile ? "14.4px" : "19.6px"}
                            fontWeight="500"
                            color="#FFF"
                          >
                            {product.title?.[i18n.language] ?? product.title.en}
                          </CommonText>
                        </Grid>
                      ))}
                    </Grid>
                  </ProductBookmark>
                </Grid>
                <Grid
                  marginTop="12px"
                  paddingX={isMobile ? 0 : "10px"}
                  container
                  width="100%"
                  direction={"row"}
                >
                  <IndexCircle>
                    <CommonText
                      fontSize={isMobile ? "14.66px" : "16px"}
                      lineHeight={isMobile ? "18.04px" : "19.68px"}
                      fontWeight="500"
                      color="#FFF"
                    >
                      {ruleIndex + 1}
                    </CommonText>
                  </IndexCircle>
                  <CommonText
                    fontSize={isMobile ? "16px" : "18px"}
                    lineHeight={isMobile ? "21.6px" : "24.3px"}
                    fontWeight="700"
                    color="#FFF"
                    style={{ marginLeft: "10px" }}
                  >
                    {rule.title?.[i18n.language] ?? rule.title?.en}
                  </CommonText>
                </Grid>
              </>
            )}
            
            {rule.icon && (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  margin: isSingle ? "0px auto 30px" : `20px 0px 30px`,
                }}
              >
                <img src={rule.icon} alt={rule.title.en} height={70} />
              </Grid>
            )}
            <Grid
              padding={isMobile ? 0 : "10px"}
              marginTop={!isSingle && rule.icon ? "30px" : "10px"}
              item
              xs
            >
              <CommonText
                fontSize="18px"
                lineHeight="28px"
                fontWeight="700"
                style={{ marginBottom: 20 }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      rule.description?.[i18n.language] ??
                      rule?.description?.en,
                  }}
                />
              </CommonText>
              {rule?.inputFields?.map((each, fieldIndex) => (
                <Grid item style={{ marginBottom: 30 }}>
                  <CommonText
                    fontSize="14px"
                    lineHeight="20px"
                    color="#FFFFFFCC"
                    style={{ marginBottom: "5px" }}
                  >
                    {each.label?.[i18n.language] ?? each.label.en}
                  </CommonText>
                  {each.type === "phone" ? (
                    <CommonFormPhoneField
                      name={each.value}
                      onChange={(value) =>
                        handleOnChange(ruleIndex, each.value, value)
                      }
                    />
                  ) : (
                    <CommonFormTextField
                      name={each.value}
                      onChange={(e) =>
                        handleOnChange(ruleIndex, each.value, e.target.value)
                      }
                    />
                  )}
                  {apiError?.[ruleIndex] != null &&
                    fieldIndex === rule.inputFields.length - 1 && (
                      <CommonText
                        color="#D93535"
                        fontSize="14px"
                        lineHeight="20px"
                        style={{ marginTop: "10px" }}
                      >
                        {apiError?.[ruleIndex]}
                      </CommonText>
                    )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
        <Grid item paddingX={isMobile ? 0 : "10px"}>
          <WhiteButton
            fullWidth
            onClick={handleOnClickSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} style={{ marginLeft: 6 }} />
            ) : (
              t("button.confirm")
            )}
          </WhiteButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const ProductBookmark = styled.div`
  width: 100%;
  background: #ffffff1a;
  border-left: 2px solid #f44434;
  padding: 10px 12px;
`;

const ProductTitleDivider = styled.div`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 12px;
  margin-bottom: 12px;
`;

const IndexCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: grid;
  place-items: center;
  background: #f44434;
`;

const Separator = styled.div`
  flex: 1;
  border-bottom: 1px solid #ffffff1a;
`;

export default ExclusiveValidation;
