import { Grid, Divider, InputAdornment, IconButton, Box } from "@mui/material";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import VISA from "../../assets/VISA.png";
import Mastercard from "../../assets/Mastercard.png";
import JCB from "../../assets/JCB.png";
import AlipayHK from "../../assets/AlipayHK.png";
import FPS from "../../assets/FPS.png";
import PayMe from "../../assets/PayMe.png";
import UnionPay from "../../assets/UnionPay.png";
import WeChatPay from "../../assets/WeChatPay.png";
import PaymentIcons from "../../assets/PaymentMethodIcons.png";
import Tick from "../../assets/Tick.svg";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import { useState } from "react";

const LARGE_DESKTOP_BREAKPOINT = `(min-width: 1024px)`;

const PaymentMethods = ["creditCard", "recon"];

const PaymentItem = ({ disabled, label, index, t, isSelected, onClick }) => {
  const getImage = () => {
    switch (label) {
      case "creditCard": {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: 12,
              justifyContent: "end",
            }}
          >
            <PaymentIcon src={VISA} alt="VISA" />
            <PaymentIcon src={Mastercard} alt="Mastercard" />
            <PaymentIcon src={JCB} alt="JCB" />
          </div>
        );
      }

      case "recon": {
        return (
          <ResponsiveUI
            mobile={
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: 12,
                }}
              >
                <PaymentIcon src={AlipayHK} alt="AlipayHK" />
                <PaymentIcon src={WeChatPay} alt="WeChat Pay HK" />
                <PaymentIcon src={UnionPay} alt="Union Pay" />
                <div></div>
                <PaymentIcon src={PayMe} alt="PayMe from HSBC" />
                <PaymentIcon src={FPS} alt="FPS" />
              </div>
            }
            desktop={
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  gridGap: 12,
                }}
              >
                <PaymentIcon src={AlipayHK} alt="AlipayHK" />
                <PaymentIcon src={WeChatPay} alt="WeChat Pay HK" />
                <PaymentIcon src={UnionPay} alt="Union Pay" />
                <PaymentIcon src={PayMe} alt="PayMe from HSBC" />
                <PaymentIcon src={FPS} alt="FPS" />
              </div>
            }
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Container
      container
      direction="row"
      style={{
        borderTop: `${index === 0 ? 1 : 0}px solid rgba(255, 255, 255, 0.3)`,
        backgroundColor: isSelected ? "#F44434" : "#000",
        opacity: disabled ? 0.3 : 1,
      }}
      onClick={onClick}
    >
      <TickBox item container justifyContent="center" alignItems="center">
        {isSelected && <img src={Tick} alt="Tick" />}
      </TickBox>
      <Grid
        item
        xs
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: `12px 15px` }}
      >
        <CommonText
          align="left"
          mFontSize="14px"
          fontSize="16px"
          mLineHeight="150%"
          lineHeight="28px"
          fontWeight="700"
          style={{ whiteSpace: "pre-line" }}
        >
          {t(`paymentOptions.${label}`)}
        </CommonText>
        {getImage()}
      </Grid>
    </Container>
  );
};

function PaymentInfo({
  t,
  disabled,
  onSelectedPaymentMethod,
  selectedPaymentMethod,
}) {
  // const [payment, setPayment] = useState("")

  return (
    <Grid container direction="column" alignItems="flex-start">
      <CommonText
        align="left"
        fontSize="24px"
        lineHeight="34px"
        fontWeight="600"
        style={{ marginBottom: 30 }}
      >
        {t("pleaseSelectYourPaymentMethod")}
      </CommonText>
      {PaymentMethods.map((item, index) => {
        const isSelected = selectedPaymentMethod === item;
        return (
          <PaymentItem
            label={item}
            index={index}
            t={t}
            disabled={disabled}
            onClick={() => {
              // setPayment(item)
              !disabled && onSelectedPaymentMethod(item);
            }}
            isSelected={isSelected}
          />
        );
      })}
      <PaymentIconsContainer item xs container>
        <img width={193} src={PaymentIcons} alt="PaymentIcons" />
      </PaymentIconsContainer>
      {/* <Grid item style={{ marginTop: 30 }}>
                <CommonText fontSize="16px" lineHeight="28px" fontWeight="400">按下”確定及付款”表示我已經閱讀並同意 <UnderlineText>條款及細則</UnderlineText> 與 <UnderlineText>私隱政策</UnderlineText></CommonText>
            </Grid> */}
    </Grid>
  );
}

export default PaymentInfo;

const UnderlineText = styled.span`
  && {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const PaymentIconsContainer = styled(Grid)`
  && {
    margin-top: 30px;
    @media ${breakpoint.mobile} {
      align-items: center;
      justify-content: center;
    }
    @media ${breakpoint.desktop} {
      align-items: center;
      justify-content: center;
    }
    @media ${LARGE_DESKTOP_BREAKPOINT} {
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

const Container = styled(Grid)`
  && {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

const TickBox = styled(Grid)`
  && {
    width: 50px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    @media ${breakpoint.desktop} {
      width: 60px;
    }
  }
`;

const PaymentIcon = styled.img`
  width: 29px;
`;
