import styled from "styled-components";
import CommonText from "../../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { breakpoint } from "../../../utils/responsive";

const TicketTitle = ({
  poster,
  sponsorZh,
  sponsorEn,
  titleZh,
  titleEn,
  livetitle,
  subtitle,
  customTitle,
  smallPoster,
}) => {
  const { t, i18n } = useTranslation("myTicket");

  return (
    <StyledTopDiv>
      <PosterDiv smallPoster={smallPoster}>
        <Poster src={poster} smallPoster={smallPoster} />
      </PosterDiv>
      <div style={{ width: "100%" }}>
        <TitleText
          fontSize="16px"
          mFontSize="14px"
          fontWeight="700"
          textAlign="left"
        >
          {i18n.language === "zh" ? (sponsorZh || "") + titleZh : (sponsorEn || "") + titleEn}
        </TitleText>
        {livetitle && (
          <LiveText>
            <Circle />
            <CommonText fontSize="14px" mFontSize="12px" color="#f44434">
              {livetitle}
            </CommonText>
          </LiveText>
        )}
        {subtitle && (
          <CommonText
            fontSize="14px"
            mFontSize="12px"
            color="#666666"
            textAlign="left"
            style={{ whiteSpace: "pre-line" }}
          >
            {subtitle}
          </CommonText>
        )}
        {customTitle}
      </div>
    </StyledTopDiv>
  );
};

const TitleText = styled(CommonText)`
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
`;

const LiveText = styled.div`
  display: flex;
  align-items: center;
`;

const Poster = styled.img`
  object-fit: contain;
  object-position: center center;
  user-drag: none;
  display: block;
  width: 100%;
  height: 100%;
`;

const PosterDiv = styled.div`
  margin-right: 15px;
  height: auto;

  @media ${breakpoint.mobile} {
    width: 60px;
    height: 60px;
  }
  @media ${breakpoint.desktop} {
    width: ${(props) => (props.smallPoster ? "60px" : "65px")};
    height: ${(props) => (props.smallPoster ? "60px" : "65px")};
  }
`;

const StyledTopDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const Circle = styled.div`
  background-color: #f44434;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

export default TicketTitle;
