import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import { breakpoint } from "../../../utils/responsive";
import CustomButton from "../buttons/CustomButton";
import { AppContext } from '../../../AppContext';

const LOCALSTORAGE_CONSENT_ACCEPTED_EXPIRY = "ConsentPopupExpiry";
export default function ConsentSnackbar() {
  const { t } = useTranslation("cookie");
  const [openCookie, setOpenCookie] = useState(false);
  const { isApp } = useContext(AppContext);

  const handleAccept = () => {
    localStorage.setItem(
      LOCALSTORAGE_CONSENT_ACCEPTED_EXPIRY,
      Date.now() + 86400000 * 365
    );
    setOpenCookie(false);
  };

  useEffect(() => {
    const accepted = localStorage.getItem(
      LOCALSTORAGE_CONSENT_ACCEPTED_EXPIRY,
      null
    );
    if (!accepted || accepted < Date.now()) {
      setOpenCookie(true);
    }
  }, []);

  return !isApp && openCookie ? (
    <ContentContainer>
      <ConsentContent>
        {t("consent_popup_content")}
        <Link style={{ textDecoration: "underline" }} to="/private-policy?id=PICS">
          <CommonText
            mFontSize="13px"
            fontWeight={600}
            style={{ display: "inherit" }}
          >
            {t("button.learn_more_button")}
          </CommonText>
        </Link>
      </ConsentContent>

      <SpaceDiv />
      <CustomButton
        onClick={handleAccept}
        style={{
          background: "#1F1F21",
          border: "1px solid rgba(255,255,255,0.5)",
          whiteSpace: "nowrap",
          padding: "10px 20px",
        }}
      >
        {t("button.ok_button")}
      </CustomButton>
    </ContentContainer>
  ) : null;
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  height: 65px;
  width: 100%;
  background-color: #1f1f21;
  position: relative;
  z-index: 9999;
`;
const ConsentContent = styled.span`
  text-align: start;

  @media ${breakpoint.mobile} {
    font-size: 12px;
  }
`;
const SpaceDiv = styled.div`
  width: 20px;
`;
