import { Divider } from "@mui/material";
import styled from "styled-components";
import Logo from "../assets/Logo.svg";
import SigninIcon from "../assets/Signin.svg";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext";
import { useTranslation } from "react-i18next";
import CommonText from "./common/typographies/CommonText";
import ImageButton from "./common/buttons/ImageButton";
import Cart from "../assets/Cart.svg";
import { Link, useLocation } from "react-router-dom";
import { breakpoint, ResponsiveUI } from "../utils/responsive";
import RedLogo from "../assets/RedLogo.svg";
import CommonDropdown from "./common/dropdowns/CommonDropdown";
import UserSignedIn from "./icons/UserSignedIn";
import VectorOrange from "../assets/VectorOrange.svg";
import VectorWhite from "../assets/VectorWhite.svg";
import colors from "../utils/colors";
import TicketWhite from "../assets/TicketWhite.svg";
import GlowRedTicket from "./icons/GlowRedTicket";
import { checkoutCart } from "../apis";
import GeoBlocking from "./GeoBlocking"

export default function Header() {
  const { user, setUser, isApp, requestSignIn, cart } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const [currentId, setCurrentId] = useState(location.pathname || "");
  useEffect(() => {
    setCurrentId(location.pathname);
  }, [location])
  const { t } = useTranslation("header");
  if (isApp) return null;
  return (
  <StyledMainDiv>
    <Container>
      <Link to="/">
        <ResponsiveUI desktop={<img src={Logo} alt="Logo" />} mobile={<img src={RedLogo} alt="Logo" />} />
      </Link>
      <div style={{ flex: 1 }} />
      {user && (
        <Link to={`/myticket`}>
          <MyticketDiv>
            {currentId === "/myticket" ? <GlowRedTicket></GlowRedTicket> : <MyTicketIcon src={TicketWhite} />}
            <ResponsiveUI desktop={<CommonText fontSize="16px" color={currentId === "/myticket" ? colors.red : colors.white}>{t("myTickets")}</CommonText>} mobile={null} />
          </MyticketDiv>
        </Link>
      )}
      {!user && (
        <SigninButton onClick={() => requestSignIn()}>
          <img src={SigninIcon} alt="Logo" />
          <ResponsiveUI
            desktop={
              <div style={{ marginLeft: 7 }}>
                <CommonText>{t("signin")}</CommonText>
              </div>
            }
            mobile={null}
          />
        </SigninButton>
      )}
      {user && (
        <ProfileContainer
          style={{
            cursor: 'pointer',
            height: '100%',
          }}
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          onMouseEnter={() => {
            setDropdownOpen(true);
          }}
          onMouseLeave={() => {
            setDropdownOpen(false);
          }}
        >
          <ResponsiveUI
            desktop={
              <div style={{ marginLeft: 7 }}>
                {currentId === "/myaccount" ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <UserSignedIn width="25.71px" height="25.71px"></UserSignedIn>
                    <CommonText style={{ marginLeft: '10px', textOverflow:'ellipsis', maxWidth:'18ch', overflow:'hidden', whiteSpace:'nowrap' }} color={colors.red}>{user.username}</CommonText>
                    <img style={{ marginLeft: '10px' }} src={VectorOrange} alt="dropdown"></img>
                  </div>
                  :
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={SigninIcon} alt="Logo" />
                    <CommonText style={{ marginLeft: '10px', textOverflow:'ellipsis', maxWidth:'18ch', overflow:'hidden', whiteSpace:'nowrap' }}>{user.username}</CommonText>
                    <img style={{ marginLeft: '10px' }} src={VectorWhite} alt="dropdown"></img>
                  </div>
                }
              </div>
            }
            mobile={
              <div style={{ marginLeft: 7 }}>
                {currentId === "/myaccount" ?
                  <div style={{ display: 'flex' }}>
                    <UserSignedIn width="25.71px" height="25.71px"></UserSignedIn>
                    {/* <img style={{ marginLeft: '10px' }} src={VectorOrange} alt="dropdown"></img> */}
                  </div>
                  :
                  <div style={{ display: 'flex' }}>
                    <img src={SigninIcon} alt="Logo" />
                  </div>
                }
              </div>
            }
          />
          {dropdownOpen && <CommonDropdown currentId={currentId} setDropdownOpen={setDropdownOpen}></CommonDropdown>}
        </ProfileContainer>
      )}
      <Link to={`/shoppingcart`}>
        <CartDiv>
          <ImageButton src={Cart} />
          {cart && <RedDot />}
        </CartDiv>
      </Link>
    </Container>
    {/* <Divider /> */}
    {/* <GeoBlocking /> */}
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  height: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1299;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 30px;
  box-sizing: border-box; 
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 51.56%, rgba(0, 0, 0, 0) 100%);
  width: 100%;

  @media ${breakpoint.desktop} {
    height: 75px;
    padding: 0px 30px;
  }

  @media ${breakpoint.mobile} {
    height: 60px;
    padding: 0px 15px;
  }
  `;
const SigninButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MyticketDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media ${breakpoint.desktop} {
    margin-right: 30px;
  }
`;

const MyTicketIcon = styled.img`
  object-fit: cover;
  @media ${breakpoint.desktop} {
    margin-right: 10px;
  }

  @media ${breakpoint.mobile} {
    margin-right: 10px;
  }
`;

const RedDot = styled.div`
  height: 10px;
  width: 10px;
  background-color: #F44434;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: -5px;
`;

const CartDiv = styled.div`
  position: relative;

  @media ${breakpoint.desktop} {
    margin-left: 30px;
  }

  @media ${breakpoint.mobile} {
    margin-left: 15px;
  }
`;