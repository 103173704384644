import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function PICS() {
    const { i18n } = useTranslation('');

    return (
        <div>
            {i18n.language === "en" ?
                // ----------------------ENGLISH VERSION----------------------
                <div style={{ textAlign: 'justify' }}>
                    <StyledDiv>
                        <StyledP>
                            The personal data and other information (including without limitation, your name, user name, email address, delivery
                            address, contact number(s), birthday information, gender, if applicable) (collectively, “Data”) that we, MakerVille
                            Company Limited (a member of the PCCW Group) (“MakerVille” or “we”) receive about you and/or is provided by you as a result
                            of or in connection with the MakeALive account registration, and related services provided, subscribed and/or registered
                            for in connection with your MakeALive Account (collectively, the “Services”), are collected, processed, used and retained by
                            MakerVille and, as the case may be, one or more of the members of the PCCW Group (being, PCCW Limited and its
                            subsidiaries, affiliates and associated companies) as the relevant service provider(s) of the Services or other services
                            provided by such members of the PCCW Group in association with MakerVille, in accordance with the requirements of
                            the Personal Data (Privacy) Ordinance (Chapter 486 of the Laws of Hong Kong), other applicable laws, rules and
                            regulations relating to data privacy, data protection and the respective Privacy Statement of the PCCW Group (the “Privacy Statement”;
                            available at <StyledALink href="www.pccw.com/legal/privacy_c.html">www.pccw.com/legal/privacy_c.html</StyledALink>). The applicable terms and conditions of the Services, the Privacy
                            Statement, and this Personal Information Collection Statement all apply to and govern our collection, processing, use and disclosure
                            of your Data.
                        </StyledP>
                        <StyledDiv>
                            <StyledP>1. By providing your Data to us, you authorize us to collect, retain, disclose and use your Data for the purposes stated in the Privacy Statement and, more specifically, for the following purposes:</StyledP>
                            <StyledOrderList type="i">
                                <StyledListItem>
                                    operating, processing, maintaining and/or managing your Account, as applicable;
                                </StyledListItem>
                                <StyledListItem>
                                    verifying your identity in connection with any products and/or services that we may provide you;
                                </StyledListItem>
                                <StyledListItem>
                                    providing to you goods and services offered by us (including but not limited to the Streaming Service)
                                </StyledListItem>
                                <StyledListItem>
                                    providing you with customer service and responding to your queries, requests, feedback or claims,
                                </StyledListItem>
                                <StyledListItem>
                                    for payment purposes of the products or services on the Site(s);
                                </StyledListItem>
                                <StyledListItem>
                                    using your Data to compile aggregated data for performing research or statistical analysis in order to improve our products and services;
                                </StyledListItem>
                                <StyledListItem>
                                    with your consent, for marketing and promoting product and services as stated in the Personal Information Collection Statement (https://makealive.com/private-policy?id=PICS);
                                </StyledListItem>
                                <StyledListItem>
                                    making such disclosures as may be required for any of the above purposes or as required by law or in respect of any feedback or claims made by you; and
                                </StyledListItem>
                                <StyledListItem>
                                    for any other purposes which you may from time to time agree.
                                </StyledListItem>

                            </StyledOrderList>
                        </StyledDiv>
                        <StyledDiv>
                            <StyledP>2. When necessary, we may also disclose and transfer (whether in Hong Kong or abroad) your Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations for purposes above. Any Data which we receive about you will be retained by us and will be accessible by persons and parties stated in the Privacy Statement.</StyledP>
                        </StyledDiv>
                        <StyledP>
                            To the extent permitted under applicable laws, rules, regulations and licence requirements, your Data may be used, processed,
                            maintained by and/or disclosed to affiliates and/or related companies of the PCCW Group, their respective agents
                            (including debt collection agents), dealers, licensors, providers, business partners and/or applicable regulatory bodies or
                            governmental authorities for purposes in connection with the Services, including without limitation processing your
                            application, providing the Services to you, enabling your access and retrieval of account information in relation to the
                            Services, ensuring your compliance of applicable terms and conditions related to the Services or other services
                            subscribed by you, and complying with applicable laws, rules, regulations and licence requirements.
                        </StyledP>

                        <StyledP>
                            The Data not indicated as voluntary or optional are mandatory in order for us to provide you with the Services. Data
                            indicated as voluntary or optional is provided by you on voluntary basis and is provided by you at your own choice. In the
                            event you choose not to provide any voluntary or optional Data, your user experience may be affected if the requested
                            Data is used to enhance or personalise the Services offered or provided to your demographic of users.
                        </StyledP>

                        <StyledP>
                            Our website and applications use cookies or similar tracking tools on your machine or device in order for us to maintain
                            your identity across multiple webpages and/or Internet sessions; we also use your Data to compile aggregate data for
                            performing research or statistical analysis in order to improve our products and services. We do not otherwise collect
                            your cookies or other online identifiers, whether for the purposes of personalising your user experience, monitor your
                            behavior, predict your preferences or otherwise. Our website and applications are initially set up to accept cookies. You
                            can opt-out of or delete historical cookies by changing the settings on your web or device browsers; however, if you do
                            so, you may find that certain features on our website do not work properly.
                        </StyledP>

                        <StyledP>
                            Subject to your preference as indicated by checking the designated box or leaving it unchecked, we may, in compliance
                            with applicable laws, rules, regulations and license requirements, use your Data (including without limitation, your name,
                            user name, email address, delivery address, contact number(s), information about the goods and services you have
                            purchased or subscribed and other information compiled subsequently) for us, members of the PCCW Group and our
                            respective dealers, licensors, providers and/or business partners (collectively, “Service Providers”) to provide you, or
                            facilitate our provision to you, with direct marketing content, including sending to you notices and/or updates about gifts,
                            discounts, privileged offers, benefits and promotions related to the Services as well as other products and/or services
                            offered by us, members of the Group and/or the Service Providers, including without limitation: TV, telecommunications,
                            over-the-top (OTT) services, content services, mobile voice, SMS and data communications, IDD/roaming, Internet
                            connectivity, cloud services, mobile payment, entertainment, secretarial services, personal assistant services and
                            information services (such as weather, finance and news information), device accessories, mobile applications and
                            software, computer peripheral, accessories and software (including notebooks, handsets, mobile devices and
                            accessories, keyboards, security installations and mobile applications), reward, loyalty and privilege programs, lifestyle,
                            networking events, travelling, banking, alcohol and tobacco, sports, music, gaming, transportation, household products,
                            food and beverages, finance, insurance, wealth management services and products, pensions, investments, brokering,
                            financial advisory, credit and other financial services and products, betting, education, health and wellness, beauty
                            products and services, fashion and accessories, electronics, social networking, technology, e-commerce, logistics, retail,
                            home and décor, media and high-end consumer products and services. The processing of your Data for the purposes of
                            direct marketing is carried out in our legitimate interests and in accordance with the applicable laws.
                        </StyledP>

                        <StyledP>
                            We will retain your Data in accordance with our internal policies. Our policies are in compliance with the applicable ordinances and the GDPR where applicable, and cover the following principles:
                        </StyledP>

                        <StyledUnorderList>
                            <StyledListItem>Data will only be retained for as long as is necessary to fulfil the original or directly related purposes for which it was collected, unless the Data is also retained to satisfy any applicable legal, regulatory or contractual obligations; and</StyledListItem>
                            <StyledListItem>Data are purged from our electronic, manual and other filing systems based on the above criteria and our internal procedures.</StyledListItem>
                        </StyledUnorderList>

                        <StyledP>
                            You are entitled to access, correct, delete, object, restrict the use of Data,  enquire about or ask for a copy of the Data held by us about you under certain circumstances. You may also limit or object to the collection, processing or usage of your Data, decline to share Data with us and/or withdraw any consents which you may have provided by contacting us in the manner as set forth under the “How to Contact Us” section, in which case, we may not be able to provide you with some of our Services or related services.
                        </StyledP>

                        <StyledP>
                            In the case that you suspect MakerVille and/or other responsible parties of violating personal data protection laws, you are entitled to file a complaint or report to the applicable authorities. If you wish to withdraw your consent to our use of your Data in direct marketing, you may click on the "Personal Details" page after having logged in to your account, then change your account setting and choose stop receiving promotional emails from us. For all other data access, data correction requests, requests for data deletion, requests for data copy, requests to limit or object to data collection, processing or usage, general enquires and/or complaints about any Data held by us about you, and/or enquiries regarding our compliance of applicable data protection and privacy laws, you can do so by writing to the PCCW Group’s Privacy Compliance Officer (address: GPO Box 9872, Hong Kong or via email to: <StyledALink href="mailto:privacy@pccw.com">privacy@pccw.com</StyledALink>).
                        </StyledP>
                    </StyledDiv>
                </div>

                :
                // ----------------------CHINESE VERSION----------------------
                <div style={{ textAlign: 'justify' }}>
                    <StyledDiv>
                        <StyledP>
                            我們，MakerVille Company Limited（「MakerVille」或「我們」）（電訊盈科集團之成員公司）就MakeALive帳戶登記及與閣下MakeALive帳戶相關服務（「本服務」）的提供、申請及／或登記或與其有關而獲取有關閣下的及／或閣下提供的個人資料和其他資料（包括但不限於閣下的姓名、用戶名稱、電郵地址、郵寄地址、聯絡號碼、生日資料、性別（如適用））（統稱「資料」）是由MakerVille及（視情況而定）一家或多家電訊盈科集團成員公司（即電訊盈科有限公司及其附屬公司、聯營公司和關聯公司）作為本服務或與MakerVille共同提供其他服務的電訊盈科集團成員的相關服務提供者，根據香港法例第486章《個人資料（私隱）條例》(「條例」)與個人資料私隱、資料保護相關的其他適用法律、規則及規例，及電訊盈科集團的「私隱聲明」（載於<StyledALink href="www.pccw.com/legal/privacy_c.html">www.pccw.com/legal/privacy_c.html</StyledALink>）（「私隱聲明」）的規定所收集、處理、利用及保存。本服務的適用條款及條件、「私隱聲明」及本聲明均適用於及監管我們對閣下資料之收集、使用、處理及披露。
                        </StyledP>

                        <StyledP>
                            當閣下提供閣下的資料予我們，即視為閣下同意我們收集、保存、披露及利用閣下之資料，於「私隱聲明」中所載之目的及下列目的：
                        </StyledP>

                        <StyledUnorderList>
                            <StyledListItem>營運丶處理丶維持及/或管理閣下的帳戶，如適用;</StyledListItem>
                            <StyledListItem>就有關我們可能向閣下提供的任何產品及/或服務核實閣下的身份;</StyledListItem>
                            <StyledListItem>向閣下提供由我們提供的產品及服務（包括但不限於串流服務）;</StyledListItem>
                            <StyledListItem>向閣下提供客戶服務及回覆閣下的查詢丶要求、意見或索賠;</StyledListItem>
                            <StyledListItem>本網站上産品或服務的付款目的;</StyledListItem>
                            <StyledListItem>為改進我們產品及服務，使用閣下資料進行編輯統計數據以進行研究或統計分析;</StyledListItem>
                            <StyledListItem>經閣下同意，用於個人資料收集聲明（https://makealive.com/private-policy?id=PICS）中所載之營銷及推廣商品及服務;</StyledListItem>
                            <StyledListItem>基於上述目的的要求或法律的要求或閣下的任何反饋或索賠要求;及</StyledListItem>
                            <StyledListItem>閣下可能不時同意的其他任何目的。</StyledListItem>
                        </StyledUnorderList>
                        
                        <StyledP>
                            如有必要、我們可能會向我們的專業顧問、執法機關、保險公司、政府及監管機構及其他組織基於上述條款中所列的相關目的披露或提供（無論是香港境內或境外）閣下的資料。我們收到有關閣下的任何資料將由我們保留及私隱條款中所載之個人及各方均將有權使用。
                        </StyledP>

                        <StyledP>
                            於適用法律、規定、規例及牌照規定允許的最大範圍內，閣下的資料可能被電訊盈科集團的聯營公司及／或關連公司、其各自的代理人（包括債務代收代理人）、零售商、授權人、供應商和業務合作夥伴及／或適用監管機構或政府機關使用、處理、保存及/或向該些組織或人士披露，以用於有關本服務的目的，包括但不限於處理閣下的申請、向閣下提供本服務、使閣下能進入及檢索有關本服務的帳戶資料，確保閣下遵守本服務或其他閣下訂購的服務應適用的條款及條件，及履行適用法律、規定、規例及牌照規定。
                        </StyledP>

                        <StyledP>
                            未指定為可選填的資料屬必須提供之資料以便我們向閣下提供本服務。指定為可選填的資料是以自願性質提供，閣下選擇提供指定為可選填的資料乃閣下自願提供。如閣下不提供指定為可選填的資料，而所要求提供的資料是用於閣下所屬群組的服務提升或向閣下所屬群組的用戶提供群組化的本服務，則閣下在使用本服務時的體驗可能會因此而受到影響。
                        </StyledP>

                        <StyledP>
                            我們的網站和應用程式會在閣下的電腦或裝置中使用cookies（小型文字檔案）或類似的追蹤工具，以便我們在多個網頁及／或互聯網段落中保留閣下的足跡；我們亦會使用閣下的資料來彙集群組數據以進行研究或統計分析，以改進我們的產品和服務。我們不會以其他方式收集閣下的cookies或其他線上識別碼（無論是用於個性化閣下的用戶體驗、監控閣下的行為、預測閣下的偏好或其他目的）。我們的網站和應用程式在最初會設定為接受cookies。閣下可以透過更改閣下網頁或裝置瀏覽器的設定選擇拒絕或刪除cookies的歷史記錄；但是，閣下在更改有關設定後，可能無法如常使用我們網站及／或應用程式的某些功能。
                        </StyledP>

                        <StyledP>
                            按照閣下剔選或留空指定方格所表示之意願，我們在遵守適用法律、規例、規例和牌照規定的情況下，可能會使用閣下的資料（包括但不限於姓名、用戶名稱、電郵地址、郵寄地址、聯絡號碼、閣下已選購或申請的產品及服務的資料及其後編制的資料）以便我們、電訊盈科集團成員公司及我們各自的零售商、授權人、供應商及／或業務合作夥伴（統稱「服務供應商」）為閣下或協助我們為閣下提供直接促銷資料，包括向閣下發出與本服務以及由我們、電訊盈科集團成員公司及／或服務供應商所提供的其他產品及／或服務有關的禮品、折扣、尊享優惠、優惠和推廣的通知及／或最新情報，包括但不限於：電視、電訊、OTT服務、資料服務、流動話音、短訊及數據通訊、IDD／漫遊、互聯網連接、雲端服務、手機付款、娛樂、秘書服務、個人助理服務和資訊服務（例如天氣、財經和新聞資訊）、裝置配件、流動應用程式和軟件、電腦周邊產品、配件和軟件（包括筆記型電腦、手提電話、流動裝置及配件、鍵盤、防禦裝置及流動應用程式）、獎賞、忠誠或尊貴計劃、生活方式、社交活動、旅遊、銀行、酒精及煙草、運動、音樂、遊戲、交通、家居產品、餐飲、金融、保險、財富管理服務和產品、退休金、投資、經紀服務、理財顧問、信貸及其他理財服務和產品、博彩、教育、健康及保健、美容產品和服務、時尚及配飾、電子產品、社交網絡、科技、電子商務、物流、零售、家居佈置、媒體及高端消費類產品及服務。為直接促銷之目的處理閣下的資料時會根據我們的合法權益並依應適用之法律進行。
                        </StyledP>

                        <StyledP>
                            本公司將會根據內部政策，持有閣下的資料。本公司的政策符合條例及GDPR（如適用），及包括以下宗旨：
                        </StyledP>

                        <StyledUnorderList>
                            <StyledListItem>資料會保存至達到與收集上述資料之原來目的，或直接有關之目的為止；除非上述資料可根據任何適用法律、法規或合約規定須予以保存；及</StyledListItem>
                            <StyledListItem>基於上述標準及本公司的內部程序，資料會在本公司的電子、人手及其他儲存系統中被清除。</StyledListItem>
                        </StyledUnorderList>

                        <StyledP>閣下有權查閱、修正、刪除、拒絕、限制資料的使用；於某些情況下，查詢或要求我們提供閣下資料的複本。閣下亦可以要求限制或拒絕收集、處理或利用閣下的資料、拒絕提供閣下的資料給我們及╱或撤回閣下作出的任何同意（經「如何聯絡我們」中所載之方式），但在這種情況下，我們可能無法向你提供本服務或與本服務相關之其他服務。</StyledP>
                        
                        <StyledP>
                            如果閣下認為MakerVille及/或其他相關負責方違反個人資料保護法例，閣下有權投訴或向相關機構報告。如閣下有意撤回就我們使用閣下的資料進行直接行銷之同意，閣下可登入帳戶後，進入“個人資料”頁面，更改設定選擇停止接收宣傳電郵。倘若閣下就我們所持有有關閣下的資料有任何查詢、資料修正、資料刪除請求、請求給予資料複本、要求限制或拒絕資料收集、處理或利用、一般查詢及/或投訴，請以書面方式致函以下地址：電訊盈科集團私隱條例事務主任（香港郵政總局信箱9872號或電郵至<StyledALink href="mailto:privacy@pccw.com">privacy@pccw.com</StyledALink>）。
                        </StyledP>
                    </StyledDiv>
                </div>
            }
        </div >
    )
}


const StyledTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-decoration: underline;
`;

const StyledSubTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    text-align: justify;
`;

const StyledOrderList = styled.ol`
    padding-left: 50px;
`;

const StyledUnorderList = styled.ul`
    padding-left: 50px;
`;

const StyledListItem = styled.li`
    padding-left: 20px;
    margin-bottom: 10px;
`;

const StyledDiv = styled.div`
    margin-bottom: 20px;
`;

const StyledP = styled.p`
    margin-bottom: 20px;
    font-size: 16px;
    font-family:'Roboto';
    color: rgba(255, 255, 255, 0.8);
    line-height: 28px;
`;

const StyledALink = styled.a`
    text-decoration: underline;
    white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
`;
