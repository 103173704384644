import styled from 'styled-components';
import Divider from '../components/common/dividers/CommonDivider';
import Grid from '@mui/material/Grid'
import { breakpoint } from '../utils/responsive';
import CommonText from '../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import Player from '../components/Player';
import { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';

export default function TvSpec() {
    const { t, i18n } = useTranslation('tvSpec');
    const SPECLIST = Object.values(t("spec", { returnObjects: true }));
    const REMARKLIST = Object.values(t("remarks", { returnObjects: true }));
    console.log(REMARKLIST)

    const { isApp } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <StyledMainDiv>
             <StyledContentDiv>
                <div>
                    {
                        SPECLIST.map((item) => {
                            return (
                                <Grid container direction={'row'} >
                                    <StyledGridLeftDiv item xs>
                                        {item.title}
                                        <i>{item.title2}</i>
                                    </StyledGridLeftDiv>
                                    <StyledGridRightDiv item xs>
                                        {item.subtitle}
                                    </StyledGridRightDiv>
                                    <Divider style={{ margin: '15px 0px' }} color="rgba(255, 255, 255, 0.5)"></Divider>
                                </Grid>
                            )
                        })
                    }
                </div>

                <StyledRemarksDiv>
                    <ul>
                        {    
                            REMARKLIST.map((item) => 
                                <li>
                                    {item}
                                </li>
                            )
                        }
                    </ul>
                </StyledRemarksDiv>
            </StyledContentDiv>
        </StyledMainDiv>
    )
}

const StyledMainDiv = styled.div`
    @media ${breakpoint.mobile} {
        padding: 30px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 60px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        padding: 0 60px;
    }
`;

const StyledContentDiv = styled.div`
    margin: 0 auto;
    @media ${breakpoint.mobile} {
        width: 100%;
    }
    @media ${breakpoint.desktop} {
        width: 768px;
    }
    @media ${breakpoint.largeDesktop} {
        width: 875px;
    }
`;

const StyledGridLeftDiv = styled(Grid)`
    text-align: left;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
    white-space: pre-line;
`;

const StyledGridRightDiv = styled(Grid)`
    text-align: right;
    color: rgba(255, 255, 255, 0.8);
    line-height: 28px;
    white-space: pre-line;
`;

const StyledRemarksDiv = styled.div`
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    line-height: 28px;
    margin-bottom: 18px;
`;