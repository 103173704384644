import { useTranslation, } from 'react-i18next';
import CommonText from '../components/common/typographies/CommonText';
import { ResponsiveUI, WindowSize } from "../utils/responsive";
import CommonDivider from "../components/common/dividers/CommonDivider";
import TermsOfUse from "../pageComponents/tnc/termsOfUse";
import { useEffect } from 'react';

export default function TNC() {
    const { t } = useTranslation('tnc');
    const tvApp = window.location.search.indexOf("tvapp=true") > -1;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Tnc = TermsOfUse();

    return (
        <div>
            {!tvApp && (
                <>
                    <CommonText style={{ textAlign: 'center', margin: WindowSize() === "mobile" ? '30px 0px' : "60px 0px" }} sizeType='Heading1' type='display'>{t('title.tnc')}</CommonText>
                    <div style={{ padding: "0px 5%"}}>
                        <CommonDivider color={'#ffffff'} opacity={0.5}></CommonDivider>
                    </div>
                </>
            )}
            <ResponsiveUI
                mobile={
                    <div style={{ marginTop: 16, padding: `0px 5% 0px 5%`, marginBottom:50}}>
                        {Tnc}
                    </div>
                }

                desktop={
                    <div style={{ marginTop: 50, padding: `0px 5% 0px 5%`, marginBottom:75 }}>
                        {Tnc}
                    </div>
                }

                largeDesktop={
                    <div style={{ padding:'0px 308px', marginBottom:100, marginTop: 60, padding: `0px 5% 0px 5%` }}>
                        {Tnc}
                    </div>
                }
            />
        </div>
    )
}