import { Divider, Grid } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import dayjs from "dayjs";
import Logo from "../assets/Logo.svg";
import WhiteLogo from "../assets/WhiteLogo.svg";
import AppStore from "../assets/AppStore.svg";
import GooglePlay from "../assets/GooglePlay.svg";
import AppGallery from "../assets/AppGallery.svg";
import Facebook from "../assets/Facebook.svg";
import Instagram from "../assets/Instagram.svg";
import CommonText from "../components/common/typographies/CommonText";
import { Link } from "react-router-dom";
import { ResponsiveUI, breakpoint, WindowSize } from "../utils/responsive";
import LanguageSelect from "./common/selects/LanguageSelect";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import CurrencySelect from "./common/selects/CurrencySelect";
import Space from "./Space";

function FooterItem({ link, children, underline }) {
  return (
    <StyledLink to={link}>
      <CommonText
        sizeType="Body"
        type="body"
        style={{
          marginBottom: 8,
          textDecoration: underline ? "underline" : "none",
        }}
      >
        {children}
      </CommonText>
    </StyledLink>
  );
}
export default function Footer() {
  const { t } = useTranslation("footer");
  const { isApp } = useContext(AppContext);
  if (isApp) return null;
  return [
    <Divider />,
    <Container container>
      <ResponsiveUI
        largeDesktop={
          <Column container>
            <Column md={2} item>
              <img
                src={WhiteLogo}
                alt="Home Banner"
                style={{ marginBottom: 20 }}
              />
            </Column>
            <Column md={1.5} item>
              <FooterItem link="/">{t("home")}</FooterItem>
              <FooterItem link="/aboutus">{t("aboutus")}</FooterItem>
              <FooterItem link="/light-stick-user-guide">{t("lightsticktutorial")}</FooterItem>
            </Column>
            <Column md={1.5} item>
              <FooterItem link="/faq">{t("faq")}</FooterItem>
              <FooterItem link="/tnc">{t("tnc")}</FooterItem>
            </Column>
            <Column md={1.5} item>
              <FooterItem link="/howtowatch">{t("howtowatch")}</FooterItem>
              <FooterItem link="/private-policy">
                {t("private-policy")}
              </FooterItem>
            </Column>

            <Column xs={1} md={1.5} item></Column>

            <Column xs={12} md={4} item>
              <DownloadTitle>
                <CommonText
                  sizeType="Body"
                  type="body"
                  style={{ textAlign: "left", fontWeight: 700 }}
                >
                  {t("download_app")}
                </CommonText>
              </DownloadTitle>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://apps.apple.com/app/id1623991918"
                  target="_blank"
                  style={{ margin: "10px 10px 0px 0px" }}
                >
                  <img src={AppStore} alt="App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.makerville.makealive"
                  target="_blank"
                  style={{ margin: "10px 10px 0px 0px" }}
                >
                  <img src={GooglePlay} alt="Google Play" />
                </a>
                <a
                  href="https://appgallery.huawei.com/app/C106277251"
                  target="_blank"
                  style={{ margin: "10px 10px 0px 0px" }}
                >
                  <img src={AppGallery} alt="App Gallery" />
                </a>
              </div>
            </Column>
            <Column xs={12} md={8} item></Column>
            <Column xs={12} md={4} item>
              <div style={{ display: "flex", margin: "20px 0px 10px 0px" }}>
                <CommonText
                  sizeType="Body"
                  type="body"
                  style={{ textAlign: "left", fontWeight: 700 }}
                >
                  {t("followus")}
                </CommonText>
                <a
                  href="https://www.facebook.com/MakeALive.official/"
                  target="_blank"
                  style={{ marginLeft: 15 }}
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/MakeALive.official/"
                  target="_blank"
                  style={{ marginLeft: 15 }}
                >
                  <img src={Instagram} alt="Instagram" />
                </a>
              </div>
            </Column>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Column xs={12} md={4} item>
                <div style={{ width: "355px", margin: "60px 0px 0px 0px" }}>
                  <CommonText
                    sizeType="Caption"
                    type="body"
                    style={{ textAlign: "left" }}
                  >
                    {t("copyright1", { year: dayjs().year() })}
                  </CommonText>
                  <CommonText
                    sizeType="Caption"
                    type="body"
                    style={{ textAlign: "right" }}
                  >
                    <Trans
                      t={t}
                      i18nKey="copyright2"
                      components={[
                        <CommonText style={{ fontWeight: 600 }} key={0} />,
                      ]}
                    />
                  </CommonText>
                </div>
              </Column>

              <Column xs={12} md={5} item></Column>

              <Column xs={12} md={3} item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    margin: "60px 0px 0px 0px",
                  }}
                >
                  <LanguageSelect width={144} />
                  {/* <Space horizontal size="16px" />
                  <CurrencySelect width={89} /> */}
                </div>
              </Column>
            </div>
          </Column>
        }
        desktop={
          <Column xs={12} container>
            <Column xs={12} md={12} item>
              <img
                src={WhiteLogo}
                alt="Home Banner"
                style={{ marginBottom: 20 }}
              />
            </Column>

            <Column xs={2} md={2} item>
              <FooterItem link="/">{t("home")}</FooterItem>
              <FooterItem link="/aboutus">{t("aboutus")}</FooterItem>
              <FooterItem link="/light-stick-user-guide">{t("lightsticktutorial")}</FooterItem>
            </Column>
            <Column xs={2} md={2} item>
              <FooterItem link="/faq">{t("faq")}</FooterItem>
              <FooterItem link="/tnc">{t("tnc")}</FooterItem>
            </Column>
            <Column xs={2} md={2} item>
              <FooterItem link="/howtowatch">{t("howtowatch")}</FooterItem>
              <FooterItem link="/private-policy">
                {t("private-policy")}
              </FooterItem>
            </Column>

            <Column xs={1} md={2} item></Column>

            <Column xs={5} md={4} item>
              <DownloadTitle>
                <CommonText
                  sizeType="Body"
                  type="body"
                  style={{ textAlign: "left", fontWeight: 700 }}
                >
                  {t("download_app")}
                </CommonText>
              </DownloadTitle>
              <div>
                <div style={{ display: "flex" }}>
                  <a
                    href="https://apps.apple.com/app/id1623991918"
                    target="_blank"
                    style={{ margin: "10px 10px 0px 0px" }}
                  >
                    <img src={AppStore} alt="App Store" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.makerville.makealive"
                    target="_blank"
                    style={{ margin: "10px 10px 0px 0px" }}
                  >
                    <img src={GooglePlay} alt="Google Play" />
                  </a>
                </div>
                <div style={{ margin: "10px 10px 0px 0px" }}>
                  <a
                    href="https://appgallery.huawei.com/app/C106277251"
                    target="_blank"
                  >
                    <img src={AppGallery} alt="App Gallery" />
                  </a>
                </div>
              </div>
            </Column>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Column xs={5} md={4} item>
                <div style={{ width: "305px", margin: "60px 0px 0px 0px" }}>
                  <CommonText
                    sizeType="Small"
                    type="body"
                    style={{ textAlign: "left" }}
                  >
                    {t("copyright1", { year: dayjs().year() })}
                  </CommonText>
                  <CommonText
                    sizeType="Small"
                    type="body"
                    style={{ textAlign: "right" }}
                  >
                    <Trans
                      t={t}
                      i18nKey="copyright2"
                      components={[
                        <CommonText style={{ fontWeight: 600 }} key={0} />,
                      ]}
                    />
                  </CommonText>
                </div>
              </Column>

              <Column xs={5} md={4} item style={{ display: "flex" }}>
                <div style={{ margin: "40px 50px 0px 0px", width: 100 }}>
                  <CommonText
                    sizeType="Body"
                    type="body"
                    style={{
                      marginBottom: 15,
                      textAlign: "left",
                      fontWeight: 700,
                    }}
                  >
                    {t("followus")}
                  </CommonText>
                  <a
                    href="https://www.facebook.com/MakeALive.official/"
                    target="_blank"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/MakeALive.official/"
                    target="_blank"
                    style={{ marginLeft: 15 }}
                  >
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    margin: "60px 0px 0px 0px",
                  }}
                >
                  <LanguageSelect width={144} />
                  {/* <Space horizontal size="16px" />
                  <CurrencySelect width={85} /> */}
                </div>
              </Column>
            </div>
          </Column>
        }
        mobile={
          <Column xs={12} container>
            <Column xs={12} md={12} item>
              <img
                src={WhiteLogo}
                alt="Home Banner"
                style={{ marginBottom: 20 }}
              />
            </Column>

            <Column xs={4} md={4} item>
              <FooterItem link="/">{t("home")}</FooterItem>
              <FooterItem link="/aboutus">{t("aboutus")}</FooterItem>
              <FooterItem link="/light-stick-user-guide">{t("lightsticktutorial")}</FooterItem>
            </Column>
            <Column xs={4} md={4} item>
              <FooterItem link="/faq">{t("faq")}</FooterItem>
              <FooterItem link="/tnc">{t("tnc")}</FooterItem>
            </Column>
            <Column xs={4} md={4} item>
              <FooterItem link="/howtowatch">{t("howtowatch")}</FooterItem>
              <FooterItem link="/private-policy">
                {t("private-policy")}
              </FooterItem>
            </Column>

            <Column xs={6} md={6} item style={{ marginTop: "30px" }}>
              <DownloadTitle>
                <CommonText
                  sizeType="Body"
                  type="display"
                  style={{ textAlign: "left", fontWeight: 700 }}
                >
                  {t("download_app")}
                </CommonText>
              </DownloadTitle>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <a
                  href="https://apps.apple.com/app/id1623991918"
                  target="_blank"
                  style={{ margin: "10px 10px 0px 0px" }}
                >
                  <img src={AppStore} alt="App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.makerville.makealive"
                  target="_blank"
                  style={{ margin: "10px 10px 0px 0px" }}
                >
                  <img src={GooglePlay} alt="Google Play" />
                </a>
                <a
                  href="https://appgallery.huawei.com/app/C106277251"
                  target="_blank"
                  style={{ margin: "10px 10px 0px 0px" }}
                >
                  <img src={AppGallery} alt="App Gallery" />
                </a>
              </div>
            </Column>
            <Column xs={6} md={6} item style={{ marginTop: "30px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div>
                  <CommonText
                    sizeType="Body"
                    type="display"
                    style={{
                      marginBottom: 15,
                      textAlign: "left",
                      fontWeight: 700,
                      width: 100,
                    }}
                  >
                    {t("followus")}
                  </CommonText>
                  <a
                    href="https://www.facebook.com/MakeALive.official/"
                    target="_blank"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/MakeALive.official/"
                    target="_blank"
                    style={{ marginLeft: 15 }}
                  >
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </div>
              </div>
            </Column>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                margin: "36px 0px 0px 0px",
              }}
            >
              <div style={{ width: "100%", flex: 1 }}>
                <LanguageSelect />
              </div>
              {/* <Space horizontal size="16px" />
              <CurrencySelect width={85} /> */}
            </div>

            <div style={{ width: "305px", margin: "30px auto 0px auto" }}>
              <CommonText
                sizeType="Small"
                type="body"
                style={{ textAlign: "left" }}
              >
                {t("copyright1", { year: dayjs().year() })}
              </CommonText>
              <CommonText
                sizeType="Small"
                type="body"
                style={{ textAlign: "right" }}
              >
                {t("copyright2")}
              </CommonText>
            </div>
          </Column>
        }
      />
    </Container>,
  ];
}

const Container = styled(Grid)`
  background-color: #000;
  z-index: 1;
  position: relative;
  @media ${breakpoint.mobile} {
    padding: 30px;
    height: auto;
  }
  @media ${breakpoint.desktop} {
    padding: 30px;
  }
  @media ${breakpoint.largeDesktop} {
    padding: 60px;
  }
`;
const Column = styled(Grid)`
  text-align: left;
`;

const DownloadTitle = styled.div`
  margin: 0px 0px 10px 0px;
`;

const FollowusTitle = styled.div`
  @media ${breakpoint.mobile} {
    margin: 20px 0px 10px 0px;
  }
`;

const StyledLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;
