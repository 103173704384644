import CommonText from "../typographies/CommonText";
import styled from "styled-components";

export default function TextButton(props) {
    return (
        <StyledText {...props}>{props.children}</StyledText>
    )
}
const StyledText = styled(CommonText)`
&& {
    cursor: pointer;
    color: #F44434;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
}
`