import { Grid, Divider } from "@mui/material";
import styled from "styled-components";
import CommonText from "../components/common/typographies/CommonText";
import CommonButton from "../components/common/buttons/CommonButton";
import BlackButton from "../components/common/buttons/BlackButton";
import Success from "../assets/Success.svg";
import Calendar from "../assets/Calendar.svg";
import Fail from "../assets/Fail.svg";
import { ResponsiveUI, breakpoint, WindowSize } from "../utils/responsive";
import { useTranslation } from "react-i18next";
import { TicketBox } from "../components/payment/TicketViewPaymentSummary";
import { PaymentInfo } from "../components/payment/PaymentSummary";
import { useSearchParams } from "react-router-dom";
import { getOrder } from "../apis";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";

const PaymentSuccess = ({ t, isLargeDesktop }) => {
  return (
    <HeaderContainer>
      <ResponsiveHeaderTitle>
        {t("thankYou")}
        {t("yourOrderHasBeenAccepted")}
      </ResponsiveHeaderTitle>
      <img src={Success} alt="Success" />
      <ResponsiveHeaderSubTitle>
        {t("confirmationEmailWillBeSentToYourEmailBox")}
      </ResponsiveHeaderSubTitle>
    </HeaderContainer>
  );
};

const PaymentFail = ({ t, isLargeDesktop }) => {
  return (
    <HeaderContainer>
      <ResponsiveHeaderTitle>
        {t("paymentNotCompleted")}
      </ResponsiveHeaderTitle>
      <img src={Fail} alt="Fail" />
      <ResponsiveHeaderSubTitle>{t("payAgain")}</ResponsiveHeaderSubTitle>
    </HeaderContainer>
  );
};

function PaymentComplete() {
  const { t, i18n } = useTranslation("paymentComplete");
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AppContext);
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const order_no = searchParams.get("order_no");
  const payment_method = searchParams.get("payment_method");
  const {
    isLoading,
    isError,
    data,
    error,
    refetch,
    isRefetching,
    isRefetchError,
  } = useQuery("getOrder", order_no && user ? () => getOrder(order_no) : null, {
    onSuccess: (data) => {
      if (data && data.success && data.data.order.status === "Pending") {
        setLoading(true);
        setTimeout(() => {
          refetch();
        }, [3000]);
      } else {
        setLoading(false);
        if (data?.data?.order?.status == "Completed") {
          window.dataLayer.push({
            event: "custom_event",
            event_name: "purchase",
            currency: data.data?.order?.currency,
            value: data.data?.order?.amount,
            items: data.data?.order?.items,
            transaction_id: order_no,
          });
        }
      }
    },
    onError: () => setLoading(false),
  });

  const isPaymentSuccess =
    data?.data?.order?.status == "Completed"
      ? true
      : false;
  const isLargeDesktop = WindowSize() === "largeDesktop" ? true : false;

  if (!user && payment_method == "alipay_hk") {
    // special handle alipay
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 60,
          marginBottom: 125,
        }}
      >
        <CommonText
          fontSize="24px"
          fontWeight={600}
          lineHeight="34px"
          color="#fffffff"
        >
          {t("success")}
        </CommonText>
        <Space size="15px" />
        <img src={Success} alt="Success" />
        <Space size="15px" />

        <CommonText
          fontSize="16px"
          fontWeight={500}
          lineHeight="28px"
          color="#ffffff"
          textAlign="center"
          style={{ padding: 15 }}
        >
          {t("close_app")}
        </CommonText>
      </div>
    );
  }
  if (loading) {
    return <Loading />;
  }
  if (isError || isRefetchError) {
    return <div>Error: {error?.message || ""}</div>;
  }
  // if (data && !data.success) {
  //     return <div style={{ padding: 20 }}>Error: {data?.error.message || ""}</div>
  // }

  return (
    <Container container direction="column" alignItems="center">
      {isPaymentSuccess ? (
        <PaymentSuccess t={t} isLargeDesktop={isLargeDesktop} />
      ) : (
        <PaymentFail t={t} isLargeDesktop={isLargeDesktop} />
      )}
      <Space />
      <PaymentInfo
        orderNumber={`#${order_no || ""}`}
        data={data?.data}
        isPaymentSuccess={isPaymentSuccess}
      />
      <Space />
      {(data?.data?.order?.items || []).map((item) => {
        return (
          <TicketBox
            currency={data?.data?.order?.currency}
            data={item}
            t={t}
            isPaymentSuccess={isPaymentSuccess}
          />
        );
      })}
      <ResponsiveBlackButton onClick={() => navigate("/")}>
        {t("homePage")}
      </ResponsiveBlackButton>
    </Container>
  );
}

export default PaymentComplete;

const ResponsiveHeaderTitle = styled(CommonText)`
  @media ${breakpoint.mobile} {
    font-size: 24px;
    line-height: 140%;
    text-align: center;
  }
  @media ${breakpoint.desktop} {
    font-size: 28px;
    line-height: 140%;
    text-align: center;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 36px;
    line-height: 40px;
    font-weight: 600;
  }
`;

const ResponsiveHeaderSubTitle = styled(CommonText)`
  @media ${breakpoint.mobile} {
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    font-weight: 400;
  }
  @media ${breakpoint.desktop} {
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    font-weight: 400;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }
`;

const Container = styled(Grid)`
  && {
    max-width: 1100px;
    margin: 60px auto;
    padding: 0px 30px;

    @media ${breakpoint.mobile} {
      padding: 0px 15px;
    }
  }
`;

const HeaderContainer = styled.div`
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponsiveBlackButton = styled(BlackButton)`
  && {
    border: 1px solid rgba(255, 255, 255, 0.3);
    @media ${breakpoint.mobile} {
      width: 100%;
      max-width: 500px;
    }
    @media ${breakpoint.desktop} {
      width: 90%;
    }
    @media ${breakpoint.largeDesktop} {
      width: 70%;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

const BoxLeftView = styled(Grid)`
  padding: 30px;
`;

const BoxRightView = styled(Grid)`
  padding: 30px;
`;

const Space = styled.div`
  @media ${breakpoint.mobile} {
    height: 32px;
  }
  @media ${breakpoint.desktop} {
    height: 30px;
  }
  @media ${breakpoint.largeDesktop} {
    height: 52px;
  }
`;
