import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CommonSnackbar from "../../components/common/snackbar/CommonSnackbar";
import TicketTitle from "../myTicket/Card/TicketTitle";
import WatchNowIcon from "../../assets/WatchNow.svg";
import CommonText from "../../components/common/typographies/CommonText";
import dayjs from "dayjs";
import _ from "lodash";
import dateString from "../../utils/dateString";

const GotoLiveSnackbar = ({ ticket }) => {
  const { t, i18n } = useTranslation("home");
  let navigate = useNavigate();

  const customTitle = (streamStart, streamId) => {
    return (
      <CustomTitleDiv>
        <CommonText
          fontSize="14px"
          mFontSize="12px"
          color="#F44434"
          textAlign="left"
          style={{ whiteSpace: "pre-line" }}
        >
          {dateString(streamStart, i18n.language)}
        </CommonText>
        <WatchNowButton>
          <CommonText
            fontSize="14px"
            mFontSize="12px"
            color="#fff"
            textAlign="left"
            testWeight="700"
            style={{ whiteSpace: "pre-line" }}
          >
            {t("watchNow")}
          </CommonText>
          <img src={WatchNowIcon} />
        </WatchNowButton>
      </CustomTitleDiv>
    );
  };

  return (
    <CommonSnackbar open={!_.isEmpty(ticket)} noBackgroundColor fullWidth>
      <StyledDiv onClick={() => {
        window.dataLayer.push({
            event: "custom_event",
            event_name: "button_click",
            button_text: "Web enter live"
        })
        navigate(`/live/${ticket?._id}`)
      }}>
        <TicketTitle
          poster={ticket?.event?.poster}
          sponsorZh={ticket?.event?.sponsorZh}
          sponsorEn={ticket?.event?.sponsorEn}
          titleZh={ticket?.event?.eventNameEn}
          titleEn={ticket?.event?.eventNameZh}
          customTitle={customTitle(ticket?.streamStart, ticket?._id)}
          smallPoster
        />
      </StyledDiv>
    </CommonSnackbar>
  );
};

const WatchNowButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  padding: 15px;
  background-color: rgb(31, 31, 33);
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: rgb(46, 46, 46);
    ${WatchNowButton} {
      text-decoration: underline;
    }
  }
`;

const CustomTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default GotoLiveSnackbar;
