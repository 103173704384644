import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookIcon from "../../assets/FacebookSignin.svg";
import { socialLogin } from "../../apis";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import CONSTANTS from "../../constants";
// import TagManager from 'react-gtm-module';
import CommonButton from "../common/buttons/CommonButton";

export default function FacebookSignin({ login = false, handlePageChange, handleClose }) {
    const { t } = useTranslation("signin");
    const { setUser, requestPhoneVerification } = useContext(AppContext)
    const responseFacebook = async (response) => {
        // call api facebook token
        if (response.accessToken) {
            const result = await socialLogin("facebook", response.accessToken);
            if (result.success) {
                localStorage.setItem('accessToken', result.data?.accessToken);
                localStorage.setItem('refreshToken', result.data?.refreshToken);
                setUser(result.data?.user)
                if (result.data?.isNewUser){
                    requestPhoneVerification();
                    window.dataLayer.push({
                        event: "custom_event",
                        event_name: "signup",
                        method: "facebook"
                    })
                } else {
                    window.dataLayer.push({
                        event: "custom_event",
                        event_name: "login",
                        method: "facebook"
                    })
                }
                handleClose();
            }
        }
    };
    return (
        <FacebookLogin
            appId={CONSTANTS.FB_ID}
            autoLoad={false}
            fields="id,name,emails,picture"
            version="17.0"
            callback={responseFacebook}
            isMobile={false}
            render={renderProps => (
                <CommonButton
                    variant="contained"
                    type="secondary"
                    style={{ width: '100%', fontWeight: 700, color: 'white', backgroundColor: "#007AFF" }}
                    startIcon={<img src={FacebookIcon} />}
                    onClick={renderProps.onClick}>
                    {login?t('facebook_signin'):t('register.facebook_register')}
                </CommonButton>
            )}
        />
    )
}