import UserBlack from "../../assets/UserBlack.svg"
import styled from "styled-components"

export default function UserSignedIn(props) {
  return (
    <Container {...props}>
      <SignedInImg src={UserBlack}/>
    </Container>
  )
}

const Container = styled.div`
  background: #F44434;
  display: flex;
  width: ${(props) => props.width || "53px"};
  height: ${(props) => props.height || "53px"};
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 0px 30px #F44434;
`

const SignedInImg = styled.img`
`
