import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProfile, setUpRefreshTokenLogic, listCartItem } from "./apis";
import SignInPopup from "./components/signin";
import PhoneVerificationPopup from "./components/phone";
export const AppContext = React.createContext();

export function AppProvider(props) {
  const [init, setInit] = useState(false);
  const [user, setUser] = useState(null);
  const [isApp, setIsApp] = useState(false);
  const [signinPopupOpen, setSigninPopupOpen] = useState(false);
  const [phoneVerificationOpen, setPhoneVerificationOpen] = useState(false);
  const [cart, setCart] = useState(false);
  const [currency, setCurrency] = useState("HKD");
  /** * @type {ReturnType<typeof useState<String | null>>} */
  const [tvOtp, setTvOtp] = useState(null);
  /** * @type {ReturnType<typeof useState<String | null>>} */
  const [signInRedirectUrl, setSignInRedirectUrl] = useState(null);
  const { i18n } = useTranslation();

  const value = React.useMemo(() => {
    return {
      init,
      user,
      setUser,
      isApp,
      signinPopupOpen,
      requestSignIn: () => {
        if (!signinPopupOpen && !user) setSigninPopupOpen(true);
      },
      requestPhoneVerification: () => {
        setPhoneVerificationOpen(true);
      },
      cart,
      setCart,
      currency,
      setCurrency,
      tvOtp,
      setTvOtp,
      signInRedirectUrl,
      setSignInRedirectUrl,
    };
  }, [
    init, 
    user, 
    setUser, 
    signinPopupOpen, 
    cart, 
    currency, 
    tvOtp, 
    setTvOtp, 
    signInRedirectUrl, 
    setSignInRedirectUrl
  ]);
  useEffect(async () => {
    setUpRefreshTokenLogic(setUser);
    let isApp = window.location.search.indexOf("app=true") > -1;
    let language =
      window.location.search.indexOf("language=en") > -1
        ? "en"
        : window.location.search.indexOf("language=zh") > -1
        ? "zh"
        : null;
    setIsApp(isApp || false);
    const token = localStorage.getItem("accessToken", null);
    if (token) {
      const response = await getProfile();
      if (response.success && response.data) {
        setUser(response.data);
      }
      setTimeout(async () => {
        const cartsResponse = await listCartItem();
        if (cartsResponse.success && cartsResponse.data) {
          const cartLength = cartsResponse.data.basket?.items?.length || 0;
          setCart(cartLength > 0 ? true : false);
      }}, 5000);
    }
    i18n.changeLanguage(language || localStorage.getItem("language") || "zh");
    // setCurrency(localStorage.getItem("currency") || "HKD");
    setInit(true);
  }, []);
  return (
    <AppContext.Provider value={value}>
      {props.children}
      {!user && (
        <SignInPopup
          open={signinPopupOpen}
          handleClose={() => setSigninPopupOpen(false)}
        />
      )}
      {user && (
        <PhoneVerificationPopup
          open={phoneVerificationOpen}
          handleClose={() => setPhoneVerificationOpen(false)}
        />
      )}
    </AppContext.Provider>
  );
}
