import { Box } from "@mui/material"

import CounterMinus from "../assets/CounterMinus.svg"
import CounterAdd from "../assets/CounterAdd.svg"

import DisableCounterMinus from "../assets/DisableCounterMinus.svg"
import DisableCounterAdd from "../assets/DisableCounterAdd.svg"
import CommonText from "./common/typographies/CommonText"
import ImageButton from './common/buttons/ImageButton';
import { useEffect, useState } from "react"

const MinusButton = ({ disabled, onClick, ...props }) => {

    const handleOnClick = () => {
        !disabled && onClick && onClick()
    }

    return (
        <ImageButton src={disabled ? DisableCounterMinus : CounterMinus} alt="CounterMinus" {...props} onClick={handleOnClick} />
    )
}

const AddButton = ({ disabled, onClick, ...props }) => {

    const handleOnClick = () => {
        !disabled && onClick && onClick()
    }

    return (
        <ImageButton src={disabled ? DisableCounterAdd : CounterAdd} alt="CounterAdd" {...props} onClick={handleOnClick} />
    )
}

function Counter({ onChange, disabled, value }){

    const [count, setCount] = useState(0)

    useEffect(() => {
        if (value) setCount(value)
    }, [value])

    const handleOnClickMinus = () => {
        let v = count - 1
        setCount(v)
        onChange && onChange(v)
    }
    const handleOnClickAdd = () => {
        let v = count + 1
        setCount(v)
        onChange && onChange(v)
    }

    const isDisable = !count

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <MinusButton disabled={disabled || isDisable} onClick={handleOnClickMinus} />
            <CommonText style={{ padding: `0px 10px` }}>{count}</CommonText>
            <AddButton disabled={disabled} onClick={handleOnClickAdd} />
        </Box>
    )
}

export default Counter
