import styled from "styled-components"
import CloseIcon from '../../../assets/Close.svg';
const StyledImage = styled.img`
&& {
    cursor: pointer;
    width: ${props => props.width || "28px"};
    height: ${props => props.height || "28px"};
    object-fit: contain;
    position: absolute;
    top: 45px;
    right: 45px;
    z-index:100;
}
`
export default function CloseButton(props) {
    return (
        <StyledImage src={CloseIcon} {...props} >{props.children}</StyledImage>
    )
}