import { useQuery } from "react-query";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import CommonText from "../../components/common/typographies/CommonText";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Grid, Box, Dialog } from "@mui/material";
import RedBorderCircle from "../../assets/Ellipse.svg";
import Tick from "../../assets/Tick.svg";

function TicketTypeItem({
  option,
  onClick,
  isSelected,
  lang,
  currency,
  price,
  originalPrice,
}) {
  return (
    <Container
      container
      direction="row"
      style={{ backgroundColor: isSelected ? "#F44434" : "transparent" }}
      onClick={onClick}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: 58 }}
      >
        {isSelected && <img src={Tick} alt="Tick" />}
      </Grid>

      <Grid
        item
        xs
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ borderLeft: `1px solid rgba(225, 225, 225, 0.3)` }}
      >
        <Grid item xs style={{ marginLeft: 15 }}>
          <CommonText textAlign="left" fontSize="17px" lineHeight="20px">
            {option.name[lang]}
          </CommonText>
        </Grid>
        <Grid item style={{ marginRight: 15 }}>
          <CommonText
            style={{ textAlign: "right" }}
            fontSize="17px"
            lineHeight="20px"
          >
            {currency && currency.replace("D", "$")}
            {price}
          </CommonText>
          {price != originalPrice && (
            <CommonText
              fontSize="14px"
              fontWeight="300"
              lineHeight="1"
              style={{ textAlign: "right", textDecoration: "line-through", opacity: 0.7 }}
            >
              {currency && currency.replace("D", "$")}
              {originalPrice}
            </CommonText>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default TicketTypeItem;

const Container = styled(Grid)`
  min-height: 58px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.3);
  cursor: pointer;
`;
