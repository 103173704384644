import Dialog from '@mui/material/Dialog';
import styled from "styled-components";
import { useRef, useState } from 'react';
import PhoneTextField from '../common/textfields/PhoneTextField';
import FormTextField from '../common/textfields/FormTextField';
import { useTranslation } from 'react-i18next';
import CommonText from '../common/typographies/CommonText';
import { updateProfile } from '../../apis';
import VerifyIcon from '../../assets/Verify.svg';
import SuccessIcon from '../../assets/Success.svg';
import Space from '../Space';
import CommonButton from '../common/buttons/CommonButton';
import { breakpoint } from '../../utils/responsive';

export default function SignInPopup({ handleClose, open }) {
    const { t, i18n } = useTranslation("register")
    const phoneOTPRef = useRef();
    const [phoneInput, setPhoneInput] = useState(null);
    const [error, setError] = useState(null);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const handleUpdatePhone = async () => {
        const result = await updateProfile({
            phone: phoneInput,
            phoneOTP: phoneOTPRef.current.value
        });
        if (result.success) {
            setPhoneVerified(true);
            setTimeout(() => {
                setRegisterSuccess(true)
            }, 3000)
        } else {
            // set api error
            if (result.error?.code == "C0004") {
                setError({ phone: t('used_phone') })
            } else if (result.error?.code == "C0005") {
                setError({ phoneOTP: t('invalid_phone_otp') })
            }
        }
    }

    const renderContent = () => {
        if (registerSuccess) {
            return (
                <SuccessContainter>
                    <CommonText fontWeight={600} fontSize="24px" lineHeight="34px">{t('register_success_title')}</CommonText>
                    <img src={SuccessIcon} alt="Success" />
                    {/* <CommonText fontSize="16px" lineHeight="28px">{t('register_success_desc')}</CommonText> */}
                    <SuccessDescription>{t('register_success_desc')}</SuccessDescription>
                    <Space size="30px" />
                    <CommonButton onClick={handleClose} style={{ width: 300 }}>{t('ok')}</CommonButton>
                </SuccessContainter>
            );
        }
        return (
            <PhoneContainer>
                <Space size="54px" />
                <CommonText fontWeight={600} fontSize="24px" lineHeight="34px">{t('phone_verification_title')}</CommonText>
                <Space size="28px" />
                <CommonText fontSize="18px" lineHeight="28px">{t('phone_verification_desc')}</CommonText>
                <Space size="23px" />
                <PhoneTextField errorText={error?.phone} onChange={(value) => setPhoneInput(value)} label={t('phone')} requestOTP />
                <FormTextField errorText={error?.phoneOTP} inputRef={phoneOTPRef} label={t('otp')} />
                <Space size="50px" />
                {phoneVerified && <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={VerifyIcon} alt="verify" />
                    <Space horizontal size="5px" />
                    <SuccessText onClick={handleClose}>{t('verification_success')}</SuccessText>
                </div>}
                <Space size="15px" />
                <CommonButton style={{ width: '100%' }} variant="contained" type={"primary"} onClick={handleUpdatePhone}>{t('confirm')}</CommonButton>
                <SkipButton onClick={() => setRegisterSuccess(true)}>{t('skip')}</SkipButton>
            </PhoneContainer>
        )
    }  
    return <StyledDialog maxWidth={1000} onClose={handleClose} open={open}>
      {renderContent()}
    </StyledDialog>

}

const StyledDialog = styled(Dialog)`
    && .MuiDialog-paper {
        margin: 10px;
    }

`
const PhoneContainer = styled.div`
    width: 100%;
    max-width: 425px;
    padding: 30px;
    @media ${breakpoint.mobile} {
        padding: 15px;
    }
`
const SuccessContainter = styled.div`
    width: 643px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 400px;
        padding: 30px 15px;
    }

    @media (max-width: 420px) {
        width: 350px;
    }
`
const SkipButton = styled(CommonText)`
&& {
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    top: 45px;
    right: 45px;
    z-index:100;
}
`
const SuccessText = styled(CommonText)`
&& {
    font-size: 16px;
    line-height: 28px;
    color: #4285F4;
    text-align: center;
}
`
const SuccessDescription = styled(CommonText)`
  && {
    font-size: 16px;
    line-height: 28px;
    color: #919191;
    text-align: center;

    @media (max-width: 420px) {
        font-size: 14px;
    }
  }
`
