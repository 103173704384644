import { CircularProgress, Divider, FormHelperText } from "@mui/material";
import OtpInput from "react-otp-input";
import { useMutation, useQuery } from "react-query";
// import Loading from "../components/Loading";
import styled, { createGlobalStyle } from "styled-components";
import { WindowSize, breakpoint } from "../utils/responsive";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext";
import { useTranslation } from "react-i18next";
import CommonText from "../components/common/typographies/CommonText";
import OtpBox from "../components/common/otp/OtpBox";
import CommonButton from "../components/common/buttons/CommonButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkTVCode } from "../apis";

const TV_PERMISSION_URL = "/tv-permission";

export default function TVLink() {
  const {
    user,
    requestSignIn,
    setTvOtp,
    setSignInRedirectUrl,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(["tvLink", "common"]);
  const [otp, setOtp] = useState("");
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [isExpiredOtp, setIsExpiredOtp] = useState(false);
  const checkTvCodeMutation = useMutation({
    mutationFn: checkTVCode,
    onSuccess: (data) => { 
      if(!data?.success){
        if (data?.error?.code === "C0001") 
          setIsInvalidOtp(true);
        else if (data?.error?.code === "C0002") 
          setIsExpiredOtp(true);
      } else {
        if (!user) {
          setSignInRedirectUrl(TV_PERMISSION_URL);
          requestSignIn();
        } else {
          navigate(TV_PERMISSION_URL);
        }
      }
    },
    onError: (error) => {
      setIsInvalidOtp(true);
    },
  });

  useEffect(() => {
    if (searchParams.get("code")) {
      setOtp(searchParams.get("code"));
      setTvOtp(searchParams.get("code"));
      checkTvCodeMutation.mutate(searchParams.get("code"));
    }

  }, [searchParams.get("code")])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextStepClick = () => {
    setTvOtp(otp);
    checkTvCodeMutation.mutate(otp);
  };

  const isWindowMobile = WindowSize() === "mobile";

  return (
    <div>
      {/* <HomeBanner /> */}
      <HeaderBackground />
      <Divider />
      <Container>
        <CommonText
          fontSize="24px"
          lineHeight="34px"
          fontWeight={600}
          style={{
            width: "100%",
            textAlign: isWindowMobile ? "left" : "center",
          }}
        >
          {t("tvLink:title_login_tv_application")}
        </CommonText>
        <Space1 />
        <CommonText
          fontWeight={400}
          fontSize="16px"
          lineHeight="28px"
          style={{
            width: "100%",
            textAlign: isWindowMobile ? "left" : "center",
          }}
        >
          {t("tvLink:message_input_otp")}
        </CommonText>
        <Space2 />
        <InputContainer>
          <OtpInput
            shouldAutoFocus
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={(index) =>
              index === 2 ? <Separator /> : <div style={{ width: 8 }} />
            }
            renderInput={(props, index) => <OtpBox inputProps={props} />}
          />
          {isInvalidOtp && (
            <div style={{ width: isWindowMobile ? "344.97px" : "100%" }}>
              <StyledErrorText>
                {t("common:otp_invalid_message")}
              </StyledErrorText>
            </div>
          )}
          {isExpiredOtp && (
            <div style={{ width: isWindowMobile ? "344.97px" : "100%" }}>
              <StyledErrorText>
                {t("common:otp_expired_message")}
              </StyledErrorText>
            </div>
          )}
        </InputContainer>
        <CommonButton
          style={{ width: "100%" }}
          disabled={otp.length < 6}
          onClick={checkTvCodeMutation.isLoading ? null : handleNextStepClick}
          disabled_background_color={"#1F1F21"}
        >
          {checkTvCodeMutation.isLoading ? (
            <CircularProgress size={20} style={{ color: "#fff" }} />
          ) : (
            t("common:next_step")
          )}
        </CommonButton>
        <Space3 />
      </Container>
    </div>
  );
}

const HeaderBackground = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 75px;
  z-index: 1;
  background-color: black;
  @media ${breakpoint.mobile} {
    height: 60px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  padding-top: 105px;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoint.mobile} {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Space1 = styled.div`
  height: 28px;
  @media ${breakpoint.mobile} {
    height: 30px;
  }
`;

const Space2 = styled.div`
  height: 20px;
  @media ${breakpoint.mobile} {
    height: 24px;
  }
`;

const Space3 = styled.div`
  height: 268px;
`;

const InputContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 20px;

  @media ${breakpoint.mobile} {
    margin-bottom: 44px;
  }
`;

const StyledErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 0;
    color: #ff0202;
  }
`;

const Separator = styled.div`
  background-color: #fff;
  height: 4px;
  width: 13px;
  margin-left: 8px;
  margin-right: 8px;
`;
