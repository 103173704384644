import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export default function BlackButton(props) {

  return (
    <StyledButton {...props}>{props.children}</StyledButton>
  );
}

const hover_background_color = 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #000;';
const StyledButton = styled(Button)`
  && {
    height: 48px;
    color: ${props => props.disabled ? "grey" : "#fff"} !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    background-color: #000000;
    &:hover {
      background: ${hover_background_color};
    }
  }
`;
