import React from 'react';
import styled from 'styled-components';
import CommonLabel from "../labels/CommonLabel";
import CommonTextField from "./CommonTextField";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Space from '../../Space';

// Input default: children 20px, black, 100% width as parent
// size full width depends on parents div
// Options(Boolean): disabled, required(not imp yet), readOnly
// Options(String): label, placeholder, helperText, errorText
// Options(Others): onChange
// Sample Usage: <FormTextfield placeholder="請輸入" label="電郵地址" errorText="錯誤碼位置" helperText="Helper Text"></FormTextfield>

function FormTextfield(props) {
    let {
        helperText,
        errorText,
        errorColor,
        children,
        label,
    } = props;

    return (
        <StyledDiv>
            <StyledLabelDiv>
                <CommonLabel>{label ? label : ''}</CommonLabel>
            </StyledLabelDiv>
            {label ? <Space size="8px" /> : null }
            <StyledFormControl>
                <CommonTextField
                    {...props}
                    label=""
                    helperText=""
                    hoverBorderColor="#fff"
                >
                    {children}
                </CommonTextField>
                {errorText && <StyledErrorText color={errorColor}>{errorText ? errorText : ''}</StyledErrorText>}
                {helperText && <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>}
            </StyledFormControl>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
`;

const StyledLabelDiv = styled.div`
    display: flex;
    justify-content: left;
`;

const StyledFormControl = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const StyledErrorText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        color: ${props => props.color ?? "#FF5C00"};
    }
`;

const StyledHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        font-size: 14px;
        line-height: 20px;
        color: white;
    }
`;

export default FormTextfield;