import React, { useState } from 'react';
import styled from 'styled-components';
import CommonLabel from "../../components/common/labels/CommonLabel";
import CommonTextField from "../../components/common/textfields/CommonTextField";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Space from '../../components/Space';
import WhiteTick from "../../assets/WhiteTick.svg";
import InputAdornment from '@mui/material/InputAdornment';
import MyAccountEdit from "../../assets/MyAccountEdit.svg";

function EditFormTextfield(props) {
    let {
        helperText,
        errorText,
        children,
        label,
        HandleSubmit,
    } = props;
    const [edit, setEdit] = useState(false);

    const HandleAddressSubmit = () =>{
        setEdit(false);
        HandleSubmit();
    }

    return (
        <StyledDiv>
            <StyledLabelDiv>
                <CommonLabel>{label ? label : ''}</CommonLabel>
            </StyledLabelDiv>
            <Space size="8px" />
            <StyledFormControl>
                <CommonTextField
                    {...props}
                    label=""
                    helperText=""
                    disabled={!edit}
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "white",
                        },
                    }}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                {
                                    edit? 
                                    <img style={{ cursor:'pointer' }} onClick={() => HandleAddressSubmit()} src={WhiteTick}/> 
                                    : 
                                    <img style={{ cursor:'pointer' }} onClick={() => setEdit(true)} src={MyAccountEdit}/>
                                }
                                
                            </InputAdornment>,
                    }}
                    style={{ background: edit? 'black' : 'rgba(31, 31, 33, 1)' }}
                >
                    {children}
                </CommonTextField>
                <StyledErrorText>{errorText ? errorText : ''}</StyledErrorText>
                <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>
            </StyledFormControl>

        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
`;

const StyledLabelDiv = styled.div`
    display: flex;
    justify-content: left;
`;

const StyledFormControl = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const StyledErrorText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        color: #FF5C00;
    }
`;

const StyledHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
    }
`;

export default EditFormTextfield;