import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { listTicketByStreaming } from "../apis";
import CommonText from "../components/common/typographies/CommonText";
import Loading from "../components/Loading";
import Player from "../components/Player";
import Countdown from "react-countdown";
import LiveBackground from "../assets/LiveBackground.svg";
import RedCircleIcon from "../assets/RedCircle.svg";
import Space from "../components/Space";
import EventTncButton from "../pageComponents/eventDetail/EventTncButton";
import NoticeButton from "../pageComponents/eventDetail/NoticeButton";
import { Dialog } from "@mui/material";
import EventTnc from "../pageComponents/eventDetail/EventTnc";
import { WindowSize, breakpoint } from "../utils/responsive";
import PlayIcon from "../assets/Play.svg";

export default function Live() {
  const { id } = useParams();
  const { isLoading, isError, data, error } = useQuery(
    ["listTicketByStreaming", id],
    () => listTicketByStreaming(id)
  );
  const [multiview, setMultiview] = useState(null);
  const [liveCountdown, setLiveCountDown] = useState(null);
  const [streamCountdown, setStreamCountDown] = useState(null);
  const [showTNC, setShowTNC] = useState(false);
  const { t, i18n } = useTranslation("live");
  let navigate = useNavigate();
  useEffect(() => {
    // check ticket
    if (data?.data) {
      if (data.data) {
        // check stream time
        let streaming = data.data;
        let liveStart = dayjs(streaming.streamStart);
        let streamStart = dayjs(streaming.streamStart).subtract(
          streaming.streamStartOffset || 120,
          "minute"
        );
        let streamEnd = dayjs(streaming.streamEnd);
        let currentTime = dayjs();
        let diff = streamStart.diff(currentTime, "hour");
        if (currentTime > streamStart && currentTime < streamEnd) {
          // live ongoing
          // if (streaming.multiViews && streaming.multiViews.length > 0) setMultiview(streaming.multiViews[0]);
          if (currentTime < liveStart) {
            setLiveCountDown(Number(liveStart.valueOf()));
          }
        } else if (currentTime < streamStart && diff <= 48) {
          // show testing video
          setMultiview({ contentId: "preshow-testing", isMultiCDN: false });
          setStreamCountDown(Number(streamStart.valueOf()));
          if (currentTime < liveStart) {
            setLiveCountDown(Number(liveStart.valueOf()));
          }
        } else navigate("/", { replace: true });
      } else {
        // no ticket
        navigate("/", { replace: true });
      }
    }
  }, [data]);
  const isMobile = WindowSize() === "mobile";
  const streamcountdownRenderer = ({ hours, minutes, seconds, completed }) => {
    return null;
  };
  const livecountdownRenderer = ({ hours, minutes, seconds, completed }) => {
    let hour = parseInt((liveCountdown - dayjs()) / 1000 / 60 / 60);
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div style={{ display: "flex" }}>
          <CommonText fontSize="16px" lineHeight="28px">
            {t("timeremaining")}
          </CommonText>
          <Space horizontal size="5px" />
          <CommonText
            style={{ color: "#F44434" }}
            fontSize="16px"
            lineHeight="28px"
          >
            {String(hour).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
            {String(seconds).padStart(2, "0")}
          </CommonText>
        </div>
      );
    }
  };
  const onCompleteLiveCountDown = () => {
    setLiveCountDown(null);
  };
  const onCompletesStreamCountDown = () => {
    setStreamCountDown(null);
    setMultiview(data?.data?.multiViews?.[0]);
  };
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  let streaming = data?.data;
  let lang = `${i18n.language[0].toUpperCase()}${i18n.language
    .slice(1)
    .toLowerCase()}`;
  return (
    <Container>
      <PlayerContainer>
        {multiview ? (
          <Player
            title={`${streaming?.event?.eventNameZh}:${streaming?.event?.sponsorZh} - ${multiview.labelZh}`}
            contentId={multiview.contentId}
            isMultiCDN={multiview.isMultiCDN}
            streaming={id}
            onError={() => setMultiview(null)}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => setMultiview(data?.data?.multiViews?.[0])}
          >
            <img src={PlayIcon} width={80} />
          </div>
        )}
      </PlayerContainer>
      {streamCountdown ? (
        <Countdown
          date={streamCountdown}
          renderer={streamcountdownRenderer}
          onComplete={onCompletesStreamCountDown}
        />
      ) : (
        <PlayerBottomContainer>
          <PlayerBottomScrollBox>
            <PlayerBottomView>
              {streaming?.multiViews &&
                streaming?.multiViews.length > 1 &&
                streaming?.multiViews?.map((item, index) => {
                  return (
                    <MultiviewItem
                    onClick={() => {
                      if (item != multiview) setMultiview(item);
                    }}
                    selected={multiview ? multiview == item : index == 0}
                    fontSize="15px"
                    lineHeight="24px"
                    >
                      {i18n.language == "zh" ? item.labelZh : item.labelEn}
                    </MultiviewItem>
                  );
                })}
            </PlayerBottomView>
          </PlayerBottomScrollBox>
        </PlayerBottomContainer>
      )}
      {liveCountdown && (
        <CountDownView>
          <Countdown
            date={liveCountdown}
            renderer={livecountdownRenderer}
            onComplete={onCompleteLiveCountDown}
          />
        </CountDownView>
      )}
      <Space size="30px" />
      <InfoContainer>
        {streaming?.[`noticeTitle${lang}`] ? (
          <NoticeButton
            label={streaming?.[`noticeTitle${lang}`]}
            content={streaming?.[`noticeContent${lang}`]}
            style={{ width: "100%", marginBottom: 30 }}
          />
        ) : null}
        <CommonText fontWeight={600} fontSize="16px" lineHeight="20px">
          {i18n.language == "zh"
            ? streaming?.event?.sponsorZh
            : streaming?.event?.sponsorEn}
        </CommonText>
        <CommonText fontWeight={600} fontSize="28px" lineHeight="1.2">
          {i18n.language == "zh"
            ? streaming?.event?.eventNameZh
            : streaming?.event?.eventNameEn}
        </CommonText>
        <div style={{ display: "flex", marginTop: 10 }}>
          <img style={{ transform: isMobile ? "scale(0.67)" : "scale(1)"}} src={RedCircleIcon} alt="live" />
          <Space horizontal size="5px" />
          <CommonText
            style={{ color: "#F44434" }}
            fontSize={isMobile? "14px": "16px"}
            lineHeight="28px"
          >
            {t("live")}
          </CommonText>
        </div>
        <Space size="30px" />
        <CommonText
          style={{ textAlign: "left" }}
          fontSize="15px"
          lineHeight="1.6"
        >
          {i18n.language == "zh"
            ? streaming?.event?.infoZh
            : streaming?.event?.infoEn}
        </CommonText>
        <Space size="60px" />
        <EventTncButton onClick={() => setShowTNC(true)} />
      </InfoContainer>
      <Dialog open={showTNC} onClose={() => setShowTNC(false)}>
        <EventTnc
          onClose={() => setShowTNC(false)}
          data={
            i18n.language == "zh"
              ? streaming?.event?.tncZh
              : streaming?.event?.tncEn
          }
        />
      </Dialog>
    </Container>
  );
}

const Container = styled.div`
  background: url(${LiveBackground}) no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  padding: 60px;
  @media ${breakpoint.mobile} {
    padding: 0px;
  }
`;
const PlayerContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 50vw;
  max-height: 675px;
  margin: auto;
  background: black;
  @media ${breakpoint.mobile} {
    height: 56.23vw;
  }
`;

const PlayerBottomContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  // mask
  -webkit-mask-image: linear-gradient(90deg, #000 94.79%, rgba(0, 0, 0, 0.00) 100%);
  mask-image: linear-gradient(90deg, #000 94.79%, rgba(0, 0, 0, 0.00) 100%);

`;

const PlayerBottomScrollBox = styled.div`
  width: max-content; 
  max-width: 100%;
  overflow-x: auto;
`;
const PlayerBottomView = styled.div`
  width: fit-content;
  /* max-width: 1200px; */
  margin: auto;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;

  padding: 0px 30px;

  @media ${breakpoint.mobile} {
    padding: 0px 15px;
  }

`;
const CountDownView = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
`;

const InfoContainer = styled.div`
  max-width: 874px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  @media ${breakpoint.mobile} {
    padding: 30px 15px;
  }
`;
const MultiviewItem = styled(CommonText)`
  color: white;
  background-color: ${(props) => (props.selected ? "#F44434" : "#1f1f21")};
  box-shadow: ${(props) => (props.selected ? "0px 0px 30px #F44434" : "none")};
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  padding: 5px 10px;
  margin: 7px 5px;
  border: 1px solid ${(props) => (props.selected ? "#f44434" : "#363636")};
  white-space: nowrap;
  cursor: pointer;
  :hover {
    background-color: ${(props) => (props.selected ? "#F44434" : "#2d2d2d")};
    border: 1px solid #f44434;
  }
`;
