import { CircularProgress, Divider } from "@mui/material";
import OtpInput from "react-otp-input";
import { useMutation, useQuery } from "react-query";
import Loading from "../components/Loading";
import styled, { createGlobalStyle } from "styled-components";
import { WindowSize, breakpoint } from "../utils/responsive";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext";
import { useTranslation } from "react-i18next";
import CommonText from "../components/common/typographies/CommonText";
import CommonButton from "../components/common/buttons/CommonButton";
import SigninIcon from "../assets/Signin2x.svg";
import SuccessSvg from "../assets/tv_permission_success.svg";
import FailureSvg from "../assets/tv_permission_fail.svg";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../components/logout";
import SignInPopup from "../components/signin";
import { bindTVCode } from "../apis";

export default function TVPermission() {
  const { user, tvOtp, setTvOtp, setSignInRedirectUrl } =
    useContext(AppContext);
  const { t } = useTranslation(["tvLink", "common"]);
  const navigate = useNavigate();
  const logout = useLogout({ forceRedirect: false });
  /** * @type {ReturnType<typeof useState<boolean| null>>} */
  const [isLinkSuccess, setIsLinkSuccess] = useState(null);
  const [signinPopupOpen, setSigninPopupOpen] = useState(false);

  const bindTvCodeMutation = useMutation({
    mutationFn: bindTVCode,
    onSuccess: (data) => {
      if (data?.success === true) {
        setIsLinkSuccess(true);
        setTvOtp(null);
      } else {
        setIsLinkSuccess(false);
      }
    },
    onError: (error) => {
      setIsLinkSuccess(false);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextStepClick = () => {
    bindTvCodeMutation.mutate(tvOtp);
  };

  const handleLoginWithAnotherUser = () => {
    logout();
    setSignInRedirectUrl("/tv-permission");
    setSigninPopupOpen(true);
  };

  const handleSignInPopupClose = () => {
    setSigninPopupOpen(false);
    setIsLinkSuccess(null);
  };

  const PermissionBox = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <img src={SigninIcon} alt="Logo" />
        </div>
        <div>
          <Space2 />
          <UserNameText>
            {String.prototype.toUpperCase.call(user?.username ?? "")}
          </UserNameText>
          <EmailText>{user?.email}</EmailText>
        </div>
        <Space3 />
        <div>
          <PermissionMessage>
            {t("tvLink:message_permission_message_line_1")}
          </PermissionMessage>
        </div>
        <Space4 />
        <CommonButton
          style={{ width: "100%" }}
          onClick={bindTvCodeMutation.isLoading ? null : handleNextStepClick}
        >
          {bindTvCodeMutation.isLoading ? (
            <CircularProgress size={20} style={{ color: "#fff" }} />
          ) : (
            t("common:button.allow")
          )}
        </CommonButton>
        <Space5 />
        <UnderlinedButton type="button" onClick={handleLoginWithAnotherUser}>
          {t("common:button.signInWithAnotherAccount")}
        </UnderlinedButton>
        <Space6 />
      </React.Fragment>
    );
  };

  const SuccessBox = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <img src={SuccessSvg} alt="Logo" />
        </div>
        <Space7 />
        <LinkResponseMessage>
          {t("tvLink:message_link_success")}
        </LinkResponseMessage>
        <Space8 />
      </React.Fragment>
    );
  };

  const FailureBox = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <img src={FailureSvg} alt="Logo" />
        </div>
        <Space7 />
        <LinkResponseMessage>
          {t("tvLink:message_link_fail")}
        </LinkResponseMessage>
        <Space9 />
        <CommonButton
          style={{ width: "100%" }}
          onClick={handleLoginWithAnotherUser}
        >
          {t("common:button.reSignIn")}
        </CommonButton>
        <Space10 />
      </React.Fragment>
    );
  };

  if (
    isLinkSuccess == null &&
    signinPopupOpen === false &&
    (tvOtp == null || user == null)
  ) {
    navigate("/tvapp");
  }

  return (
    <div>
      {/* <HomeBanner /> */}
      <HeaderBackground />
      <Divider />
      <Container>
        <CommonText
          fontSize="24px"
          lineHeight="34px"
          fontWeight={600}
          style={{
            width: "100%",
            textAlign: WindowSize() === "mobile" ? "left" : "center",
          }}
        >
          {t("tvLink:title_login_tv_application")}
        </CommonText>
        <Space1 />
        {isLinkSuccess == null && (user == null ? <></> : <PermissionBox />)}
        {isLinkSuccess != null && isLinkSuccess === true && <SuccessBox />}
        {isLinkSuccess != null && isLinkSuccess === false && <FailureBox />}
      </Container>
      <SignInPopup
        open={signinPopupOpen}
        handleClose={handleSignInPopupClose}
      />
    </div>
  );
}

const HeaderBackground = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 75px;
  z-index: 1;
  background-color: black;
  @media ${breakpoint.mobile} {
    height: 60px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  min-height: 611px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 105px;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoint.mobile} {
    max-width: none;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 584px;
  }
`;

const Space1 = styled.div`
  height: 35.71px;
  @media ${breakpoint.mobile} {
    height: 30px;
  }
`;

const Space2 = styled.div`
  height: 8px;
`;

const Space3 = styled.div`
  height: 32px;
  @media ${breakpoint.mobile} {
    height: 48px;
  }
`;

const Space4 = styled.div`
  height: 20px;
`;

const Space5 = styled.div`
  height: 24px;
`;

const Space6 = styled.div`
  height: 89px;
  @media ${breakpoint.mobile} {
    height: 114px;
  }
`;

const Space7 = styled.div`
  height: 0px;
  @media ${breakpoint.mobile} {
    height: 20px;
  }
`;

const Space8 = styled.div`
  height: 184px;
  @media ${breakpoint.mobile} {
    height: 138px;
  }
`;

const Space9 = styled.div`
  height: 20px;
`;

const Space10 = styled.div`
  height: 88px;
  @media ${breakpoint.mobile} {
    height: 2px;
  }
`;

const UserNameText = styled.p`
  //styleName: Tablet/Display/D1 - 50;
  font-family: Clash Grotesk;
  font-size: 32px;
  font-weight: 600;
  line-height: 35.2px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  max-width: 300px;
  word-break: break-word;
  white-space: pre-wrap;
  
`;

const EmailText = styled.p`
  //styleName: Body (All Devices)/B2 - 16R;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  opacity: 70%;
  margin: 0 auto;
`;

const PermissionMessage = styled.p`
  //styleName: Body (All Devices)/B2 - 16R;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin: 0;
`;

const LinkResponseMessage = styled.p`
  //styleName: Body (All Devices)/B0 - 20R;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin: 0;
`;

const UnderlinedButton = styled.button`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-decoration: underline;
  color: #ffffffcc;
  cursor: pointer;
  background: transparent;
  border: none;
`;

const Separator = styled.div`
  background-color: #fff;
  height: 4px;
  width: 13px;
  margin-left: 8px;
  margin-right: 8px;
`;
