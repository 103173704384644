import styled from 'styled-components';
import { CommonDialog } from './commonDialog';
import React from "react";
import CustomButton from '../buttons/CustomButton';
import { breakpoint } from '../../../utils/responsive';
import CommonText from '../typographies/CommonText';
import Space from '../../Space';

export function TitleIconButtonDialog(props) {
  const { title, body, icon, ImgComp, visible, setVisible, buttonText, onClick, ...other } = props;
  return (
    <CommonDialog
      visible={visible}
      {...other}
    >
      <StyledDiv>
        <CommonText type="display" textAlign="center" sizeType="Heading2">{title}</CommonText>
        {ImgComp && <div style={{ margin:'15px'}}><ImgComp></ImgComp></div>}
        {icon && <div style={{ margin:'15px'}}><img src={icon} alt="icon"></img></div>}
        {body && <CommonText style={{marginBottom:"15px", whiteSpace: "pre-wrap"}} textAlign="center" color="#BCBCBC" type="body" sizeType="Body">{body}</CommonText>}
        <Space size="15px"></Space>
        <StyledButton onClick={onClick ? () => {setVisible(false); onClick();} : () => setVisible(false)}>
          <CommonText type="title" sizeType="Heading5">{buttonText}</CommonText>
        </StyledButton>
      </StyledDiv>
    </CommonDialog>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #1F1F21;
  @media ${breakpoint.mobile} {
      width: 300px;
      padding: 20px;
  }
  @media ${breakpoint.desktop} {
      width: 400px;
      padding: 30px;
  }
  @media ${breakpoint.largeDesktop} {
      width: 640px;
      padding: 30px;
  }
`;

const StyledButton = styled(CustomButton)`
  @media ${breakpoint.mobile} {
      width: 260px;
      height: 48px;
  }
  @media ${breakpoint.desktop} {
      width: 325px;
      height: 48px;
  }
  @media ${breakpoint.largeDesktop} {
      width: 300px;
      height: 45px;
  }
`;