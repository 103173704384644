import { Dialog, IconButton, DialogContent } from "@mui/material";
import React from "react";
import styled from "styled-components";
import IconClose from "../../../assets/Close.svg";
import CommonText from "../typographies/CommonText";

export function CommonDialog(props) {
  const { onClose, visible, children, fullScreen, ...other } = props;
  return (
    <Dialog
      maxWidth={false}
      onClose={onClose}
      open={visible}
      fullScreen={fullScreen}
      style={{ width: "100%" }}
      {...other}
    >
      {children}
    </Dialog>
  );
}

export default function CommonCloseDialog(props) {
  const { onClose, width, visible, children, fullScreen, title, titleStyle, padding, ...other } = props;

  return (
    <CommonDialog
      maxWidth={false}
      onClose={onClose}
      open={visible}
      fullScreen={fullScreen}
      {...other}
    >
      <Container width={width}>
        <PaddingDiv fullScreen={fullScreen} padding={padding}>
          <TitleDiv>
            <CommonText fontSize={titleStyle?.fontSize || "24px"} mFontSize={titleStyle?.mFontSize || "16px"} fontWeight={titleStyle?.fontWeight || "600"}>{title}</CommonText>
            <IconButton style={{ alignSelf: "end" }} onClick={onClose}>
              <img src={IconClose} alt="Close" />
            </IconButton>
          </TitleDiv>
          {children}
        </PaddingDiv>
      </Container>
    </CommonDialog>
  );
}

const Container = styled.div`
  width: ${(props) => (props.width || "100%")};
  height: 100%;
`;

const PaddingDiv = styled.div`
  padding: ${(props) => (props.padding || (props.fullScreen ? "20px" : "60px"))};
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
