import styled from 'styled-components';
import { useEffect, useMemo } from "react";
import { Grid } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, } from 'react-i18next';
import CommonText from '../components/common/typographies/CommonText';
import MenuItem from '../components/common/menuItems/CommonMenuItem';
import { ResponsiveUI, WindowSize } from "../utils/responsive";
import CommonDivider from "../components/common/dividers/CommonDivider";
import CustomButton from '../components/common/buttons/CustomButton';
import PICS from '../pageComponents/privatePolicy/PICS';

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PrivatePolicy() {
    const { t } = useTranslation('privatePolicy');
    const location = useLocation()
    const navigate = useNavigate();
    const query = useQuery();
    const currentId = query.get('id') || "";

    const Pics = PICS();
    const MENU_ITEMS = Object.keys(t("menu", { returnObjects: true }));
    const tvApp = window.location.search.indexOf("tvapp=true") > -1;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (location.pathname === '/private-policy' && (!location.search || !currentId || !MENU_ITEMS.includes(currentId))) {
            navigate(`/private-policy?id=${MENU_ITEMS[0]}`, { replace: true })
        }
    }, [location, query, MENU_ITEMS, currentId, navigate])

    const onClickHandler = (itemId) => {
        if (itemId === "privacyStatement") {
            window.open('http://www.pccw.com/privacy-statement/index.page?locale=zh', '_blank');
        } else {
            navigate(`/private-policy?id=${itemId}`, { replace: true })
        }
    }

    return (
        <Grid container direction={'column'} style={{ padding: `0px 5% 0px 5%` }}>
            {!tvApp && (
                <>
                    <Grid item xs style={{ 
                        marginTop: WindowSize() === "mobile" ? 30 : 60, 
                        marginBottom: WindowSize() === "mobile" ? 30 : 60, 
                    }}>
                        {/* Heading 1 Size */}
                        <CommonText style={{ textAlign: 'center' }} sizeType='Heading1' type='display'>{t(`privacy_terms`)}</CommonText>
                    </Grid>
                    <CommonDivider color={'#ffffff'} opacity={0.5}></CommonDivider>
                </>
            )} 

            <ResponsiveUI
                mobile={
                    <Grid xs item direction='column' style={{ width: '100%', margin: `${tvApp? "24px" : "50px"} 10px` }}>
                        {!tvApp && (
                            <StyledMenuButtonDiv>
                                {
                                    MENU_ITEMS.map((itemId) => {
                                        return (
                                            <div onClick={() => onClickHandler(itemId)} style={{ textDecoration: 'none', margin: '0px 10px' }} key={itemId}>
                                                <CustomButton
                                                    style={{
                                                        background: currentId === itemId ? '#F44434' : '#000',
                                                        border: '1px solid rgba(255,255,255,0.5)',
                                                        whiteSpace: "nowrap",
                                                        padding: '12px 20px'
                                                    }}
                                                >
                                                    {t(`menu.${itemId}`)}
                                                </CustomButton>
                                            </div>
                                        )
                                    })
                                }
                            </StyledMenuButtonDiv>
                        )}
                        
                        <Grid item xs direction='row' style={{ width: '100%', marginTop: 15 }}>
                            {
                                location.search.indexOf("id=PICS") > -1  &&
                                <div style={{ width: '100%' }}>{Pics}</div>
                            }

                            {
                                location.search.indexOf("id=privacyStatement") > -1 &&
                                <a href="http://www.pccw.com/privacy-statement/index.page?locale=zh">http://www.pccw.com/privacy-statement/index.page?locale=zh</a>
                            }
                        </Grid>
                    </Grid>
                }

                desktop={
                    <Grid item xs container direction='row' style={{ margin: '50px 10px', alignItems: 'flex-start' }}>
                        <Grid item xs={3} style={{ position: 'sticky', top: 100 }} >
                            {
                                MENU_ITEMS.map((itemId) => {
                                    return (
                                        <div onClick={() => onClickHandler(itemId)} style={{ textDecoration: 'none' }} key={itemId}>
                                            <MenuItem
                                                label={t(`menu.${itemId}`)}
                                                mb={1}
                                                selected={currentId === itemId}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs style={{ marginLeft: 30 }}>
                            {
                                location.search.indexOf("id=PICS") > -1 &&
                                <div>{Pics}</div>
                            }

                            {
                                location.search.indexOf("id=privacyStatement") > -1 &&
                                <a href="http://www.pccw.com/privacy-statement/index.page?locale=zh">http://www.pccw.com/privacy-statement/index.page?locale=zh</a>
                            }
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    )
}


const StyledMenuButtonDiv = styled.div`
    margin: 30px 0px;
    width: 100%;
    overflow-x: scroll; 
    display:flex;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;