import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonLabel from "../labels/CommonLabel";
import CommonTextField from "./CommonTextField";
import FormHelperText from '@mui/material/FormHelperText';
import { requestEmailOTP } from '../../../apis';
import { useTranslation } from 'react-i18next';
import constants from '../../../constants';
import { AppContext } from '../../../AppContext';
import Countdown from 'react-countdown';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import FailIcon from "../../../assets/Fail.svg";
import Space from '../../Space';
import CommonButton from '../buttons/CommonButton';
import TextButton from '../buttons/TextButton';
import CloseButton from '../buttons/CloseButton';
import CommonText from '../typographies/CommonText';
import { TitleIconButtonDialog } from '../dialogs/TitleIconButtonDialog';
import ExclamationRoundOrange from '../../icons/ExclamationRoundOrange';
function EmailTextfield(props) {
    let {
        helperText,
        errorText,
        errorColor,
        label,
        requestOTP,
        defaultValue,
        purpose,
        username
    } = props;
    let localStorageCountDownKey = purpose + "_emailOTP"
    const navigate = useNavigate();
    const [email, setEmail] = useState(defaultValue)
    const [countdown, setCountDown] = useState(null);
    const [userExist, setUserExist] = useState(false);
    const [error, setError] = useState(null)
    const { requestSignIn } = useContext(AppContext);
    const { t } = useTranslation('register');
    useEffect(() => {
        const count = localStorage.getItem(localStorageCountDownKey, null);
        if (count && count > Date.now()) {
            setCountDown(Number(count))
        } else {
            onCompleteCountDown()
        }
    }, [])
    const handleRequestOTP = async () => {
        const validEmail = new RegExp(constants.EMAIL_REGEX);
        if (validEmail.test(email)) {
            const result = await requestEmailOTP(email, purpose, username);
            if (result.success) {
                let emailCountDown = Date.now() + 600 * 1000
                setCountDown(emailCountDown);
                localStorage.setItem(localStorageCountDownKey, emailCountDown);
                setError(t('email_otp_sent'))
            } else {
                if (result.error?.code == "C0001") setUserExist(true)
                else setError(t('invalid_email'))
            }
        } else {
            setError(t('invalid_email'))
        }
    }
    const onCompleteCountDown = () => {
        // Clear Email count down
        localStorage.removeItem(localStorageCountDownKey)
        setCountDown(null);
    }
    const handleChange = (event) => {
        setEmail(event.target.value);
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <TextButton style={{ width: 120, textAlign: 'right' }} onClick={handleRequestOTP}>{t('request_otp')}</TextButton>;
        } else {
            // Render a countdown
            return <span>{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}</span>;
        }
    };
    return (
        <StyledDiv>
            <StyledLabelDiv>
                <CommonLabel>{label ? label : ''}</CommonLabel>
            </StyledLabelDiv>
            <Space size="8px" />
            <CommonTextField
                {...props}
                label=""
                type="email"
                InputProps={{
                    endAdornment: requestOTP ? (countdown ? <Countdown date={countdown} renderer={renderer} onComplete={onCompleteCountDown} /> : <TextButton style={{ width: 120, textAlign: 'right' }} onClick={handleRequestOTP}>{t('request_otp')}</TextButton>) : null,
                    style: { height: 48, fontFamily:'Roboto',  }
                }}
                onChange={handleChange}
                hoverBorderColor="#fff"
            />
            <StyledErrorText color={errorColor}>{error || (errorText ? errorText : '')}</StyledErrorText>
            <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>
            <TitleIconButtonDialog
                visible={userExist}
                setVisible={setUserExist}
                title={t("attention")}
                body={t("user_exist")}
                ImgComp={ExclamationRoundOrange}
                buttonText={t("login_now")}
                onClick={() => {
                    requestSignIn(); 
                    setUserExist(false);
                }}
            />
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
`;

const StyledLabelDiv = styled.div`
    display: flex;
    justify-content: left;
`;

const StyledErrorText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        color: ${props => props.color ?? "#FF5C00"};
    }
`;

const StyledHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
    }
`;

export default EmailTextfield;