import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TicketCard from "./TicketCard";
import CustomButton from "../../components/common/buttons/CustomButton";
import CommonDialog from "../../components/common/dialogs/commonDialog";
import FeedbackDialog from "./FeedbackDialog";
import { useState } from "react";
import { breakpoint } from "../../utils/responsive";
import { Divider } from "@mui/material";

const CategoryList = ({ title, list, t, feedbackOnClick }) => {
  if (list && list.length > 0) {
    //TODO submit value from api
    const submitted = false;
    return (
      <div>
        <CategoryTitle>
          <CommonText fontSize="14px" textAlign="left" color="#BCBCBC">
            {title}
          </CommonText>
        </CategoryTitle>

        <StyledItem>
          {list &&
            list.map((item, idx) => {
              return (
                <TicketCard
                  data={item}
                  subtitle={t("ended")}
                  button={
                    submitted === true ? (
                      <FeedbackSentText>
                        <CommonText background="#1F1F21" color="#FFFFFF50">
                          {t("feedbackSent")}
                        </CommonText>
                      </FeedbackSentText>
                    ) : (
                      <CustomButton
                        style={{ width: "100%" }}
                        background="#1F1F21"
                        onClick={() => {
                          feedbackOnClick(idx);
                        }}
                      >
                        {t("feedback")}
                      </CustomButton>
                    )
                  }
                />
              );
            })}
        </StyledItem>
      </div>
    );
  } else {
    return null;
  }
};

const PastEventList = ({ data }) => {
  const { t } = useTranslation("myTicket");
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(null);

  const feedbackOnClick = (id) => {
    setSelectedId(id);
  };

  return (
    <StyledMainDiv>
      <StyledListDiv>
        <CategoryList
          title={""}
          list={data}
          t={t}
          feedbackOnClick={feedbackOnClick}
        />
      </StyledListDiv>
      <Divider style={{ marginTop: 30 }} />

      {
        <FeedbackDialog
          setSelectedId={setSelectedId}
          visible={selectedId !== null}
          data={data && data[selectedId]}
        />
      }
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  width: auto;
  padding: 15px;

  @media ${breakpoint.desktop} {
    width: 100%;
    padding: 0px;
  }
`;

const StyledListDiv = styled.div`
  height: auto;
  width: 100%;
  min-height: 550px;

  @media ${breakpoint.desktop} {
    padding: 50px;
  }
`;

const StyledItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
`;

const CategoryTitle = styled.div`
  margin-left: 15px;
`;

const FeedbackSentText = styled.div`
  height: 48px;
`;

export default PastEventList;
