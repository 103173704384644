import styled from 'styled-components';
import { PaymentInfo, TicketBox } from '../../components/payment/PaymentSummary';
import { breakpoint } from "../../utils/responsive";
import LeftArrow from '../../assets/LeftArrow.svg';
import dayjs from "dayjs";
import { getOrder} from '../../apis';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';

export default function OrderSummary({orderNumber, setOrder, t}) {
    const { isLoading, isError, data, error } = useQuery("getOrder", () => getOrder(orderNumber));
    if (isLoading) {
        return <Loading />
    }
    if (isError) {
        return <div>Error: {error.message}</div>
    }
    const isPaymentSuccess = (data?.data?.order?.status == "Completed") ? true : false
    return (
        <MainDiv>
            <HeaderDiv>
                <img style={{cursor:'pointer'}} src={LeftArrow} alt={'back'} onClick={() => setOrder(null)}></img>
            </HeaderDiv>
            <PaymentInfo
                orderNumber={`#${data?.data?.order?.id}`}
                isPaymentSuccess={isPaymentSuccess}
                data={data?.data}
            />
            <Space></Space>
            {
                data?.data?.order?.items.map((item) => {
                    return <TicketBox currency={data?.data?.order?.currency} data={item} t={t} isPaymentSuccess={isPaymentSuccess} />
                })
            }
        </MainDiv>
    )
}

const MainDiv = styled.div`
    width: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    @media ${breakpoint.mobile} {
        padding: 20px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 30px
    }
    @media ${breakpoint.largeDesktop} {
        padding: 60px
    }
`

const HeaderDiv = styled.div`
    display: flex;
    width: 100%;
    @media ${breakpoint.mobile} {
        margin-bottom: 25px;
    }
    @media ${breakpoint.desktop} {
        margin-bottom: 35px;
    }
    @media ${breakpoint.largeDesktop} {
        margin-bottom: 65px;
    }
`

const Space = styled.div`
  @media ${breakpoint.mobile} {
    height: 32px;
  }
  @media ${breakpoint.desktop} {
    height: 30px;
  }
  @media ${breakpoint.largeDesktop} {
    height: 52px;
  }
`
