
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useLogout = (options = { forceRedirect: true }) =>{
    const { user, setUser, setCart } = useContext(AppContext);
    const navigate = useNavigate();
    return () => {
        setCart(false)
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setUser(null);
        if(options.forceRedirect) navigate(`/`);
    }
} 