import { Box, Grid } from "@mui/material"
import styled from 'styled-components';
import dayjs from "dayjs";
import CommonText from '../../components/common/typographies/CommonText'

function TicketDateItem({ t, date, index, isSelected, onClick, ended, purchased }){
    return (
        <Container item xs container direction="column" justifyContent="center" alignItems="center" style={{ cursor: ended ? "not-allowed" : "pointer", borderLeft: `${index === 0 ? 0 : 1}px solid rgba(233,233,233,0.3)`, backgroundColor: "transparent" }} onClick={!ended && onClick}>
            <CommonText align="center" fontSize="18px" lFontSize="20px" lineHeight="24px" style={{ color: ended ? "#575759": (isSelected ? "#F44434" : "#FFFFFF"),fontWeight: isSelected ? 600 : 500 }}>{dayjs(date).tz("Asia/Hong_Kong").format(t("dateFormat"))}</CommonText>
            <CommonText align="center" fontSize="14px" lineHeight="16px" style={{ color: ended ? "#575759": (isSelected ? "#F44434" : "#FFFFFF") }}>{dayjs(date).tz("Asia/Hong_Kong").format("YYYY")}</CommonText>
            { purchased && <PurchasedTag>{t('ordered')}</PurchasedTag> }
            { ended && 
                <ExpiredLine>
                    <svg width='100%' height='100%' viewBox='0 0 10 10' preserveAspectRatio='none'>
                        <line x1="0" y1="10" x2="10" y2="0" vector-effect="non-scaling-stroke" stroke="#575759" />
                    </svg>
                </ExpiredLine>
            }
        </Container>
    )
}

export default TicketDateItem

const Container = styled(Grid)`
    position: relative;
    overflow: hidden;
    height: 90px;
    && {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;


const ExpiredLine = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`

const PurchasedTag = styled.div`
    position: absolute;
    background-color: #444444;
    padding: 4px 10px;
    top: 0px;
    left: 0px;
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 700;
`;