import { Snackbar } from "@mui/material";
import styled from "styled-components";
import colors from "../../../utils/colors";

export default function CommonSnackbar({ children, open, onClose, noBackgroundColor, fullWidth }) {
  return (
    <Snackbar
      style={{ bottom: "0", left: "0", right: "0", transform: "unset" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={onClose}
    >
      <Container noBackgroundColor={noBackgroundColor} fullWidth={fullWidth}>{children}</Container>
    </Snackbar>
  );
}

const Container = styled.div`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  background: ${(props) => (props.noBackgroundColor ? "none" : colors.black70Alpha)};
  //   box-shadow: ${colors.black20Alpha} 0px 8px 24px;
`;
