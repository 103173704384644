import { Grid } from "@mui/material";
import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import Counter from "../../components/Counter";
import Remove from "../../assets/Remove.svg";
import ImageButton from "../../components/common/buttons/ImageButton";
import { ResponsiveUI, breakpoint, WindowSize } from "../../utils/responsive";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import dateString from "../../utils/dateString";
import { useMemo } from "react";

function CartItem({ t, language, currency, data, onDelete, isDeleting }) {
  const streamStart = data?.streaming?.streamStart
    ? dayjs(data.streaming.streamStart)
    : null;

  const title = useMemo(() => {
    if (language == "en") {
      if (data?.streaming?.event?.sponsorEn)
        return (
          data?.streaming?.event?.sponsorEn +
          " " +
          data?.streaming?.event?.eventNameEn
        );
      else return data?.streaming?.event.eventNameEn;
    } else {
      if (data?.streaming?.event?.sponsorZh)
        return (
          data?.streaming?.event?.sponsorZh +
          " " +
          data?.streaming?.event?.eventNameZh
        );
      else return data?.streaming?.event?.eventNameZh;
    }
  }, [data?.streaming?.event, language]);
  return (
    <Container container direction="row">
      <Grid item style={{ height: 135 }}>
        {data?.streaming?.event?.poster ? (
          <CartItemImage
            src={data.streaming.event.poster}
          />
        ) : null}
      </Grid>
      <Grid item xs container direction="column">
        <Grid item xs>
          <Grid container direction="row" style={{ columnGap: 4 }}>
            <Grid item xs>
              <CommonText
                align="left"
                fontSize={WindowSize() === "mobile" ? "14px" : "16px"}
                lineHeight="1.5"
                fontWeight="700"
              >
                {title}
              </CommonText>
            </Grid>
            <Grid item>
              <CommonText
                align="left"
                fontSize="16px"
                lineHeight="28px"
                fontWeight="400"
                style={{ textAlign: "right" }}
              >
                {currency}
                {data.option.discountedPrice != null ? data.option.discountedPrice:data.option.price}
              </CommonText>
              {data.option?.discountedPrice != null && (
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="1"
                  fontWeight="300"
                  style={{ textAlign: "right", textDecoration: "line-through", opacity: 0.6 }}
                >
                  {currency}
                  {data.option.price}
                </CommonText>
              )}
            </Grid>
          </Grid>
          <CommonText
            align="left"
            fontSize="14px"
            lineHeight="20px"
            fontWeight="400"
            style={{ color: `rgba(255, 255, 255, 0.6)` }}
          >
            {data?.option?.name[language] ? `${t("ticketType")}${data?.option?.name[language]}` : ""}
          </CommonText>
          {streamStart && (
            <CommonText
              align="left"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="400"
              style={{ color: `rgba(255, 255, 255, 0.6)` }}
            >
              {`${t("streamStartDate")}${dateString(streamStart, language)}`}
            </CommonText>
          )}
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <CommonText
              align="left"
              fontSize="16px"
              lineHeight="28px"
              fontWeight="400"
              style={{ color: `rgba(255, 255, 255, 0.6)`, marginRight: 10 }}
            >
              {t("quantity")}
            </CommonText>
          </Grid>
          <Grid item xs>
            <Counter value={data.quantity} disabled />
          </Grid>
          <Grid item>
            <ImageButton
              src={Remove}
              alt="Remove"
              onClick={isDeleting ? null : () => onDelete(data.productId, data.productOptionId)}
            />
            {isDeleting && <CircularProgress size={20} />}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const Container = styled(Grid)`
  && {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const CartItemImage = styled.img`
  object-fit: contain;
  @media ${breakpoint.mobile} {
    width: 60px;
    height: 60px;
  }
  @media ${breakpoint.desktop} {
    width: 100px;
    height: 100px;
  }
  @media ${breakpoint.size1024} {
    width: 135px;
    height: 135px;
  }
`

export default CartItem;
