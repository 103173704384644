import { Box } from '@mui/material'
import CommonText from '../typographies/CommonText'
import Vector from '../../../assets/Vector.svg';

function MenuItem(props) {
    const { label = '', selected, ...others } = props
    return (
        <Box sx={{ padding: '10px 0px', borderBottom: '1px solid rgba(255, 255, 255, 0.5)', margin:'0px' }} minHeight={60} display='flex' flexDirection='row' alignItems='center' bgcolor={selected ? '#F44434' : '#000'} {...others}>
            <CommonText fontWeight="600" lineHeight="150%" type='body' style={{ flex: 1, marginLeft: 18, color: selected ? '#fff' : '#fff', fontSize: 18 }}>{label}</CommonText>
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height: 30, width: 30, marginRight: 20 }}>
                <img style={{ width:'16px', height:'16px', transform: 'rotate(90deg)'}} src={Vector} alt="Vector"></img>
            </div>
        </Box>
    )
}

export default MenuItem