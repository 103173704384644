import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { breakpoint } from '../../../utils/responsive';

function CommonTextField(props) {
    return (
        <StyledTextfield
            {...props}
            fullWidth
            variant="outlined"
        >
            {props.children}
        </StyledTextfield>
    );
}

const StyledTextfield = styled(TextField)`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: rgba(255, 255, 255, .5);
            border-radius: 0;
        }
        &:hover fieldset {
            border-color: ${props => props?.hoverBorderColor || '#fff'};
            @media ${breakpoint.mobile} {
                border-color: rgba(255, 255, 255, .5);
            }
        }
        &.Mui-focused fieldset {
            border-color: ${props => props?.hoverBorderColor || '#fff'};
            @media ${breakpoint.mobile} {
                border-color: rgba(255, 255, 255, .5);
            }
        }
        &.Mui-error fieldset {
            border-color: #F44434;
        }
    }
  `;

export default CommonTextField;
