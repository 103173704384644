import { Box } from "@mui/material"
import CommonText from "../../components/common/typographies/CommonText"
import RightArrow from "../../assets/RightArrow.svg"
import { useTranslation } from "react-i18next"

function EventTncButton({ onClick }) {
    const { t } = useTranslation('tnc');
    return (
        <Box
            borderTop={`1px solid rgba(255, 255, 255, 0.5)`}
            borderBottom={`1px solid rgba(255, 255, 255, 0.5)`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: `15px`, margin: `15px 0px`, cursor: "pointer" }}
            onClick={onClick}
            width="100%"
        >
            <CommonText fontSize="15px" lineHeight="24px">{t('title.eventTnc')}</CommonText>
            <img src={RightArrow} alt="RightArrow" />
        </Box>
    )
}

export default EventTncButton