import { Divider, Grid } from "@mui/material";
import { useContext, useRef, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import EmailTextField from "../components/common/textfields/EmailTextField";
import PasswordTextField from "../components/common/textfields/PasswordTextField";
import FormTextField from "../components/common/textfields/FormTextField";
import CommonText from "../components/common/typographies/CommonText";
import Space from "../components/Space";
import constants from "../constants";
import { register } from "../apis";
import CommonButton from "../components/common/buttons/CommonButton";
import LanguageToggleButton from "../components/common/buttons/LanguageToggleButton";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import CheckBox from "../components/common/checkboxs/Checkbox";
import TextButton from "../components/common/buttons/TextButton";
import { WindowSize } from "../utils/responsive";

export default function Signin() {
    const { t } = useTranslation("register")
    const [loading, setLoading] = useState(false);
    const [promotion, setPromotion] = useState(false)
    const [agree, setAgree] = useState(false)
    const [error, setError] = useState(null);
    const [language, setLanguage] = useState("chinese");
    const [recaptcha, setRecaptcha] = useState(null)
    const { setUser, tvOtp, requestSignIn, requestPhoneVerification, setSignInRedirectUrl } = useContext(AppContext)
    const recaptchaRef = useRef(null);
    const usernameRef = useRef();
    const emailRef = useRef();
    const emailOTPRef = useRef();
    const passwordRef = useRef();
    const retypePasswordRef = useRef();
    const onReCaptchaChange = (value) => {
        setRecaptcha(value)
    }
    const currentWindow = WindowSize()
    const validate = () => {
        let error = {};
        if (usernameRef.current.value.length < 2 || usernameRef.current.value.length > 50) error.username = t('invalid_username')
        const validEmail = new RegExp(constants.EMAIL_REGEX);
        if (!(validEmail.test(emailRef.current.value))) error.email = t('invalid_email')
        if (emailOTPRef.current.value.length != 6) error.emailOTP = t('invalid_email_otp')
        const validPassword = new RegExp(constants.PASSWORD_REGEX);
        if (!(validPassword.test(passwordRef.current.value))) error.password = t('invalid_password')
        else if (passwordRef.current.value != retypePasswordRef.current.value) error.retypePassword = t('invalid_retype_password')
        setError(error)
        if (Object.keys(error).length === 0) return true;
        else return false;
    }

    const handleRegister = async () => {
        if (!loading && validate()) {
            setLoading(true);
            const body = {
                username: usernameRef.current.value,
                email: emailRef.current.value,
                emailOTP: emailOTPRef.current.value,
                password: passwordRef.current.value,
                retypePassword: retypePasswordRef.current.value,
                promotion,
                language,
                recaptcha
            }
            const result = await register(body);
            if (result.success) {
                localStorage.setItem('accessToken', result.data.accessToken);
                localStorage.setItem('refreshToken', result.data.refreshToken);
                setUser(result.data.user);
                window.dataLayer.push({
                    event: "custom_event",
                    event_name: "signup",
                    method: "email"
                })
                requestPhoneVerification();
            } else {
                // set api error
                if (result.error?.code == "C0001") {
                    setError({ username: t('used_username') })
                } else if (result.error?.code == "C0002") {
                    setError({ email: t('used_email') })
                } else if (result.error?.code == "C0003") {
                    setError({ emailOTP: t('invalid_email_otp') })
                }
            }
            recaptchaRef.current.reset();
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => {
            if(setSignInRedirectUrl != null){
                setSignInRedirectUrl(null);
            }
        }
    }, [])

    return <Container>
        <HeaderContainer>
            <CommonText fontSize={currentWindow === "mobile" ? "28px" : "36px"} lineHeight="40px">{t('register_title')}</CommonText>
            <CommonText fontSize="16px" lineHeight="28px">
                <Trans
                    t={t}
                    i18nKey="signin"
                    components={[
                        <span style={{ cursor: 'pointer', color: '#F44434', whiteSpace: "nowrap" }} key={0} onClick={() => requestSignIn()} />,
                    ]}
                />
            </CommonText>
        </HeaderContainer>
        <Space size="60px" />
        <Divider width="100%" />
        <Space size="60px" />
        <FormContainer>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CommonText 
                        fontSize={currentWindow === "mobile" ? "20px" : "24px"} 
                        lineHeight={currentWindow === "mobile" ? "1.4" : "34px"}
                    >
                        {t('register_desc')}
                    </CommonText>
                </Grid>
                <Grid item xs={12}>
                    <EmailTextField placeholder={t('mandatory')} purpose="web" inputRef={emailRef} errorText={error?.email} label={t('email')} requestOTP />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField placeholder={t('enter_otp')} inputRef={emailOTPRef} errorText={error?.emailOTP} label={t('otp')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField placeholder={t('mandatory')} inputRef={usernameRef} errorText={error?.username} label={t('username')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PasswordTextField placeholder={t('mandatory')} inputRef={passwordRef} errorText={error?.password} helperText={t('password_hint')} label={t('password')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PasswordTextField placeholder={t('mandatory')} inputRef={retypePasswordRef} errorText={error?.retypePassword} label={t('retypepassword')} />
                </Grid>
            </Grid>
            <Space size="30px" />
            <CommonText style={{ width: '100%', textAlign: 'left' }}>{t('receive_promote_language')}</CommonText>
            <Space size="9px" />
            <div style={{ maxWidth: 356, width: '100%', alignSelf: 'flex-start' }}>
                <LanguageToggleButton value={language} onChange={(event) => setLanguage(event.target.value)} />
            </div>
            <Space size="30px" />
            <div style={{ width: '100%', boxSizing: 'border-box', padding: 30, border: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <CheckBox onClick={() => setAgree(!agree)} isChecked={agree}>
                    <Trans
                        t={t}
                        i18nKey="register_terms"
                        components={[
                            <Link style={{ textDecoration: 'underline' }} key={0} target="_blank" to="/tnc" />,
                            <Link style={{ textDecoration: 'underline' }} key={1} target="_blank" to="/private-policy?id=PICS" />,
                        ]}
                    />
                </CheckBox>
                <Space size="15px" />
                <CheckBox onClick={() => setPromotion(!promotion)} isChecked={promotion}>
                    <Trans
                        t={t}
                        i18nKey="promotion_terms"
                        components={[
                            <Link style={{ textDecoration: 'underline' }} key={0} target="_blank" to="/private-policy" />,
                        ]}
                    />
                </CheckBox>
            </div>
            <Space size="30px" />
            <ReCAPTCHA
                sitekey={constants.RECAPTCHA}
                onChange={onReCaptchaChange}
                ref={recaptchaRef}
            />
            <Space size="30px" />
            <RegisterButton disabled={!agree} onClick={handleRegister}>{t('register')}</RegisterButton>
        </FormContainer>
    </Container>
}

const Container = styled.div`
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 30px 15px;
    }
`
const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12;
    flex-wrap: wrap;
`
const FormContainer = styled.div`
    width: 100%;
    max-width: 742px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const RegisterButton = styled(CommonButton)`
    max-width: 425px;
    width: 100%;
`