import React, { useState, useEffect, useContext } from "react";
import CommonSelect from "./CommonSelect";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "../../../assets/Language.svg";
import CommonText from "../typographies/CommonText";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ResponsiveUI } from "../../../utils/responsive";
import { AppContext } from "../../../AppContext";
import { updateCartCurrency } from "../../../apis";
import WhiteTick from "../../../assets/WhiteTick.svg";

const CurrencySelect = ({ width }) => {
  const { t, i18n } = useTranslation();
  const { user, currency, setCurrency } = useContext(AppContext);
  const handleChange = async (event) => {
    if (user) {
      const result = await updateCartCurrency(event.target.value);
      if (result.success) {
        localStorage.setItem("currency", event.target.value);
        setCurrency(event.target.value);
      }
    } else {
      localStorage.setItem("currency", event.target.value);
      setCurrency(event.target.value);
    }
  };

  return (
    <CommonSelect
      name="currency"
      style={{ width: width || "100%" }}
      options={["HKD", "USD"]}
      value={currency}
      onChange={handleChange}
      openAbove
      renderValue={(value) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ResponsiveUI
            largeDesktop={
              <CommonText sizeType="Caption" type="body">
                {value}
              </CommonText>
            }
            desktop={
              <CommonText sizeType="Caption" type="body">
                {value}
              </CommonText>
            }
            mobile={
              <CommonText sizeType="Caption" type="body">
                {value}
              </CommonText>
            }
          />
        </div>
      )}
    >
      <MenuItem value={"HKD"}>
        <MenuLabel>
          <ResponsiveUI
            largeDesktop={
              <CommonText sizeType="Caption" type="body">
                HKD
              </CommonText>
            }
            desktop={
              <CommonText sizeType="Caption" type="body">
                HKD
              </CommonText>
            }
            mobile={
              <CommonText sizeType="Caption" type="body">
                HKD
              </CommonText>
            }
          />
          {currency == "HKD" ? <Tick src={WhiteTick} /> : null}
        </MenuLabel>
      </MenuItem>
      <MenuItem value={"USD"}>
        <MenuLabel>
          <ResponsiveUI
            largeDesktop={
              <CommonText sizeType="Body" type="body">
                USD
              </CommonText>
            }
            desktop={
              <CommonText sizeType="Caption" type="body">
                USD
              </CommonText>
            }
            mobile={
              <CommonText sizeType="Caption" type="body">
                USD
              </CommonText>
            }
          />
          {currency == "USD" ? <Tick src={WhiteTick} /> : null}
        </MenuLabel>
      </MenuItem>
    </CommonSelect>
  );
};

const MenuLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Tick = styled.img`
  width: ${(props) => props.size || "15px"};
  height: ${(props) => props.size || "11px"};
`;

export default CurrencySelect;
