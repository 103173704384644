import styled from "styled-components";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CommonText from "../typographies/CommonText";
import { AppContext } from "../../../AppContext";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";
import { useLogout } from "../../logout";

function LinkItem({ index, link, children, selected, onClick }) {
  return (
    <StyledLink key={index} to={link} onClick={onClick}>
      <StyledText fontWeight="600" sizeType="Body" type="body">
        {children}
      </StyledText>
    </StyledLink>
  );
}

export default function CommonDropdown({
  currentId,
  setDropdownOpen,
  ...props
}) {
  const { t } = useTranslation("myAccount");
  const MENU_ITEMS = Object.keys(t("menu", { returnObjects: true }));
  const { user, setUser } = useContext(AppContext);
  const logout = useLogout();

  return [
    <Container {...props}>
      {MENU_ITEMS.map((itemId, index) => {
        return (
          <LinkItem
            onClick={() => setDropdownOpen(false)}
            key={index}
            index={index}
            itemId={itemId}
            link={`/myaccount?id=${itemId}`}
            selected={currentId === itemId}
          >
            {t(`menu.${itemId}`)}
          </LinkItem>
        );
      })}
      <StyledLogoutDiv onClick={logout}>
        <CommonText fontWeight="600" sizeType="Body" type="body">
          {t(`common.logout`)}
        </CommonText>
      </StyledLogoutDiv>
    </Container>,
  ];
}

const Container = styled.div`
  position: absolute;
  right: 0px;
  top: 70px;
  background: #000000;
  padding: 10px;
  z-index: 10000;
  @media ${breakpoint.mobile} {
    width: 100%;
    height: auto;
  }
  @media ${breakpoint.desktop} {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  @media ${breakpoint.largeDesktop} {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    background: red;
  }
`;

const StyledText = styled(CommonText)`
  display: flex;
  justify-content: center;
  margin: 10;
  padding: 12px 51px;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const StyledLogoutDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 10;
  padding: 12px 51px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
