import { useContext, useMemo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import CommonText from '../components/common/typographies/CommonText';
import styled from 'styled-components';
import ManageProfileInfoForm from '../pageComponents/myAccount/ManageProfileInfoForm';
import ManagePasswordForm from '../pageComponents/myAccount/ManagePasswordForm';
import ManageDeliveryForm from '../pageComponents/myAccount/ManageDeliveryForm';
import { AppContext } from "../AppContext";
import LinkMenu from "../pageComponents/myAccount/LinkMenu";
import { getProfile } from "../apis/";
import { useQuery } from 'react-query';
import { breakpoint, WindowSize } from "../utils/responsive";
import { AccountDeleteConfirmation } from "../pageComponents/myAccount/AccountDeleteConfirmation";
import MyOrder from "./MyOrder";
import { useLogout } from "../components/logout";

export default function MyAccount() {
    const query = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    const logout = useLogout();
    const { t } = useTranslation('myAccount');
    const path = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const currentId = path.get('id') || "";
    const MENU_ITEMS = Object.keys(t("menu", { returnObjects: true }));
    const windowSize = WindowSize();
    let isLargeDesktop = windowSize === "largeDesktop" || windowSize === "1024";
    const [deleteOpen, setDeleteOpen] = useState(false);
    useEffect(() => {
        if (location.pathname === '/myaccount' && (!location.search || !currentId || !MENU_ITEMS.includes(currentId))) {
            navigate(`/myaccount?id=${MENU_ITEMS[0]}`, { replace: true })
        }
    }, [location, query, MENU_ITEMS, currentId, navigate])

    const { isLoading, isError, data, error } = useQuery("getProfile", () => getProfile());
    const { user } = useContext(AppContext);
    if(isLoading){
        return(
            <div>Loading</div>
        )
    }
    if(isError || error){
        return(
            <div>Something went wrong</div>
        )
    }
    return(
        <StyledMainDiv>
            <StyledMenuDiv>
                <StyledUserNameDiv>
                    <CommonText style={{ overflowWrap:"anywhere" }} uppercase="true" fontWeight='600' sizeType="Heading1" type='display'>{user.username}</CommonText>
                    {
                        !isLargeDesktop &&
                        <StyledLogoutDiv onClick={logout}>
                            <CommonText fontWeight="700" sizeType="Caption" type='body'>{t(`common.logout`)}</CommonText>
                        </StyledLogoutDiv>
                    }
                </StyledUserNameDiv>

                <StyledMenuLinkDiv>
                    {
                        MENU_ITEMS.map((itemId, index) => {
                            return (
                                <LinkMenu onClick={() => setDeleteOpen(false)} key={index} itemId={itemId} url={`/myaccount?id=${itemId}`} text={t(`menu.${itemId}`)} selected={currentId === itemId}></LinkMenu>
                            )
                        })
                    }
                </StyledMenuLinkDiv>

                {
                    isLargeDesktop &&
                    <StyledLogoutDiv onClick={logout}>
                        <CommonText style={{ textAlign:"center", padding: 10 }} fontWeight="700" sizeType="Caption" type='body'>{t(`common.logout`)}</CommonText>
                    </StyledLogoutDiv>
                }
            </StyledMenuDiv>

            {
                location.search === "?id=myorder" && !deleteOpen &&
                <MyOrder></MyOrder>
            }
            {
                location.search === "?id=managePersonalInfo" && !deleteOpen &&
                <StyledInfoDiv>
                    <ManageProfileInfoForm/>
                    <ManagePasswordForm/>
                    {/* <ManageDeliveryForm/> */}
                    <StyledDeleteDiv>
                        <Trans
                            t={t}
                            i18nKey="deleteAccount.redirectMessage"
                            components={[
                                <Link style={{ textDecoration: 'underline' }} key={0} onClick={() => setDeleteOpen(true)} to="/myaccount?id=managePersonalInfo" />,
                            ]}
                        />
                    </StyledDeleteDiv>
                </StyledInfoDiv>
            }
            {
                deleteOpen &&
                <StyledInfoDiv>
                    <AccountDeleteConfirmation setDeleteOpen={setDeleteOpen}></AccountDeleteConfirmation>
                </StyledInfoDiv>
            }


        </StyledMainDiv>
    )
}

const StyledMainDiv = styled.div`
    @media ${breakpoint.desktop} {
        height: 100%;
    }
    @media ${breakpoint.size1024} {
        display: flex;
        height: 100%;
    }
    @media ${breakpoint.largeDesktop} {
        display: flex;
        height: 100%;
    }
`;

const StyledMenuDiv = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    flex-shrink: 0;
    @media ${breakpoint.size1024} {
        top: 75px;
        position: sticky;
        width: 30%;
        height: 100%;
    }
    @media ${breakpoint.largeDesktop} {
        width: 30%;
        height: 100%;
    }
`;

const StyledMenuLinkDiv = styled.div`
    @media ${breakpoint.mobile} {
        display: none;
    }
    @media ${breakpoint.desktop} {
        display: none;
    }
    @media ${breakpoint.size1024} {
        display: block;
    }
    @media ${breakpoint.largeDesktop} {
        display: block;
    }
`;

const StyledInfoDiv = styled.div`
    @media ${breakpoint.size1024} {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        border-top: 1px solid rgba(255, 255, 255, 0.5)
    }
    height: 100%;
    width: 100%;
`;

const StyledUserNameDiv = styled.div`
    @media ${breakpoint.mobile} {
        justify-content: space-between;
        padding: 30px 15px;
    }
    @media ${breakpoint.desktop} {
        justify-content: space-between;
        padding: 40px 30px;
    }
    @media ${breakpoint.size1024} {
        justify-content: center;
        padding: 40px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        justify-content: center;
        padding: 40px 30px;
    }
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const StyledLogoutDiv = styled.div`
    @media ${breakpoint.mobile} {
    }
    @media ${breakpoint.desktop} {
    }
    @media ${breakpoint.size1024} {
        border: 1px solid rgba(255, 255, 255, 0.3);
        margin: 35px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        border: 1px solid rgba(255, 255, 255, 0.3);
        margin: 35px 30px;
    }
    cursor: pointer;
    white-space: nowrap;
    padding: 10px auto;
`;

const StyledDeleteDiv = styled.div`
    @media ${breakpoint.mobile} {
        padding: 20px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 20px 30px;
    }
    @media ${breakpoint.size1024} {
        padding: 30px;
    }
    @media ${breakpoint.largeDesktop} {
        padding: 30px;
    }
    width: 100%;
    height: 100%;
    text-align: left;
`;
