import React from 'react';
import styled from 'styled-components';

function CommonDivider({color, opacity, ...props}) {

    return (
        <StyledHr opacity={opacity} borderColor={color} {...props}/>
    );
}

const StyledHr = styled.hr`
    width: 100%;
    opacity: ${props => props.opacity? props.opacity : 1};
    border-color: ${props => props.borderColor? props.borderColor : 'white'};
`;

export default CommonDivider;