import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import constants from "../constants";
import CONSTANTS from "../constants";
import getDeviceType from "../utils/getDeviceType";

// Obtain the fresh token each time the function is called
function getAccessToken() {
  return localStorage.getItem("accessToken");
}

// Use interceptor to inject the token to requests
axios.interceptors.request.use((request) => {
  let token = getAccessToken();
  if (token) request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return request;
});

function setUpRefreshTokenLogic(setUser) {
  // Function that will be called to refresh authorization
  const refreshAuthLogic = (failedRequest) => {
    const refreshToken = localStorage.getItem("refreshToken", null);
    if (refreshToken) {
      const params = new URLSearchParams();
      params.append("refresh_token", refreshToken);
      params.append("grant_type", "refresh_token");
      params.append("client_id", CONSTANTS.CLIENT_ID);
      params.append("client_secret", CONSTANTS.CLIENT_SECRET);
      return axios({
        method: "post",
        url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: params,
        skipAuthRefresh: true,
      })
        .then((tokenRefreshResponse) => {
          if (
            tokenRefreshResponse.data?.success &&
            tokenRefreshResponse.data?.data
          ) {
            localStorage.setItem(
              "accessToken",
              tokenRefreshResponse.data?.data.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              tokenRefreshResponse.data?.data.refreshToken
            );
            failedRequest.response.config.headers["Authorization"] =
              "Bearer " + tokenRefreshResponse.data?.data.accessToken;
          }
          return Promise.resolve();
        })
        .catch(async (error) => {
          console.log("tokenRefreshResponse error", error);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          setUser(null);
          return Promise.resolve();
        });
    }
  };
  createAuthRefreshInterceptor(axios, refreshAuthLogic);
}

const request = (param) => {
  return axios({
    ...param,
  })
    .then((res) => res.data)
    .catch(async (error) => {
      console.log("debug", error);
      return error?.response?.data || error?.response?.status;
    });
};

const register = (body) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/user/register`,
    headers: { "Content-Type": "application/json" },
    data: body,
  });
};

const forgotPassword = (type, emailOrPhone, phoneOTP) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/user/forgot-password`,
    headers: { "Content-Type": "application/json" },
    data:
      type == "email"
        ? { email: emailOrPhone }
        : { phone: emailOrPhone, phoneOTP },
  });
};

const resetPassword = (resetPasswordToken, password, retypePassword) => {
  let body = {
    resetPasswordToken: resetPasswordToken,
    password: password,
    retypePassword: retypePassword,
  };
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/user/reset-password`,
    headers: { "Content-Type": "application/json" },
    data: body,
  });
};

const requestEmailOTP = (email, purpose, username) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/user/email/otp`,
    headers: { "Content-Type": "application/json" },
    data: { email, purpose, username },
  });
};

const requestPhoneOTP = (phone, language) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/user/phone/otp`,
    headers: { "Content-Type": "application/json" },
    data: { phone, language },
  });
};

const login = (username, password) => {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);
  params.append("grant_type", "password");
  params.append("client_id", CONSTANTS.CLIENT_ID);
  params.append("client_secret", CONSTANTS.CLIENT_SECRET);
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};

const socialLogin = (type, accessToken) => {
  const params = new URLSearchParams();
  params.append("access_token", accessToken);
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/auth/${type}/token`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};

const appleLogin = (code, lastName, firstName) => {
  const params = new URLSearchParams();
  params.append("code", code);
  params.append("firstName", firstName);
  params.append("lastName", lastName);
  params.append("clientId", CONSTANTS.APPLE_SIGN_IN_CLIENT_ID);
  params.append("redirect_url", CONSTANTS.APPLE_SIGN_IN_REDIRECT_URI);
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/auth/apple/token`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};

const socialRegister = (
  type,
  accessToken,
  memberType,
  username,
  phone,
  promotion,
  language,
  recaptcha
) => {
  const params = new URLSearchParams();
  params.append("access_token", accessToken);
  params.append("memberType", memberType);
  params.append("username", username);
  if (phone) params.append("phone", phone);
  if (promotion) params.append("promotion", promotion);
  if (language) params.append("language", language);
  params.append("recaptcha", recaptcha);
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/auth/${type}/register`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};

const appleRegister = (
  code,
  lastName,
  firstName,
  memberType,
  username,
  phone,
  promotion,
  language,
  recaptcha
) => {
  const params = new URLSearchParams();
  params.append("code", code);
  params.append("firstName", firstName);
  params.append("lastName", lastName);
  params.append("clientId", CONSTANTS.APPLE_SIGN_IN_CLIENT_ID);
  params.append("redirect_url", CONSTANTS.APPLE_SIGN_IN_REDIRECT_URI);
  params.append("memberType", memberType);
  params.append("username", username);
  if (phone) params.append("phone", phone);
  if (promotion) params.append("promotion", promotion);
  if (language) params.append("language", language);
  params.append("recaptcha", recaptcha);
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/auth/apple/register`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};

// tv login
/** @param {string} code */
async function checkTVCode(code) {
  return request({
    url: `${CONSTANTS.API_ENDPOINT}/auth/tvcode/check/${code}`,
    method: "post", 
  })
}

/** @param {string} code */
async function bindTVCode(code) {
  return request({
    url: `${CONSTANTS.API_ENDPOINT}/auth/tvcode/bind/${code}`,
    method: "post", 
  })
}

const getProfile = () => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
  });
};

const updateProfile = (body) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
    data: body,
  });
};

const deleteAccount = () => {
  return request({
    method: "delete",
    url: `${CONSTANTS.API_ENDPOINT}/user`,
  });
};

const getBanner = () => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}//banner?platform=WEB`
  })
}

const listEvents = () => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/event?web=true`,
  });
};

const listEventTickets = (id, currency, locale = "zh") => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/event/${id}/eshopTickets?currency=${currency}&locale=${locale}`,
  });
};

const getEvent = (id) => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/event/${id}`,
  });
};

const checkoutStream = (contentId, streaming) => {
  const params = new URLSearchParams();
  params.append("contentId", contentId);
  params.append("type", getDeviceType());
  if (streaming) params.append("streaming", streaming);
  if (window.streamSession) params.append("session", window.streamSession);
  return request({
    method: "post",
    url: constants.CHECKOUT_DOMAIN + "/checkout",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};
const keepaliveStream = (contentId, session) => {
  const params = new URLSearchParams();
  params.append("contentId", contentId);
  params.append("session", session);
  return request({
    method: "post",
    url: constants.CHECKOUT_DOMAIN + "/keepalive",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: params,
  });
};

const listTicket = () => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/tickets/me/streamings/`,
  });
};

const listTicketByStreaming = (id) => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/tickets/me/streamings/${id}`,
  });
};

const listTicketByEvent = (id) => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/tickets/me/events/${id}`,
  });
};

const addToCart = (productId, optionId, replace) => {
  return request({
    method: replace?"put":"post",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/cart`,
    data: {
      productId: productId,
      productOptionId: optionId,
    },
  });
};

const listCartItem = () => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/cart/list`,
  });
};

const updateCartCurrency = (currency) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/cart/currency`,
    data: {
      currency: currency,
    },
  });
};

const deleteCartItem = (productId, optionId) => {
  return request({
    method: "delete",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/cart`,
    data: {
      productId: productId,
      productOptionId: optionId,
    },
  });
};

const checkoutCart = (payload) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/checkout`,
    data: payload,
  });
};

const getOrderList = (page) => {
  let skip = (page - 1) * 10;
  let limit = 10;
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/orders?skip=${skip}&limit=${limit}`,
  });
};

const getOrder = (id) => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/orders/${id}`,
  });
};
const contactUs = (body) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/contactus`,
    data: body,
  });
};

const getFeedback = (productId) => {
  return request({
    method: "get",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/products/${productId}/feedback`,
  });
}

const submitFeedback = (productId, body) => {
  return request({
    method: "post",
    url: `${CONSTANTS.API_ENDPOINT}/eshop/products/${productId}/feedback`,
    data: body,
  });
  // const params = new URLSearchParams();
  // params.append("orgid", "00D8c000006KnjJ");
  // params.append("retURL", "http://");
  // params.append("00N8c00000dfsJV", "MakeALive - Feedback");
  // params.append("name", body.name);
  // params.append("email", body.email);
  // params.append("00N8c00000dfsJu", body.content);
  // params.append("00N8c00000dfsLl", body.overall);
  // params.append("00N8c00000dfsNh", body.ticket);
  // params.append("00N8c00000dfsNm", body.livestream);
  // params.append("description", body.comment);
  // params.append("submit", "Submit");
  // console.log("params", body);
  // return fetch(
  //   `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8`,
  //   {
  //     method: "post",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: params,
  //   }
  // );
};

export {
  setUpRefreshTokenLogic,
  register,
  forgotPassword,
  resetPassword,
  requestEmailOTP,
  requestPhoneOTP,
  login,
  socialLogin,
  appleLogin,
  socialRegister,
  appleRegister,
  checkTVCode,
  bindTVCode,
  getProfile,
  updateProfile,
  deleteAccount,
  getBanner,
  listEvents,
  listEventTickets,
  getEvent,
  checkoutStream,
  keepaliveStream,
  listTicket,
  listTicketByStreaming,
  listTicketByEvent,
  addToCart,
  updateCartCurrency,
  listCartItem,
  deleteCartItem,
  checkoutCart,
  getOrderList,
  getOrder,
  contactUs,
  getFeedback,
  submitFeedback,
};
