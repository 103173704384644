import CommonText from "../../components/common/typographies/CommonText"
import { Grid, Box } from "@mui/material";
import styled from 'styled-components';
import { useState } from "react";

const Ball = ({ number, isSelected, onClick }) => {
    return (
        <BallContainer style={{ backgroundColor: isSelected ? "#F44434" : "rgba(255, 255, 255, 0.2)" }} onClick={onClick}>
            <CommonText style={{ color: isSelected ? "#ffffff" : "#000000" }}>{number}</CommonText>
        </BallContainer>
    )
}

const StepItem = ({ number, label, isSelected, onClick }) => {
    return (
        <Grid item xs={isSelected} style={{ marginRight: 10 }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Ball number={number} isSelected={isSelected} onClick={onClick} />
                {isSelected && <CommonText fontSize="23px" lineHeight="27px" fontWeight="500" style={{ color: "#F44434", marginLeft: 7 }}>{label}</CommonText>}
            </Box>
        </Grid>
    )
}

function Stepper({ steps = [], step, setStep }) {

    // const [step, setStep] = useState(0);

    const handleOnClick = (index) => {
        setStep(index)
    }

    return (
        <Grid container direction="row">
            {
                steps.map((stepLabel, index) => {
                    const isSelected = step === index;
                    return (
                        <StepItem
                            number={index + 1}
                            label={stepLabel}
                            isSelected={isSelected}
                            // onClick={() => handleOnClick(index)}
                        />
                    )
                })
            }
        </Grid>
    )
}

export default Stepper

const SIZE = 30
const BallContainer = styled.div`
    height: ${SIZE}px; 
    width: ${SIZE}px; 
    border-radius: ${SIZE / 2}px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
`