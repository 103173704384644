import WhiteTick from "../../assets/WhiteTick.svg"
import styled from "styled-components"

export default function SuccessRoundOrange(props) {
  return (
    <Container>
      <Tick src={WhiteTick} {...props} />
    </Container>
  )
}

const Container = styled.div`
  background: #F44434;
  display: flex;
  width: ${(props) => props.size || "53px"};
  height: ${(props) => props.size || "53px"};
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 0px 30px #F44434;
`

const Tick = styled.img`
  width: ${(props) => props.size || "27px"};
  height: ${(props) => props.size || "19px"};
`
