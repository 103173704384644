import { useState, useContext } from "react";
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from "react-i18next"
import { AppContext } from "../../AppContext";
import CustomButton from "../../components/common/buttons/CustomButton";
import EditFormTextfield from "./EditFormTextField";
import { breakpoint } from "../../utils/responsive";
import { updateProfile } from "../../apis"; 

export default function ManageDeliveryForm() {
    const { t } = useTranslation('myAccount');
    const { user } = useContext(AppContext);
    const [address, setAddress] = useState(user.addresses || []);
    const [editAdress, setEditAddress] = useState(null);

    const AddAddress = () => {
        let temp = [...address, ''];
        setAddress(temp);
    }

    const SetAddressDetail = (value, index) =>{
        let newAddress = [...address];
        newAddress[index] = value;
        setAddress(newAddress);
    }

    const HandleSubmit = async () =>{
        let body ={
            addresses: address,
        }
        let result = await updateProfile(body);
    }

    return (
        <StyledMainDiv>
            <StyledTitleDiv>
                <CommonText fontWeight='600' sizeType="Heading2" type='display'>{t(`deliveryAddress.title`)}</CommonText>
            </StyledTitleDiv>

            <StyledDetailDiv>

                <div>
                    {
                        address &&
                        address.map((item, index) => {
                            return (
                                <StyledItemDiv key={index} onClick={() => setEditAddress(index)}>
                                    <CommonText style={{color:'rgba(255, 255, 255, 0.8)', marginBottom:8}} fontWeight='400' sizeType="Caption" type='body'>{t(`deliveryAddress.address1`)} {index+1}</CommonText>
                                    <EditFormTextfield 
                                        multiline 
                                        maxRows={2}
                                        value={item} 
                                        onChange={(e) => SetAddressDetail(e.target.value, index)}
                                        HandleSubmit={HandleSubmit}
                                    />
                                </StyledItemDiv>
                            )
                        })
                    }
                </div>


                <StyledCustomButton onClick={AddAddress}>
                    <CommonText fontWeight='600' sizeType="Body" type='body'>{t(`deliveryAddress.addAddress`)}</CommonText>
                </StyledCustomButton>
            </StyledDetailDiv>
        </StyledMainDiv>
)}

const StyledMainDiv = styled.div`
    @media ${breakpoint.mobile} {
        padding: 38px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 31px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        display: flex;
        padding: 60px 90px 60px 60px;
    }
    width: 100%;
    height: 100%;
    text-align: left;
`;

const StyledTitleDiv = styled.div`
    @media ${breakpoint.mobile} {
        margin-bottom: 23px;
    }
    @media ${breakpoint.desktop} {
        margin-bottom: 23px;
    }
    width: 100%;
    max-width: 273px;
`;

const StyledDetailDiv = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledItemDiv = styled.div`
    margin-bottom: 15px;
`;

const StyledCustomButton = styled(CustomButton)`
    &&{
        height: 44px;
        width: 100%;
        background: rgba(31, 31, 33, 1);
    }
`;