import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { breakpoint, ResponsiveUI } from "../utils/responsive";
import CommonText from "../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import LinkCard from "../pageComponents/aboutUs/LinkCard";
import Facebook from "../assets/Facebook.svg";
import Instagram from "../assets/Instagram.svg";
import Mail from "../assets/Mail.svg";
import GooglePlay from "../assets/GooglePlay.svg";
import AppStore from "../assets/AppStore.svg";
import AppGallery from "../assets/AppGallery.svg";
import FormTextField from "../components/common/textfields/FormTextField";
import CommonButton from "../components/common/buttons/CommonButton";
import { TitleIconButtonDialog } from "../components/common/dialogs/TitleIconButtonDialog";
import SuccessRoundOrange from "../components/icons/SuccessRoundOrange";
import FailIcon from "../assets/Alert.svg";
import Banner from "../assets/AboutUs.jpg";
import { contactUs } from "../apis";
import PhoneTextfield from "../components/common/textfields/PhoneTextField";
import { CircularProgress, Stack } from "@mui/material";
import UploadButton, { handleUpload } from "../components/common/buttons/UploadButton";
import constants from "../constants";

const tvApp = window.location.search.indexOf("tvapp=true") > -1;

export default function AboutUs() {
  const { t } = useTranslation("aboutUs");
  const [dialogOpen, setDialog] = useState(false);
  const [failDialogOpen, setFailDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [phoneInput, setPhoneInput] = useState(null);
  const [nameInput, setNameInput] = useState(null);
  const [emailInput, setEmailInput] = useState(null);
  const [messageInput, setMessageInput] = useState(null);
  const [file, setChosenFile] = useState();
  const [fileUrl, setChosenFileUrl] = useState();
  const [errors, setErrors] = useState(null);
  const ordernoRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validate = (isSubmit) => {
    let error = {};
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    if (!emailInput) {
      error.email = t("missing_email");
      isSubmit && setErrors(error);
    } else if (!(validEmail.test(emailInput))){
      error.email = t("invalid_email");
      isSubmit && setErrors(error);
    }
    if (!messageInput) {
      error.message = t("missing_message");
      isSubmit && setErrors(error);
    }
    if (!nameInput) {
      error.name = t("missing_name");
      isSubmit && setErrors(error);
    }
    if (Object.keys(error).length === 0) return true;
    else return false;
  };
  const handleSubmit = async () => {
    setErrors({});
    if (validate(true) && !submitting) {
      try {
        setSubmitting(true);
        let body = {
          name: nameInput,
          email: emailInput,
          phone: phoneInput,
          orderno: ordernoRef.current.value,
          content: messageInput,
        };
        if (file) {
          const urlPath = await handleUpload(file);
          body.file_upload_url = urlPath;
        }
        const result = await contactUs(body);
        if (result.success) {
          setNameInput(null);
          setEmailInput(null);
          setPhoneInput("");
          ordernoRef.current.value = "";
          setMessageInput(null);
          setChosenFile(null);
          setChosenFileUrl(null);
          setDialog(true);
        } else {
          setFailDialog(true);
        }
        setSubmitting(false);
      } catch (e) {}
    }
  };
  const disabled = !validate(false);
  return (
    <StyledMainDiv>
      <ResponsiveUI
        mobile={
          <StyledGridDiv container direction={"column"}>
              <StyledGridTopDiv item xs>
              {!tvApp && (
                <div style={{ marginBottom: "30px" }}>
                  <CommonText sizeType="Heading1" type="display" fontWeight="600">
                    {t(`aboutUs`)}
                  </CommonText>
                </div>
              )}
                <StyledImgDiv src={Banner} alt="Banner"></StyledImgDiv>
              </StyledGridTopDiv>
            <StyledGridLeftDiv item xs>
              <StyledSubTitleDiv>
                <CommonText sizeType="Heading2" type="display" fontWeight="600">
                  {t(`title`)}
                </CommonText>
              </StyledSubTitleDiv>

              <CommonText
                style={{ color: "#BCBCBC", whiteSpace: "pre-line" }}
                sizeType="Body"
                type="body"
              >
                {t(`body`)}
              </CommonText>

              {!tvApp && (
                <div style={{ marginTop: 30 }}>
                  <a
                    style={{ marginRight: "15px" }}
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.makerville.makealive"
                  >
                    <img src={GooglePlay} />
                  </a>
                  <a
                    style={{ marginRight: "15px" }}
                    target="_blank"
                    href="https://apps.apple.com/au/app/makealive/id1623991918"
                  >
                    <img src={AppStore} />
                  </a>
                  <a href="https://appgallery.huawei.com/app/C106277251"
                    target="_blank">
                    <img src={AppGallery} alt="AppGallery" />
                  </a>
                </div>
             )}
            </StyledGridLeftDiv>
          </StyledGridDiv>
        }
        desktop={
          <StyledGridDiv container direction={"column"}>
            <StyledGridTopDiv item xs>
              <div style={{ marginBottom: "30px" }}>
                <CommonText sizeType="Heading1" type="display" fontWeight="600">
                  {t(`aboutUs`)}
                </CommonText>
              </div>
              <StyledImgDiv src={Banner} alt="Banner"></StyledImgDiv>
            </StyledGridTopDiv>

            <StyledGridLeftDiv item xs>
              <StyledSubTitleDiv>
                <CommonText sizeType="Heading2" type="display" fontWeight="600">
                  {t(`title`)}
                </CommonText>
              </StyledSubTitleDiv>

              <CommonText
                style={{ color: "#BCBCBC", whiteSpace: "pre-line" }}
                sizeType="Body"
                type="body"
              >
                {t(`body`)}
              </CommonText>

              <div style={{ marginTop: 30 }}>
                <a
                  style={{ marginRight: "15px" }}
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.makerville.makealive"
                >
                  <img src={GooglePlay} alt="GooglePlay" />
                </a>
                <a
                  style={{ marginRight: "15px" }}
                  target="_blank"
                  href="https://apps.apple.com/au/app/makealive/id1623991918"
                >
                  <img src={AppStore} alt="AppStore" />
                </a>
                <a href="https://appgallery.huawei.com/app/C106277251"
                  target="_blank">
                  <img src={AppGallery} alt="AppGallery" />
                </a>
              </div>
            </StyledGridLeftDiv>
          </StyledGridDiv>
        }
        largeDesktop={
          <StyledGridDiv container direction={"row"}>
            <StyledGridLeftDiv item xs>
              <div>
                <CommonText sizeType="Heading1" type="display" fontWeight="600">
                  {t(`aboutUs`)}
                </CommonText>
              </div>
              <StyledSubTitleDiv>
                <CommonText sizeType="Heading2" type="display" fontWeight="600">
                  {t(`title`)}
                </CommonText>
              </StyledSubTitleDiv>

              <CommonText
                style={{ color: "#BCBCBC", whiteSpace: "pre-line" }}
                sizeType="Body"
                type="body"
              >
                {t(`body`)}
              </CommonText>

              <div style={{ marginTop: 30 }}>
                <a
                  style={{ marginRight: "15px" }}
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.makerville.makealive"
                >
                  <img src={GooglePlay} alt="GooglePlay" />
                </a>
                <a
                  style={{ marginRight: "15px" }}
                  target="_blank"
                  href="https://apps.apple.com/au/app/makealive/id1623991918"
                >
                  <img src={AppStore} alt="AppStore" />
                </a>
                <a href="https://appgallery.huawei.com/app/C106277251"
                  target="_blank">
                  <img src={AppGallery} alt="AppGallery" />
                </a>
              </div>
            </StyledGridLeftDiv>

            <StyledGridRightDiv item xs>
              <StyledImgDiv src={Banner} alt="Banner"></StyledImgDiv>
            </StyledGridRightDiv>
          </StyledGridDiv>
        }
      />

      {!tvApp && (
        <StyledSubTitleDiv>
          <CommonText sizeType="Heading5" type="title">
            {t(`contactUs`)}
          </CommonText>
        </StyledSubTitleDiv>
      )}

      <ResponsiveUI
        mobile={
          !tvApp && (
          <StyledContentDiv>
            <LinkCard
              url="mailto:enquiry@makealive.com"
              desc={t("coopContact")}
              text="enquiry@makealive.com"
              icon={Mail}
            ></LinkCard>
            <LinkCard
              url="https://www.facebook.com/MakeALive.official/"
              desc="Facebook"
              text="MakeALive.official"
              icon={Facebook}
            ></LinkCard>
            <LinkCard
              url="https://www.instagram.com/MakeALive.official/"
              desc="Instagram"
              text="MakeALive.official"
              icon={Instagram}
            ></LinkCard>
          </StyledContentDiv>
          )
        }
        desktop={
          <div>
            <div style={{ marginBottom: "10px" }}>
              <LinkCard
                url="mailto:enquiry@makealive.com"
                desc={t("coopContact")}
                text="enquiry@makealive.com"
                icon={Mail}
              ></LinkCard>
            </div>
            <StyledContentDiv>
              <LinkCard
                url="https://www.facebook.com/MakeALive.official/"
                desc="Facebook"
                text="MakeALive.official"
                icon={Facebook}
              ></LinkCard>
              <LinkCard
                url="https://www.instagram.com/MakeALive.official/"
                desc="Instagram"
                text="MakeALive.official"
                icon={Instagram}
              ></LinkCard>
            </StyledContentDiv>
          </div>
        }
        largeDesktop={
          <StyledContentDiv>
            <LinkCard
              url="mailto:enquiry@makealive.com"
              desc={t("coopContact")}
              text="enquiry@makealive.com"
              icon={Mail}
            ></LinkCard>
            <LinkCard
              url="https://www.facebook.com/MakeALive.official/"
              desc="Facebook"
              text="MakeALive.official"
              icon={Facebook}
            ></LinkCard>
            <LinkCard
              url="https://www.instagram.com/MakeALive.official/"
              desc="Instagram"
              text="MakeALive.official"
              icon={Instagram}
            ></LinkCard>
          </StyledContentDiv>
        }
      />

      {!tvApp && (
        <>
          <StyledSubTitleDiv>
            <CommonText sizeType="Heading5" type="title">
              {t(`csContact`)}
            </CommonText>
            <CommonText style={{ color: "#BCBCBC" }} sizeType="Body" type="body">
              {t(`csRemark`)}
            </CommonText>
          </StyledSubTitleDiv>

          <Stack spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={6}>
                <CommonText sizeType="Heading5" type="title">
                  {t(`name`)}
                </CommonText>
                <FormTextField
                  errorText={errors?.name}
                  onChange={(event) => setNameInput(event.target.value)}
                  placeholder={t("fillIn")}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <CommonText sizeType="Heading5" type="title">
                  {t(`email`)}
                </CommonText>
                <FormTextField
                  errorText={errors?.email}
                  onChange={(event) => setEmailInput(event.target.value)}
                  placeholder={t("fillIn")}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <CommonText sizeType="Heading5" type="title">
                  {t(`phone`)}
                </CommonText>

                <PhoneTextfield 
                  errorText={errors?.phone} 
                  value={phoneInput}
                  placeholder={t("fillIn")}
                  onChange={(value) => setPhoneInput(value)} />
              </Grid>
              <Grid item xs={12} xl={6}>
                <CommonText sizeType="Heading5" type="title">
                  {t(`orderno`)}
                </CommonText>
                <FormTextField
                  errorText={errors?.orderno}
                  inputRef={ordernoRef}
                  placeholder={t("fillIn")}
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <CommonText sizeType="Heading5" type="title">
                  {t(`content`)}
                </CommonText>
                <FormTextField
                  errorText={errors?.message}
                  onChange={(event) => setMessageInput(event.target.value)}
                  placeholder={t("fillOpinion")}
                  multiline
                  rows={8}
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <CommonText sizeType="Heading5" type="title">
                  {t(`file`)}
                </CommonText>
                <UploadButton
                  setChosenFileUrl={setChosenFileUrl}
                  fileUrl={fileUrl}
                  setChosenFile={setChosenFile}
                  file={file}
                />
              </Grid>
            </Grid>
          </Stack>
          <div style={{ marginTop: 25, display: "flex", justifyContent: "center" }}>
            {submitting?
            <StyledButton>
              <CircularProgress size={25} color="inherit" />
            </StyledButton>:
            <StyledButton disabled={disabled} onClick={handleSubmit}>
              {t(`send`)}
            </StyledButton>}
          </div>

          <TitleIconButtonDialog
            visible={failDialogOpen}
            setVisible={setFailDialog}
            title={t("submitFailTitle")}
            body={t("submitFailDesc")}
            icon={FailIcon}
            buttonText={t("submitSuccessButton")}
          />
          <TitleIconButtonDialog
            visible={dialogOpen}
            setVisible={setDialog}
            title={t("submitSuccessTitle")}
            ImgComp={SuccessRoundOrange}
            buttonText={t("submitSuccessButton")}
          />
        </>
      )}

      
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  @media ${breakpoint.mobile} {
    padding: ${tvApp?"24px":"55px"}15px 100px 15px;
  }
  @media ${breakpoint.desktop} {
    padding: 55px 30px 120px 30px;
  }
  @media ${breakpoint.largeDesktop} {
    padding: 55px 90px 120px 90px;
  }
`;

const StyledContentDiv = styled.div`
  @media ${breakpoint.mobile} {
    display: block;
  }
  margin: 0 auto;
  display: flex;
  width: 100%;
`;

const StyledSubTitleDiv = styled.div`
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-top: 61px;
  margin-bottom: 16px;
  line-height: 26px;
`;

const StyledGridDiv = styled(Grid)``;

const StyledGridLeftDiv = styled(Grid)`
  @media ${breakpoint.largeDesktop} {
    padding: 90px 90px 0px 0px;
  }
  text-align: left;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  line-height: 24px;
`;

const StyledGridRightDiv = styled(Grid)`
  && {
    color: rgba(255, 255, 255, 0.8);
    line-height: 28px;
  }
`;

const StyledGridTopDiv = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    line-height: 28px;
  }
`;

const StyledImgDiv = styled.img`
  @media ${breakpoint.mobile} {
    width: 345px;
    height: 222px;
  }
  @media ${breakpoint.desktop} {
    width: 708px;
    height: 455px;
  }
  @media ${breakpoint.largeDesktop} {
    width: 585px;
    height: 700px;
  }
  object-fit: cover;
`;

const StyledButton = styled(CommonButton)`
  && {
    @media ${breakpoint.mobile} {
      width: 100%;
    }
    @media ${breakpoint.desktop} {
      width: 40%;
    }
    @media ${breakpoint.largeDesktop} {
      width: 30%;
    }
    display: flex;
    white-space: nowrap;
  }
`;
