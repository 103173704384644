import React from "react";
import { isValidUrl } from "../utils/url";

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: "image/png" });
};

const downloadToStaticFile = (dataUrl, filename) => {
  const a = document.createElement("a");
  a.href = dataUrl;
  a.style = "display: none";
  a.download = filename || "";
  document.body.appendChild(a);
  a.click();
};

export const useWebShare = () => {
  const onWebShareClick = async (url, filename) => {
    // exit if invalid url
    if (!url || !isValidUrl(url)) return;
    const dataBlobUrl = await toDataURL(url);
    const filenameWithExtension = `${filename}.png`;
    const shareData = {
      files: [dataURLtoFile(dataBlobUrl, filenameWithExtension)],
    };
    const canBrowserShare = !!(
      window.navigator.canShare && window.navigator.canShare(shareData)
    );
    if (canBrowserShare) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        if(err instanceof Error) {
          if(err.name === "NotAllowedError") {
            downloadToStaticFile(dataBlobUrl, filenameWithExtension);
          } 
        }
      }
    } else {
      downloadToStaticFile(dataBlobUrl, filenameWithExtension);
    }
    window.dataLayer.push({
        event: "custom_event",
        event_name: "button_click",
        button_text: "Web Share "
    })
  };

  return { onWebShareClick };
};
