import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint } from "../../utils/responsive";

export default function TermOfUse() {
    const { i18n } = useTranslation('');

    return (
        <div>
            {i18n.language === "en" ?
                // ----------------------ENGLISH VERSION----------------------
                <div style={{ textAlign: 'justify' }}>
                    {/* <StyledTitle>MakeALive - Terms of Use</StyledTitle> */}

                    <StyledDiv>
                        <StyledP>This document contains the terms of use (“Terms of Use”) that are applicable to your use of and access to the MakeALive website at <StyledALink href="www.makealive.com">www.makealive.com</StyledALink> and the MakeALive app (collectively “Site(s)”).</StyledP>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledP>Please read these Terms of Use carefully before using the Site(s). By accessing and/or using the Site(s), you agree to be bound by these Terms of Use (as may be updated from time to time). Please do not access or otherwise use the Site(s) if you do not agree to be bound by these Terms of Use.</StyledP>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>1. Important Notice</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>The Sites are operated by MakerVille Company Limited (“we”, “us” and “our” shall be construed accordingly), a member of PCCW Media Group.
                            </StyledListItem>
                            <StyledListItem>We may update these Terms of Use from time to time. It is your responsibility to check for updates to these Terms of Use as they take effect upon posting on any Site(s). Unless otherwise required by the Applicable Law, your use of any Site(s) following such variation or amendments of these Terms of Use, whether or not perused by you, shall constitute your acceptance to be bound by such variations or amendments. “Applicable Law” in relation to any person, entity, action or thing means the following in relation to that person, action or thing: (i) any law, rule or regulation of any country (or political sub-division of a country); (ii) any obligation under any license in any country (or political sub-division of a country); and (iii) any lawful and binding determination, decision or direction of a regulator in any country (or political sub-division of a country).
                            </StyledListItem>
                            <StyledListItem>The Site(s) and/or the products and services offered on the Site(s) may be modified, updated, interrupted, suspended or discontinued at any time without notice, for any or no reason and without any liability to us.
                            </StyledListItem>
                            <StyledListItem>Where the context so requires or permits in these Terms of Use, the use of singular form includes the plural, and the use of the plural form includes the singular.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>2. The MakeALive Streaming Service </StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem> 	We offer online streaming service for designated events and content to MakeALive members (“Member(s)”) on the Site(s) (“Streaming Service”).
                            </StyledListItem>
                            <StyledListItem>  	You will be required to set up a MakeALive account (“Account”) and become a Member in order to access certain content and sub-pages of the Site(s). Content includes things that you may see, read, hear, download or access on or via the Site(s) (including but not limited to any content accessible through the Streaming Service (“Service Content”), user interface, features, functionalities, layout, messages, files, data, object codes, software, images, photographs, illustrations, text and other materials).
                            </StyledListItem>
                            <StyledListItem>  	You must be at the age of majority or over in your territory or country to set up an Account and become a Member. By applying to set up an Account, you represent that you are at such age of majority or over.
                            </StyledListItem>
                            <StyledListItem>  	There may be some Service Content which is not suitable for viewing by individual(s) under the age of majority (“Minor(s)”). You acknowledge that you are fully aware of the potential risks and you are solely responsible (i) to verify the age of any person who wishes or intends to access the Streaming Service or Service Content via your device or Account, and (ii) to ensure that Minor(s) do not access the Streaming Service or Service Content via your device or Account without adequate and suitable adult supervision and guidance.
                            </StyledListItem>
                            <StyledListItem> 	You warrant that no individual(s) prohibited or otherwise restricted under any Applicable Law from accessing the Streaming Service or Service Content will access the Streaming Service or Service Content via your device or Account.
                            </StyledListItem>
                            <StyledListItem>  	The Streaming Service is intended to be offered to Members within Hong Kong and selected territories only.
                            </StyledListItem>
                            <StyledListItem> 	The Streaming Service and Service Content are for your own private, residential, non-commercial use for personal enjoyment only. As such, you shall not yourself, or permit any other person to, show, play, use, access or otherwise view the Streaming Service or Service Content for, among other purposes, any public or commercial purpose.
                            </StyledListItem>
                            <StyledListItem>  	We do not guarantee the Streaming Service or Service Content will be available through the device(s) and/or network(s) used by you. We are not responsible for any unavailability or technical fault.
                            </StyledListItem>

                            <StyledListItem>

                                <StyledDiv>
                                    <StyledP>
                                        The Site(s) and Streaming Service are provided to you on an “AS-IS” and “AS AVAILABLE” basis and without any express or implied warranty or condition of any kind. To the fullest extent permitted by law, we disclaim any representation or warranty whether express or implied:
                                    </StyledP>
                                </StyledDiv>
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        as to the title, quality, reliability, fitness for a particular purpose, merchantability, accuracy, completeness, or standard of quality and resolution, transmission continuity and timeliness of the Site(s), Streaming Service, and Service Content;
                                    </StyledListItem>
                                    <StyledListItem>
                                        that your access to and use of the Site(s), Streaming Service, and Service Content will be uninterrupted, free of delay or error, free of disruption or suspension, free of viruses, malicious code or other harmful components or will be otherwise secure;
                                    </StyledListItem>

                                    <StyledListItem>
                                        that the Site(s), Streaming Service, and Service Content will meet your requirements;
                                    </StyledListItem>

                                    <StyledListItem>
                                        as to the results to be obtained from use of the Site(s), Streaming Service, and Service Content unless otherwise specifically mentioned in these Terms of Use;
                                    </StyledListItem>

                                    <StyledListItem>
                                        as to any obligation to maintain the confidentiality of information (although our current practice is to maintain such confidentiality)
                                    </StyledListItem>

                                    <StyledListItem>
                                        that installation, presence or usage of the Site(s), Streaming Service, and/or Service Content on your device, will not affect the availability or usability of other software, applications, content and/or services.
                                    </StyledListItem>
                                </StyledOrderList>

                                <StyledListItem>
                                    No refund or compensation will be provided by us in respect of any time when the Site(s), Streaming Service, and/ or Service Content is interrupted, delayed, disrupted, suspended or otherwise affected due to (i) maintenance, (ii) any error or technical issue, or (iii) any circumstance beyond our reasonable control, or where you are not able to access or otherwise use (including but not limited to viewing and streaming) the Site(s), Streaming Service and/or Service Content for whatever reason.
                                </StyledListItem>

                                <StyledListItem>
                                    If your use of the Site(s), Streaming Service, and/or Service Content results in the need for servicing or replacing any property, material, equipment or data, we will not be responsible for such costs.
                                </StyledListItem>

                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>


                    <StyledDiv>
                        <StyledSubTitle>3. MakeALive Member</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                You will be required to register as a MakeALive Member in order to access certain Content and sub-pages of the Site(s).
                            </StyledListItem>

                            <StyledListItem>
                                You shall ensure that all information provided by you to us is and remains to be truthful, complete, accurate and up to date.
                            </StyledListItem>

                            <StyledListItem>
                                You shall be strictly and solely responsible for all obligation and liability arising from any use of or access to the Site(s), Streaming Service and/or Service Content under your Account.
                            </StyledListItem>

                            <StyledListItem>
                                The use of the Streaming Service and/or other product(s) or service(s) offered by us and/or certain features of the Site(s) under your Account may be subject to additional terms and conditions. In the event of any inconsistency with these Terms of Use, the additional terms and conditions shall prevail.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>


                    <StyledDiv>
                        <StyledSubTitle>4.  Stream Tickets</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                The Streaming Service and Service Content is only available to Members who have purchased the applicable stream ticket(s) (“Stream Ticket(s)”) and completed payment (if so required) in accordance with the applicable instructions. The sale and usage of Stream Tickets shall be subject to additional terms and conditions posted on the Site(s).
                            </StyledListItem>

                            <StyledListItem>
                                Members are solely responsible to verify (i) the accuracy of information provided (including but not limited to payment information) and (ii) the details of their order (including but not limited to the Stream Ticket ordered). Once payment is completed, no application or request for change, cancellation or refund will be accepted. We are not responsible for any inaccuracies in the payment information provided by Members, which may cause failure or problems in their order placement, redemption of Stream Ticket(s), and/or access to or use of the Streaming Service and/or Service Content.
                            </StyledListItem>

                            <StyledListItem>
                                You understand and agree that (i) we do not guarantee the Streaming Service or Service Content will be available through the device(s) and/or network(s) used by you, and (ii) no refund or compensation will be provided by us in respect of your inability to access or use the Streaming Service or Service Content after the purchase of any Stream Ticket(s).
                            </StyledListItem>

                            <StyledListItem>
                                Each member is only entitled to purchase one stream ticket for any single show of an event under each Account.
                            </StyledListItem>

                            <StyledListItem>
                                The maximum number of devices on which you may simultaneously stream the Service Content depends on the Stream Ticket and is specified on the ticket information page.
                            </StyledListItem>

                            <StyledListItem> In the event that the Service Content or the streaming of an event is cancelled or postponed by the event organizer or by us, the refund and/or related arrangements will be posted on the Site(s). We shall not be responsible for any loss or damage arising from the cancellation or postponement.
                            </StyledListItem>

                            <StyledListItem>
                                We may offer discounted Stream Tickets from time to time. In relation to such discount, we may impose certain conditions. If at any time you fail to comply with any of these conditions, your Stream Ticket will be automatically forfeited without any refund or compensation.
                            </StyledListItem>

                            <StyledListItem>
                                All Stream Ticket(s) and handling fee(s) are non-refundable under any circumstances.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>5. Usage of the Site(s) and the Streaming Service</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                You use the Site(s) and Streaming Service at your own risk.
                            </StyledListItem>

                            <StyledListItem>
                                You must evaluate, and bear all risks associated with the use of any content, including but not limited to reliance on the accuracy, completeness or usefulness of any content. All information provided on or via the Site(s) and Streaming Service is for information purposes only. You should seek your own independent advice with respect to any content.
                            </StyledListItem>

                            <StyledListItem>
                                You must not by yourself or permit any other person to:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        use any of the Site(s), Streaming Service and/or Service Content (or any part thereof) in any way for any purpose other than your own private, residential, non-commercial use for personal enjoyment;
                                    </StyledListItem>

                                    <StyledListItem> sell, reproduce, copy, distribute, modify, display, publish, publicly perform, prepare or license derivative works based on, record, download, frame, repost, adapt, sub-license, exploit, translate, sell, reverse engineer, decompile or disassemble any of the Site(s), Streaming Service and/or Service Content (or any part thereof) in any way;
                                    </StyledListItem>

                                    <StyledListItem>
                                        disrupt or interfere with the Site(s), the Streaming Service, servers, network, software, hardware or equipment connected to or via the Site(s) and/or the Streaming Service;
                                    </StyledListItem>

                                    <StyledListItem>
                                        restrict or inhibit any other persons from using the Site(s) or the Streaming Service, including without limitation, by means of “hacking” or defacing any portion of the Site(s) and/or the Streaming Service;
                                    </StyledListItem>

                                    <StyledListItem>
                                        access or use the Site(s), the Streaming Service or the Service Content for any unlawful purpose or any purpose not eopressly authorised by these Terms of Use;
                                    </StyledListItem>

                                    <StyledListItem>
                                        remove any copyright, trademark, or other proprietary rights notices contained in the Site(s), the Streaming Service and/or the Service Content;
                                    </StyledListItem>

                                    <StyledListItem> use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, indeo, “data mine,” or in any way reproduce, disrupt or circumvent the navigational structure or presentation of the Site(s), the Streaming Service and/or the Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        hack, break into, or attempt to hack or break into, in any manner the Site(s), the Streaming Service, the Service Content and/or any data areas on our server(s) or that of any third parties;
                                    </StyledListItem>

                                    <StyledListItem>
                                        download, copy, store, rip, distribute, share or re-direct any Content (including but not limited to the Service Content) from the Site(s) or the Streaming Service in any way or through any media;
                                    </StyledListItem>

                                    <StyledListItem>
                                        incorporate any software or other materials that contain any virus, worm, time bomb, trojan horse, or other harmful or disruptive component into the Site(s), the Streaming Service or the Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content (including but not limited to the Service Content) transmitted through the Site(s) or the Streaming Service;
                                    </StyledListItem>

                                    <StyledListItem>
                                        engage in advertising or any commercial behavior in connection with the Site(s) and/or the Streaming Service;
                                    </StyledListItem>

                                    <StyledListItem>
                                        use our name or the name of MakeAlive to spread false information in any way which may harm others reputation or privacy, publish false, untrue or criminal information, spread pornography, obscenity, computer viruses, engage in any illegal or commercial infringement;
                                    </StyledListItem>


                                    <StyledListItem>
                                        violate any Applicable Law relating to your use of the Site(s), the Streaming Service or the Service Content;
                                    </StyledListItem>


                                    <StyledListItem>
                                        violate any of our operating and/or usage rules, as amended from time to time; and/or
                                    </StyledListItem>


                                    <StyledListItem>
                                        collect or store personal data of other users of the Site(s) and/or the Streaming Service;
                                    </StyledListItem>


                                    <StyledListItem>
                                        charge any other person:
                                        <StyledOrderList type="a">
                                            <StyledListItem>
                                                for access to or use of the Site(s), Streaming Service and/or the Service Content; and/or
                                            </StyledListItem>

                                            <StyledListItem>
                                                at any premises where the Streaming Service and/or Service Content is shown (in any way for any purpose other than your own private, residential, non-commercial use for personal enjoyment).
                                            </StyledListItem>
                                        </StyledOrderList>
                                    </StyledListItem>


                                    <StyledListItem>
                                        remove, modify or otherwise alter any content protection in the Site(s), Streaming Service and/or the Service Content;
                                    </StyledListItem>


                                    <StyledListItem>
                                        infringe the intellectual property rights of any person or entity in connection with the Site(s), Streaming Service and/or Service Content.
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                You acknowledge and agree that:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        we do not exercise any editorial control over certain Service Content and do not make any claims as to the truth in any Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        certain Service Content are provided by third party providers, and the availability and editorial control of such Service Content is outside of our control and we assume no responsibility or liability in this regard;
                                    </StyledListItem>

                                    <StyledListItem>
                                        you have not relied upon any information or statement to the effect that any particular Service Content will be made available to you in whole or in part;
                                    </StyledListItem>

                                    <StyledListItem>
                                        we and our third party providers make no representation, promise, warranty, undertaking or guarantee as to the quality, reliability, completeness, usefulness or continuity of the Streaming Service and any Service Content, and disclaim all liability in respect of such;
                                    </StyledListItem>

                                    <StyledListItem>
                                        we and our third party providers can vary, replace or withdraw any Service Content without notice and without any liability to you;
                                    </StyledListItem>

                                    <StyledListItem>
                                        the content of the Service Content solely reflects the view(s) of the author(s) or speaker(s) of said Service Content, not our views or any entity associated with us;
                                    </StyledListItem>

                                    <StyledListItem>
                                        nothing you do on or in relation to the Site(s), the Streaming Service or the Service Content will transfer any intellectual property rights to you or license you to exercise any intellectual property rights;
                                    </StyledListItem>

                                    <StyledListItem>
                                        you must safeguard the log-in name and password of your Account;
                                    </StyledListItem>

                                    <StyledListItem>
                                        you shall not access any Streaming Service or Service Content without the purchase of the applicable
                                        Stream Ticket;
                                    </StyledListItem>

                                    <StyledListItem>
                                        our provision of Streaming Service, your ability to use features of the Site(s) and your accessibility to the Streaming Service is contingent upon, among other things, (i) the service capacity allocated by us, (ii) the reliability and stability of your internet connection or mobile network, (iii) the compatibility of your device(s), and (iv) the operating systems and/or browsers installed on your device(s). We will not be liable to you or any other third parties for any interruption, disruption, failure, error, delay or suspension in your access to or use of the Site(s), Streaming Service or Service Content (whether due to the lack of streaming capacity or otherwise) and/or the consequences or effects on your mobile network, internet connection, their speed and/or bandwidth that may be caused by the Site(s), Streaming Service or Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        the streaming of Service Content on the Site(s) is subject to the agreements signed by us with their respective rights holders to streaming the same; and
                                    </StyledListItem>

                                    <StyledListItem>
                                        we may at any time employ and/or install software in any device(s) used by you, where it is necessary for purposes of detecting and/or preventing any unlawful downloading, copying, storing, ripping, distribution, sharing or re-direction of Service Content from the Site(s) and/or Streaming Service in any way or through any media.
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                You agree that to the extent permitted by Applicable Law:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        we can expand, reduce, withdraw and/or modify any part of the Streaming Service and/or any Service Content, remove, disable and/or suspend transmission or streaming of the Streaming Service and/or Service Content which we consider in our sole opinion to be (i) in breach of any Applicable Law, or (ii) unacceptable;
                                    </StyledListItem>

                                    <StyledListItem>
                                        we reserve the right at any time to charge you for any access to the Streaming Service and/or the Service Content under your Account without a purchased and valid Stream Ticket. Any such charge(s) will be calculated by reference to data recorded or logged by us or our affiliates and not by reference to any data recorded or logged by you. Records held and logging procedures adopted by us will be conclusive evidence of your access to the Streaming Service and/or the Service Content; and
                                    </StyledListItem>

                                    <StyledListItem>
                                        we reserve the right at any time to vary or amend the applicable terms and conditions pertaining to our provision of and/or your access to the Streaming Service and/or the Service Content.
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                Using the Streaming Service through a mobile network may result in mobile data charges. Please contact your mobile data service provider for further information.
                            </StyledListItem>

                            <StyledListItem>
                                Unauthorised access to the Streaming Service and/or any Service Content is a material breach of these Terms of Use and a violation of the law.
                            </StyledListItem>

                            <StyledListItem>
                                You represent and warrant that:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        you are not designated on the Specially Designated Nationals and Blocked Persons List maintained by the Office of Foreign Assets Control of the United States Department of Treasury or any other sanctions list, and
                                    </StyledListItem>

                                    <StyledListItem>
                                        you are not restricted to access the Site(s), the Streaming Service or the Service Content under any Applicable Law.
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                Our authorisation for you to access the Site(s), the Streaming Service and Service Content is conditional upon your strict compliance with these Terms of Use.
                            </StyledListItem>

                            <StyledListItem>
                                You may from time to time be requested by us to download and install software on your device, or update the MakeAlive app. You acknowledge that you may be unable to use or access the Streaming Service in the event you fail to successfully install the software, or update the MakeALive app. Your usage of such software and/or updated MakeALive app is subject to these Terms of Use and the applicable software license.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>6. Merchandise</StyledSubTitle>
                        Merchandise will be offered for sale on the Site(s) from time to time. The sale of Merchandise shall be subject to additional terms and conditions posted on the Site(s).
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>7. Payment</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                For payment (s) to us (including but not limited to the payment for Stream Ticket or Merchandise), you must provide your valid payment details to one of our authorised third party payment gateways.
                            </StyledListItem>

                            <StyledListItem>
                                You warrant that the payment information you provide is correct and that you are fully authorised to use the applicable payment card, payment instrument and/or payment method.
                            </StyledListItem>

                            <StyledListItem>
                                You are advised to read the terms and conditions of our third party payment gateway before you provide any payment details or information to it. We shall not be responsible for any details or information provided to such payment gateways and you agree that we will not be liable for any damages, losses and/or expenses that you may suffer by doing so.
                            </StyledListItem>

                            <StyledListItem>
                                We will not store or collect your payment card details. That information is provided directly to our third party payment gateway(s) whose use of your personal information is governed by their privacy policy.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>8. Account Termination and Access Restriction</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                To the extent permitted by the Applicable Law, we can limit, suspend, end or restrict your access to or use of the Site(s), the Streaming Service and/or Service Content and/or terminate your Account, without giving you advance notice and without providing any refund or compensation if:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        the Applicable Law requires us to do so;
                                    </StyledListItem>

                                    <StyledListItem>
                                        there is an emergency that affects our ability to provide any Site(s), the Streaming Service and/or Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        the Site(s), the Streaming Service and/or Service Content is withdrawn or restricted from operation for whatever reason;
                                    </StyledListItem>

                                    <StyledListItem>
                                        we no longer hold the right to offer or operate the Site(s), the Streaming Service and/or Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        we fail to obtain, hold, retain or renew any relevant regulatory approvals or if any relevant regulatory approvals are revoked or if we are required by a relevant authority to cease or restrict operation of the Site(s) or the Streaming Service;
                                    </StyledListItem>

                                    <StyledListItem>
                                        the Site(s), the Streaming Service and/or Service Content (including but not limited to advertisements contained therein) fails to comply with any regulatory standards, relevant laws or any requirements or orders issued from time to time by a relevant authority; or
                                    </StyledListItem>

                                    <StyledListItem>
                                        there is a suspected or actual breach by you of these Terms of Use (including but not limited to the use of the Site(s), Streaming Service or Service Content for any purpose other than your own private, residential, non-commercial use for personal enjoyment).
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>


                            <StyledListItem>
                                We reserve the sole and absolute discretion to block visitors from certain IP addresses and prevent their access to the Site(s) or Streaming Service, and we shall not be obliged to provide reasons thereof.
                            </StyledListItem>

                            <StyledListItem>
                                Upon termination of your Account, all licenses, rights and privileges granted to you shall cease. Any such termination shall not affect the applicability of these Terms of Use and all accrued rights or liabilities of each party hereto, nor shall termination affect the coming into force or the continuance in force of any provision contained in these Terms of Use which is expressly or by implication intended to come into or continue in force on or after such termination.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>9. Third Party Providers</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                You acknowledge that parts of the Site(s) and/or Streaming Service may be provided or maintained by third party providers ("Third Party Providers") and not by us.
                            </StyledListItem>

                            <StyledListItem>
                                Your correspondence or dealings with any Third Party Provider are solely between you and that Third Party Provider, and you agree that we will not be liable or responsible for any loss or damage of any sort incurred as the result of such dealings.
                            </StyledListItem>

                            <StyledListItem>
                                Your use of third party webpages or apps linked to the Site(s) may be governed by additional or different terms and conditions.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>10. Security System</StyledSubTitle>
                        <StyledP>
                            We take a variety of protective measures, including encryption and verification tools to protect visitor information. For visitors' product orders, we use secure servers. All credit card information and payment-related information provided by visitors will be transmitted through Security Sockets Layer (SSL) technology and encrypted in our database.
                        </StyledP>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>11. Copyright and Trademark</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                The trademarks, trade names and logos displayed on the Site(s) are owned by MakerVille Company Limited and/ or are licensed to be used by us. You must not by yourself or permit any other person to these without the written consent of the applicable entity. You are granted no right or license with respect to any of the aforesaid trademarks, trade names and logo.
                            </StyledListItem>

                            <StyledListItem>
                                The Site(s) and any information available on it, including but not limited to, text, graphics, icons, images, software, source code and trademarks, are protected by copyright, design, trademark and other intellectual property rights owned or controlled by us and/or the licensors.
                            </StyledListItem>

                            <StyledListItem>
                                You must not copy, reproduce, republish, upload, download, post, transmit, store in retrieval system, modify, alter or display in public or distribute in any way the Contents or any part of the Site(s) (including but not limited to Service Content) for any purpose without our prior written permission.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>12. Personal Data </StyledSubTitle>
                        <StyledOrderList type="a">

                            <StyledListItem>
                                We collect, process, utilize, disclose, retain and use your Personal Data (defined below) in accordance with the Personal Information Collection Statement (<StyledALink href="https://makealive.com/private-policy?id=PICS">https://makealive.com/private-policy?id=PICS</StyledALink>) and PCCW Group Privacy Statement (<StyledALink href="http://www.pccw.com/privacy-statement/index.page?locale=zh">http://www.pccw.com/privacy-statement/index.page?locale=zh）</StyledALink>) (collectively the “Privacy Terms”). Please read the Privacy Terms carefully as they provide information about how we use, share, store and process your Personal Data (as defined in the Personal Data (Privacy) Ordinance Cap 486 of Hong Kong).
                            </StyledListItem>

                            <StyledListItem>
                                By providing your Personal Data to us, you authorize and consent for us to collect, retain, disclose and use Personal Data for the purposes stated in the Privacy Terms and, more specifically, for the following purposes:
                                <StyledOrderList type="i">
                                    <StyledListItem>operating, processing, maintaining and/or managing your Account, as applicable;</StyledListItem>
                                    <StyledListItem>verifying your identity in connection with any products and/or services that we may provide you;</StyledListItem>

                                    <StyledListItem>providing to you goods and services offered by us (including but not limited to the Streaming Service);</StyledListItem>
                                    <StyledListItem>providing you with customer service and responding to your queries, requests, feedback or claims;</StyledListItem>

                                    <StyledListItem>for payment purposes of the products or services on the Site(s);</StyledListItem>
                                    <StyledListItem>
                                        using your Personal Data to compile aggregated data for performing research or statistical analysis in order to improve our products and services;
                                    </StyledListItem>

                                    <StyledListItem>
                                        with your consent, for marketing and promoting product and services as stated in the Personal Information Collection Statement (<StyledALink href="https://makealive.com/private-policy?id=PICS">https://makealive.com/private-policy?id=PICS</StyledALink>);
                                    </StyledListItem>

                                    <StyledListItem>
                                        making such disclosures as may be required for any of the above purposes or as required by law or in respect of any feedback or claims made by you; and
                                    </StyledListItem>

                                    <StyledListItem>for any other purposes which you may from time to time agree.</StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                When necessary, we may also disclose and transfer (whether in Hong Kong or abroad) your Personal Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations for purposes above.
                            </StyledListItem>

                            <StyledListItem>
                                Any Personal Data which we receive about you will be retained by us and will be accessible by persons and parties stated in the Privacy Terms.
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>13. Cookies</StyledSubTitle>

                        <StyledListItem>
                            The Site(s) use cookies or similar tracking tools on your machine or device in order for us to maintain your identity across multiple webpages and/or internet sessions; we also use your Data (for instance, via Google Analytics) to compile aggregate data for performing research or statistical analysis in order to improve our products and services. We do not otherwise collect your cookies or other online identifiers, whether for the purposes of personalising your user experience, monitor your behavior, predict your preferences or otherwise. The Site(s) is initially set up to accept cookies. You can opt-out of or delete historical cookies by changing the settings on your web or device browsers; however, if you do so, you may find that certain features on the Site(s) are unable to work properly. With respect to Google Analytics, if you want to deactivate Google Analytics or opt-out, please download and install the add -on for your web browser provided by Google at <StyledALink href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</StyledALink> through such link, you may learn more about the opt-out and how to properly install the browser add-on, and also the Google Analytics Privacy Policy.
                        </StyledListItem>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>14. Links to third party apps, websites or webpages</StyledSubTitle>
                        The Site(s) may contain links to other apps, websites and/or webpages which are operated by third parties. We have no control over the Content of the linked apps, websites and/or webpages or the way in which the operators of those apps, websites and/or webpages deal with your personal data. We shall not be responsible for the Content or accuracy of the linked apps, websites and/or webpages operated by third parties. You should review the terms and conditions/privacy policy for those third party apps, websites and/or webpages to understand the ways in which your personal data may be used by those third parties and we disclaim all responsibility and liability for any loss or damage that may be suffered by you in connection with the linked apps, websites and/or webpages. The inclusion of any link does not imply that we endorse the linked apps, websites and/or webpages, and you use the links at your own risk.
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>15. Additional Disclaimers</StyledSubTitle>
                        <StyledOrderList type="a">

                            <StyledListItem>
                                Products and services offered on the Site(s) (including but not limited to the Streaming Service) are intended for users within Hong Kong and selected territories only. Please note that accordingly we are not able to service any requests specific to users outside of Hong Kong and selected territories, including without limitation, the payment of goods and/or services in local currencies or the delivery of any goods or service to addresses outside of Hong Kong and selected territories. "Hong Kong" means the Hong Kong Special Administrative Region of the People's Republic of China.
                            </StyledListItem>

                            <StyledListItem>
                                Information and promotional offers in relation to any goods and/or services including but not limited to the Streaming Service) may be updated on the Site(s) from time to time without prior notice to you. The valid period of any information or promotional offers displayed on the Site(s) is based on Hong Kong time in accordance with our timer in the server.
                            </StyledListItem>

                            <StyledListItem>
                                The Content contained on the Site(s) is for reference only. We do not warrant the accuracy, completeness, reliability or timeliness of the information on the Site(s) for any reason whatsoever. We shall not be liable to you or any user for any costs, claims, liabilities, losses or damages whatsoever arising directly or indirectly from the use of or reliance on any such information.
                            </StyledListItem>

                            <StyledListItem>
                                We provide no warranty regarding non-infringement, security, fitness for purpose or freedom from computer viruses is given in connection with any content on the Site(s) or any other websites linked to or accessed via the Site(s), we accept no liability for any loss or damage howsoever arising from any use or access of the Site(s) or any such other linked website or any reliance upon any content of the Site(s) or any such other linked website.
                            </StyledListItem>

                            <StyledListItem>
                                To the fullest extent permitted by law, we expressly disclaim any and all liability arising out of or in connection with:
                                <StyledOrderList type="i">
                                    <StyledListItem>your access to, use, inability to use or inability to access the Site(s), Streaming Service, Service Content or any other website you access through a link from the Site(s);</StyledListItem>
                                    <StyledListItem>any change in any Content made available on or through the Site(s) or Streaming Service;</StyledListItem>

                                    <StyledListItem>any viruses, malicious codes or other harmful component that may infect your computer equipment or other property, or any damage to or loss of data suffered by you by reason of your use of or access to the Site(s), Streaming Service and/or Service Content;</StyledListItem>
                                    <StyledListItem>your inability or failure (i) to purchase any Stream Ticket or (ii) to access or use the Streaming Service and/or Service Content, due to any error, system or technical fault or other reasons;</StyledListItem>

                                    <StyledListItem>your access to, use of, inability to use or access the Site(s), Streaming Service or Service Content outside of Hong Kong and selected territories. For avoidance of doubt, no refund or compensation will be provided by us in this connection.</StyledListItem>
                                    <StyledListItem>
                                        any loss or theft of your log-in details and/or password, or any unauthorised use of your Account;
                                    </StyledListItem>

                                    <StyledListItem>
                                        any failure or delay to supply, provide or make available the Streaming Service and/or Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        any interruption, disruption, suspension, delay, error or failure of the Site(s), Streaming Service and/or Service Content thereof:
                                    </StyledListItem>

                                    <StyledListItem>
                                        any claim relating to the title, quality, reliability, fitness for a particular purpose, merchantability, accuracy, completeness, or standard of quality and resolution, transmission continuity and timeliness of the Site(s), Streaming Service, and/or Service Content;
                                    </StyledListItem>

                                    <StyledListItem>
                                        if the Site(s), Streaming Service, and Service Content does not meet your requirements;
                                    </StyledListItem>

                                    <StyledListItem>
                                        any infringement of intellectual property rights arising from your access to or use of the Site(s), Streaming Service and/or Service Content; and
                                    </StyledListItem>

                                    <StyledListItem>
                                        any impact or effect on the availability or usability of other software, applications, content and/or services on your device due to the installation, presence or usage of the Site(s), Streaming Service, and/ or Service Content.
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                Unless otherwise indicated by us, we do not participate in the planning, organisation or execution of any event(s) which may be streamed via our Streaming Service. For any questions related to the event(s), please contact the event organizer(s) directly.
                            </StyledListItem>
                        </StyledOrderList>

                        <StyledDiv>
                            <StyledSubTitle>16. Limitation of Liability and Indemnity</StyledSubTitle>
                            <StyledOrderList type="a">
                                <StyledListItem>
                                    To the fullest extent permitted by Applicable Law, we will under no circumstances:
                                    <StyledOrderList type="i">
                                        <StyledListItem>
                                            accept any liability whatsoever for any incidental, consequential or indirect damages, loss or corruption of data, loss of profits, goodwill, bargain or opportunity or loss of anticipated savings or any other loss in connection with the Site(s), the Streaming Service, Service Content and/or any other goods or services supplied and/or provided by us, whether based on warranty, contract, tort, negligence or any other legal theory, and whether or not we know of the possibility of such damage; and
                                        </StyledListItem>

                                        <StyledListItem>
                                            pay more than Hong Kong One Hundred Dollars (HK$100) in compensation or the price of the applicable Stream Ticket (if applicable) paid by the Member, whichever is higher, for our aggregate or services supplied and/or provided by us.
                                        </StyledListItem>
                                    </StyledOrderList>
                                </StyledListItem>

                                <StyledListItem> You agree to indemnify us, our affiliates, agents, directors, officers, employees, content providers, sub-contractors, licensors and/or agents against any actions, liability, costs, claims, losses, damages, proceedings and/or expense arising from, in connection with or which is related to:
                                    <StyledOrderList type="i">
                                        <StyledListItem>
                                            your use of or access to the Site(s), the Streaming Service and Service Content;
                                        </StyledListItem>

                                        <StyledListItem>
                                            the use of or access to the Site(s), the Streaming Service and Service Content under your Account; and
                                        </StyledListItem>

                                        <StyledListItem>
                                            any breach or non-observance of these Terms of Use by you.
                                        </StyledListItem>
                                    </StyledOrderList>
                                </StyledListItem>

                                <StyledListItem>
                                    Your indemnity obligation under these Terms of Use shall survive your usage or access of the Site(s) or the Streaming Service.
                                </StyledListItem>
                            </StyledOrderList>
                        </StyledDiv>

                        <StyledDiv>
                            <StyledSubTitle>17. Notices</StyledSubTitle>
                            <StyledOrderList type="a">
                                <StyledListItem>
                                    Subject to the Applicable Law, we will give you any necessary notices by posting them on the Site(s). You agree:
                                    <StyledOrderList type="i">
                                        <StyledListItem>
                                            to check the Site(s) for notices; and
                                        </StyledListItem>

                                        <StyledListItem>
                                            that you will be considered to have received a notice when it is posted on the Site(s).
                                        </StyledListItem>
                                    </StyledOrderList>
                                </StyledListItem>
                            </StyledOrderList>
                        </StyledDiv>

                        <StyledDiv>
                            <StyledSubTitle>18. General Provisions</StyledSubTitle>
                            <StyledOrderList type="a">
                                <StyledListItem>
                                    If any provisions of these Terms of Use shall be construed to be illegal, invalid or unenforceable for any reason, such provisions shall not affect the legality, validity or enforceability of the other provisions of these Terms of Use. The illegal, invalid or unenforceable provision shall be amended to the extent necessary to make them legal, valid or enforceable or, if not possible to do so, deleted from these Terms of Use, but all other provisions of these Terms of Use shall continue in full force and effect.
                                </StyledListItem>

                                <StyledListItem>
                                    The governing law of and exclusive forum for dispute resolution relating to these Terms of Use shall be Hong Kong law and Hong Kong courts.
                                </StyledListItem>

                                <StyledListItem>
                                    Our failure to enforce any rights or provisions under these Terms of Use shall not constitute a waiver of such or any other provision.
                                </StyledListItem>

                                <StyledListItem>
                                    We shall not be liable or responsible for any failure to fulfil any of our obligations due to causes or events beyond our reasonable control.
                                </StyledListItem>

                                <StyledListItem>
                                    Should there be any inconsistencies between the English version and the Chinese version of these Terms of Use, the English version shall prevail.
                                </StyledListItem>

                                <StyledListItem>
                                    Subject to the Applicable Law, we can transfer our rights and obligations hereunder to any person or entity without your consent. We can also use an agent or subcontractor to perform our responsibilities.
                                </StyledListItem>

                                <StyledListItem>
                                    A person or entity who is not a party to these Terms of Use shall have no right under the Contracts (Rights of Third Parties) Ordinance (Chapter 623 of the laws of Hong Kona) or any similar legislation in any jurisdiction to enforce any part of these Terms of Use.
                                </StyledListItem>
                            </StyledOrderList>
                        </StyledDiv>
                    </StyledDiv>

                </div>

                :
                // ----------------------CHINESE VERSION----------------------
                <div style={{ textAlign: 'justify' }}>
                    {/* <StyledTitle>MakeALive使用條款</StyledTitle> */}

                    <StyledDiv>
                        <StyledP>
                            本文包含的使用條款(「本使用條款」)適用於閣下使用及訪問MakeALive網站(<StyledALink href="www.makealive.com">www.makealive.com</StyledALink>)及MakeALive應用程式（MakeALive網站及應用程式以下統稱(「本網站」)。
                        </StyledP>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledP>
                            請於使用本網站前仔細閱讀本使用條款。閣下訪問及/或使用本網站即代表閣下同意受本使用條款（可能會不時更改）約束。如閣下不願受本使用條款約束，請停止訪問或使用本網站。
                        </StyledP>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>1. 重要提示</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                本網站是由電訊盈科媒體集團旗下成員MakerVille Company Limited （「我們」）營運。
                            </StyledListItem>
                            <StyledListItem>
                                我們會不時更新本使用條款。閣下有責任查閱有關更新內容，因為本使用條款的更新內容於本網站公佈後即時生效。除非適用法律另有規定，閣下於本使用條款更改或修訂後繼續使用本網站，均表示閣下同意接受有關更改或修訂後內容的約束，無論閣下有否細閱有關更改或修訂。「適用法律」指就任何人士、機構、行動或事宜而言，與該人士、行為或事情有關的以下各項：（i） 任何國家（或國家之政治分區）的任何法律、法則或法規；（ii） 任何國家（或國家之政治分區）的任何特許文件所規定的任何責任及義務；及（iii） 任何國家（或國家之政治分區）的監管機構的任何合法及具約束力的裁決、決定或指引。
                            </StyledListItem>
                            <StyledListItem>
                                本網站及／或本網站上提供的產品及服務可能基於任何原因或無需任何原因，隨時修改、更新、中斷、暫停或停止，我們就此不作另行通知及承擔任何責任。
                            </StyledListItem>
                            <StyledListItem>
                                除本使用條款的文義需要不同釋義，否則單數字眼包含複數之意思，而複數字眼亦包含單數之意思。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>2. MakeALive串流服務</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                我們於本網站上為MakeALive會員(「會員」）提供指定活動及指定內容的線上串流服務(「串流服務」)。
                            </StyledListItem>
                            <StyledListItem>
                                閣下需要建立一個MakeALive帳戶(「帳戶」)及成為會員以訪問本網站上的特定內容及介面。內容包括閣下在或經本網站觀看、閱讀、收聽、下載或訪問的內容（包括但不限於任何經串流服務獲取的內容(「服務內容」)、用戶介面、特點、功能、設計、訊息、文件、數據、目標代碼、軟件、圖像、照片、插圖、文本及其他材料）。
                            </StyledListItem>
                            <StyledListItem>
                                閣下必須合乎閣下所居住區域或國家規定之成年年齡或以上方可建立帳戶及成為會員。閣下建立帳戶即代表閣下已成年或為成年年齡以上。
                            </StyledListItem>
                            <StyledListItem>
                                部分服務內容可能不適合未成年人士(「未成年人」)觀看。閣下聲明其完全明白前述情況下可能引起的風險及閣下獨自負責：（i）檢查任何希望或有意透過閣下的設備或帳戶訪問串流服務或服務內容的人士的年齡，及（ii）確保未成年人在沒有足夠及適當的成人監督及指引下不會透過閣下的設備或帳戶訪問串流服務或服務內容。
                            </StyledListItem>
                            <StyledListItem>
                                閣下保證任何適用法律禁止或限制訪問串流服務或服務內容的人士不會透過閣下的設備或帳戶訪問串流服務或服務內容。
                            </StyledListItem>
                            <StyledListItem>
                                串流服務意在僅向位於香港及指定區域的會員提供。
                            </StyledListItem>
                            <StyledListItem>
                                串流服務及服務內容僅供閣下作個人私人、住宅、非商業用途及私人享用。因此，閣下自身不得及不得容許任何其他人士放映、播放、使用、訪問或瀏覽串流服務或服務內容，作任何公開或商業用途。
                            </StyledListItem>
                            <StyledListItem>
                                我們就串流服務或服務內容可否於閣下使用的設備及/或網絡上使用不作出任何保證。我們對任何不可用性或技術問題均不承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                <StyledDiv>
                                    <StyledP>
                                        本網站及串流服務均按「現況」及「現狀」基礎提供，並不包含任何明示或暗示的保證。在法律允許的最大範圍內，我們對下列事項不作任何明示或暗示的聲明或保證：
                                    </StyledP>
                                </StyledDiv>
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        本網站，串流服務及服務內容的所有權、質素、可靠性、對某特定用途的適用性、可銷售性、準確性、完整性、質素、解析度的標準、傳輸連續性和及時性;
                                    </StyledListItem>
                                    <StyledListItem>
                                        閣下訪問及使用本網站，串流服務及服務內容不會出現中斷、不會發生延誤或錯誤、不會被干擾或暫停、沒有病毒、惡意代碼或其他有害元件、及不會出現安全性問題;
                                    </StyledListItem>

                                    <StyledListItem>
                                        本網站，串流服務及服務內容會滿足閣下的需求;
                                    </StyledListItem>

                                    <StyledListItem>
                                        使用本網站，串流服務及服務內容會產生的結果，除非本使用條款另有明確表示;
                                    </StyledListItem>

                                    <StyledListItem>
                                        任何對資料保密的責任（雖然我們現行的守則也維持該等資料保密原則）;
                                    </StyledListItem>

                                    <StyledListItem>
                                        在閣下的設備中安裝、呈現或使用本網站，串流服務及╱或服務內容不會影響其他軟體、應用程式、內容及╱或服務的可用情況或可用性。
                                    </StyledListItem>
                                </StyledOrderList>

                                <StyledListItem>
                                    於任何時候，因（i）維修；（ii）任何錯誤或技術問題；或（iii）超出我們能合理控制的任何情況，而導致本網站、串流服務及／或服務內容中斷、干擾、暫停或受影響，或閣下因任何原因未能訪問或使用(包括但不限於瀏覽及串流)本網站、串流服務及/或服務內容，我們不會退款或賠償。
                                </StyledListItem>

                                <StyledListItem>
                                    倘若閣下因使用本網站，串流服務及╱或服務內容而導致需要維修或更換任何財產、材料、設備或數據，我們將不會負責有關費用。
                                </StyledListItem>

                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>


                    <StyledDiv>
                        <StyledSubTitle>3. MakeALive會員</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                閣下需要建立一個帳戶而成為MakeALive會員以訪問本網站的特定內容及介面。
                            </StyledListItem>

                            <StyledListItem>
                                閣下必須保證閣下提供給我們的所有資料均為真實、完整、正確且及時。
                            </StyledListItem>

                            <StyledListItem>
                                就任何通過閣下帳戶使用或訪問本網站、串流服務及/或服務內容而引起的所有義務及責任，閣下自行承擔全部責任。
                            </StyledListItem>

                            <StyledListItem>
                                通過閣下帳戶使用串流服務、及/或我們提供的其他產品或服務及／或本網站的某些功能時，可能會受附加的條款及條件約束。附加的條款及條件如與本使用條款有歧異，概以該等附加條款及條件為準。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>


                    <StyledDiv>
                        <StyledSubTitle>4. 串流門票</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                串流服務及服務內容僅供已購買相關串流門票(「串流門票」)及依據相關指示完成付款（如需要）的會員使用及訪問。串流門票的銷售及使用須受本網站上的附加條款及條件約束。
                            </StyledListItem>

                            <StyledListItem>
                                會員就(i)其提供之資料的準確性（包括但不限於付款資料）；及（ii）其訂單詳情(包括但不限於訂購的串流門票)自負責任。付款經完成，任何更改、取消或退款的申請均將不被接受。我們就會員提供的任何不正確付款資料（可能導致確認訂單、換領串流門票及/或訪問或使用串流服務及/或服務內容失敗或出現問題）不承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                閣下明白及同意(i)我們就串流服務或服務內容可於閣下使用的設備及/或網絡上使用不作任何保證；及（ii）就閣下購買任何串流門票後未能訪問或使用串流服務或服務內容，不設任何退款或賠償。
                            </StyledListItem>

                            <StyledListItem>
                                每位會員通過每個帳戶就任何一個活動的同一場次只可訂購一張串流門票。
                            </StyledListItem>

                            <StyledListItem>
                                閣下可同時使用串流服務內容之設備數量視串流門票，及列明於門票資料網頁。
                            </StyledListItem>

                            <StyledListItem>
                                如活動的主辦方或我們取消或延遲服務內容或一項活動的串流服務，退款及／或相關安排將發佈於本網站。我們就取消或延遲而引致之任何損失或損害不承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                我們可能會不時提供串流門票的折扣優惠。我們可能會就該折扣優惠設置額外條件。倘閣下於任何時候未能符合此等條件，閣下的串流門票將會自動作廢，且不設任何退款或賠償。
                            </StyledListItem>

                            <StyledListItem>
                                所有串流門票及任何行政費於任何情況下均不退款。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>5. 本網站及串流服務的使用</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                閣下使用本網站及串流服務，須自負風險。
                            </StyledListItem>

                            <StyledListItem>
                                閣下須評估及承擔與使用任何有關內容（包括但不限於依賴任何有關內容的準確性、完整性或有用性）有關的一切風險。所有在或經本網站及串流服務提供的資料僅供參考。閣下應就任何有關內容自行尋求獨立意見。
                            </StyledListItem>

                            <StyledListItem>
                                閣下不得自行或容許任何其他人士:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        以任何方式將本網站、串流服務及/或服務內容（或其任何部分）用於任何目的（為個人、私人、住宅、非商業用途及私人享用除外）;
                                    </StyledListItem>

                                    <StyledListItem>
                                        以任何形式出售、複製、拷貝、發行、更改、展示、公開、公開演出、製作或許可服務內容製作衍生作品、錄影、下載、提取、再張貼、修改、改編、再授權、利用、翻譯、銷售、逆向工程、解編或拆解本網站、串流服務及／或服務內容（或其任何部分）;
                                    </StyledListItem>

                                    <StyledListItem>
                                        擾亂或干擾本網站、串流服務、服務器、網絡、軟件、或連接至或經過本網站及／或串流服務連接的設備或硬件;
                                    </StyledListItem>

                                    <StyledListItem>
                                        限制或阻止其他人士使用本網站或串流服務，包括但不限於「非法入侵」或破壞本網站的任何部分及/或串流服務;
                                    </StyledListItem>

                                    <StyledListItem>
                                        訪問或使用本網站、串流服務或服務內容以逹致任何非法目的或本使用條款未明確授權的任何目的;
                                    </StyledListItem>

                                    <StyledListItem>
                                        删除本網站、串流服務及/或服務內容所包含的任何版權、商標或其他所有權聲明;
                                    </StyledListItem>

                                    <StyledListItem>
                                        使用任何機械人、蜘蛛程序、網站搜尋/檢索應用程序，或其他手動或自動裝置或程序檢索、編制索引、「挖掘數據」，或以任何方式複製及重現、擾亂或規避本網站、串流服務及/或服務內容的導航結構或呈列方式;
                                    </StyledListItem>

                                    <StyledListItem>
                                        以任何方式非法入侵、闖入或企圖以任何方式非法入侵或闖入本網站、串流服務、服務內容及/或我們或任何第三方服務器上的任何數據區;
                                    </StyledListItem>

                                    <StyledListItem>
                                        以任何方式或經任何媒介，下載、複製、儲存、翻錄、發行、分享或再指引本網站或串流服務的任何內容（包括但不限於服務內容）;
                                    </StyledListItem>

                                    <StyledListItem>
                                        在本網站、串流服務或服務內容加入含有任何病毒、蠕蟲、計時炸彈、特洛伊木馬或其他有害或具破壞性組件的任何軟件或其他物料;
                                    </StyledListItem>

                                    <StyledListItem>
                                        僞造標題或以其他方式操控標識符以掩飾透過本網站或串流服務傳送的任何內容之來源;
                                    </StyledListItem>

                                    <StyledListItem>
                                        從事與本網站及/或串流服務相關的廣告或任何商業性質之行為；
                                    </StyledListItem>

                                    <StyledListItem>
                                        冒用我們及／或MakeALive之名義，以任何途徑散播可能含妨害他人名譽或私隱的不實消息、發表虛假不實或犯罪的資訊，傳播色情、猥褻、電腦病毒、從事任何非法或商業侵權行為；
                                    </StyledListItem>


                                    <StyledListItem>
                                        違反任何有關閣下使用本網站、串流服務或服務內容的適用法律；
                                    </StyledListItem>


                                    <StyledListItem>
                                        違反我們的任何操作及/或使用規則（經不時修訂）；
                                    </StyledListItem>

                                    <StyledListItem>
                                        收集或儲存本網站及/或串流服務的其他用戶的個人資料；
                                    </StyledListItem>

                                    <StyledListItem>
                                        向任何人士就以下收取費用：
                                        <StyledOrderList type="a">
                                            <StyledListItem>
                                                訪問或使用本網站、串流服務及/或服務內容；及/或
                                            </StyledListItem>

                                            <StyledListItem>
                                                於串流服務及 /或服務內容以任何方式及於任何目的（為閣下的個人、私人、住宅、非商業用途及私人享用除外）被展示的任何場所。
                                            </StyledListItem>
                                        </StyledOrderList>
                                    </StyledListItem>


                                    <StyledListItem>
                                        移除、改變或更改本網站、串流服務及/或服務內容上的任何內容保護措施;
                                    </StyledListItem>

                                    <StyledListItem>
                                        侵犯與本網站、串流服務及/或服務內容有關的任何人士或組織的知識產權。
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                閣下明白及同意：
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        我們對某些服務內容沒有編輯管制，亦不對任何服務內容的真實性發表任何聲明；
                                    </StyledListItem>

                                    <StyledListItem>
                                        某些服務內容是由第三方供應商提供，我們就此等服務內容的供應性及編輯沒有管制，及我們就此不承擔任何責任或負責。
                                    </StyledListItem>

                                    <StyledListItem>
                                        閣下沒有以任何資料或陳述作為依據而認為任何個別服務內容會整段或部分供應予閣下；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們及我們的第三方供應商對串流服務及任何服務內容的質素、可靠性、完整性、用處、播放持續性不作任何聲明、允諾、擔保、承諾或保證；及就前述不承擔任何責任；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們及我們的第三方供應商可更改、更換或取消任何服務內容，並不作另行通知，且就此我們毋須對閣下承擔任何責任；
                                    </StyledListItem>

                                    <StyledListItem>
                                        服務內容的內容只代表該服務內容的作者或講者的個別意見，並不代表我們或任何與我們有關聯的公司之意見；
                                    </StyledListItem>

                                    <StyledListItem>
                                        閣下於本網站、串流服務或服務內容上服務內容所作出或與其相關的任何行為不會使閣下獲任何知識產權的轉讓，或被授權行使任何知識產權；
                                    </StyledListItem>

                                    <StyledListItem>
                                        閣下須保護閣下帳戶的登錄名及密碼；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們提供串流服務及閣下能否使用本網站上提供的服務功能及使用串流服務取決於（其中包括但不限於）（i）我們分配的服務流量；(ii）閣下互聯網或流動網絡連線的可靠性及穩定性;（iii)閣下設備的兼容性，及(iv)閣下設備上安裝的操作系統及 /或瀏覽器。我們概不對閣下或任何其他第三方就訪問本網站、串流服務或服務內容或訪問時的任何中斷、干擾、失敗、錯誤、延遲或暫停（不論是由於串流流量不足或其他因素所致），及/或本網站、串流服務或服務內容可能對閣下的流動網絡和互聯網連線、其速度及/或頻寬所造成的結果或影響承擔任何責任；
                                    </StyledListItem>

                                    <StyledListItem>
                                        於本網站串流服務內容須受我們與相關的播放權持有人就串流此等服務內容所簽訂的協議所約束；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們可隨時以任何方式或透過任何媒介，於閣下使用的任何裝置設置及／或安裝軟體，以監測及阻止任何從本網站及／或串流服務不合法下載、複製、儲存、錄製、發行、分享及╱或重新定向任何服務服務內容的行為。
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                閣下同意在適用法律允許的最大範圍內：
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        在我們認為串流服務及/或服務內容違反任何適用法律或在其他方面不可接受的情況下，我們可擴充、縮減、取消及／或修改串流服務及/或服務內容的任何部分，移除、禁止使用及/或暫停串流服務及/或服務內容的傳送或串流;
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們保留權利隨時就任何人士通過閣下帳戶(但未使用已購買的有效串流門票)訪問任何串流服務及/或服務內容而向閣下收取費用。該費用將會依照我們或我們的關聯公司所記錄或記存的數據計算，而非參照閣下記錄或記存的任何資料。我們所持的紀錄及所採用的記存程序將為閣下使用串流服務及/或服務內容的最終憑證；及
                                    </StyledListItem>

                                    我們保留權利隨時更改或修訂我們向閣下提供及/或閣下使用本網站、串流服務及/或服務內容相關的適用條款及條件。
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                透過流動網絡使用串流服務可能會產生流動數據費用。請聯絡閣下的流動數據服務供應商了解詳情。
                            </StyledListItem>

                            <StyledListItem>
                                未經授權訪問串流服務及／或任何服務內容，屬於違反本使用條款及觸犯法律的行為。
                            </StyledListItem>

                            <StyledListItem>
                                如未有購買有效串流門票，閣下不得訪問相關的串流服務及／或服務內容。
                            </StyledListItem>

                            <StyledListItem>
                                <StyledP>
                                    閣下聲明及保證：
                                </StyledP>
                                <StyledP>
                                    閣下未列名美國外國資產控制辦公室(Office of Foreign Assets Control of the United States Department of Treasury)的特別指定國民名單或其他禁止人員名單 (Specially Designated Nationals And Blocked Persons List)或任何其他制裁名單；及
                                </StyledP>

                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        閣下未被任何適用法律禁止訪問本網站、串流服務或服務內容。
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                我們授權閣下訪問本網站、串流服務及服務內容是以閣下嚴格遵守本使用條款為條件。
                            </StyledListItem>

                            <StyledListItem>
                                閣下可能會隨時被要求下載及安裝軟體或更新MakeALive應用程式。閣下明白如閣下未能成功安裝軟體或更新MakeALive應用程式，閣下可能會不能使用或訪問串流服務。閣下使用該等軟體及/或已更新的MakeALive應用程式須受本使用條款及適用的軟件許可的約束。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>6. 商品</StyledSubTitle>
                        本網站會不時提供商品銷售。商品的銷售受本網站發佈的附加條款及條件約束。
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>7. 付款</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                向我們支付的付款（包括但不限於為串流門票或商品支付），閣下必須向我們授權的第三方付款平台提供閣下的有效付款詳情。
                            </StyledListItem>

                            <StyledListItem>
                                閣下保證閣下提供的付款資料均屬正確，及閣下已獲充分授權使用相關付款卡、付款工具及/或付款方式。
                            </StyledListItem>

                            <StyledListItem>
                                我們建議閣下向我們的第三方付款平台提供任何付款詳情或資料前閱讀其條款及條件。我們就閣下向該等付款平台提供的任何詳情或資料不承擔任何責任，而且閣下同意我們就閣下因此而遭受的任何損害、損失及/或費用不承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                我們將不會儲存或收集閣下付款卡的資料。此等資料將直接提供於我們的第三方付款平台，該第三方付款平台對閣下個人資料的使用受其私隱政策的約束。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>8. 帳戶終止及訪問限制</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                倘屬以下情況，於適用法律允許的最大範圍內，我們可在不給予閣下事先通知的情況下限制、暫停、終止或約束閣下訪問及/或使用本網站、串流服務及/或服務內容及/或終止閣下的帳戶，並不提供任何退款或賠償:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        適用法律要求我們如此行事；
                                    </StyledListItem>

                                    <StyledListItem>
                                        發生緊急情況影響我們提供本網站、串流服務及／或服務內容的能力；
                                    </StyledListItem>

                                    <StyledListItem>
                                        本網站、串流服務及／或服務內容因任何原因而被撤銷營運或被限制；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們不再擁有提供或營運本網站、串流服務及/或服務內容的權利；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們未能獲得、持有、保留或重續任何相關監管批准，或倘任何相關監管批准被撤銷，或倘相關當局要求我們停止或限制營運本網站、或串流服務；
                                    </StyledListItem>

                                    <StyledListItem>
                                        本網站、串流服務及／或服務內容（包括但不限於當中的任何廣告）未能遵守任何監管標準、相關法律或相關當局不時發佈的任何規定或命令;或
                                    </StyledListItem>

                                    <StyledListItem>
                                        如我們懷疑閣下或閣下已經違反本使用條款（包括但不限於使用本網站、串流服務或服務內容作任何目的，為閣下個人、私人、住宅、非商業用途及私人享用除外）。
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>


                            <StyledListItem>
                                我們保留全權及絕對的權利阻止來自特定的IP位址的訪問者及阻止其訪問本網站，及我們將無義務為此提供理由。
                            </StyledListItem>

                            <StyledListItem>
                                閣下的帳戶終止後，授予閣下的所有許可、權利及特權亦終止。任何有關終止概不影響本使用條款的適用性（及各有關方的既有權利或責任，亦不影響本使用條款中明示或暗示於有關終止之時或之後擬生效或繼續生效的任何條款的生效或繼續生效）。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>9. 第三方供應商</StyledSubTitle>

                        <StyledOrderList type="a">
                            <StyledListItem>
                                閣下確認已明白，本網站及/或串流服務的某些部分是由第三方供應商(「第三方供應商」)而不是由我們提供或維持。
                            </StyledListItem>

                            <StyledListItem>
                                閣下與任何第三方供應商的通訊及交易，僅涉及閣下與該第三方供應商，且閣下同意，我們概不就因該等交易而導致的任何損失或損害承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                閣下使用連結至本網站的第三方網頁或應用程式，可能受到附加或不同條款及條件的約束。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>10. 安全系統</StyledSubTitle>
                        <StyledP>
                            我們採取多種保護方法(包括加密和驗證工具)來保護訪客的信息。對於訪客的產品訂單，我們使用安全的伺服器。所有訪客提供的信用卡信息和與付款相關的信息，將被通過安全通訊端層（SSL）技術傳輸並在我們的資料庫中加密。
                        </StyledP>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>11. 版權與商標</StyledSubTitle>
                        <StyledOrderList type="a">
                            <StyledListItem>
                                本網站所顯示的商標、商號和標誌均由MakerVille Company Limited擁有及/或經許可於我們使用。未經相關機構的書面同意，閣下不得(自行或容許其他人士)使用前述商標、商號和標誌。就任何前述商標、商號及標誌，閣下並未被授予任何權利或許可。
                            </StyledListItem>

                            <StyledListItem>
                                本網站及其所載的任何資料，包括但不限於文字、圖像、圖標、影像、軟件、原始代碼及商標等均受我們及／或其授權人擁有或控制的版權、外觀設計、商標及其他知識產權保護。
                            </StyledListItem>

                            <StyledListItem>
                                未經我們事先書面同意，閣下不得（自行或容許其他人士）將本網站的內容或其任何部份（包括但不限於服務內容），為任何目的，複製、重製、再版、上傳、下載、刊登、傳送、儲存於某一檔案復原系統、更改、改變或公開展示或以任何方式分發。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>12. 個人資料 </StyledSubTitle>
                        <StyledOrderList type="a">

                            <StyledListItem>
                                我們按照個人資料收集聲明（<StyledALink href="https://makealive.com/private-policy?id=PICS">https://makealive.com/private-policy?id=PICS</StyledALink>）及電訊盈科集團私隱聲明（<StyledALink href="http://www.pccw.com/privacy-statement/index.page?locale=zh">http://www.pccw.com/privacy-statement/index.page?locale=zh）</StyledALink>）（統稱「私隱條款」）收集、處理、利用、披露、保留及使用閣下的個人資料。請仔細閱讀並參見私隱條款，當中載有有關我們如何使用、分享、儲存及處理閣下的個人資料（定義見香港法例第486章《個人資料（私隱）條例》）的資訊。
                            </StyledListItem>

                            <StyledListItem>
                                當閣下提供閣下的個人資料予我們，即視作閣下允許及同意我們收集丶保留、披露及使用閣下之個人資料用於私隱條款中所載之目的，及具體下列目的：
                                <StyledOrderList type="i">
                                    <StyledListItem>營運丶處理丶維持及/或管理閣下的帳戶，如適用；</StyledListItem>
                                    <StyledListItem>就有關我們可能向閣下提供的任何產品及/或服務核實閣下的身份;</StyledListItem>

                                    <StyledListItem>向閣下提供由我們提供的產品及服務（包括但不限於串流服務）;</StyledListItem>
                                    <StyledListItem>向閣下提供客戶服務及回覆閣下的查詢丶要求、意見或索賠;</StyledListItem>

                                    <StyledListItem>用於為本網站上産品或服務的付款；</StyledListItem>
                                    <StyledListItem>
                                        為改進我們產品及服務，使用閣下個人資料進行編輯統計數據以進行研究或統計分析;
                                    </StyledListItem>

                                    <StyledListItem>
                                        經閣下同意，用於個人資料收集聲明（<StyledALink href="https://makealive.com/private-policy?id=PICS">https://makealive.com/private-policy?id=PICS</StyledALink>）中所載之營銷及推廣商品及服務;
                                    </StyledListItem>

                                    <StyledListItem>
                                        基於上述目的的要求或法律的要求或閣下的任何反饋或索賠要求而作出的披露；及
                                    </StyledListItem>

                                    <StyledListItem>閣下可能不時同意的其他任何目的。</StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                如有必要、我們可能會向我們的專業顧問、執法機關、保險公司、政府及監管機構及其他組織基於上述條款中所列的相關目的披露及提供（無論是香港境內或境外）閣下的個人資料。
                            </StyledListItem>

                            <StyledListItem>
                                我們收到有關閣下的任何個人資料將由我們保留及私隱條款中所載之個人及各方均將有權使用。
                            </StyledListItem>
                        </StyledOrderList>
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>13. 曲奇</StyledSubTitle>
                        本網站會在閣下的裝置或設備中使用cookies (小型文字檔案）或類似的追蹤工具，以便我們在多個網頁和/或互聯網段落中保留閣下的足跡；我們亦會使用閣下的個人資料或資料來彙集（例如通過Google Analytics）群組數據以進行研究或統計分析，以改進我們的產品和服務。我們不會以其他方式收集閣下的 cookies 或其他線上識別碼（無論是用於個性化閣下的用戶體驗、監控閣下的行為、預測閣下的偏好或其他目的）。我們的網站在最初會設定為接受 cookies。閣下可以透過更改閣下網頁或裝置瀏覽器的設定選擇拒絕或刪除 cookies 的歷史記錄；但是，閣下在更改有關設定後，閣下可能會發現本網站的某些功能會不能正常運作。有關Google Analytics， 如果閣下想停用或拒絕 Google Analytics，請於閣下的網頁瀏覽器下載及安裝Google在  <StyledALink href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</StyledALink> 上提供的附加元件(add-on)。透過該連結，閣下可以了解更多有關拒絕及如何適當安裝瀏覽器附加元件，及Google Analytics私隱政策。
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>14. 第三方應用程式丶網站或網頁之連結</StyledSubTitle>
                        本網站可能載有通往其他由第三方所運作的應用程式、網站及／或網頁的連結。我們沒有權控制該等連結應用程式、網站及／或網頁的內容或該等連結應用程式、網站及／或網頁的營運者處理閣下個人資料的方法。我們就第三方運營的、連結的應用程式、網站及／或網頁上的內容或其準確性不承擔任何責任。閣下應檢閱該等第三方應用程式、網站及／或網頁之私隱政策及／或條款及條件，以瞭解該等第三方可能如何使用閣下之個人資料。我們就閣下因該等連結應用程式、網站及／或網頁而遭受的任何損失或損害不承擔任何責任。我們提供的任何連結並不代表我們推薦該等應用程式、網站及／或網頁，閣下使用此等連結需自負風險。
                    </StyledDiv>

                    <StyledDiv>
                        <StyledSubTitle>15. 其他免責聲明</StyledSubTitle>
                        <StyledOrderList type="a">

                            <StyledListItem>
                                本網站提供的商品及服務（包括但不限於串流服務）意在僅向位於香港及指定區域的使用者提供。因此請注意我們不能為香港及指定區域以外的使用者提出的要求提供服務，包括但不限於以閣下當地貨幣為商品付款或向香港以外的地址寄送任何商品。「香港」是指中華人民共和國香港特別行政區。
                            </StyledListItem>

                            <StyledListItem>
                                本網站上有關任何產品及/或服務（包括但不限於串流服務）的資料及促銷優惠可能會不時更新，且不會另行提前通知閣下。本網站顯示的任何資料或促銷優惠的有效期以我們服務器的計時及香港時間為準。
                            </StyledListItem>

                            <StyledListItem>
                                在本網站顯示的內容僅供參考用途。我們並不會基於任何原因就本網站上資料的準確性、完整性、可靠性或及時效性作任何保證。我們就閣下或任何用戶因使用或依賴該等資料所引起直接或間接的費用、申索、責任、損失或損害等，不承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                我們對關於任何於本網站內或本網站連結的或經本網站訪問的任何其他網站的任何內容之不侵權、保安、於某用途的適用性或不受電腦病毒影響等不作出保證，如因使用或訪問本網站或任何連結網站或依據本網站或任何連結網站的內容而招致任何損失或損害，我們概不承擔任何責任。
                            </StyledListItem>

                            <StyledListItem>
                                在法律允許的最大範圍內，我們明確表示我們不就因以下事項產生或有關的損害或損失承擔任何責任:
                                <StyledOrderList type="i">
                                    <StyledListItem>
                                        閣下訪問、使用、未能使用或未能訪問本網站、串流服務、服務內容或經本網站中連結訪問的任何其他網站；
                                    </StyledListItem>
                                    <StyledListItem>
                                        本網站或串流服務上提供的任何內容的任何更改；
                                    </StyledListItem>

                                    <StyledListItem>
                                        閣下使用或訪問本網站、串流服務及/或服務內容而導致可能影響閣下電腦設備或其他財產的病毒、惡意代碼或其他有害元件；
                                    </StyledListItem>
                                    <StyledListItem>
                                        由於任何錯誤、延遲、系統或技術故障或其他原因而造成閣下不能或未能（i）購買任何串流門票，或（ii）訪問或使用串流服務及／或服務內容；
                                    </StyledListItem>

                                    <StyledListItem>
                                        閣下於香港及指定區域訪問、使用、未能使用或未能訪問本網站、串流服務或服務內容。為免存疑，在此種情況下，我們不會提供任何退款或賠償；
                                    </StyledListItem>

                                    <StyledListItem>
                                        閣下登入資料及/或密碼的遺失或被竊，或任何人士未經許可使用閣下帳戶；
                                    </StyledListItem>

                                    <StyledListItem>
                                        我們未能或延遲供應或提供任何串流服務及/或串流內容；
                                    </StyledListItem>

                                    <StyledListItem>
                                        本網站、串流服務及/或服務內容的任何中斷、干擾、暫停、延遲、錯誤或失敗；
                                    </StyledListItem>

                                    <StyledListItem>
                                        有關本網站，串流服務及/或服務內容的所有權、質素、可靠性、對某特定用途的適用性、可銷售性、準確性、完整性或質素及解析度的標準、傳輸連續性和及時性；
                                    </StyledListItem>

                                    <StyledListItem>
                                        本網站，串流服務及/或服務內容未能達到閣下的要求；
                                    </StyledListItem>

                                    <StyledListItem>
                                        因閣下使用本網站，串流服務及╱或服務內容而導致侵犯任何知識產權；及
                                    </StyledListItem>

                                    <StyledListItem>
                                        本網站、串流服務及╱或服務內容的安裝、存在或使用對閣下設備上其他軟件、應用程式、內容及/或服務造成的影響。
                                    </StyledListItem>
                                </StyledOrderList>
                            </StyledListItem>

                            <StyledListItem>
                                除非我們明示，我們不會參與可能透過我們的串流服務串流的任何活動的策劃、組織或實行。就任何有關該等活動的問題，請直接聯絡此等活動的主辦方。
                            </StyledListItem>
                        </StyledOrderList>

                        <StyledDiv>
                            <StyledSubTitle>16. 責任限制及補償</StyledSubTitle>
                            <StyledOrderList type="a">
                                <StyledListItem>
                                    於適用法律允許的最大範圍內，在任何情況下，我們均不會:
                                    <StyledOrderList type="i">
                                        <StyledListItem>
                                            對閣下因本網站、串流服務、及服務內容及/或我們供應及/或提供的任何其他產品或服務，而引起的任何附帶引起、相應或間接的損害、數據損失或損壞，利潤、商譽、優惠買賣或機會的損失，或預期節省的損失，或任何其他損失而承擔任何責任，不論是否基於保證、合約、侵權、疏忽或任何其他法律理論，也不論我們是否獲悉該等損害的可能性；
                                        </StyledListItem>

                                        <StyledListItem>
                                            就有關本網站、串流服務、服務內容及/或我們所供應及╱或提供的任何其他產品或服務下的我們的全部責任支付超過港幣一百元（港幣100元）或閣下支付的相關串流門票價格（如適用）(兩者以較高者為準)的補償。
                                        </StyledListItem>
                                    </StyledOrderList>
                                </StyledListItem>

                                <StyledListItem>
                                    閣下同意就因以下各項所產生、與之相關或有關的任何訴訟、法律責任、費用、賠償、損失、損害、法律程序及╱或開支向我們、我們的聯屬公司、代理、董事、高級職員、僱員、內容供應商、分包商、授權人及╱或代理作出補償：
                                    <StyledOrderList type="i">
                                        <StyledListItem>
                                            閣下使用或訪問本網站、串流服務及服務內容；
                                        </StyledListItem>

                                        <StyledListItem>
                                            經閣下帳戶使用或訪問本網站、串流服務及服務內容；及
                                        </StyledListItem>

                                        <StyledListItem>
                                            閣下違反或不遵守本使用條款的任何條款。
                                        </StyledListItem>
                                    </StyledOrderList>
                                </StyledListItem>

                                <StyledListItem>
                                    本使用條款中閣下的補償責任將在閣下停止使用或訪問本網站或串流服務後繼續存在。
                                </StyledListItem>
                            </StyledOrderList>
                        </StyledDiv>

                        <StyledDiv>
                            <StyledSubTitle>17. 通知</StyledSubTitle>
                            <StyledOrderList type="a">
                                <StyledListItem>
                                    在適用法律的規定下，我們將透過在本網站公佈通知的方式向閣下發出任何必要的通知。閣下同意:
                                    <StyledOrderList type="i">
                                        <StyledListItem>
                                            查看本網站上的通知；及
                                        </StyledListItem>

                                        <StyledListItem>
                                            當有關通知公佈於本網站時，即視為閣下已收到該通知。
                                        </StyledListItem>
                                    </StyledOrderList>
                                </StyledListItem>
                            </StyledOrderList>
                        </StyledDiv>

                        <StyledDiv>
                            <StyledSubTitle>18. 一般條款</StyledSubTitle>
                            <StyledOrderList type="a">
                                <StyledListItem>
                                    倘本使用條款的任何條文因任何原因被認為屬非法、無效或不可執行，該等條文不影響本使用條款中其他條文的合法性、有效性或可執行性。非法、無效或不可執行的條文應在必要的範圍內予以修訂以使其合法、有效或可執行，否則應從本使用條款中刪除，而本使用條款的所有其他條文應繼續具十足效力及作用。
                                </StyledListItem>

                                <StyledListItem>
                                    與本使用條款有關的糾紛應受香港法律管限及由香港法院的專屬司法管轄權管轄。
                                </StyledListItem>

                                <StyledListItem>
                                    我們未能執行本使用條款項下的任何權利或條文並不構成豁免該等或任何其他條文。
                                </StyledListItem>

                                <StyledListItem>
                                    對於因超出我們合理控制範圍的原因或活動而導致我們無法履行任何義務的情況，我們概不承擔任何責任或負責。
                                </StyledListItem>

                                <StyledListItem>
                                    倘本使用條款的英文版本與中文版本有任何不一致，概以英文版本為準。
                                </StyledListItem>

                                <StyledListItem>
                                    在適用法律的規定下，我們可轉讓我們於本使用條款下的權利及義務予任何人士或機構，毋須經閣下的同意。我們亦可委聘代理人或分包商履行我們的責任。
                                </StyledListItem>

                                <StyledListItem>
                                    非本使用條款訂約方的人士或機構概無權利根據《合約（第三者權利）條例》（香港法例第623章）或任何司法管轄區的任何類似法例執行本使用條款的任何部分。
                                </StyledListItem>
                            </StyledOrderList>
                        </StyledDiv>
                    </StyledDiv>
                </div>
            }
        </div>
    )
}


const StyledTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-decoration: underline;
`;

const StyledSubTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    text-align: justify;
`;

const StyledDiv = styled.div`
    margin-bottom: 20px;
`;

const StyledP = styled.p`
    margin-bottom: 20px;
    font-size: 16px;
`;

const StyledOrderList = styled.ol`
    padding-left: 50px;
`;

const StyledListItem = styled.li`
    padding-left: 20px;
    margin-bottom: 10px;
`;

const StyledALink = styled.a`
    text-decoration: underline;
    white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
`;
