import { login } from "../../apis";
import CommonButton from "../common/buttons/CommonButton";
import PhoneTextField from "../common/textfields/PhoneTextField";
import EmailTextField from "../common/textfields/EmailTextField";
import PasswordTextField from "../common/textfields/PasswordTextField";
import Space from "../Space";
import EmailPhoneToggleButton from "../common/buttons/EmailPhoneToggleButton";
import { useState, useRef, useContext } from "react";
import TextButton from "../common/buttons/TextButton";
import CommonText from "../common/typographies/CommonText";
import { AppContext } from "../../AppContext";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import constants from "../../constants";
import Logo from '../../assets/Logo.svg';
import BackButton from "../common/buttons/BackButton";
import { CircularProgress } from "@mui/material";
import TagManager from 'react-gtm-module'
import { useNavigate } from "react-router-dom";

export default function Login({ handlePageChange, handleClose }) {
    const { t } = useTranslation("signin")
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("email");
    const [error, setError] = useState(null);
    const [phoneInput, setPhoneInput] = useState("");
    const { setUser, signInRedirectUrl, setSignInRedirectUrl } = useContext(AppContext)
    const emailRef = useRef();
    const passwordRef = useRef();
    const validate = () => {
        let error = {};
        const validPassword = new RegExp(constants.PASSWORD_REGEX);
        if (type == "email") {
            const validEmail = new RegExp(constants.EMAIL_REGEX);
            if (!(validEmail.test(emailRef.current.value) && validPassword.test(passwordRef.current.value))) error.password = t('invalid_email_password')
        } else if (type == "phone") {
            const validPhone = new RegExp(constants.PHONE_REGEX);
            if (!(validPhone.test(phoneInput) && validPassword.test(passwordRef.current.value))) error.password = t('invalid_email_password')
        }
        setError(error);
        if (Object.keys(error).length === 0) return true;
        else return false;
    }
    const handleLogin = async () => {
        if (!loading && validate()) {
            setLoading(true);
            const result = await login(type == "email" ? emailRef.current.value : phoneInput, passwordRef.current.value);
            if (result.success) {
                //login success
                localStorage.setItem('accessToken', result.data.accessToken);
                localStorage.setItem('refreshToken', result.data.refreshToken);
                setUser(result.data.user);
                window.dataLayer.push({
                    event: "custom_event",
                    event_name: "login",
                    method: "email"
                })
                if(signInRedirectUrl != null) navigate(signInRedirectUrl);
                setSignInRedirectUrl(null);
                handleClose();
            } else {
                setError({ password: t('invalid_email_password') });
            }
            setLoading(false);
        }
    }
    return (
        <Container>
            <Space size="30px" />
            <img width={105} src={Logo} alt="Logo" />
            <Space size="15px" />
            <CommonText type="primary" fontSize="24px" fontWeight="400" lineHeight="34px">{t('member_login')}</CommonText>
            <Space size="30px" />
            <EmailPhoneToggleButton value={type} onChange={(event) => setType(event.target.value)} />
            <Space size="25px" />
            {type == "email" ? <EmailTextField inputRef={emailRef} label={t('email')} /> : <PhoneTextField onChange={(value) => setPhoneInput(value)} label={t('phone')} />}
            <Space size="25px" />
            <PasswordTextField errorText={error?.password} inputRef={passwordRef} label={t('password')} height={48} style={{ fontFamily: 'Roboto' }} />
            <Space size="9px" />
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}><TextButton onClick={() => handlePageChange("forgotpassword")}>{t('forgotpassword')}</TextButton></div>
            <Space size="25px" />
            <CommonButton style={{ width: '100%' }} variant="contained" type={"primary"} onClick={loading ? null : handleLogin}>{loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : t('login')} </CommonButton>
            <BackButton onClick={() => handlePageChange("root")} />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`