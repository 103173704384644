import React from "react";
import styledcomponents from "styled-components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import colors from "../../../utils/colors";
import DownArrow from "../../../assets/DownArrow.svg";

const BootstrapInput = styled(InputBase)(({ theme, hoverBorderColor }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    color: "#ffffff",
    borderRadius: 0,
    position: "relative",
    border: "1px solid rgba(255, 255, 255, .5)",
    paddingLeft: 15,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: hoverBorderColor || colors.red,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
function CommonSelect(props) {
  const iconStyle = {
    position: "absolute",
    right: 13,
    pointerEvents: "none",
    height: 9.5,
    width: 16.5,
    opacity: props.disabled ? "50%" : "100%",
  };

  const SelectIcon = (props) => {
    return (
      <img
        {...props}
        style={{ marginTop: 7, marginRight: 5 }}
        src={DownArrow}
      />
    );
  };

  return (
    <StyledSelect
      {...props}
      fullWidth
      variant="outlined"
      IconComponent={SelectIcon}
      input={<BootstrapInput hoverBorderColor={props?.hoverBorderColor} />}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: props.openAbove ? "bottom" : "top",
          horizontal: "left",
        },
        elevation: 100,
        marginThreshold: 16,
        getContentAnchorEl: null,
        PaperProps: {
          sx: {
            background: "#000000",
            border: "1px solid rgba(255,255,255,0.5)",
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#ffffff1a !important",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "transparent",
            },
            "& .MuiMenuItem-root.Mui-selected.Mui-focusVisible": {
              backgroundColor: "transparent",
            },
          },
        },
      }}
      sx={{
        borderRadius: 0,
        "&:hover": {
          "&& fieldset": {
            border: props.disabled ? "1px solid #C5C5C5" : "1px solid #FF5C00",
          },
        },
        "&.Mui-focused": {
          "&& fieldset": {
            border: props.disabled ? "1px solid #C5C5C5" : "1px solid #FF5C00",
          },
        },
      }}
    >
      {props.children ||
        props.options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  && {
    height: 54px;
    font-family: "Clash Grotesk";
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    color: #ffffff;
    && fieldset {
      border-color: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      color: #ffffff;
    }
    && fieldset:hover {
      border-color: #ff0000;
      background-color: #0f0;
      color: #0f0;
    }
  }
`;

export default CommonSelect;
