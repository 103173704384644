import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import { breakpoint } from "../../../utils/responsive";
import CustomButton from "../buttons/CustomButton";
import { AppContext } from "../../../AppContext";
import CloseButton from "../buttons/CloseButton";
import AlertSvg from "../../../assets/Alert20.svg";
import _, { transform } from "lodash";
import { useQuery } from "react-query";
import { getBanner, listTicket } from "../../../apis";
import Space from "../../Space";
import CloseIcon from '../../../assets/Close.svg';

const BannerTarget = {
  ALL: "ALL",
  TICKET: "TICKET",
};

const BannerAction = {
  LINK: "LINK",
  LIVE: "LIVE",
};

const LOCALSTORAGE_SHOW_BANNER_EXPIRY = "BannerPopupExpiry";
export default function BannerSnackbar() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [openCookie, setOpenCookie] = useState(false);
  const { user, isApp } = useContext(AppContext);
  

  const {
    isLoading: bannerLoading,
    isError: bannerIsError,
    data: bannerData,
    error: bannerError,
  } = useQuery(["getBanner", user], () => getBanner());
  const {
    isLoading: ticketLoading,
    isError: ticketIsError,
    data: ticketData,
    error: ticketError,
  } = useQuery(["listTicket", user], () => listTicket(), {
    enabled: !!user,
  });

  const myProductIds = useMemo(() => {
    if(ticketData == null) {
      return [];
    }
    /** @type {string[]} */
    let productIds = [];
    Array.prototype.concat.call(ticketData?.data?.ongoing, ticketData?.data?.past).forEach((each) => {
      if(each.product != null) {
        productIds.push(each.product);
      }
    });
    return productIds;
  },[ticketData]);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem(
      LOCALSTORAGE_SHOW_BANNER_EXPIRY,
      // 24hour
      Date.now() + 60 * 60 * 24
    );
    setOpenCookie(false);
  };

  const handleClickBanner = (e) => {
    const banner = bannerData?.data?.[0];
    if(banner == null){
      return;
    }
    if(banner.action != null){
      if(banner?.action === BannerAction.LINK && banner?.actionValue != null){
        window.open(banner?.actionValue, "_blank");
      } else if(banner?.action === BannerAction.LIVE && banner.actionValue != null){
        navigate(`/live/${banner.actionValue}`);
      }
    }
  }

  useEffect(() => {
    const accepted = localStorage.getItem(
      LOCALSTORAGE_SHOW_BANNER_EXPIRY,
      null
    );
    if (!accepted || accepted < Date.now()) {
      setOpenCookie(true);
    }
  }, []);

  const shouldDisplay = !isApp 
    && openCookie 
    /**
     * banner target group === 'ALL' 
     * or banner target group === 'TICKET' + targetTickets is in my ticket list
     */
    && bannerData != null && (bannerData?.data?.[0]?.target === BannerTarget.ALL 
      || (bannerData?.data?.[0]?.target === BannerTarget.TICKET && myProductIds.length > 0 && (
          _.intersection(bannerData?.data?.[0]?.targetTickets, myProductIds).length > 0
        )
      )
    )

  return shouldDisplay ? (
    <PositionContainer>
      <ButtonBanner type="button" clickable={!!bannerData?.data?.[0]?.action} onClick={handleClickBanner}>
          <MainContent>
            <img src={AlertSvg} />
            <CommonText
              mFontSize="14px"
              style={{  width: "100%", flex: 1, textAlign: "center", marginLeft: "8px" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: 
                    i18n.language === "en"
                      ? bannerData?.data?.[0]?.bannerContentEn
                      : bannerData?.data?.[0]?.bannerContentZh,
                }}
              />
            </CommonText>
            <StyledImage src={CloseIcon} onClick={handleClose}></StyledImage>
          </MainContent>
      </ButtonBanner>
    </PositionContainer>
  ) : null;
}

const PositionContainer = styled.div`
  padding: 5px 15px;
  width: 100%;
  background-color: #f44434;
  z-index: 900;
  position: relative;
`;

const ButtonBanner = styled.button`
  /** reset button style */
  background: none;
	border: none;
	font: inherit;
	outline: inherit;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;


  cursor: ${props => props.clickable ? "pointer": "auto"};

  &:focus {
    outline: revert;
  }

  // depends on height of header
  @media ${breakpoint.largeDesktop} {
    justify-content: center;
  }
`;

const MainContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${breakpoint.mobile} {
    font-size: 12px;
  }
`;

const StyledImage = styled.img`
  && {
    cursor: pointer;
    width: 28px;
    height: 28px;
    object-fit: contain;
    @media ${breakpoint.desktop} {
      margin-left: 50px;
    }
  }
`