import { Divider } from '@mui/material';
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import Vector from '../../assets/Vector.svg';
import { breakpoint } from "../../utils/responsive";

export default function LinkCard({ url, desc, text, icon }) {
    return [
        <a href={url} target="_blank">
            <ViewDetailButton>
                <ViewDetailButtonIconDiv>
                    <ViewDetailButtonIcon src={icon} alt={"Icon"}></ViewDetailButtonIcon>
                    <CommonText style={{ whiteSpace:'nowrap', marginLeft: '10px'}} lineHeight="85%" sizeType='Body' type='body'>{desc}</CommonText>
                </ViewDetailButtonIconDiv>
                <StyledDivider orientation='vertical' />
                <ViewDetailButtonText sizeType='Body' type='body'>{text}</ViewDetailButtonText>
                <ViewDetailButtonArrow src={Vector} alt="Vector" />
            </ViewDetailButton>
        </a>
    ]
}

const ViewDetailButton = styled.div`
    @media ${breakpoint.desktop} {
        margin-right: 10px;
    }
    @media ${breakpoint.largeDesktop} {
        margin-right: 25px;
    }
    display: flex;
    align-items: center;
    height: 58px;
    background: rgba(31, 31, 33, 1);
`

const ViewDetailButtonIconDiv = styled.div`
    @media ${breakpoint.mobile} {
        width: 118px;
    }
    @media ${breakpoint.desktop} {
        width: 120px;
    }
    @media ${breakpoint.largeDesktop} {
        height:48px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 21px 0px 21px 19px;
`

const ViewDetailButtonIcon = styled.img`
    max-width: 20px;
    max-height: 20px;
`

const ViewDetailButtonText = styled(CommonText)`
    text-align: left;
    line-height: 85%;
    flex:1;
`
const ViewDetailButtonArrow = styled.img`
    padding: 20px;
    transform: rotate(90deg);
    @media ${breakpoint.mobile} {
        padding: 10px;
    }
`

const StyledDivider = styled(Divider)`
    &&{
        @media ${breakpoint.mobile} {
        }
        @media ${breakpoint.desktop} {
        }
        @media ${breakpoint.largeDesktop} {
            height:48px;
        }
        margin: 0px 15px;
        border-color: rgba(255, 255, 255, 0.2);
    }
`