import CommonText from '../../components/common/typographies/CommonText'
import { Grid } from "@mui/material"
import Close from "../../assets/Close.svg"
import { useTranslation } from 'react-i18next';

function EventTnc({ data, onClose }) {
    const { t } = useTranslation('tnc');

    return (
        <Grid container>
            <Grid  container direction="row" style={{ margin: 30 }}>
                <Grid item xs>
                    <CommonText fontSize="18px" lineHeight="26px" fontWeight="700">{t('title.eventTnc')}</CommonText>
                </Grid>
                <img src={Close} alt="Close" style={{ cursor: "pointer" }} onClick={onClose}/>
            </Grid>
            <Grid item style={{ margin: `0px 30px 20px 30px` }}>
                <div dangerouslySetInnerHTML={{ __html: data }} />
            </Grid>
        </Grid>
    )
}

export default EventTnc