//PROD
export default {
  IS_DEV: false,
  API_ENDPOINT: "https://api.makealive.com",
  CHECKOUT_DOMAIN: "https://stream.makealive.com",
  GTM_ID: "GTM-WW4FHK2",
  APPLE_SIGN_IN_CLIENT_ID: "makealive.com",
  APPLE_SIGN_IN_REDIRECT_URI: "https://www.makealive.com",
  FB_ID: "271024998512552",
  GOOGLE_ID:
    "55545195307-5bnub9g1khl849vjtc2dk8nukc56ba91.apps.googleusercontent.com",
  EMAIL_REGEX:
    "^[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$",
  FIRSTNAME_REGEX: "^[a-zA-Z\\s\\.'\\-]{2,30}$",
  LASTNAME_REGEX: "^[a-zA-Z\\s\\.'\\-]{2,30}$",
  PHONE_REGEX: "^\\+852[0-9]{8}$",
  PASSWORD_REGEX: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[[A-Za-z\\d@$!%*#?&]{8,30}$",
  RECAPTCHA: "6LchAe4gAAAAAHIK4ZZt0DL4Sx8bzUHL7tpXG5cN",
  CLIENT_ID: "6c6fa27bac69aba82880672f9427daf9",
  CLIENT_SECRET: "dSgVkYp3s6v9yaB&E)H@McQeThWmZq4t",
}
