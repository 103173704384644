import React, { useEffect } from "react";
import styled from 'styled-components';
import CastingIcon from '../assets/CastingIcon.svg';

export default function CastingView({ streamUrl, drmToken }) {
    const handleCast = () => {
        window.cjs.cast(streamUrl, {}, {
            licenseUrl: `https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=` + drmToken
        });
    }
    if (window.cjs && window.cjs.connected) {
        return <Container>
            <CastingButton src={CastingIcon} onClick={handleCast} />
        </Container>
    } else return null;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: black;
`
const CastingButton = styled.img`
    width: 100px;
    cursor: pointer;
`
