import React from "react";
import Lottie from "react-lottie";
import * as animationData from '../../assets/animation/EventPosterBgCircle.json'
import styled from "styled-components";
import { ResponsiveUI, breakpoint, WindowSize } from "../../utils/responsive"

export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LottieDiv>
      <Lottie options={defaultOptions} />
    </LottieDiv>
  );
}

const LottieDiv = styled.div`
    position: absolute;
    border: 0px solid red;
    width: 70vh;
    top: 15%;
    min-width: 200px;
    min-height: 200px;

    @media ${breakpoint.mobile} {
      width: 100%;
      top: 23%;
      height: 70vh;
      max-width: 540px;
    }
    @media (max-width: 1024px) {
      max-width: 600px;
      height: 70vh;
    }

`;
