import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import CommonDialog from "../../components/common/dialogs/commonDialog";
import { useTranslation } from "react-i18next";
import IconStar from "../../assets/RatingStar.svg";
import IconStarSelected from "../../assets/RatingStarSelected.svg";
import CommonButton from "../../components/common/buttons/CommonButton";
import CommonTextField from "../../components/common/textfields/CommonTextField";
import Space from "../../components/Space";
import TicketTitle from "./Card/TicketTitle";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useRef, useState, useContext } from "react";
import SuccessImg from "../../assets/Success.svg";
import { breakpoint } from "../../utils/responsive";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getFeedback, submitFeedback } from "../../apis";
import { AppContext } from "../../AppContext";
import dateString from "../../utils/dateString";
import { useQuery } from "react-query";
import { useEffect } from "react";
import BlackButton from "../../components/common/buttons/BlackButton";

const FeedbackDialog = ({ visible, data, setSelectedId }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { isLoading, isError, data: feedbackData, error: getFeedbackError } = useQuery(
    ["getFeedback", data?.ticketInfo?.productId],
    () => getFeedback(data?.ticketInfo?.productId)
  );
  const { user } = useContext(AppContext);
  const { t, i18n } = useTranslation("myTicket");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rating, setRating] = useState({
    content: "na",
    whole_ux: "na",
    purchase_ux: "na",
    streaming: "na",
  });
  const [content, setContent] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const matcheMobile = useMediaQuery(breakpoint.mobile);
  const date = dateString(data?.streamStart, i18n.language);
  const ticketName = data?.ticketInfo?.option?.name[i18n.language];
  useEffect(() => {
    if (feedbackData && feedbackData.data && feedbackData?.data?.rating){
      setRating({
        content: feedbackData?.data?.rating?.content || "na",
        whole_ux: feedbackData?.data?.rating?.whole_ux || "na",
        purchase_ux: feedbackData?.data?.rating?.purchase_ux || "na",
        streaming: feedbackData?.data?.rating?.streaming || "na",
      });
      setContent(feedbackData?.data?.content || "")
      setSubmitted(true);
    } else {
      setRating({
        content: "na",
        whole_ux: "na",
        purchase_ux: "na",
        streaming: "na",
      });
      setContent("");
      setSubmitted(false);
    }
  }, [feedbackData]);
  const ratingList = [
    { name: "content", title: t("programmeContent") },
    { name: "whole_ux", title: t("overallExperience") },
    { name: "purchase_ux", title: t("buyingLiveStreaming") },
    { name: "streaming", title: t("watchLiveStreaming") },
    // { name: "delivery", title: t("delivery") },
  ];
  const validate = () => {
    let error = false;
    Object.entries(rating).forEach(([key, value]) => {
      if (value === "") error = true;
    });
    setError(error);

    return error;
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setSelectedId(null);
    setSuccess(false);
  };

  const handleSubmit = async () => {
    try{
      if (validate() === false) {
        const body = {
          email: user?.email,
          content,
          rating
        };
        const result = await submitFeedback(data?.ticketInfo?.productId, body);
        setSuccess(true);
      }
    }catch(e){
      setSuccess(true);
      console.log("submit feedback error", e)
    }

  };

  const RatingSelect = ({ title, name }) => {
    return (
      <StyledRating>
        <RatingTitle>
          <CommonText fontSize="16px" mFontSize="14px" color="#BCBCBC">
            {title}
          </CommonText>
        </RatingTitle>
        <StyledSelectDiv>
          <CommonText
            fontSize="16px"
            mFontSize="14px" 
            color={rating[name] == "na" ? "#F44434" : "#ffffff80"}
            style={{ cursor: submitted?"auto":"pointer" }}
            disabled={submitted}
            onClick={() => !submitted && setRating({ ...rating, [name]: "na" })}
          >
            {t("NA")}
          </CommonText>
          <RatingStar
            src={
              rating[name] == "1" || rating[name] == "2" || rating[name] == "3"
                ? IconStarSelected
                : IconStar
            }
            style={{ cursor: submitted?"auto":"pointer" }}
            disabled={submitted}
            onClick={() => !submitted && setRating({ ...rating, [name]: "1" })}
          />
          <RatingStar
            src={
              rating[name] == "2" || rating[name] == "3"
                ? IconStarSelected
                : IconStar
            }
            style={{ cursor: submitted?"auto":"pointer" }}
            disabled={submitted}
            onClick={() => !submitted && setRating({ ...rating, [name]: "2" })}
          />
          <RatingStar
            src={rating[name] == "3"
                ? IconStarSelected
                : IconStar}
            style={{ cursor: submitted?"auto":"pointer" }}
            disabled={submitted}
            onClick={() => !submitted && setRating({ ...rating, [name]: "3" })}
          />
        </StyledSelectDiv>
      </StyledRating>
    );
  };

  const _renderSuccess = () => {
    return (
      <SuccessDiv>
        <CommonText fontSize="24px" mFontSize="24px">{t("feedbackHasBeenSent")}</CommonText>
        <img width={100} src={SuccessImg} />
        <CommonText
          fontSize="16px"
          style={{ color: "#BCBCBC", whiteSpace: "pre-line", textAlign: "center" }}
        >
          {t("thankyouYourFeedback")}
        </CommonText>
        <Space size="30px" />
        <CommonButton style={{width: matcheMobile?275:355}} onClick={handleClose}>{t('done')}</CommonButton>
      </SuccessDiv>
    );
  };

  return (
    <CommonDialog
      width={matcheMobile?"100%":"500px"}
      title={t("feedback")}
      visible={visible}
      onClose={handleClose}
      fullScreen={false}
      padding="20px 30px 30px 30px"
      titleStyle={{ fontSize: "18px", mFontSize: "16px", fontWeight: "600" }}
    >
      {success === true ? (
        _renderSuccess()
      ) : (
        <div>
          <Space size={matcheMobile?"35px":"50px"} />
          <CommonText sizeType="Heading5" fontWeight="700">
            {t("thankyouWatching")}
          </CommonText>
          <StyledInfoDiv>
            <TicketTitle
              poster={data && data?.event?.poster}
              sponsorZh={data && data?.event?.sponsorZh}
              sponsorEn={data && data?.event?.sponsorEn}
              titleZh={data && data?.event?.eventNameZh}
              titleEn={data && data?.event?.eventNameEn}
              subtitle={`${i18n.language=="zh"?"門票類型：":"Ticket type:"} ${ticketName}\n${i18n.language=="zh"?"直播日期及時間：":"Streaming date and time:"} ${date}`}
            />
          </StyledInfoDiv>
          <CommonText fontSize="16px" mFontSize="14px">
            {t("fillEvaluation")}
          </CommonText>

          <Space size="30px" />
          <CommonText sizeType="Heading5" fontWeight="700">
            {t("indicateSatisfaction")}
          </CommonText>
          {ratingList.map((item) => {
            return <RatingSelect title={item.title} name={item.name} />;
          })}
          {error && (
            <CommonText fontSize="16px" color="#F44434">
              {t("pleaseSelectAllRating")}
            </CommonText>
          )}
          <Space size="15px" />

          <CommonText fontSize="18px" fontWeigh="700">
            {t("others")}
          </CommonText>
          <Space size="15px" />
          {
              submitted?
              <ContentText fontSize="18px" fontWeigh="700">
                {content}
              </ContentText>:
              <CommonTextField
                placeholder={t("writeYourCommentHere")}
                value={content}
                multiline
                rows={4}
                onChange={(e) => !submitted && setContent(e.target.value)}
              />
          }
          <Space size="30px" />
          { 
            submitted?<BlackButton
              disabled
              style={{ width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.1)"
              }}
            >
              {t("feedbackSent")}
            </BlackButton>:
            <CommonButton
              style={{ width: "100%" }}
              onClick={() => handleSubmit()}
            >
              {t("send")}
            </CommonButton>
          }
        </div>
      )}
    </CommonDialog>
  );
};

const ContentText = styled(CommonText)`
  font-size: 16px;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 16.5px 14px;
  border: 1px solid #ffffff80;
  height: 125px;
`
const StyledRating = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 10px 0px;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    margin-bottom: 15px;
  }

  @media ${breakpoint.desktop} {
    flex-direction: row;
  }
`;

const RatingTitle = styled.div`
  @media ${breakpoint.mobile} {
    margin-bottom: 10px;
  }

  @media ${breakpoint.desktop} {
    margin-bottom: 0px;
  }
`;

const StyledSelectDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInfoDiv = styled.div`
  background-color: #000;
  padding: 15px;
  margin: 15px 0px;
`;

const RatingStar = styled.img`
  margin-left: 15px;
  cursor: pointer;
`;

const SuccessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  padding-bottom: 20px;
`;

export default FeedbackDialog;
