import useMediaQuery from '@mui/material/useMediaQuery';

const size = {
    mobile: 767,
    desktop: 768,
    largeDesktop: 1441,
  }

export const breakpoint = {
    mobile: `(max-width: ${size.mobile}px)`,
    desktop: `(min-width: ${size.desktop}px)`,
    largeDesktop: `(min-width: ${size.largeDesktop}px)`,
    size1024: `(min-width: 1024px)`,
}

export const ResponsiveUI = ({desktop, mobile, largeDesktop, size1024, mobileOffset = 0}) => {
  const matches = useMediaQuery(`(min-width: ${size.desktop + mobileOffset}px)`);
  const matches1024 = useMediaQuery(`(min-width: 1024px)`);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);

  if(matchLarge && largeDesktop){
      return largeDesktop;
  } else if (matches1024 && size1024) {
      return size1024;
  } else if(matches && desktop) {
      return desktop;
  } else{
      return mobile;
  }
}

export const WindowSize = () => {
  const matches = useMediaQuery(`(min-width: ${size.desktop}px)`);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);
  const match1024 = useMediaQuery(`(min-width: 1024px)`)

  if(matchLarge){
      return 'largeDesktop';
  } else if(match1024){
      return '1024';
  } else if(matches) {
      return 'desktop';
  } else{
      return 'mobile';
  }
}