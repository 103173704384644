import { useState, useContext } from "react";
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from "react-i18next"
import FormTextField from '../../components/common/textfields/FormTextField';
import { AppContext } from "../../AppContext";
import CustomButton from "../../components/common/buttons/CustomButton";
import MyAccountPasswordShow from "../../assets/MyAccountPasswordShow.svg";
import MyAccountPasswordHide from "../../assets/MyAccountPasswordHide.svg";
import InputAdornment from '@mui/material/InputAdornment';
import { breakpoint } from "../../utils/responsive";
import { updateProfile } from "../../apis";
import { TitleIconButtonDialog } from "../../components/common/dialogs/TitleIconButtonDialog";
import ExclamationRoundOrange from '../../components/icons/ExclamationRoundOrange';
import SuccessRoundOrange from '../../components/icons/SuccessRoundOrange';
import constants from "../../constants";

export default function ManagePasswordForm() {
    const { t } = useTranslation('myAccount');
    const { user } = useContext(AppContext);
    const [password, setPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [passwordShow, setPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [failDialogOpen, setFailDialog] = useState(false);
    const [successDialogOpen, setSuccessDialog] = useState(false);
    const [error, setError] = useState({ title: "", body: "" });
    let handlePasswordChange = async () => {
        let body = {
            oldPassword: password,
            password: newPassword,
            retypePassword: confirmPassword,
        }
        if (newPassword === confirmPassword) {
            const validPassword = new RegExp(constants.PASSWORD_REGEX);
            if (!(validPassword.test(newPassword))) {
                setError({ title: t("passwordManagement.resetPasswordFailed"), body: t("passwordManagement.invalid_password") })
                setFailDialog(true);
                return;
            } else {
                let result = await updateProfile(body);
                if (result.success) {
                    setPassword(null);
                    setNewPassword(null);
                    setConfirmPassword(null);
                    setSuccessDialog(true);
                    // window.dataLayer.push({
                    //     event: "custom_event",
                    //     event_name: "user_password_update",
                    // })
                } else {
                    if (user.isGeneratedPassword)
                        setError({ title: t("passwordManagement.resetPasswordFailed"), body: t("passwordManagement.thirdPartyResetError") })
                    else
                        setError({ title: t("passwordManagement.resetPasswordFailed"), body: t("passwordManagement.invalid_password") })
                    setFailDialog(true);
                }
            }
        } else {
            setError({ title: t("passwordManagement.resetPasswordFailed"), body: t("passwordManagement.invalid_password") })
            setFailDialog(true);
        }

    }
    return (
        <StyledMainDiv>

            <StyledTitleDiv>
                <CommonText fontWeight='600' sizeType="Heading2" type='display'>{t(`passwordManagement.title`)}</CommonText>
            </StyledTitleDiv>

            <StyledDetailDiv>
                <StyledItemDiv>
                    <CommonText style={{ color: 'rgba(255, 255, 255, 0.8)', marginBottom: 8 }} fontWeight='400' sizeType="Caption" type='body'>{t(`passwordManagement.currentPassword`)}</CommonText>
                    <FormTextField
                        autoComplete="new-password"
                        type={passwordShow ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    {
                                        passwordShow ?
                                            <img style={{ cursor: 'pointer' }} onClick={() => setPasswordShow(false)} src={MyAccountPasswordShow} />
                                            :
                                            <img style={{ cursor: 'pointer' }} onClick={() => setPasswordShow(true)} src={MyAccountPasswordHide} />
                                    }

                                </InputAdornment>,
                        }}
                    />
                </StyledItemDiv>

                <StyledItemDiv>
                    <CommonText style={{ color: 'rgba(255, 255, 255, 0.8)', marginBottom: 8 }} fontWeight='400' sizeType="Caption" type='body'>{t(`passwordManagement.newPassword`)}</CommonText>
                    <FormTextField
                        type={newPasswordShow ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    {
                                        newPasswordShow ?
                                            <img style={{ cursor: 'pointer' }} onClick={() => setNewPasswordShow(false)} src={MyAccountPasswordShow} />
                                            :
                                            <img style={{ cursor: 'pointer' }} onClick={() => setNewPasswordShow(true)} src={MyAccountPasswordHide} />
                                    }

                                </InputAdornment>,
                        }}
                    />
                    <CommonText style={{ color: 'rgba(255, 255, 255, 0.8)', marginBottom: 8 }} fontWeight='400' sizeType="Caption" type='body'>{t('passwordManagement.password_hint')}</CommonText>
                </StyledItemDiv>

                <StyledItemDiv>
                    <CommonText style={{ color: 'rgba(255, 255, 255, 0.8)', marginBottom: 8 }} fontWeight='400' sizeType="Caption" type='body'>{t(`passwordManagement.confirmPassword`)}</CommonText>
                    <FormTextField
                        type={confirmPasswordShow ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    {
                                        confirmPasswordShow ?
                                            <img style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow(false)} src={MyAccountPasswordShow} />
                                            :
                                            <img style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow(true)} src={MyAccountPasswordHide} />
                                    }

                                </InputAdornment>,
                        }}
                    />
                </StyledItemDiv>

                <StyledCustomButton onClick={handlePasswordChange}>
                    <CommonText fontWeight='600' sizeType="Body" type='body'>{t(`passwordManagement.resetPassword`)}</CommonText>
                </StyledCustomButton>
                <TitleIconButtonDialog visible={failDialogOpen} setVisible={setFailDialog} title={error.title} body={error.body} ImgComp={ExclamationRoundOrange} buttonText={t("common.ok")} />
                <TitleIconButtonDialog visible={successDialogOpen} setVisible={setSuccessDialog} title={t("passwordManagement.updateSuccess")} body={""} ImgComp={SuccessRoundOrange} buttonText={t("common.ok")} />
            </StyledDetailDiv>
        </StyledMainDiv>
    )
}

const StyledMainDiv = styled.div`
    @media ${breakpoint.mobile} {
        padding: 38px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 31px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        display: flex;
        padding: 60px 90px 60px 60px;
    }
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    text-align: left;
`;

const StyledTitleDiv = styled.div`
    @media ${breakpoint.mobile} {
        margin-bottom: 23px;
    }
    @media ${breakpoint.desktop} {
        margin-bottom: 23px;
    }
    width: 100%;
    max-width: 273px;
`;

const StyledDetailDiv = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledItemDiv = styled.div`
    margin-bottom: 15px;
`;

const StyledCustomButton = styled(CustomButton)`
    &&{
        height: 44px;
        width: 100%;
        background: rgba(31, 31, 33, 1);
    }
`;