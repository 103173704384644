import CommonText from "../../components/common/typographies/CommonText";
import { Grid, Stack } from "@mui/material";
import Close from "../../assets/Close.svg";
import TicketDateItem from "../../pageComponents/eventDetail/TicketDateItem";
import styled from "styled-components";
import TicketTypeItem from "./TicketTypeItem";
import CommonButton from "../../components/common/buttons/CommonButton";
import CloseButton from "../../components/common/buttons/CloseButton";
import BlackButton from "../../components/common/buttons/BlackButton";
import WhiteButton from "../../components/common/buttons/WhiteButton";
import Add from "../../assets/Add.svg";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { StreamingStartedDialog } from "../checkout/StreamingStartedDialog";
import TicketPurchasedPlaceholder from "../../assets/TicketPurchasedPlaceholder.svg";

function SelectTicker({ onClose, purchasedProductIds, products, onClickAddToCart, onClickBuy }) {
  const { t, i18n } = useTranslation("myTicket");
  const [loading, setLoading] = useState({ type: "", status: false });
  const [isStreamingStarted, setIsStreamingStarted] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOptionValue, setSelectedOptionValue] = useState(null);

  const checkInSalesPeriod = (product) => {
    let salesStart = dayjs(product.productInfo.startSalesAt);
    let salesEnd = dayjs(product.productInfo.endSalesAt);
    let currentTime = dayjs();
    if (currentTime > salesStart && currentTime < salesEnd) {
      return true;
    }
    return false;
  }

  const checkDatePurchased = (product) => {
    console.log("product", product.product);
    console.log("purchasedProductIds", purchasedProductIds);
    if (purchasedProductIds.indexOf(product.product) !== -1) {  
      return true;
    }
    return false;
  }

  const isButtonDisabled =
    loading.status || !selectedProduct || !selectedOptionValue;

  useEffect(() => {
    if (products && products.length > 0) {
      let selectedProductIndex = 0;
      for (let i = 0; i < products.length; i++) {
        if (products[i].productInfo && checkInSalesPeriod(products[i])){
            selectedProductIndex = i;
          if (checkDatePurchased(products[i]) === false){
            break;
          }
        }
      }
      setSelectedProduct(products[selectedProductIndex]);
      setSelectedOptionValue(
        products[selectedProductIndex]?.productInfo?.options[0]
      );
    }
  }, [products]);

  const handleOnApiError = () => {
    setLoading({ type: "", status: false });
  };

  const handleOnClickAddToCart = () => {
    setLoading({ type: "cart", status: true });
    onClickAddToCart({
      productId: selectedProduct.product,
      productOptionId: selectedOptionValue.id,
      replace: false,
      onError: handleOnApiError,
    });
  };

  const handleOnClickBuy = (checkStreamStarted) => {
    if (checkStreamStarted && dayjs().isAfter(dayjs(selectedProduct.streamStart))) {
      setIsStreamingStarted(true);
      return;
    }
    setLoading({ type: "buy", status: true });
    onClickBuy({
      productId: selectedProduct.product,
      productOptionId: selectedOptionValue.id,
      onError: handleOnApiError,
    });
  };

  const productOptions = selectedProduct?.productInfo?.options;
  let selectedPorductPurchased = selectedProduct && checkDatePurchased(selectedProduct);
  console.log("kazaf", selectedPorductPurchased, selectedProduct);
  return (
    <Container container direction="column">
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: 20 }}
      >
        <CommonText fontSize="24px" lineHeight="34px">
          {t("tickets")}
        </CommonText>
        <CloseButton onClick={onClose} style={{ position: "static" }} />
      </Grid>
      <Grid item xs style={{ overflow: "auto" }}>
        <CommonText
          style={{ marginLeft: 30, marginBottom: 11 }}
          fontSize="16px"
          lineHeight="24px"
        >
          {t("ticketDate")}
        </CommonText>
        <DatesContainer item container direction="row" wrap="nowrap">
          {products.map((product, index) => {
            const isSelected = selectedProduct?._id === product._id;
            if (product.productInfo)
              return (
                <TicketDateItem
                  date={product?.streamStart}
                  ended={!checkInSalesPeriod(product)}
                  purchased={checkDatePurchased(product)}
                  index={index}
                  isSelected={isSelected}
                  t={t}
                  onClick={() => {
                    setSelectedProduct(product);
                    setSelectedOptionValue(product.productInfo?.options[0])
                    // setSelectedDate(isSelected ? null : date)
                    // setSelectedTicket(null)
                  }}
                />
              );
            else return null;
          })}
        </DatesContainer>
        {
          selectedPorductPurchased ? 
          <TicketPurchasedPlaceholderContainer justifyContent="center" alignItems="center">
            <img style={{ width: 160, height: 160 }} src={TicketPurchasedPlaceholder} />
            <CommonText fontSize="16px" lineHeight="24px" style={{ width: "100%", maxWidth: 400, textAlign: "center", marginTop: 20 }}>
                {t("onlyOneTicketCanBePurchased")}
            </CommonText>
          </TicketPurchasedPlaceholderContainer>:
          <>
            <Grid item style={{ margin: `30px 0px 11px 30px` }}>
              <CommonText fontSize="16px" lineHeight="24px">
                {t("ticketType")}
              </CommonText>
            </Grid>
            <Grid item style={{ borderTop: `1px solid rgba(225, 225, 225, 0.3)` }}>
              {(productOptions || []).map((option) => {
                const isSelected = selectedOptionValue?.id === option.id;
                const productPrice = option?.price || 0;
                const ticketPrice = (option?.discountedPrice != null ? option?.discountedPrice : option?.price) || 0;
                return (
                  <TicketTypeItem
                    key={option.id}
                    option={option}
                    lang={i18n.language}
                    isSelected={isSelected}
                    currency={selectedProduct?.productInfo?.currency || "HKD"}
                    price={ticketPrice}
                    originalPrice={productPrice}
                    onClick={() =>
                      setSelectedOptionValue(isSelected ? null : option)
                    }
                  />
                );
              })}
            </Grid>
          </>
        }
      </Grid>
      { 
        !selectedPorductPurchased &&
        <Grid item container direction="row" style={{ padding: `30px 15px` }}>
          <Grid item xs style={{ marginRight: 15 }}>
            <BlackButton
              fullWidth
              onClick={handleOnClickAddToCart}
              disabled={isButtonDisabled}
            >
              {loading.status && loading.type === "cart" ? (
                <CircularProgress size={20} style={{ marginLeft: 6 }} />
              ) : (
                <>
                  {t("AddToCart")}
                  <PlusIcon src={Add} alt="Add" />
                </>
              )}
            </BlackButton>
          </Grid>
          <Grid item xs>
            <WhiteButton
              fullWidth
              disabled={isButtonDisabled}
              onClick={() => handleOnClickBuy(true)}
            >
              {loading.status && loading.type === "buy" ? (
                <CircularProgress size={20} style={{ marginLeft: 6 }} />
              ) : (
                t("buyNow")
              )}
            </WhiteButton>
          </Grid>
        </Grid>
      }
      <StreamingStartedDialog
        visible={isStreamingStarted}
        setVisible={setIsStreamingStarted}
        onClick={()=> handleOnClickBuy(false)}
      />
    </Container>
  );
}

export default SelectTicker;

const Container = styled(Grid)`
  && {
    height: 100%;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
  }
`;

const DatesContainer = styled(Grid)`
  overflow: auto;
  white-space: nowrap;
  border-top: 1px solid rgba(233, 233, 233, 0.3);
  border-bottom: 1px solid rgba(233, 233, 233, 0.3);
`;

const PlusIcon = styled.img`
  margin-left: 10px;
  width: 20px;
  height: 20px;
`;

const TicketPurchasedPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 130px);
`