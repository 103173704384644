import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CommonButton from "../../components/common/buttons/CommonButton";
import TicketTitle from "./Card/TicketTitle";
import { breakpoint } from "../../utils/responsive";
import { Link, useLocation } from "react-router-dom";
import dateString from "../../utils/dateString";

const TicketCard = ({ data, session, subtitle, button }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { t, i18n } = useTranslation("myTicket");

  const before24Hour = dayjs(data?.streamStart).subtract(1, "day");
  const isLiveEvent = session === "upcoming" && dayjs(new Date()).isAfter(before24Hour) && dayjs(new Date()).isBefore(data?.streamEnd);

  return (
    <CardBase>
      <TicketTitle
        poster={data && data?.event?.poster}
        sponsorZh={data && data?.event?.sponsorZh}
        sponsorEn={data && data?.event?.sponsorEn}
        titleZh={data && data?.event?.eventNameZh}
        titleEn={data && data?.event?.eventNameEn}
        livetitle={isLiveEvent && t("live")}
        subtitle={subtitle}
      />
      <Divider style={{ margin: "30px 0px" }} />

      <CommonText fontSize="14px" textAlign="left">
        {data?.ticketInfo?.option?.name[i18n.language]}
      </CommonText>
      <LiveDate>
        <CommonText fontSize="14px" textAlign="left">
          { data?.streamStart && dateString(data?.streamStart, i18n.language)}
        </CommonText>
        <CommonText fontSize="14px">{`#${data?.ticketInfo?.id}`}</CommonText>
      </LiveDate>
      {isLiveEvent && (
        <Link onClick={()=> {
          window.dataLayer.push({
              event: "custom_event",
              event_name: "button_click",
              button_text: "Web enter live"
          })
        }} to={`/live/${data?._id}`}>
          <CommonButton style={{ width: "100%" }}> {t("watchNow")}</CommonButton>
        </Link>
      )}
      {button}
    </CardBase>
  );
};

const CardBase = styled.div`
  background-color: #000;
  height: auto;
  position:relative;
  &:before {
    content:'';
    position:absolute;
    bottom:50%;
    left: -15px;
    width:30px;
    height:30px;
    border-radius:100%;
    background-color: #1F1F21;
  }
  &:after {
    content:'';
    position:absolute;
    bottom:50%;
    right: -15px;
    width:30px;
    height:30px;
    border-radius:100%;
    background-color: #1F1F21;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
  }

  @media ${breakpoint.desktop} {
    width: 40%;
    min-width: 325px;
    margin: 15px;
    padding: 30px;
  }
`;

const LiveDate = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export default TicketCard;
