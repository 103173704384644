import RedticketIcon from "../../assets/RedticketIcon.svg";
import styled from "styled-components"

export default function GlowRedTicket(props) {
  return (
    <Container>
      <Ticket src={RedticketIcon} {...props} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 12px rgba(244, 68, 52, 0.5));
  margin-right: 10px;
`

const Ticket = styled.img`
  filter: drop-shadow(0px 0px 12px rgba(244, 68, 52, 0.5));
`