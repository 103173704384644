import styled from 'styled-components';
import { CommonDialog } from '../../components/common/dialogs/commonDialog';
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import CustomButton from '../../components/common/buttons/CustomButton';
import { breakpoint } from '../../utils/responsive';
import CommonText from '../../components/common/typographies/CommonText';
import LeftArrow from '../../assets/LeftArrow.svg';
import ExclamationRoundOrange from '../../components/icons/ExclamationRoundOrange';
import SuccessRoundOrange from '../../components/icons/SuccessRoundOrange';
import { TitleIconButtonDialog } from "../../components/common/dialogs/TitleIconButtonDialog";
import { Trans, useTranslation } from "react-i18next";
import { deleteAccount } from "../../apis";
import { useNavigate } from "react-router-dom";
import { useLogout } from '../../components/logout';

export function AccountDeleteConfirmation(props) {
    const { user, setUser } = useContext(AppContext);{ useLogout }
    const { setDeleteOpen, ...other } = props;
    const [successDialogOpen, setSuccessDialog] = useState(false);
    const [failDialogOpen, setFailDialog] = useState(false);
    const navigate = useNavigate();
    const logout = useLogout();
    const { t } = useTranslation('myAccount');

    const onDeleteHander = async () => {
        const result = await deleteAccount();
        if(result.success){
            setSuccessDialog(true);
        }
    }

    return (
        <StyledMainDiv
            {...other}
        >
            <StyledHeader>
                <img style={{ cursor: 'pointer' }} onClick={() => setDeleteOpen(false)} src={LeftArrow} alt="icon"></img>
                <div style={{ flex:1, display: 'flex', justifyContent:'center', marginRight:'28px'}}>
                    <ExclamationRoundOrange/>
                </div>
            </StyledHeader>
            <StyledDiv>
                <CommonText fontWeight="700" type="title" sizeType="Heading2">{t("deleteAccount.confirmToDelete")}</CommonText>

                <StyledInfoDiv>
                    <CommonText fontWeight="600" type="display" sizeType="Heading4">{user.username}</CommonText>
                    <CommonText color='#BCBCBC' fontWeight="400" type="body" sizeType="Body">{user.email}</CommonText>
                </StyledInfoDiv>

                <StyledMessageDiv>
                    <CommonText fontWeight="500" type="body" sizeType="Body">
                    {t("deleteAccount.suggestion")}
                    </CommonText>
                </StyledMessageDiv>

                <StyledButton onClick={() => onDeleteHander()}>
                    <CommonText type="title" sizeType="Heading5">{t("deleteAccount.confirmToDeleteButton")}</CommonText>
                </StyledButton>

                <TitleIconButtonDialog visible={successDialogOpen} onClick={logout} setVisible={setSuccessDialog} title={t("deleteAccount.accountDeleted")} ImgComp={SuccessRoundOrange} buttonText={t("common.ok")}/>
                {/* Fail to delete dialog*/}
                {/* <TitleIconButtonDialog visible={failDialogOpen} setVisible={setFailDialog} title={t("deleteAccount.accountDeleted")} ImgComp={ExclamationRoundOrange} buttonText={t("deleteAccount.ok")}/> */}
            </StyledDiv>
        </StyledMainDiv>
    );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: start;
  @media ${breakpoint.mobile} {
    margin: 20px 15px;
  }
  @media ${breakpoint.desktop} {
    margin: 30px 30px;
  }
  @media ${breakpoint.largeDesktop} {
    margin: 60px 60px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  @media ${breakpoint.mobile} {
      width: 345px;
      margin: 0px auto 80px auto;
  }
  @media ${breakpoint.desktop} {
      width: 540px;
      margin: 0px auto 100px auto;
  }
  @media ${breakpoint.largeDesktop} {
      width: 540px;
      margin: 0px auto 100px auto;
  }
`;

const StyledInfoDiv = styled.div`
    padding: 10px 20px;
    width: 100%;
    background: #1F1F21;
    @media ${breakpoint.mobile} {
        margin: 10px 0px;
    }
    @media ${breakpoint.desktop} {
        margin: 20px 0px;
    }
    @media ${breakpoint.largeDesktop} {
        margin: 20px 0px;
    }
`;

const StyledMessageDiv = styled.div`
    width: 100%;
    @media ${breakpoint.mobile} {
        margin: 0px 0px 10px 0px;
    }
    @media ${breakpoint.desktop} {
        margin: 0px 0px 20px 0px;
    }
    @media ${breakpoint.largeDesktop} {
        margin: 0px 0px 20px 0px;
    }
`;

const StyledButton = styled(CustomButton)`
  @media ${breakpoint.mobile} {
    width: 100%;
    height: 48px;
  }
  @media ${breakpoint.desktop} {
    width: 100%;
    height: 48px;
  }
  @media ${breakpoint.largeDesktop} {
    width: 100%;
    height: 45px;
  }
`;