import React from "react";
import { useDropzone } from "react-dropzone";
import CONSTANTS from "../../../constants";
import styled from "styled-components";
import CommonText from "../typographies/CommonText";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

function sendFile(presignedurl, type, file, progressCallback) {
  return new Promise((resolve, reject) => {
    // from http://blog.rudikovac.com/react-native-upload-any-file-to-s3-with-a-presigned-url/
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener(
      "progress",
      (e) => {
        // handle notifications about upload progress: e.loaded / e.total
        console.log(e);
        // onProgress(e);
        if (typeof progressCallback == "function") {
          progressCallback(e);
        }
      },
      false
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        resolve({ status: xhr.status, response: xhr.response });
      }
    };
    xhr.open("PUT", presignedurl);
    xhr.setRequestHeader("X-Amz-ACL", "public-read");
    xhr.setRequestHeader("Content-type", type); // it has to be a space char
    xhr.send(file);
  });
}

export const handleUpload = async (file) => {
  // setChosenFile(e.target.files[0].name);
  const response = await fetch(
    `${CONSTANTS.API_ENDPOINT}/s3/presignedUrl?&type=application/octet-stream`
  );
  const result = await response.json();
  if (result.url) {
    // const file = e.target.files[0];
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = async (e) => {
        try {
          let response = null;
          const sendResult = await sendFile(
            result.url,
            file.type,
            e.target.result,
            (progress) => {}
          );
          // Saving response for later use in lower scopes
          if (sendResult.status < 200 || sendResult.status >= 300) {
            // Throw error
            response = sendResult;
            throw sendResult;
          } else {
            // onUploadSuccess({ target: { name, value: data.fileKey } });
            // return result.fullPath;
            resolve(result.fullPath.replace(CONSTANTS.S3_DOMAIN, CONSTANTS.CLOUDFRONT));
          }
        } catch (error) {
          if (response) {
            console.log(`Request failed with status ${response.status}.`);
          } else {
            console.log(`Request failed.`);
          }
          reject(error);
        }
      };
    });
  }
};

const UploadButton = ({ setChosenFileUrl, fileUrl, setChosenFile, file }) => {
  const { t, i18n } = useTranslation("aboutUs");
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
  } = useDropzone({
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "video/*": [".mp4", ".mov"],
    },
    uploadMultiple: false,
    maxFiles: 1,
    maxSize: 16000000, //16MB
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileSrc = URL.createObjectURL(file);
      setChosenFile(file);
      setChosenFileUrl(fileSrc);
    },
  });

  return (
    <div>
      <Container {...getRootProps({ isFocused, isDragActive })}>
        <input {...getInputProps()} />
        {file &&
        (file.type === "video/quicktime" || file.type === "video/mp4") ? (
          <video
            controls
            src={fileUrl}
            style={{ height: 180, objectFit: "cover" }}
          ></video>
        ) : file &&
          (file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png") ? (
          <img src={fileUrl} style={{ height: 180, objectFit: "cover" }} />
        ) : (
          <Stack spacing={1}>
            <UploadText type="common">{t("uploadButton")}</UploadText>
            <CommonText style={{ opacity: 0.3 }} type="common">
              {t("uploadButtonDesc")}
            </CommonText>
          </Stack>
        )}
      </Container>
      {fileRejections && fileRejections.length > 0 && (
        <CommonText color="#FF5C00" fontSize="12px" textAlign="left">
          {t("uploadError")}
        </CommonText>
      )}
    </div>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 180px;
  border-width: 1px;
  border-color: ${(props) => getColor(props)};
  border-style: solid;
  outline: none;
  cursor: copy;
  transition: border 0.24s ease-in-out;
`;

const getColor = (props) => {
  if (props.isFocused || props.isDragActive) {
    return "#fff";
  }
  return "rgba(255, 255, 255, .5)";
};

const UploadText = styled(CommonText)`
  padding: 5px 13px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
`;

export default UploadButton;
