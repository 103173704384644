import styled from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import dayjs from "dayjs";
import { breakpoint } from "../../utils/responsive";
import { useEffect, useState } from "react";
import TicketList from "./TicketList";

const CategoryList = ({ title, list, t, offset = 0 }) => {
  if (list && list.length > 0) {
    return (
      <div>
        <StyledItem>
          {list &&
            list.map((item, index) => {
              return (
                <TicketList
                  data={item}
                  category={title}
                  index={index + offset}
                  session="upcoming"
                />
              );
            })}
        </StyledItem>
      </div>
    );
  } else {
    return null;
  }
};

const OngoingTicketList = ({ data }) => {
  const { t } = useTranslation("myTicket");
  const [tickets, setTickets] = useState({});
  useEffect(() => {
    if (data) {
      let todayList = [];
      let tmrList = [];
      let monthList = [];
      let otherList = [];
      data.forEach((item) => {
        let streamStart = dayjs(item.streamStart);
        let streamEnd = dayjs(item.streamEnd);
        let currentTime = dayjs();
        let tmrDate = dayjs(new Date()).add(1, "day");
        if (currentTime > streamStart && currentTime < streamEnd)
          todayList = [...todayList, item];
        else if (streamStart > currentTime) {
          if (streamStart.isSame(tmrDate, "date")) tmrList = [...tmrList, item];
          else if (streamStart.isSame(currentTime, "month"))
            monthList = [...monthList, item];
          else otherList = [...otherList, item];
        }
      });
      setTickets({
        today: todayList,
        tmr: tmrList,
        month: monthList,
        other: otherList,
      });
    }
  }, [data]);
  
  const offsetForTomorrow = tickets?.today?.length || 0
  const offsetForThisMonth = offsetForTomorrow + (tickets?.tmr?.length || 0);
  const offsetForOthers = offsetForThisMonth + (tickets?.month?.length || 0);

  return (
    <StyledMainDiv>
      <CategoryList title={t("today")} list={tickets.today} t={t} offset={0}/>
      <CategoryList title={t("tomorrow")} list={tickets.tmr} t={t} offset={offsetForTomorrow} />
      <CategoryList title={t("thisMonth")} list={tickets.month} t={t} offset={offsetForThisMonth}/>
      <CategoryList title={t("upcoming")} list={tickets.other} t={t} offset={offsetForOthers}/>
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  margin: 0px 16px;
  min-height: 550px;

  @media ${breakpoint.desktop} {
    margin: 0px 60px;
  }

  @media ${breakpoint.largeDesktop} {
    margin: 0px 120px;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  gap: 64px;
`;

export default OngoingTicketList;
