import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import CommonText from '../../components/common/typographies/CommonText';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Grid, Box, Dialog } from '@mui/material'
import PlanIconsSingle from "../../assets/PlanIconsSingle.svg";
import PlanIcons from "../../assets/PlanIcons.svg";
import BlackDot from "../../assets/BlackDot.svg";
import { ResponsiveUI, breakpoint } from "../../utils/responsive"

function TicketItem({ ticket, index, onClick, lang, xs, price }) {
    return (
        <Container item xs={xs} container direction='column' onClick={onClick} index={index}>
            <Grid item container direction='row' alignItems="center" style={{ padding: 15 }}>
                <Grid item style={{ marginRight: 10 }}>
                    {
                        index ? <img src={PlanIcons} alt="PlanIcons" /> : <img src={PlanIconsSingle} alt="PlanIconsSingle" />
                    }
                </Grid>
                <Grid item xs>
                    <CommonText textAlign="left" fontSize="17px" lineHeight="20px" >{ticket?.name || ""}</CommonText>
                </Grid>
                <Grid item><CommonText fontSize="16px" lineHeight="24px">${price}</CommonText></Grid>
            </Grid>
            <Grid item style={{ padding: 15 }}>
                {
                    (ticket?.descriptionsZh || []).map((item) => {
                        return (
                            <Box display="flex" flexDirection="row" alignItems="flex-start">
                                <img src={BlackDot} alt='BlackDot' style={{ marginRight: 15, marginTop: 8 }} />
                                <CommonText textAlign="left" fontSize="14px" lineHeight="20px">{item}</CommonText>
                            </Box>
                        )
                    })
                }
            </Grid>
        </Container>
    )
}

export default TicketItem

const Container = styled(Grid)`
    && {
        border: 1px solid rgba(255,255,255, 0.5);
        
        @media ${breakpoint.mobile} {
            margin-top: ${props => props.index ? 15 : 0}px;
        }
    
        @media ${breakpoint.desktop} {
            margin-left: ${props => props.index % 2 !== 0 ? 15 : 0}px;
            margin-bottom: 20px;
        }
    }
`