import { Grid, Divider } from "@mui/material";
import styled from "styled-components";
import CommonText from "../common/typographies/CommonText";
import CommonButton from "../common/buttons/CommonButton";
import BlackButton from "../common/buttons/BlackButton";
import Calendar from "../../assets/Calendar.svg";
import { ResponsiveUI, breakpoint, WindowSize } from "../../utils/responsive";
import dayjs from "dayjs";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
import { useNavigate } from "react-router-dom";
import AddToCalendarButton from "../addToCalendarButton";
import { useTranslation } from "react-i18next";
import orderStatus from "../../utils/orderStatus";
import utc from "dayjs/plugin/utc";
import dateString from "../../utils/dateString";
import { useMemo } from "react";

const ResponsiveDivider = ({ isMobile }) => {
  if (!isMobile) {
    return <Divider orientation="vertical" style={{ height: "initial" }} />;
  } else {
    return <Divider />;
  }
};

export const PaymentInfo = ({ data, orderNumber, isPaymentSuccess }) => {
  const { t, i18n } = useTranslation("paymentComplete");
  const transactionDate = data?.order?.createdAt
    ? dateString(data.order.createdAt, i18n.language)
    : "";

  return (
    <PaymentInfoContainer container>
      <Grid container direction="column">
        <CommonText
          align="left"
          fontSize="24px"
          lineHeight="34px"
          fontWeight="600"
        >
          {t("order")} {orderNumber}
        </CommonText>
        <Grid item container direction="row" alignItems="center">
          <CommonText
            align="left"
            fontSize={WindowSize() === "largeDesktop" ?"16px": "14px"}
            lineHeight="28px"
            fontWeight="700"
            style={{ color: "#F44434" }}
          >
            {t("total")} {data?.order?.currency.replace("D", "$")}
            {data?.order?.amount || 0}
          </CommonText>
          <CommonText
            align="left"
            fontSize={WindowSize() === "largeDesktop" ?"16px": "14px"}
            lineHeight="20px"
            fontWeight="400"
            style={{ color: "#F44434", margin: `0px 5px 0px 40px` }}
          >
            {t("status")}
          </CommonText>
          <CommonText
            fontSize={WindowSize() === "largeDesktop" ?"16px": "14px"}
            lFontSize="16px"
            lineHeight="28px"
            fontWeight="400"
            style={{ color: "#F44434" }}
          >
            {orderStatus(data?.order?.status) || ""}
          </CommonText>
        </Grid>
      </Grid>

      <PaymentInfoInnerContainer container direction="row">
        <PaymentInfoItemContainer item>
          <CommonText
            align="left"
            fontSize="14px"
            lineHeight="20px"
            fontWeight="400"
            style={{ color: "#BCBCBC" }}
          >
            {t("orderTime")}
          </CommonText>
          <CommonText
            align="left"
            fontSize="14px"
            lFontSize="16px"
            lineHeight="28px"
            fontWeight="400"
          >
            {transactionDate}
          </CommonText>
        </PaymentInfoItemContainer>
        {isPaymentSuccess ? (
          <PaymentInfoItemContainer item>
            <CommonText
              align="left"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="400"
              style={{ color: "#BCBCBC" }}
            >
              {t("paymentMethod")}
            </CommonText>
            <CommonText
              align="left"
              fontSize="14px"
              lFontSize="16px"
              lineHeight="28px"
              fontWeight="400"
            >
              {data?.paymentInfo?.type == "FREE"
                ? t("na")
                : data?.paymentInfo?.type == "RECON"
                ? t("payment." + data?.paymentInfo?.paymentMethod)
                : data?.paymentInfo?.paymentMethod || ""}
            </CommonText>
          </PaymentInfoItemContainer>
        ) : (
          <PaymentInfoItemContainer item>
            <CommonText
              align="left"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="400"
              style={{ color: "#BCBCBC" }}
            >
              {t("failCode")}
            </CommonText>
            <CommonText
              align="left"
              fontSize="14px"
              lFontSize="16px"
              lineHeight="28px"
              fontWeight="400"
            >
              {data?.paymentInfo?.failCode || ""}
            </CommonText>
          </PaymentInfoItemContainer>
        )}
        <PaymentInfoItemContainer item>
          <CommonText
            align="left"
            fontSize="14px"
            lineHeight="20px"
            fontWeight="400"
            style={{ color: "#BCBCBC" }}
          >
            {t("paymentNumber")}
          </CommonText>
          <CommonText
            align="left"
            fontSize={WindowSize() === "largeDesktop" ? "16px": "14px"}
            lineHeight="28px"
            fontWeight="400"
          >
            {data?.paymentInfo?.type == "FREE"
              ? t("na")
              : data?.paymentInfo?.paymentTransactionId || ""}
          </CommonText>
        </PaymentInfoItemContainer>
      </PaymentInfoInnerContainer>
    </PaymentInfoContainer>
  );
};

export const getStreamingStatus = (start, end) => {
  if (!start || !end) return null;
  const before24Hour = dayjs(start).subtract(1, "day");
  if (dayjs().isBefore(before24Hour)) {
    return "before";
  } else if (dayjs().isAfter(before24Hour) && dayjs().isBefore(end)) {
    return "streaming";
  } else if (dayjs().isAfter(end)) {
    return "end";
  } else {
    return "";
  }
};

export const TicketBox = ({ data, currency, isPaymentSuccess }) => {
  const { t, i18n } = useTranslation("paymentComplete");
  let navigate = useNavigate();
  dayjs.extend(utc);
  const isMobile = WindowSize() === "mobile";
  const productOption = data?.option || {};
  const price = (data?.option?.discountedPrice != null ? data?.option?.discountedPrice : data?.option?.price) || 0;
  const streamStatus = getStreamingStatus(
    data?.streaming?.streamStart,
    data?.streaming?.streamEnd
  );
  const renderActionButton = () => {
    switch (streamStatus) {
      case "before":
      case "end": {
        return (
          <BoxRightView item xs={4} justifyContent="center" container direction="column">
            <ResponsiveBlackButton
              style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
              onClick={() => {
                if (data?.streaming?.event?._id)
                  navigate(`/event/${data.streaming.event._id}`);
              }}
            >
              {t("eventPage")}
            </ResponsiveBlackButton>
          </BoxRightView>
        );
      }

      case "streaming": {
        return (
          <BoxRightView item xs={4} justifyContent="center" container direction="column">
            <ResponsiveBlackButton
              style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
              onClick={() => {
                if (data?.streaming?.event?._id)
                  navigate(`/event/${data.streaming.event._id}`);
              }}
            >
              {t("eventPage")}
            </ResponsiveBlackButton>
            <div style={{ height: 15, width: 1 }} />
            <ResponsiveCommonButton
              onClick={() => {
                if (data?.streaming?._id){
                  window.dataLayer.push({
                      event: "custom_event",
                      event_name: "button_click",
                      button_text: "Web enter live"
                  })
                  navigate(`/live/${data.streaming._id}`);
                }
              }}
            >
              {t("watchNow")}
            </ResponsiveCommonButton>
          </BoxRightView>
        );
      }

      default: {
        return null;
      }
    }
  };
  let event = {
    name: i18n.language == "en"?`Live stream - ${data.streaming?.event?.sponsorEn || ""}${data.streaming?.event?.eventNameEn}`:`網上直播 - ${data.streaming?.event?.sponsorZh || ""}${data.streaming?.event?.eventNameZh}`,
    details: `https://www.makealive.com`,
    startsAt: data.streaming?.streamStart,
    endsAt: data.streaming?.streamEnd,
  };

  const title = useMemo(() => {
    if (i18n.language == "en") {
      if (data?.streaming?.event?.sponsorEn)
        return (
          data?.streaming?.event?.sponsorEn +
          " " +
          data?.streaming?.event?.eventNameEn
        );
      else return data?.streaming?.event?.eventNameEn;
    } else {
      if (data?.streaming?.event?.sponsorZh)
        return (
          data?.streaming?.event?.sponsorZh +
          " " +
          data?.streaming?.event?.eventNameZh
        );
      else return data?.streaming?.event?.eventNameZh;
    }
  }, [data?.streaming?.event, i18n.language]);

  return (
    <TicketBoxContainer container direction="column">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs>
          <CommonText
            align="left"
            fontSize="18px"
            lineHeight="26px"
            fontWeight="700"
          >
            {t("onlineLiveTicket")}
          </CommonText>
        </Grid>
        {isPaymentSuccess && (
          <div style={{ cursor: "pointer", display: "flex" }}>
            <Grid item>
              <AddToCalendarButton
                event={event}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {t("addToCalendar")}
                <img src={Calendar} alt="Calendar" />
              </AddToCalendarButton>
            </Grid>
          </div>
        )}
      </Grid>
      <Grid
        item
        container
        direction="column"
        style={{ backgroundColor: "#1F1F21", minHeight: 300 }}
      >
        <TicketBoxTitleHeader item container direction="row">
          {!isMobile && data?.streaming?.event?.poster && (
            <img
              src={data.streaming.event.poster}
              style={{ height: 135, width: 135, objectFit: "contain" }}
            />
          )}
          <Grid item xs style={{ height: "auto" }}>
            <CommonText
              align="left"
              fontSize="18px"
              lineHeight="22px"
              fontWeight="700"
            >
              {title}
            </CommonText>
          </Grid>
          {/* <CommonText fontSize="16px" lineHeight="28px" fontWeight="400" style={{ color: "#F44434" }}>{t('confirmed')}</CommonText> */}
        </TicketBoxTitleHeader>
        <Divider />
        <Grid container direction={isMobile ? "column" : "row"}>
          <BoxLeftView item xs>
            <Grid container direction="row" style={{ marginBottom: 15 }}>
              <Grid item xs>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC", "white-space": "nowrap" }}
                >
                  {t("ticketType")}
                </CommonText>
              </Grid>
              <Grid item>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC", "white-space": "nowrap" }}
                >
                  {productOption.name[i18n.language]}
                </CommonText>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginBottom: 15 }}>
              <Grid item xs>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC", "white-space": "nowrap" }}
                >
                  {t("showDateAndTime")}
                </CommonText>
              </Grid>
              <Grid item>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC" }}
                >
                  {" "}
                  {dateString(data?.streaming?.streamStart, i18n.language)}{" "}
                </CommonText>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginBottom: 15 }}>
              <Grid item xs>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC" }}
                >
                  {t("quantity")}
                </CommonText>
              </Grid>
              <Grid item>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC" }}
                >
                  {data.quantity}
                </CommonText>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginBottom: 15 }}>
              <Grid item xs>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC" }}
                >
                  {t("price")}
                </CommonText>
              </Grid>
              <Grid item>
                <CommonText
                  align="left"
                  fontSize="14px"
                  lineHeight="20px"
                  style={{ color: "#BCBCBC" }}
                >
                  {currency && currency.replace("D", "$")}
                  {price}
                </CommonText>
              </Grid>
            </Grid>
          </BoxLeftView>
          <ResponsiveDivider isMobile={isMobile} />
          {isPaymentSuccess ? (
            renderActionButton()
          ) : (
            <BoxRightView item xs={4} justifyContent="center" container direction="column">
              <ResponsiveBlackButton
                style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                onClick={() => {
                  if (data?.streaming?.event?._id)
                    navigate(`/event/${data.streaming.event._id}`);
                }}
              >
                {t("eventPage")}
              </ResponsiveBlackButton>
            </BoxRightView>
          )}
        </Grid>
      </Grid>
    </TicketBoxContainer>
  );
};

const PaymentInfoContainer = styled(Grid)`
  && {
    margin: 30px auto 0px auto;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    max-width: 1024px;
    padding-top: 30px;
    width: 100%;
    @media ${breakpoint.mobile} {
      padding-top: 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media ${breakpoint.desktop} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media ${breakpoint.largeDesktop} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const PaymentInfoInnerContainer = styled(Grid)`
  && {
    padding: 15px 0px;
    @media ${breakpoint.mobile} {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media ${breakpoint.desktop} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media ${breakpoint.largeDesktop} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const PaymentInfoItemContainer = styled(Grid)`
  @media ${breakpoint.mobile} {
    && {
      margin-bottom: 10px;
    }
  }
`;

const TicketBoxContainer = styled(Grid)`
  && {
    margin: 0px auto 58px auto;
    max-width: 1024px;
    width: 100%;
  }
`;

const TicketBoxTitleHeader = styled(Grid)`
  && {
    @media ${breakpoint.mobile} {
      padding: 15px;
    }
    @media ${breakpoint.desktop} {
      padding: 30px;
    }
    @media ${breakpoint.largeDesktop} {
      padding: 30px;
    }
  }
`;

const BoxLeftView = styled(Grid)`
  padding: 30px;
  @media ${breakpoint.mobile} {
    && {
      padding: 15px;
    }
  }
`;

const BoxRightView = styled(Grid)`
  padding: 30px;
`;

const ResponsiveCommonButton = styled(CommonButton)`
  @media ${breakpoint.mobile} {
    && {
      font-size: 14px;
    }
  }
`;

const ResponsiveBlackButton = styled(BlackButton)`
  @media ${breakpoint.mobile} {
    && {
      font-size: 14px;
    }
  }
`;
