import styled from "styled-components";
import NoTicketIcon from "../../assets/NoTicketIcon.png";
import TicketPlayerIcon from "../../assets/TicketPlayerIcon.svg";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";

const NoTicket = () => {
  const { t } = useTranslation("myTicket");
  return (
    <StyledMainDiv>
      <img src={NoTicketIcon} />
      <CommonText
        sizeType="Heading3"
        fontWeight="600"
        style={{ marginTop: "30px" }}
      >
        {t("noRecords")}
      </CommonText>

      <StyledSubtitle>
        <CommonText fontSize="14px">{t("purchaseTicketToView")}</CommonText>
      </StyledSubtitle>
    </StyledMainDiv>
  );
};

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 80px 0px 60px 0px;
`;

const StyledSubtitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export default NoTicket;
