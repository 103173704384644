import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledToggleButton = styled(ToggleButton)`
    && {
        border-radius: 0;
        width: 50%;
        height: 53px;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        border: 0px solid rgba(255, 255, 255, 0.5);
        &.Mui-selected, &.Mui-selected:hover {
            background-color: rgba(255, 255, 255, 0.1);
            color: white;
            font-weight: 700;
        }
    }
`
const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 2px;
    width: 100%;
`
export default function EmailPhoneToggleButton({ value, onChange }) {
    const { t } = useTranslation('signin');
    return (
        <StyledToggleButtonGroup value={value} onChange={onChange} exclusive>
            <StyledToggleButton value="email">
                {t('email')}
            </StyledToggleButton>
            <StyledToggleButton value="phone">
                {t('mobile_phone')}
            </StyledToggleButton>
        </StyledToggleButtonGroup>
    )
}
