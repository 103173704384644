// import GoogleLogin from 'react-google-login';
import GoogleIcon from "../../assets/GoogleSignin.svg";
import { socialLogin } from "../../apis";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../constants";
// import TagManager from 'react-gtm-module';
import CommonButton from "../common/buttons/CommonButton";
import { useGoogleLogin } from "@react-oauth/google";
export default function GoogleSignin({
  login = false,
  handlePageChange,
  handleClose,
}) {
  const { t } = useTranslation("signin");
  const { setUser, requestPhoneVerification } = useContext(AppContext);
  const onLoginClick = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      // call api google token
      if (access_token) {
        const result = await socialLogin("google", access_token);
        if (result.success) {
          localStorage.setItem("accessToken", result.data?.accessToken);
          localStorage.setItem("refreshToken", result.data?.refreshToken);
          setUser(result.data?.user);
          if (result.data?.isNewUser) {
            requestPhoneVerification();
            window.dataLayer.push({
              event: "custom_event",
              event_name: "signup",
              method: "google",
            });
          } else {
            window.dataLayer.push({
              event: "custom_event",
              event_name: "login",
              method: "google",
            });
          }
          handleClose();
        }
      }
    },
  });
  return (
    <CommonButton
      size="large"
      variant="contained"
      type="secondary"
      style={{
        width: "100%",
        fontWeight: 700,
        backgroundColor: "#ffffff",
        color: "rgba(0, 0, 0, 0.54)",
      }}
      startIcon={<img src={GoogleIcon} />}
      onClick={onLoginClick}
    >
      {login ? t("google_signin") : t("register.google_register")}
    </CommonButton>
    //     {/* <GoogleLogin
    //     clientId={CONSTANTS.GOOGLE_ID}
    //     onSuccess={responseGoogle}
    //     onFailure={responseGoogle}
    //     cookiePolicy={'single_host_origin'}
    //     scope="profile"
    //     render={renderProps => (
    //         <CommonButton
    //             size="large"
    //             variant="contained"
    //             style={{ width: '100%', fontWeight: 700, backgroundColor: "#ffffff", color: "rgba(0, 0, 0, 0.54)" }}
    //             startIcon={<img src={GoogleIcon} />}
    //             onClick={renderProps.onClick}>
    //             {login?t('google_signin'):t('register.google_register')}
    //         </CommonButton>
    //     )}
    // /> */}
  );
}
