import { Grid, Divider, InputAdornment, IconButton, Box } from "@mui/material"
import styled from 'styled-components';
import CommonText from "../../components/common/typographies/CommonText"
import CommonTextField from "../../components/common/textfields/CommonTextField"
import { useState } from "react"
import Truck from '../../assets/Truck.svg'
import Goods from '../../assets/Goods.svg'
import TextButton from '../../components/common/buttons/TextButton';
import Add from "../../assets/Add.svg"
import CommonSelect from "../../components/common/selects/CommonSelect"

const DeliveryMethods = [{ id: "home-delivery", price: 60 }, { id: "pickup", price: 0 }];

const DeliveryMethodItem = ({ label, price, isSelected, index, onClick }) => {
    return (
        <Grid container direction="column" style={{ padding: `0px 15px`, borderRight: `${index ? 0 : 1}px solid rgba(255,255,255,0.6)`, backgroundColor: isSelected ? "#F44434" : "transparent", cursor: "pointer" }} justifyContent="center" onClick={onClick}>
            <Grid item container direction="row" justifyContent="space-between">
                {
                    label === "home-delivery" ? <img src={Truck} alt="Truck" /> : <img src={Goods} alt="Goods" />
                }
                <CommonText align="left" fontSize="12px" lineHeight="20px">{price ? "免費" : "HK$60起"}</CommonText>
            </Grid>
            <CommonText align="left" fontSize="18px" lineHeight="22px" fontWeight="700">{label}</CommonText>
        </Grid>
    )
}

function DeliveryInfo() {
    const [editing, setEditing] = useState({
        deliveryMethod: "home-delivery"
    })

    return (
        <Grid container direction="column">
            <Box display="flex" flexDirection="row" style={{ border: `1px solid rgba(255,255,255,0.6)` }}>
                {
                    DeliveryMethods.map((item, index) => {
                        const isSelected = editing.deliveryMethod === item.id
                        return (
                            <DeliveryMethodItem
                                label={item.id}
                                price={item.price}
                                isSelected={isSelected}
                                index={index}
                                onClick={() => setEditing({ ...editing, deliveryMethod: item.id })}
                            />
                        )
                    })
                }
            </Box>
            <Grid item style={{ marginTop: 45 }}>
                {
                    editing.deliveryMethod === "home-delivery" ?
                        <>
                            <Title align="left" fontSize="16px" lineHeight="24px">收貨地址</Title>
                            <AddressButton>
                                <CommonText fontSize="16px" lineHeight="24px" fontWeight="600" style={{ marginRight: 12 }}>新增送貨地址</CommonText>
                                <AddIcon src={Add} alt="Add" />
                            </AddressButton>
                        </> :
                        <>
                            <Title align="left" fontSize="16px" lineHeight="24px">自取地點</Title>
                            <CommonSelect name="shop" style={{ border: `1px solid rgba(255,255,255,0.6)` }} options={["Address1"]} placeholder="選擇門市" />
                        </>
                }
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Title align="left" fontSize="16px" lineHeight="24px">{editing.deliveryMethod === "home-delivery" ? "收件人" : "姓名"}</Title>
                <CommonTextField />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Title align="left" fontSize="16px" lineHeight="24px">手提電話號碼</Title>
                <Grid container direction="row">
                    <CommonSelect name="phoneCode" style={{ width: 103, border: `1px solid rgba(255,255,255,0.6)` }} options={["+852"]} value="+852" />
                    <CommonTextField
                        style={{ flex: 1, marginLeft: 10 }}
                        label=""
                        name="phoneNumber"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DeliveryInfo;

const Title = styled(CommonText)`
    && {
        margin-bottom: 8px;
        color: #BCBCBC;
    }
`

const AddressButton = styled(Box)`
    padding: 12px; 
    cursor: pointer;
    background-color: #1F1F21;
    display: flex; 
    justify-content: center; 
    align-items: center;
`
const AddIcon = styled.img`
    height: 20px; 
    width: 20px;
`