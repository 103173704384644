import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { listCartItem, deleteCartItem } from "../apis";
import Loading from "../components/Loading";
import CommonText from "../components/common/typographies/CommonText";
import CommonButton from "../components/common/buttons/CommonButton";
import dayjs from "dayjs";
import { useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Dialog,
  Snackbar,
  SnackbarContent,
  Divider,
} from "@mui/material";
import CartItem from "../pageComponents/shoppingCart/cartItem";
import { Link, useNavigate } from "react-router-dom";
import { ResponsiveUI, breakpoint, WindowSize } from "../utils/responsive";
import { useTranslation } from "react-i18next";
import { AppContext } from "../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StreamingStartedDialog } from "../pageComponents/checkout/StreamingStartedDialog";

const LARGE_DESKTOP_BREAKPOINT = `(min-width: 1024px)`;

const Header = ({ count, t, isLargeDesktop }) => {
  return (
    <HeaderContainer container>
      <Grid
        item
        xs={isLargeDesktop ? true : false}
        style={{ marginBottom: isLargeDesktop ? 0 : 20 }}
      >
        <CommonText align="left" fontSize="36px" lineHeight="44px">
          {t("shoppingCart")}
        </CommonText>
      </Grid>
      <Grid item>
        <CommonText fontSize="18px" lineHeight="28px">
          {count} {t("itemsAddedToCart")}
        </CommonText>
      </Grid>
    </HeaderContainer>
  );
};

const ResponsiveDivider = ({ isLargeDesktop }) => {
  if (isLargeDesktop) {
    return <Divider orientation="vertical" style={{ height: "initial" }} />;
  } else {
    return <Divider />;
  }
};

function ShoppingCart() {
  const { t, i18n } = useTranslation("shoppingCart");
  const { cart, setCart, currency } = useContext(AppContext);
  const [isDeleting, setIsDeleting] = useState({ id: null, status: false });
  const [isStreamingStarted, setIsStreamingStarted] = useState(false);
  const navigate = useNavigate();
  const { isLoading, isError, data, refetch, error, status } = useQuery(
    ["listCartItem", currency],
    () => listCartItem(),
    { cacheTime: "Infinite" }
  );
  const queryClient = useQueryClient();
  const isLargeDesktop = useMediaQuery(LARGE_DESKTOP_BREAKPOINT) ? true : false;
  const isMobile = WindowSize() === "mobile";
  useEffect(() => {
    if (data?.data?.basket)
      window.dataLayer.push({
        event: "custom_event",
        event_name: "view_cart",
        currency: data?.data?.basket?.currency || "HKD",
        value: data?.data?.basket?.orderTotal,
        items: data?.data?.basket?.items,
      });
  }, [data]);

  const checkout = () => {
    let items = data?.data?.basket?.items || [];
    let streamStarted = false
    for (let i = 0; i < items.length; i++) {
      if (dayjs().isAfter(dayjs(items[i].streaming?.streamStart))) {
        streamStarted = true;
        break;
      }
    }
    if (streamStarted){
      setIsStreamingStarted(true);
    } else {
      navigate("/checkout");
    }
  }

  const handleOnDeleteCartItem = async (productId, productOptionId) => {
    if (!productId) return;
    setIsDeleting({ id: productId, status: true });
    try {
      const response = await deleteCartItem(productId, productOptionId);
      if (response.success) {
        const cartLength = response.data?.items?.length || 0;
        setCart(cartLength > 0 ? true : false);
        refetch();
        setIsDeleting({ id: null, status: false });
        window.dataLayer.push({
          event: "custom_event",
          event_name: "remove_from_cart",
          currency: response.data?.currency || "HKD",
          value: response.data?.orderTotal,
          items: response.data?.items,
        });
      } else {
        alert("Cannot delete");
        setIsDeleting({ id: null, status: false });
      }
    } catch (error) {
      alert("Cannot delete");
      setIsDeleting({ id: null, status: false });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return [
    <Header
      count={data?.data?.basket?.items?.length || 0}
      t={t}
      isLargeDesktop={isLargeDesktop}
    />,
    <StyledDivider />,
    <>
      {data?.data?.basket?.items?.length > 0 ? (
        <ContentContainer container>
          <LeftView item xs={isLargeDesktop ? 7 : true}>
            <Grid item xs={12}>
              <CommonText align="left" fontSize="14px" lineHeight="20px">
                {t("tickets")}
              </CommonText>
            </Grid>
            <Grid item>
              {(data?.data?.basket?.items || []).map((item) => {
                const deleting =
                  isDeleting.status && isDeleting.id === item.itemId;
                return (
                  <>
                    <CartItem
                      t={t}
                      language={i18n.language}
                      key={item.itemId}
                      currency={data?.data?.basket?.currency ? data?.data?.basket?.currency.replace("D", "$"):"HK$"}
                      data={item}
                      onDelete={handleOnDeleteCartItem}
                      isDeleting={deleting || isLoading}
                    />
                  </>
                );
              })}
            </Grid>
          </LeftView>
          <ResponsiveDivider isLargeDesktop={isLargeDesktop} />
          <RightView item xs={isLargeDesktop ? true : (isMobile ? true : 6)}>
            <CommonText
              fontSize="20px"
              lineHeight="30px"
              fontWeight="700"
              align="left"
            >
              {t("total")} {data?.data?.basket?.currency ? data?.data?.basket?.currency.replace("D", "$"): "HK$"}
              {data?.data?.basket?.orderTotal || "0"}
            </CommonText>
            {/* <CommonText fontSize="16px" lineHeight="28px" fontWeight="400" align="left" style={{ color: "#919191" }}>{t('shippingDesc')}</CommonText> */}
            <CommonButton style={{ marginTop: 30 }} onClick={checkout} fullWidth>
              {t("checkout")}
            </CommonButton>
          </RightView>
        </ContentContainer>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CommonText>{t("empty")}</CommonText>
        </Box>
      )}
    </>,
    <StreamingStartedDialog onClick={()=>navigate("/checkout")} visible={isStreamingStarted} setVisible={setIsStreamingStarted} />,
  ];
}
export default ShoppingCart;

const StyledDivider = styled(Divider)`
  && {
    margin: 0px 60px;
  }
  @media ${breakpoint.mobile} {
    && {
      margin: 0px 15px;
    }
  }
`;

const ContentContainer = styled(Grid)`
  height: 100%;
  overflow: auto;

  @media ${breakpoint.mobile} {
    && {
      padding: 0px 15px;
      flex-direction: column;
    }
  }
  @media ${breakpoint.desktop} {
    && {
      padding: 0px 60px;
      flex-direction: column;
    }
  }
  @media ${LARGE_DESKTOP_BREAKPOINT} {
    && {
      padding: 0px 60px;
      flex-direction: row;
    }
  }
`;

const HeaderContainer = styled(Grid)`
  height: 130px;

  @media ${breakpoint.mobile} {
    && {
      padding: 0px 15px;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media ${breakpoint.desktop} {
    && {
      padding: 0px 60px;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media ${LARGE_DESKTOP_BREAKPOINT} {
    && {
      padding: 0px 60px;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const LeftView = styled(Grid)`
  && {
    @media ${breakpoint.mobile} {
      margin: 20px 0px 20px 0px;
    }
    @media ${breakpoint.desktop} {
      margin: 20px 0px 20px 0px;
    }
    @media ${LARGE_DESKTOP_BREAKPOINT} {
      margin: 60px;
    }
  }
`;

const RightView = styled(Grid)`
  && {
    @media ${breakpoint.mobile} {
      padding: 20px 0px 60px 0px;
    }
    @media ${breakpoint.desktop} {
      padding: 20px 0px 60px 0px;
    }
    @media ${LARGE_DESKTOP_BREAKPOINT} {
      margin: 40px 60px 60px 60px;
    }
  }
`;
