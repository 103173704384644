import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

export default function CustomButton(props) {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}

const StyledButton = styled(Button)`
  && {
    height: 48px;
    color: ${(props) => props.fontColor || "#ffffff"};
    font-size: 17px;
    font-weight: 700;
    background: ${(props) => props.background || "#F44434"};
    &:hover {
      background: ${props => props.hoverBackgroundColor};
      color: ${props => props.hoverFontColor};
    }
  }
`;
