import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonLabel from "../labels/CommonLabel";
import CommonTextField from "./CommonTextField";
import FormHelperText from '@mui/material/FormHelperText';
import CommonSelect from '../selects/CommonSelect';
import TextButton from '../buttons/TextButton';
import { requestPhoneOTP } from '../../../apis';
import { useTranslation } from 'react-i18next';
import Space from '../../Space';
import Countdown from 'react-countdown';
import constants from '../../../constants';

function PhoneTextfield(props) {
    let {
        helperText,
        errorText,
        errorColor,
        placeholder,
        label,
        value,
        onChange,
        requestOTP
    } = props;
    const { t, i18n } = useTranslation('register');
    const [phoneCode, setPhoneCode] = useState((props.value && props.value.substring(0, 4)) || '+852')
    const [phoneNumber, setPhoneNumber] = useState(props.value && props.value.substring(4, props.length))
    const [countdown, setCountDown] = useState(null);
    const [error, setError] = useState(null);
    const [phoneExist, setPhoneExist] = useState(false);

    useEffect(() => {
        if (value == "") {
            setPhoneCode('+852');
            setPhoneNumber("");
        }
    }, [value]);
    useEffect(() => {
        const count = localStorage.getItem("phoneOTP", null);
        if (count && count > Date.now()) {
            setCountDown(Number(count))
        } else {
            onCompleteCountDown()
        }
    }, [])
    const handleRequestOTP = async () => {
        const phone = phoneCode + phoneNumber;
        const validPhone = new RegExp(constants.PHONE_REGEX);
        if (phone && phone.length > 0 && validPhone.test(phoneCode + phoneNumber)) {
            const result = await requestPhoneOTP(phoneCode + phoneNumber, i18n.language);
            if (result.success) {
                let phoneCountDown = Date.now() + 600 * 1000
                setCountDown(phoneCountDown);
                localStorage.setItem('phoneOTP', phoneCountDown);
                setError(t('phone_otp_sent'))
            } else {
                setError(t('invalid_phone'))
            }
        } else {
            setError(t('invalid_phone'))
        }
    }
    const onCompleteCountDown = () => {
        // Clear Email count down
        localStorage.removeItem("phoneOTP")
        setCountDown(null);
    }
    const handleChange = (event) => {
        if (event.target.name == "phoneNumber") {
            setPhoneNumber(event.target.value);
            if (onChange) onChange(event.target.value ? phoneCode + event.target.value : '');
        } else if (event.target.name == "phoneCode") {
            setPhoneCode(event.target.value);
            if (onChange) onChange(phoneNumber ? event.target.value + phoneNumber : '');
        }
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <TextButton style={{ width: 300, textAlign: 'right' }} onClick={handleRequestOTP}>{t('request_otp')}</TextButton>;
        } else {
            // Render a countdown
            return <span>{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}</span>;
        }
    };
    return (
        <StyledDiv>
            <StyledLabelDiv>
                <CommonLabel>{label ? label : ''}</CommonLabel>
            </StyledLabelDiv>
            {label ? <Space size="8px" /> : null}
            <Row >
                <CommonSelect 
                    name="phoneCode" 
                    onChange={handleChange} 
                    value={phoneCode} 
                    style={{ 
                        fontFamily:'Roboto', 
                        fontSize: 16, 
                        width: 103,
                        lineHeight: '45px'
                    }} 
                    options={["+852"]}
                    hoverBorderColor="#fff"
                />
                <CommonTextField
                    {...props}
                    style={{ flex: 1, marginLeft: 10 }}
                    label=""
                    name="phoneNumber"
                    value={phoneNumber}
                    placeholder={placeholder ? placeholder : ''}
                    FormHelperTextProps={{ style: { display:'none' }}}
                    InputProps={{
                        endAdornment: requestOTP ? (countdown ? <Countdown date={countdown} renderer={renderer} onComplete={onCompleteCountDown} /> : <TextButton style={{ width: 300, textAlign: 'right' }} onClick={handleRequestOTP}>{t('request_otp')}</TextButton>) : null,
                        style: { fontFamily:'Roboto',  }
                    }}
                    onChange={handleChange}
                    hoverBorderColor="#fff"
                />
            </Row>
            <StyledErrorText color={errorColor}>{(errorText ? errorText : (error || ''))}</StyledErrorText>
            <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
`;
const Row = styled.div`
    display: flex;
    width: 100%;
`

const StyledLabelDiv = styled.div`
    display: flex;
    justify-content: left;
`;

const StyledErrorText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        color: ${props => props.color ?? "#FF5C00"};
    }
`;

const StyledHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        font-size: 0.875rem;
    }
`;

export default PhoneTextfield;