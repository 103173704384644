import React, { useState } from 'react';
import styled from 'styled-components';
import CommonLabel from "../labels/CommonLabel";
import CommonTextField from "./CommonTextField";
import FormHelperText from '@mui/material/FormHelperText';
import ImageButton from '../buttons/ImageButton';
import PasswordShowIcon from '../../../assets/PasswordShow.svg';
import PasswordHideIcon from '../../../assets/PasswordHide.svg';
import Space from '../../Space';

function PasswordTextField(props) {
    let {
        helperText,
        errorText,
        errorColor,
        label, 
        height
    } = props;
    const [isPasswordShow, setIsPasswordShow] = useState(false)

    const toggleHiddenPassword = () =>{
        setIsPasswordShow(!isPasswordShow);
    }
    return (
        <StyledDiv>
            <StyledLabelDiv>
                <CommonLabel>{label ? label : ''}</CommonLabel>
            </StyledLabelDiv>
            <Space size="8px" />
            <CommonTextField
                {...props}
                label=""
                helperText=""
                type={isPasswordShow?"text":"password"}
                InputProps={{
                    endAdornment: <ImageButton src={isPasswordShow?PasswordShowIcon:PasswordHideIcon} onClick={toggleHiddenPassword} />,
                    style: { height: height }
                }}
                hoverBorderColor="#fff"
            />
            <StyledErrorText color={errorColor}>{errorText ? errorText : ''}</StyledErrorText>
            <StyledHelperText>{helperText ? helperText : ''}</StyledHelperText>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
`;

const StyledLabelDiv = styled.div`
    display: flex;
    justify-content: left;
`;

const StyledErrorText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        color: ${props => props.color ?? "#FF5C00"};
    }
`;

const StyledHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-left: 0;
        font-size: 14px;
        line-height: 20px;
        color: white;
    }
`;

export default PasswordTextField;