import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { breakpoint } from "../utils/responsive";
import CloseIcon from "../assets/Close.svg";

export default function GeoBlocking({ openCookie }) {
  const { t } = useTranslation("home");
  const [openGeoBlock, setOpenGeoBlock] = useState(false);

  const handleClose = () => {
    setOpenGeoBlock(false);
  };

  useEffect(() => {
    //TODO call api for geo blocking
    setOpenGeoBlock(true);
  }, []);

  return openGeoBlock ? (
    <ContentContainer>
      <ConsentContent>{t("geoBlocking")}</ConsentContent>
      <CloseButton src={CloseIcon} onClick={handleClose} />
    </ContentContainer>
  ) : null;
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  background-color: #f44434;
`;
const ConsentContent = styled.div`
  display: flex;

  @media ${breakpoint.largeDesktop} {
    justify-content: center;
    flex: 1;
  }

  @media ${breakpoint.mobile} {
    font-size: 12px;
  }
`;

const CloseButton = styled.img`
  cursor: pointer;
`;
