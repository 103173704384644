import { useState, cloneElement } from 'react'
import Popover from '@mui/material/Popover';
import { Typography } from '@mui/material';
import makeUrls from './makeUrls';
import styled from "styled-components";

const LIST = [
    { id: 'ics', name: 'Apple Calendar' },
    { id: 'google', name: 'Google' },
    { id: 'outlook', name: 'Outlook' },
    { id: 'outlookWebApp', name: 'Outlook Web App' },
    { id: 'yahoo', name: 'Yahoo' }
]

const ListItem = ({ id, name, index, urls }) => {
    const url = id === "outlookWebApp" ? urls.ics : urls?.[id] || ""
    return (
        <ListItemContainer
            onClick={() => window.open(url, '_blank')}
        >
            {name}
        </ListItemContainer>
    )
}

function AddToCalendarButton({
    component,
    buttonStyle,
    children,
    event,
    anchorOrigin = { vertical: 'top', horizontal: 'right', },
    transformOrigin = { vertical: 'bottom', horizontal: 'right', }
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const urls = makeUrls(event)

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <div style={{ background: "#fff", borderRadius: 4 }}>
                    {
                        LIST.map((item, i) => (<ListItem {...item} index={i} urls={urls} />))
                    }
                </div>
            </Popover>
            {
                component ?
                    cloneElement(component, { onClick: handleClick }) :
                    <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', ...buttonStyle }} onClick={handleClick}>{children}</div>
            }
        </>
    )
}

export default AddToCalendarButton

const ListItemContainer = styled(Typography)`
    && {
        cursor: pointer;
        padding: 10px;
        color: #000;
        &:hover {
            background: rgba(0,0,0,0.1)
          }
    }
`