import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { checkoutStream, keepaliveStream } from "../apis/index.js";
// import ReactJWPlayer from "react-jw-player";
import jwplayerjs from "../assets/js/jwplayer-8.25.1/jwplayer.js";
import base64DecodeUint8Array from "../utils/base64DecodeUint8Array.js";
import base64EncodeUint8Array from "../utils/base64EncodeUint8Array.js";
import ExclamationRoundOrange from "./icons/ExclamationRoundOrange";
import CastingView from "./CastingView.js";
import CastingIcon from "../assets/CastingIcon.svg";
import { TitleIconButtonDialog } from "./common/dialogs/TitleIconButtonDialog";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import getDeviceType from "../utils/getDeviceType.js";

export default function Player({
  width,
  height,
  title,
  contentId,
  isMultiCDN = true,
  streaming,
  onError,
}) {
  const { isLoading, isError, data, error } = useQuery(
    ["checkoutStream", contentId, streaming],
    () => checkoutStream(contentId, streaming)
  );
  const [casting, setCasting] = useState(false);
  const [sessionError, setSessionError] = useState(null);
  const { t, i18n } = useTranslation("live");
  if (window.jwplayer)
    window.jwplayer.key = "nvNka3tbOHB6pWg0gDCvVeRT4EiRZSBQaYLcCA==";
  useEffect(() => {
    window.cjs.on("statechange", () => {
      if (window.cjs.state == "connected") {
        setCasting(true);
      } else if (window.cjs.state == "disconnected") {
        setCasting(false);
      }
    });
  }, []);
  useEffect(() => {
    let keepaliveTimer;
    let gaTimer;
    if (data && data.status == "success") {
      window.streamSession = data.session;
      const player = window.jwplayer("liveplayer");
      if (sessionError) {
        if (casting) {
          window.cjs.disconnect();
        } else {
          player.remove();
        }
      } else {
        if (casting) {
          if (player) player.remove();
        } else {
          player.setup({
            playlist: [
              {
                sources: [
                  {
                    default: false,
                    file: data.streampath,
                    type: "mpd",
                    label: 1,
                    drm: {
                      widevine: {
                        url: "https://license-global.pallycon.com/ri/licenseManager.do",
                        headers: [
                          {
                            name: "pallycon-customdata-v2",
                            value: data.drmtoken,
                          },
                        ],
                      },
                      playready: {
                        url: "https://license-global.pallycon.com/ri/licenseManager.do",
                        headers: [
                          {
                            name: "pallycon-customdata-v2",
                            value: data.drmtoken,
                          },
                        ],
                      },
                    },
                  },
                  {
                    default: false,
                    file: data.streampath,
                    type: "hls",
                    label: 1,
                    drm: {
                      fairplay: {
                        certificateUrl:
                          data.fairplayCertUrl, // uses .der file version fps cert URL
                        extractContentId: function (initDataUri) {
                          const contentId = initDataUri.substring(
                            initDataUri.indexOf("skd://") + 6
                          );
                          return contentId;
                        },
                        processSpcUrl:
                          "https://license-global.pallycon.com/ri/licenseManager.do",
                        licenseResponseType: "text",
                        licenseRequestHeaders: [
                          {
                            name: "Content-type",
                            value: "application/x-www-form-urlencoded",
                          },
                          {
                            name: "pallycon-customdata-v2",
                            value: data.drmtoken,
                          },
                        ],
                        licenseRequestMessage: function (message, session) {
                          var payload =
                            "spc=" + base64EncodeUint8Array(message);
                          return payload;
                        },
                        extractKey: function (response) {
                          console.log("license Response : ", response);
                          return base64DecodeUint8Array(response);
                        },
                      },
                    },
                  },
                ],
              },
            ],
            width: width || "100%",
            height: height || "100%",
            autostart: true,
            cast: {},
            mute: false,
            volume: 75,
            ...(isMultiCDN
              ? {
                  dnaConfig: {
                    compassProperty: contentId,
                  },
                }
              : {}),
          });
          if (window.cjs && window.cjs.available) {
            // add cast button
            setTimeout(() => {
              let streamUrl = data.streampath;
              let drmToken = data.drmtoken;
              const player = window.jwplayer("liveplayer");
              try {
                player.addButton(
                  CastingIcon,
                  "Cast",
                  () => {
                    window.cjs.cast(
                      streamUrl,
                      {},
                      {
                        licenseUrl:
                          `https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=` +
                          drmToken,
                        contentId: contentId,
                        multiCDNEnabled: isMultiCDN,
                      }
                    );
                  },
                  "cast-button"
                );
              } catch (e) {
                console.log(e);
              }
            }, 3000);
          }
        }
        if (data.session) {
          keepaliveTimer = setInterval(async () => {
            let result = await keepaliveStream(contentId, data.session);
            // result = { status: "fail" }
            if (result) {
              window.keepaliveRetry = 0;
              if (result.status == "fail") {
                // kick user out
                setSessionError("session");
              }
            } else {
              // call api fail
              let retry = window.keepaliveRetry || 0;
              if (retry < 2) {
                window.keepaliveRetry = window.keepaliveRetry
                  ? window.keepaliveRetry + 1
                  : 1;
              } else {
                // kick user out
                window.keepaliveRetry = 0;
                setSessionError("retry");
              }
            }
          }, 300000);
        }
        gaTimer = setInterval(async () => {
          window.dataLayer.push({
            event: "custom_event",
            event_name: "video_heartbeat",
            video_title: title,
            video_url: data.streampath,
            video_provider: "MakeALive",
          });
        }, 900000);
      }
    }
    return () => {
      if (keepaliveTimer) {
        clearInterval(keepaliveTimer);
      }
      if (gaTimer) {
        clearInterval(gaTimer);
      }
    };
  }, [data, casting, sessionError]);

  const renderContent = () => {
    if (casting) {
      return (
        <CastingView streamUrl={data.streampath} drmToken={data.drmtoken} />
      );
    } else if (sessionError) {
      return (
        <TitleIconButtonDialog
          visible={sessionError}
          setVisible={setSessionError}
          title={
            sessionError == "session" ? t("session_exceed_title") : t("sorry")
          }
          body={
            sessionError == "session"
              ? t("session_exceed_desc")
              : t("playback_fail")
          }
          ImgComp={ExclamationRoundOrange}
          buttonText={t("ok")}
          onClick={() => {
            if (onError) onError();
          }}
        />
      );
    }
    return null;
    // <ReactJWPlayer
    //     playerId="liveplayer"
    //     playerScript={jwplayerjs.toString()}
    //     playlist={playlist}
    //     isAutoPlay={true}
    //     customProps={{
    //         width: width || "100%",
    //         height: height || "100%",
    //         cast: {},
    //         mute: false,
    //         volume: 75,
    //         // ...(isMultiCDN ? {
    //         //     dnaConfig: {
    //         //         "compassProperty": contentId
    //         //     }
    //         // } : {})
    //     }
    //     }
    //     licenseKey="nvNka3tbOHB6pWg0gDCvVeRT4EiRZSBQaYLcCA=="
    //     // onReady={onPlayerReady}
    // //     onAutoStart={this.onAutoStart}
    // //     onPlay={this.onPlay}
    // //     onTime={this.onTime}
    // //     // onSetupError={this.onAdBlock}
    // //     onError={this.onError}
    // />
  };
  const type = getDeviceType();
  return (
    <Container>
      {window.jwplayer && isMultiCDN && (
        <Helmet>
          {/* <script type="text/javascript" src="https://test.viu.tv/assets/jwplayer-8.23.2/jwplayer.js"></script> */}
          <script src="https://cdn.streamroot.io/jwplayer-dash-dna-plugin/1/stable/jwplayer-dash-dna-plugin.js?srKey=8f53cb68-872a-4234-b6b2-80bc082fbe6d"></script>
          <script src="//tools.streamroot.io/usage-graphs/latest/graphs.js"></script>
        </Helmet>
      )}
      <div id="liveplayer" />
      {casting && (
        <CastingView streamUrl={data.streampath} drmToken={data.drmtoken} />
      )}
      {sessionError && (
        <TitleIconButtonDialog
          visible={sessionError}
          setVisible={setSessionError}
          title={
            sessionError == "session" ? t("session_exceed_title") : t("sorry")
          }
          body={
            sessionError == "session"
              ? t("session_exceed_desc")
              : t("playback_fail")
          }
          ImgComp={ExclamationRoundOrange}
          buttonText={t("ok")}
          onClick={() => {
            if (onError) onError();
          }}
        />
      )}
      {/* {isMultiCDN && type == "dash" && <div id="streamroot-graphs"></div>} */}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  .jw-icon-rewind {
    display: none;
  }
`;
