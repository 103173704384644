import React from "react"
import styled from 'styled-components';
import CheckboxCheckedIcon from "../../../assets/CheckboxChecked.svg";
import CheckboxUncheckedIcon from "../../../assets/CheckboxUnchecked.svg";
import CommonText from "../typographies/CommonText"

const CheckBox = (props) => {
  const { isChecked, onClick, children } = props
  return (
    <Container>
      <CheckIcon src={isChecked?CheckboxCheckedIcon:CheckboxUncheckedIcon} onClick={onClick} />
      {/* Ref: src/typographies.json > Checkbox */}
      <CommonText sizeType="Checkbox" style={{ marginLeft: '15px', textAlign: 'start' }}>{children}</CommonText>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const CheckIcon = styled.img`
  cursor: pointer;
`

export default CheckBox
