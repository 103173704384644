import { listTicket, listEvents } from "../apis";
import { useQuery } from "react-query";
import Loading from "../components/Loading";
import TicketMenu from "../pageComponents/myTicket/TicketMenu";
import OngoingTicketList from "../pageComponents/myTicket/OngoingTicketList";
import styled from "styled-components";
import { useState } from "react";
import PastEventList from "../pageComponents/myTicket/PastEventList";
import { breakpoint } from "../utils/responsive";
import LatestEvent from "../pageComponents/myTicket/LatestEvent";
import PastTicketList from "../pageComponents/myTicket/PastTicketList";
import Lottie from "react-lottie";
import * as animationData from "../assets/animation/MyTicketBgLine.json";
import NoTicket from "../pageComponents/myTicket/NoTicket";
import Space from "../components/Space";

export default function MyTicket() {
  const { isLoading, isError, data, error } = useQuery("listTicket", () =>
    listTicket()
  );
  const {
    isLoading: eventLoading,
    isError: eventIsError,
    data: eventData,
    error: eventError,
  } = useQuery("listEvents", () => listEvents());
  const [currentSession, setCurrentSession] = useState("upcoming");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (isLoading || eventLoading) {
    return <Loading />;
  }
  if (isError || eventIsError) {
    return <div>Error: {error ? error?.message : eventError?.error}</div>;
  }

  const withOngoing = data && data.data && data.data?.ongoing?.length > 0;
  const withPast = data && data.data && data.data?.past?.length > 0;
  const withTicket = withOngoing || withPast;

  return (
    <StyledDiv>
      {withOngoing && (
        <LottieMobileDiv>
          <Lottie options={defaultOptions} height="100%"/>
        </LottieMobileDiv>
      )}
      {withTicket ? (
        <StyledPageLayout>
          <div style={{ width: "100%", zIndex: 100 }}>
            <TicketMenu
              currentSession={currentSession}
              setCurrentSession={setCurrentSession}
            />
            {withOngoing ? (
              <OngoingTicketList
                data={data && data.data && data.data.ongoing}
              />
            ) : null}
            {withOngoing && withPast && <StyledTicketPadding />}
            {withPast ? (
              <PastTicketList withOngoing={withOngoing} data={data && data.data && data.data.past} />
            ) : null}
          </div>
        </StyledPageLayout>
      ) : (
        <>
          <NoTicket />
          <LatestEvent data={eventData && eventData.data} />
        </>
      )}
      <Space size="100px" />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  /* border-top: 1px solid rgba(255, 255, 255, 0.5); */
`;

const LottieMobileDiv = styled.div`
  position: absolute;
  width: 100vw;
  min-width: 1920px;
  z-index: 0;
`;

const StyledTicketPadding = styled.div`
  height: 90px;

  @media ${breakpoint.desktop} {
    height: 180px;
  }

  @media ${breakpoint.size1024} {
    height: 160px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 112px;
  }
`;
