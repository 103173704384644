import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ResponsiveUI, WindowSize, breakpoint } from "../../utils/responsive";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";
import CommonText from "../../components/common/typographies/CommonText";
import { Link } from "react-router-dom";
import CommonButton from "../../components/common/buttons/CommonButton";
import { ReactComponent as TriangleRightSvg } from "../../assets/TriangleRight.svg";
import CustomButton from "../../components/common/buttons/CustomButton";
import TicketShareButton from "../../components/common/buttons/TicketShareButton";
import { useWebShare } from "../../hooks/useWebShare";

const GoToLiveButton = ({ data, session }) => {
  if (data == null || data._id == null) return null;
  const { t, i18n } = useTranslation("myTicket");
  const before24Hour = dayjs(data?.streamStart).subtract(1, "day");
  const isLiveEvent =
    session === "upcoming" &&
    dayjs(new Date()).isAfter(before24Hour) &&
    dayjs(new Date()).isBefore(data?.streamEnd);
  const buttonWidthString = WindowSize() === "mobile" ? "180px" : "200px";
  return isLiveEvent ? (
    <Link onClick={()=> {
          window.dataLayer.push({
              event: "custom_event",
              event_name: "button_click",
              button_text: "Web enter live"
          })
        }} to={`/live/${data?._id}`}>
      <CommonButton style={{ width: buttonWidthString, fontSize: "16px" }}>
        <span style={{ marginRight: 12.14 }}>{t("watchNow")}</span>
        <TriangleRightSvg />
      </CommonButton>
    </Link>
  ) : (
    <Link to={`/event/${data?.event?._id}`}>
      <CustomButton
        style={{ width: buttonWidthString, fontSize: "16px" }}
        background="rgba(255, 255, 255, 0.1)"
        hoverBackgroundColor="#000000"
      >
        {t("eventPage")}
      </CustomButton>
    </Link>
  );
};

const TicketList = ({ data, category, index, session, subtitle, button }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { t, i18n } = useTranslation("myTicket");
  const { onWebShareClick } = useWebShare();

  const titleZh = useMemo(() => {
    if (data?.event?.sponsorZh)
      return data?.event?.eventNameZh;
    else return data?.event?.eventNameZh;
  }, [data?.event]);

  const titleEn = useMemo(() => {
    if (data?.event?.sponsorEn)
      return data?.event?.eventNameEn;
    else return data?.event?.eventNameEn;
  }, [data?.event]);

  const ticketImageUrl = useMemo(() => {
    return i18n.language === "zh" ? data.displayImageZh : data.displayImageEn;
  }, [i18n?.language]);

  const shouldFlip = useMemo(() => {
    return index % 2 === 1;
  }, [index]);

  return (
    <Styled.ListBase>
      <ResponsiveUI
        mobile={
          <Styled.MobileView>
            <Styled.MobileTitle>
              <Styled.CategoryText isMobile={true}>
                {category}
              </Styled.CategoryText>
              <Styled.TitleTextBox>
                <Styled.SponsorText>
                  {i18n.language === "zh"
                    ? data.event?.sponsorZh
                    : data.event?.sponsorEn}
                </Styled.SponsorText>
                <CommonText fontSize="24px" fontWeight="500" textAlign="left">
                  {i18n.language === "zh" ? titleZh : titleEn}
                </CommonText>
              </Styled.TitleTextBox>
            </Styled.MobileTitle>
            <TicketImage src={ticketImageUrl} />
            <Styled.MobileFooter>
              <GoToLiveButton data={data} session={session} />
              <TicketShareButton
                onClick={() =>
                  onWebShareClick(
                    i18n.language === "zh"
                      ? data.downloadImageZh
                      : data.downloadImageEn,
                    `${data?.product ?? "ticket"}-${i18n.language}`
                  )
                }
              />
            </Styled.MobileFooter>
          </Styled.MobileView>
        }
        size1024={
          <Styled.DesktopView flip={shouldFlip}>
            <Styled.DesktopTitle>
              <div style={{ alignSelf: "start" }}>
                <Styled.CategoryText>{category}</Styled.CategoryText>
              </div>
              <Styled.TitleTextBox>
                <Styled.SponsorText>
                  {i18n.language === "zh"
                    ? data.event?.sponsorZh
                    : data.event?.sponsorEn}
                </Styled.SponsorText>
                <CommonText fontSize="24px" fontWeight="500" lineHeight="1.2">
                  {i18n.language === "zh" ? titleZh : titleEn}
                </CommonText>
              </Styled.TitleTextBox>
              <Styled.DesktopButtonGroup>
                <GoToLiveButton data={data} session={session} />
                <TicketShareButton
                  onClick={() =>
                    onWebShareClick(
                      i18n.language === "zh"
                        ? data.downloadImageZh
                        : data.downloadImageEn,
                      `${data?.product ?? "ticket"}-${i18n.language}`
                    )
                  }
                />
              </Styled.DesktopButtonGroup>
            </Styled.DesktopTitle>
            <TicketImage src={ticketImageUrl} />
          </Styled.DesktopView>
        }
      />
    </Styled.ListBase>
  );
};

TicketList.propTypes = {
  category: PropTypes.string,
  index: PropTypes.number,
};

const ListBase = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  gap: 60px;
`;
const CategoryText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: grid;
  place-items: center;
  white-space: nowrap;
  padding: 15px ${(props) => (props.isMobile ? "16px" : "32px")};
  border-width: 1px 0px;
  border-style: solid;
  border-color: #ffffff;
`;

const MobileView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DesktopView = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.flip ? "row-reverse" : "row")};
  justify-content: ${(props) => (props.flip ? "flex-end" : "space-between")};
  gap: 60px;
  align-items: center;
  width: 100%;
`;

const MobileTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: nowrap;
  gap: 20px;
`;

const DesktopTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
`;

const TitleTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
`;

const SponsorText = styled.span`
  color: #FFF;
  font-size: 16px;
  font-family: "Clash Grotesk";
  font-weight: 500;
  line-height: 1.2;
  text-align: left;

  @media ${breakpoint.largeDesktop} {
    font-size: 18px;
  }
`;

const MobileFooter = styled.div`
  width: 320px;
  margin-top: 32px; // 40px - 16px (gap) = 24px
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoint.mobile} {
    width: 300px;
  }
`;

const DesktopButtonGroup = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const TicketImage = styled.img`
  margin-top: 32.5px;
  display: block;
  width: 320px;
  height: 640px;
  flex-shrink: 0;

  /* border: 1px solid #ffffff; */
  @media ${breakpoint.mobile} {
    width: 300px;
    height: 600px;
  }
  @media ${breakpoint.size1024} {
    margin-top: 0px;
  }
`;

const Styled = {
  ListBase,
  CategoryText,
  MobileView,
  MobileTitle,
  DesktopView,
  DesktopTitle,
  TitleTextBox,
  SponsorText,
  DesktopButtonGroup,
  MobileFooter,
};

export default TicketList;
