import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/common/buttons/CustomButton";
import { Link, useNavigate, useLocation } from "react-router-dom";

const LatestEvent = ({ data }) => {
  const { t } = useTranslation("myTicket");
  let navigate = useNavigate();

  return (
    <Container>
      <ContentContainer>
        <CommonText
          fontSize="18px"
          fontWeight="700"
          style={{ marginBottom: 20 }}
        >
          {t("latestEvent")}
        </CommonText>
        <PosterDiv>
          {data &&
            data.map((event, idx) => {
              if (idx < 3) {
                return (
                  <Poster
                    src={event?.poster}
                    idx={idx}
                    onClick={() => navigate(`/event/${event?._id}`)}
                  />
                );
              }
            })}
        </PosterDiv>
        <Link to="/">
          <CustomButton
            fontColor="#000"
            background="#fff"
            hoverBackgroundColor="#fff"
            style={{ width: "100%" }}
          >
            {t("viewAll")}
          </CustomButton>
        </Link>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  //   width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  @media ${breakpoint.mobile} {
    margin: 35px 15px;
  }
  @media ${breakpoint.desktop} {
    margin-bottom: 50px;
  }
`;

const ContentContainer = styled.div`
  background-color: #1e1e20;
  height: auto;
  @media ${breakpoint.mobile} {
    padding: 15px;
    width: 100%;
  }
  @media ${breakpoint.desktop} {
    padding: 15px;
    width: 430px;
  }
  @media ${breakpoint.largeDesktop} {
    padding: 30px;
    width: 480px;
  }
`;

const PosterDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1 / 1;
  margin-bottom: 15px;

  @media ${breakpoint.mobile} {
    height: 28vw;
  }
  @media ${breakpoint.desktop} {
    height: 120px;
  }
  @media ${breakpoint.largeDesktop} {
    height: 132px;
  }
`;

const Poster = styled.img`
  margin-right: ${(props) => (props.idx != 2 ? "12px" : "0px")};
  cursor: pointer;
`;

export default LatestEvent;
