import { Divider, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled, { createGlobalStyle } from "styled-components";
import CommonText from "../../components/common/typographies/CommonText";
import Space from "../../components/Space";
import RightArrow from "../../assets/RightArrow.svg";
import RightArrowBlack from "../../assets/RightArrowBlack.svg";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import TicketTitle from "../myTicket/Card/TicketTitle";
import WatchNowIcon from "../../assets/WatchNow.svg";
import { breakpoint, ResponsiveUI, WindowSize } from "../../utils/responsive";
import { StreamDateConverter } from "../../utils/dateConverter";
import GotoLiveSnackbar from "./GotoLiveSnackbar";
import dateString from "../../utils/dateString";

export default function EventItem({ event, idx, ticket }) {
  const { t, i18n } = useTranslation("home");
  const isDesktop = useMediaQuery(`(min-width: 1024px)`);
  let navigate = useNavigate();

  const getLiveColor = () => {
    let isLiveEvent = false;
    event?.streamings &&
      event?.streamings?.map((item) => {
      const before24Hour = dayjs(item.streamStart).subtract(1, "day");
        if (isLiveEvent === false) {
          isLiveEvent =
            dayjs(new Date()).isAfter(before24Hour) &&
            dayjs(new Date()).isBefore(item.streamEnd);
        }
      });

    if (isLiveEvent) {
      return "#F44434";
    } else {
      return "#fff";
    }
  };

  const customTitle = (streamStart, streamId) => {
    return (
      <CustomTitleDiv>
        <CommonText
          fontSize="14px"
          mFontSize="12px"
          color="#F44434"
          textAlign="left"
          style={{ whiteSpace: "pre-line" }}
        >
          {dateString(streamStart, i18n.language)}
        </CommonText>
        <WatchNowButton>
          <CommonText
            fontSize="14px"
            mFontSize="12px"
            color="#fff"
            textAlign="left"
            testWeight="700"
            style={{ whiteSpace: "pre-line" }}
          >
            {t("watchNow")}
          </CommonText>
          <img src={WatchNowIcon} />
        </WatchNowButton>
      </CustomTitleDiv>
    );
  };

  const getGotoLiveBtn = () => {
    let isDisplay = false;

    if (ticket) {
      const before24Hour = dayjs(ticket.streamStart).subtract(1, "day");
      if (
        dayjs(new Date()).isAfter(before24Hour) &&
        dayjs(new Date()).isBefore(ticket.streamEnd) &&
        isDisplay === false
      ) {
        isDisplay = true;
        return isDesktop ? (
          <GotoLiveBtn onClick={() => {
            window.dataLayer.push({
                event: "custom_event",
                event_name: "button_click",
                button_text: "Web enter live"
            })
            navigate(`/live/${ticket?._id}`)
          }}>
            <TicketTitle
              poster={ticket?.event?.poster}
              sponsorEn={ticket?.event?.sponsorEn}
              sponsorZh={ticket?.event?.sponsorZh}
              titleZh={ticket?.event?.eventNameEn}
              titleEn={ticket?.event?.eventNameZh}
              customTitle={customTitle(ticket.streamStart, ticket?._id)}
            />
          </GotoLiveBtn>
        ) : (
          <GotoLiveSnackbar ticket={ticket} customTitle={customTitle} />
        );
      }
    } else {
      return null;
    }
  };

  const posterDisplay = () => {
    return (
      <PosterDiv>
        <Poster src={event?.poster} alt={event?.eventNameEn} />
      </PosterDiv>
    );
  };

  const eventDetail = () => {
    return (
      <EventMainDiv>
        <EventDetailDiv>
          <Circle color={getLiveColor()} />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CommonText
              lFontSize="18px"
              fontSize="16px"
              lineHeight="1.2"
              style={{ wordBreak: "breakAll" }}
            >
              {i18n.language === "zh" ? event?.sponsorZh : event?.sponsorEn}
            </CommonText>
            <CommonText
              lFontSize="36px"
              fontSize="28px"
              lineHeight="44px"
              style={{ wordBreak: "breakAll" }}
            >
              {i18n.language === "zh" ? event?.eventNameZh : event?.eventNameEn}
            </CommonText>
            <StreamDateConverter
              i18n={i18n}
              streams={event?.streamings}
              displayLimit={5}
            />
          </div>
          {ticket && getGotoLiveBtn()}
          <Link to={`/event/${event?._id}`}>
            <ViewDetailButton>
              <ViewDetailButtonText>
                {t("view_event_detail")}
              </ViewDetailButtonText>
              <ViewDetailButtonDivider orientation="vertical" />
              <ViewDetailButtonArrow src={RightArrow} alt="RightArrow" />
              <ViewDetailButtonArrowBlack
                src={RightArrowBlack}
                alt="RightArrow"
              />
            </ViewDetailButton>
          </Link>
        </EventDetailDiv>
      </EventMainDiv>
    );
  };

  const _renderMobile = () => {
    return (
      <div>
        <div style={{ padding: "30px 15px" }}>
          <EventTitleMobile>
            <CircleMobile color={getLiveColor()} />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CommonText
                lFontSize="18px"
                fontSize="16px"
                lineHeight="1.2"
                style={{ wordBreak: "breakAll" }}
              >
                {i18n.language === "zh" ? event?.sponsorZh : event?.sponsorEn}
              </CommonText>
              <CommonText fontSize="24px" lineHeight="28px">
                {i18n.language === "zh"
                  ? event?.eventNameZh
                  : event?.eventNameEn}
              </CommonText>
            </div>
          </EventTitleMobile>

          <Poster src={event?.poster} alt={event?.eventNameEn} />
          <StreamDateConverter
            i18n={i18n}
            streams={event?.streamings}
            displayLimit={3}
          />
          <Link to={`/event/${event?._id}`}>
            <ViewDetailButton>
              <ViewDetailButtonText>
                {t("view_event_detail")}
              </ViewDetailButtonText>
              <ViewDetailButtonDivider orientation="vertical" />
              <ViewDetailButtonArrow src={RightArrow} alt="RightArrow" />
              <ViewDetailButtonArrowBlack
                src={RightArrowBlack}
                alt="RightArrow"
              />
            </ViewDetailButton>
          </Link>
        </div>
        <Divider />
        {getGotoLiveBtn()}
      </div>
    );
  };

  const _renderDesktop = () => {
    return (
      <Container container>
        <LeftView item xs={6} container justifyContent="center" zeroMinWidth>
          {idx % 2 === 0 ? posterDisplay() : eventDetail()}
        </LeftView>
        <RightView item xs={6} container justifyContent="center" zeroMinWidth>
          {idx % 2 === 0 ? eventDetail() : posterDisplay()}
        </RightView>
      </Container>
    );
  };

  return <ResponsiveUI desktop={_renderDesktop()} mobile={_renderMobile()} />;
}

const Container = styled(Grid)`
  width: 100%;
  height: 720px;
  display: flex;
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
`;

const LeftView = styled(Grid)`
  position: relative;
  border-right: 1px solid rgb(255, 255, 255, 0.5);
`;

const WatchNowButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const GotoLiveBtn = styled.div`
  width: 30vw;
  margin-bottom: 15px;
  background-color: rgb(31, 31, 33);
  padding: 15px;
  cursor: pointer;
  &:hover {
    background-color: rgb(46, 46, 46);
    ${WatchNowButton} {
      text-decoration: underline;
    }
  }
`;

const Poster = styled.img`
  object-fit: contain;
  aspect-ratio: 1 / 1;

  @media ${breakpoint.largeDesktop} {
    width: calc(100% - 180px);
    height: calc(720px - 180px);
    margin: 90px;
  }

  @media ${breakpoint.desktop} {
    width: calc(100% - 60px);
    height: calc(720px - 60px);
    margin: 30px;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    height: 100%;
    margin: 15px 0px;
  }
`;

const PosterDiv = styled.div`
  background-color: #000;
  width: 100%;
  height: calc(100% - 4px);
`;

const RightView = styled(Grid)`
  position: relative;
`;

const ViewDetailButtonText = styled(CommonText)`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ViewDetailButtonDivider = styled(Divider)`
  background: rgb(255, 255, 255, 0.3);
`;

const ViewDetailButtonArrow = styled.img`
  padding: 20px;
`;

const ViewDetailButtonArrowBlack = styled.img`
  padding: 20px;
  display: none;
`;

const ViewDetailButton = styled.div`
  display: flex;
  align-items: center;
  width: 30vw;
  height: 56px;
  border: 1px solid rgb(255, 255, 255, 0.5);

  @media ${breakpoint.mobile} {
    width: 100%;
    margin-top: 15px;
  }

  &:hover {
    background: rgb(255, 255, 255);
    ${ViewDetailButtonText} {
      color: #000;
    }
    ${ViewDetailButtonDivider} {
      background: #000;
    }
    ${ViewDetailButtonArrow} {
      display: none;
    }
    ${ViewDetailButtonArrowBlack} {
      display: inline;
    }
  }
`;

const EventMainDiv = styled.div`
  @media ${breakpoint.largeDesktop} {
    padding: 60px 120px;
  }

  @media ${breakpoint.desktop} {
    padding: 30px;
  }
`;

const EventDetailDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  background-color: ${(props) => props.color};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;

  @media ${breakpoint.largeDesktop} {
    top: 60px;
    left: 60px;
  }

  @media ${breakpoint.desktop} {
    top: 30px;
    left: 30px;
  }
`;

const CircleMobile = styled.div`
  background-color: ${(props) => props.color};
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 3px;
`;

const EventTitleMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const CustomTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
