/* eslint-disable */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import constants from '../../constants';
import { appleLogin } from "../../apis";
import { AppContext } from "../../AppContext";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import AppleIcon from "../../assets/AppleSignin.svg";
import CommonButton from '../common/buttons/CommonButton';

export default function AppleSignIn({ handlePageChange, handleClose }) {
    const { t } = useTranslation("signin");
    const { setUser, requestPhoneVerification } = useContext(AppContext)
    const responseApple = async () => {
        try {
            const data = await AppleID.auth.signIn()
            const result = await appleLogin(data.authorization.code, data.user?.name?.lastName, data.user?.name?.firstName);
            if (result.success) {
                localStorage.setItem('accessToken', result.data?.accessToken);
                localStorage.setItem('refreshToken', result.data?.refreshToken);
                setUser(result.data?.user);
                if (result.data?.isNewUser) {
                    window.dataLayer.push({
                        event: "custom_event",
                        event_name: "signup",
                        method: "apple"
                    })
                    requestPhoneVerification();
                } else {
                    window.dataLayer.push({
                        event: "custom_event",
                        event_name: "login",
                        method: "apple"
                    })
                }
                handleClose();
            }
        } catch (error) {
            //handle error.
        }
    }

    return (
        <div style={{ width: '100%' }} >
            <Helmet>
                <meta name="appleid-signin-client-id" content={constants.APPLE_SIGN_IN_CLIENT_ID} />
                <meta name="appleid-signin-scope" content="name email" />
                <meta name="appleid-signin-use-popup" content="true" />
                <meta name="appleid-signin-redirect-uri" content={"https://" + window.location.hostname} />
                <script type="text/javascript" src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" async defer></script>
            </Helmet>
            <CommonButton
                variant="contained"
                type="secondary"
                style={{ width: '100%', fontWeight: 700, backgroundColor: "#ffffff", color: "#000000" }}
                startIcon={<img src={AppleIcon} />}
                onClick={responseApple}>
                {t('apple_signin')}
            </CommonButton>
        </div>
    );
} 