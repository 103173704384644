import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export default function CommonButton(props) {
  let background_color = '#F44434';
  let hover_background_color = 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F44434;';
  let font_color = '#ffffff';
  let box_shadow = '0px 0px 30px #F44434';
  let disabled_background_color = "rgb(31,31,31,0.5)";
  if (props.type == 'secondary'){
    background_color = '#ffffff';
    hover_background_color = '#ffffff';
    font_color = '#000000';
    box_shadow = 'none';
  }
  return (
    <StyledButton
      background_color={background_color}
      hover_background_color={hover_background_color}
      disabled_background_color={disabled_background_color}
      font_color={font_color}
      box_shadow={box_shadow}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  && {
    height: 48px;
    font-size: 17px;
    font-weight: 700;
    background: ${props => props.background_color};
    color: ${props => props.font_color};
    box-shadow: ${props => props.box_shadow};
    &:hover {
      background: ${props => props.hover_background_color};
      color: ${props => props.font_color};
    }
    &.MuiButton-root.Mui-disabled {
      background: ${props => props.disabled_background_color};
      box-shadow: none;
      color: white;
    }
  }
`;
