import styled from 'styled-components';
import Divider from '../components/common/dividers/CommonDivider';
import Grid from '@mui/material/Grid'
import { breakpoint } from '../utils/responsive';
import CommonText from '../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import Player from '../components/Player';
import { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';

export default function HowToWatch() {
    const { t, i18n } = useTranslation('howToWatch');
    const SPECLIST = Object.values(t("spec", { returnObjects: true }));
    const { isApp } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <StyledMainDiv>
            {!isApp && 
            <StyledTitleGrid>
                <CommonText style={{ textAlign: 'center', fontWeight: '600' }} sizeType='Heading1' type='display'>{t(`common.title`)}</CommonText>
            </StyledTitleGrid>
            }

            {!isApp && <Divider color="rgba(255, 255, 255, 0.5)"></Divider>}

            <StyledContentDiv>
                {!isApp && <StyledSuggestionDiv>{t(`common.suggestion`)}</StyledSuggestionDiv>}

                {/* INSERT PLAYER HERE */}
                {!isApp && <PlayerContainer>
                    <Player title="How to Watch" contentId="howtowatch" isMultiCDN={false} />
                </PlayerContainer>}

                <StyledSubTitleDiv>{t(`common.subTitle`)}</StyledSubTitleDiv>
                <div>
                    {
                        SPECLIST.map((item) => {
                            return (
                                <Grid container direction={'row'} >
                                    <StyledGridLeftDiv item xs>
                                        {item.title}
                                        <i>{item.title2}</i>
                                    </StyledGridLeftDiv>
                                    <StyledGridRightDiv item xs>
                                        {item.subtitle}
                                    </StyledGridRightDiv>
                                    <Divider style={{ margin: '15px 0px' }} color="rgba(255, 255, 255, 0.5)"></Divider>
                                </Grid>
                            )
                        })
                    }
                </div>

                <StyledRemarksDiv>
                    <ul>
                        <li>
                            {t("remarks.remark1")}
                        </li>

                        <li>
                            {t("remarks.remark2")}
                        </li>

                        <li>
                            {t("remarks.remark3")}
                        </li>

                        <li>
                            {t("remarks.remark4")}
                        </li>

                        <li>
                            {t("remarks.remark5")}
                        </li>

                        <li>
                            {t("remarks.remark6")}
                        </li>

                        <li>
                            {t("remarks.remark7")}
                        </li>

                        <li>
                            {t("remarks.remark8")}
                        </li>
                    </ul>
                </StyledRemarksDiv>
            </StyledContentDiv>
        </StyledMainDiv>
    )
}

const StyledMainDiv = styled.div`
    @media ${breakpoint.mobile} {
        padding: 30px 15px;
    }
    @media ${breakpoint.desktop} {
        padding: 60px 30px;
    }
    @media ${breakpoint.largeDesktop} {
        padding: 0 60px;
    }
`;

const PlayerContainer = styled.div`
    @media ${breakpoint.mobile} {
        width: 100%;
        height: 200px;
    }
    @media ${breakpoint.desktop} {
        width: 768px;
        height: calc(768px*0.5625);
    }
    @media ${breakpoint.largeDesktop} {
        width: 875px;
        height: calc(875px*0.5625);
    }
`
const StyledContentDiv = styled.div`
    margin: 0 auto;
    @media ${breakpoint.mobile} {
        width: 100%;
    }
    @media ${breakpoint.desktop} {
        width: 768px;
    }
    @media ${breakpoint.largeDesktop} {
        width: 875px;
    }
`;

const StyledTitleGrid = styled.div`
    margin-top: 60px;
    margin-bottom: 60px;
    @media ${breakpoint.mobile} {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;

const StyledSuggestionDiv = styled.div`
    text-align: center;
    font-weight: 700;
    color: rgba(244, 68, 52, 1);
    margin-bottom: 30px;
    line-height: 30px;
    @media ${breakpoint.mobile} {
        margin-top: 30px;
    }
    @media ${breakpoint.desktop} {
        margin-top: 30px;
    }
    @media ${breakpoint.largeDesktop} {
        margin-top: 60px;
    }
`;

const StyledSubTitleDiv = styled.div`
    text-align: left;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 23px;
    line-height: 26px;
`;

const StyledGridLeftDiv = styled(Grid)`
    text-align: left;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
    white-space: pre-line;
`;

const StyledGridRightDiv = styled(Grid)`
    text-align: right;
    color: rgba(255, 255, 255, 0.8);
    line-height: 28px;
    white-space: pre-line;
`;

const StyledRemarksDiv = styled.div`
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    line-height: 28px;
    margin-bottom: 18px;
`;