import { Grid, Divider, InputAdornment, IconButton, Box } from "@mui/material";
import CommonText from "../../components/common/typographies/CommonText";
import CommonTextField from "../../components/common/textfields/CommonTextField";
import AddCoupon from "../../assets/AddCoupon.svg";
import TagIcon from "../../assets/Tag.svg";
import CloseIcon from "../../assets/Close.svg";
import styled from "styled-components";
import { ResponsiveUI, breakpoint, WindowSize } from "../../utils/responsive";
import dayjs from "dayjs";
import dateString from "../../utils/dateString";
import { useMemo } from "react";

const LARGE_DESKTOP_BREAKPOINT = `(min-width: 1024px)`;

const AddButton = () => {
  return (
    <Box display="flex" flexDirection="row">
      <CommonText
        style={{ color: "#ffffff", marginRight: 4 }}
        fontSize="14px"
        lineHeight="20px"
        fontWeight="700"
      >
        加入
      </CommonText>
      <img src={AddCoupon} alt="AddCoupon" />
    </Box>
  );
};

const Tag = ({ label }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(255, 255, 255, 0.1)"
      style={{ height: 36, width: "fit-content", padding: `0px 10px` }}
    >
      <img
        src={TagIcon}
        alt="TagIcon"
        style={{ height: 16, width: 16, marginRight: 4 }}
      />
      <CommonText fontSize="12px" lineHeight="20px">
        {label}
      </CommonText>
      <img
        src={CloseIcon}
        alt="CloseIcon"
        style={{ height: 20, width: 20, marginLeft: 4, cursor: "pointer" }}
      />
    </Box>
  );
};

const OrderHeader = ({ t, language, currency, products }) => {
  const Header = () => {
    return (
      <OrderHeaderContainer
        container
        direction="row"
        style={{ padding: `15px 0px 0px 0px` }}
      >
        <Grid item xs>
          <WhiteOpacity60
            align="left"
            style={{ marginBottom: 15, whiteSpace: "nowrap" }}
          >
            {t("items")}
          </WhiteOpacity60>
        </Grid>
        <Grid item width={64}>
          <WhiteOpacity60
            style={{
              marginBottom: 15,
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            {t("quantity")}
          </WhiteOpacity60>
        </Grid>
        <Grid item width={80}>
          <WhiteOpacity60
            style={{
              marginBottom: 15,
              textAlign: "right",
              whiteSpace: "nowrap",
            }}
          >
            {t("price")}
          </WhiteOpacity60>
        </Grid>
      </OrderHeaderContainer>
    );
  };

  const OrderItem = ({ product, language }) => {
    const streamStart = product?.streaming?.streamStart
      ? dayjs(product?.streaming?.streamStart)
      : null;
    const title = useMemo(() => {
      if (language == "en") {
        if (product?.streaming?.event?.sponsorEn)
          return (
            product?.streaming?.event?.sponsorEn +
            " " +
            product?.streaming?.event?.eventNameEn
          );
        else return product?.streaming?.event?.eventNameEn;
      } else {
        if (product?.streaming?.event?.sponsorZh)
          return (
            product?.streaming.event?.sponsorZh +
            " " +
            product?.streaming?.event?.eventNameZh
          );
        else return product?.streaming?.event?.eventNameZh;
      }
    }, [product?.streaming?.event, language]);

    return (
      <OrderHeaderContainer
        container
        direction="row"
        style={{ padding: `15px 0px 0px 0px` }}
      >
        <Grid item xs>
          <CommonText
            align="left"
            fontSize="14px"
            lineHeight="20px"
            fontWeight="400"
            style={{ marginBottom: 5 }}
          >
            {title}
          </CommonText>
          <WhiteOpacity60 align="left" fontSize="14px" lineHeight="20px">
            {product?.option?.brand || ""}
          </WhiteOpacity60>
          <WhiteOpacity60
            align="left"
            fontSize="14px"
            lineHeight="20px"
            fontWeight="400"
            style={{ color: `rgba(255, 255, 255)` }}
          >
            {product?.option?.name[language] ? `${t("ticketType")}${product?.option?.name[language]}` : ""}
          </WhiteOpacity60>
          {streamStart && (
            <WhiteOpacity60
              align="left"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="400"
              style={{ color: `rgba(255, 255, 255)` }}
            >
              {`${t("streamStartDate")}${dateString(streamStart, language)}`}
            </WhiteOpacity60>
          )}
        </Grid>
        <Grid item width={64}>
          <CommonText style={{ textAlign: "center" }}>
            {product.quantity}
          </CommonText>
        </Grid>
        <Grid item width={80}>
          <CommonText style={{ textAlign: "right" }}>
            {currency && currency.replace("D", "$")}
            {product.option.discountedPrice != null ? product.option.discountedPrice : product.option.price}
          </CommonText>
        </Grid>
      </OrderHeaderContainer>
    );
  };

  return (
    <ResponsiveContainer container direction="column">
      <Header />
      {products.map((item) => {
        return <OrderItem language={language} product={item} />;
      })}
    </ResponsiveContainer>
  );
};

const Discount = ({ t }) => {
  return (
    <Grid container direction="column" style={{ padding: 20 }}>
      <Grid item>
        <CommonText align="left" style={{ marginBottom: 15 }}>
          {t("promotion")}
        </CommonText>
      </Grid>
      <Grid item style={{ marginBottom: 15 }}>
        <CommonTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="add coupon" edge="end">
                  <AddButton />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder={t("enterPromoCode")}
        />
      </Grid>
      <Grid item>
        <Tag label="WELCOME" />
      </Grid>
    </Grid>
  );
};

const Price = ({ t, currency, price }) => {
  return (
    <ResponsiveContainer container direction="column" style={{ padding: 15 }}>
      {/* <Grid container direction="row">
                <Grid item xs>
                    <CommonText align="left" fontSize="16px" lineHeight="24px" fontWeight="700" style={{ marginBottom: 15 }}>{t('subtotal')}</CommonText>
                </Grid>
                <Grid item>
                    <CommonText align="left" fontSize="16px" lineHeight="24px" fontWeight="700" style={{ marginBottom: 15 }}>{currency && currency.replace('D','$')}{price}</CommonText>
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item xs>
                    <CommonText align="left" fontSize="16px" lineHeight="24px" fontWeight="700" style={{ marginBottom: 15 }}>
                        {t('discount')}
                        <span style={{ marginLeft: 6, color: "rgba(255, 255, 255, 0.6)" }}>使用優惠碼: WELCOME10</span>
                    </CommonText>
                </Grid>
                <Grid item>
                    <CommonText align="left" fontSize="16px" lineHeight="24px" fontWeight="700" style={{ marginBottom: 15 }}>0</CommonText>
                </Grid>
            </Grid> */}

      <Grid container direction="row">
        <Grid item xs>
          <CommonText
            align="left"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="700"
            style={{ marginBottom: 15 }}
          >
            {t("total")}
          </CommonText>
        </Grid>
        <Grid item>
          <CommonText
            align="left"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="700"
            style={{ marginBottom: 15 }}
          >
            {currency && currency.replace("D", "$")}
            {price}
          </CommonText>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

export default function OrderInfo({ t, language, data }) {
  return (
    <Grid container direction="column" style={{ backgroundColor: "#1F1F21" }}>
      <OrderHeader
        t={t}
        language={language}
        currency={data?.basket?.currency || "HKD"}
        products={data?.basket?.items || []}
      />
      {/* <Divider />
            <Discount t={t} /> */}
      <Divider />
      <Price
        t={t}
        currency={data?.basket?.currency || "HKD"}
        price={data?.basket?.orderTotal || 0}
      />
    </Grid>
  );
}

const WhiteOpacity60 = styled(CommonText)`
  && {
    color: #fff;
    opacity: 0.6;
  }
`;

const OrderHeaderContainer = styled(Grid)`
  @media ${breakpoint.mobile} {
    && {
      padding: 15px 0px 0px 0px;
      column-gap: 30px;
    }
  }
  @media ${breakpoint.desktop} {
    && {
      padding: 20px 0px 0px 0px;
    }
  }
  @media ${LARGE_DESKTOP_BREAKPOINT} {
    && {
      padding: 20px 0px 0px 0px;
    }
  }
`;

const ResponsiveContainer = styled(Grid)`
  @media ${breakpoint.mobile} {
    && {
      padding: 15px;
    }
  }
  @media ${breakpoint.desktop} {
    && {
      padding: 20px;
    }
  }
  @media ${LARGE_DESKTOP_BREAKPOINT} {
    && {
      padding: 20px;
    }
  }
`;
