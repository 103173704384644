import WhiteTick from "../../assets/WhiteTick.svg"
import OrangeSquare from "../../assets/OrangeSquare.svg"
import styled from "styled-components"

export default function SmallTickIcon(props) {
  const {
    isChecked,
    onClick,
  } = props;
  return (
    <Container onClick={onClick} style={{ border:' 1px solid rgba(0, 0, 0, 1)', outline:'1px solid rgba(255, 255, 255, 0.5)'}}>
      <CircleDiv image={isChecked ? OrangeSquare : ''} {...props}>
      { isChecked && <Tick src={WhiteTick} {...props} /> }
      </CircleDiv>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: ${(props) => props.size || "25px"};
  height: ${(props) => props.size || "25px"};
  align-items: center;
  justify-content: center;
`
const CircleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.image});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: ${(props) => props.size || "25px"};
  height: ${(props) => props.size || "25px"};
`
const Tick = styled.img`
  width: ${(props) => props.size || "17px"};
  height: ${(props) => props.size || "17px"};
`
